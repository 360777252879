(function() {
    'use strict';

    angular.module('americanoApp').config(stateConfig);

    stateConfig.$inject = [ '$stateProvider' ];

    function stateConfig($stateProvider) {
    	$stateProvider
		.state('contract.distribution', {
            parent : 'entity',
            url : '/contract/distribution/{id_contract}?page&sort&search',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/contract/distribution/form-distribution.html',
                    controller : 'FormDistributionController',
                    controllerAs : 'vm'
                }
            },
            params : {
                page : {
                    value : '1',
                    squash : true
                },
                sort : {
                    value : 'id,desc',
                    squash : true
                },
                search : null
            },
            resolve : {
                translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('distribution');
                	return $translate.refresh();
                }],
                pagingParams : ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page : PaginationUtil
                            .parsePage($stateParams.page),
                        sort : $stateParams.sort,
                        predicate : PaginationUtil
                            .parsePredicate($stateParams.sort),
                        ascending : PaginationUtil
                            .parseAscending($stateParams.sort),
                        search : $stateParams.search
                    };
              	}],
            }
		})
		.state('contract.distribution.new', {
	        parent : 'contract.distribution',
	        url : '/new',
	        data : {
	            authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
	            pageTitle : 'americanoApp.form.detail.title'
	        },
	        onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/distribution/form-create-distribution.html',
                    controller : 'FormCreateDistributionController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('distribution');
                            return $translate.refresh();
                     	}],
                        formVendorSetDefaultAssignPermission : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_vendor_set_default_assign_permission"
                        	});
                        }],
                        formDistributionSpecificTicketDetailPlateStock : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_specific_ticket_detail_plate_stock"
                        	});
                        }],
                        formDistributionSpecificTicketDetailTicketingSegment : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_specific_ticket_detail_ticketing_segment"
                        	});
                        }],
                        formDistributionSpecificTicketDetailAdditionalTicketingInstruction : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_specific_ticket_detail_additional_ticketing_instruction"
                        	});
                        }],
                        formDistributionSpecificTicketDetailCommision : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_specific_ticket_detail_commision"
                        	});
                        }],
                        formDistributionSpecificTicketDetailBaggage : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_specific_ticket_detail_baggage"
                        	});
                        }],
                        formDistributionFareCreator : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_fare_creator"
                        	});
                        }],
                        formDistributionFareCreatorFareCalculation : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_fare_creator_fare_calculation"
                        	});
                        }],
                        formDistributionFareBox : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_farebox"
                        	});
                        }],
                        formDistributionTicketedFaresSabre : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_ticketed_fares_sabre"
                        	});
                        }],
                        formDistributionFareBoxPayment : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_payment"
                        	});
                        }],
                        formDistributionSpecificTicketDetailPassenger : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_specific_ticket_detail_passenger"
                            });
                        }],
                        formDistributionTextInformation : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "	distribution_text_information"
                            });
                        }],
                        passengers : [ 'PassengerCustom',function(PassengerCustom) {
                            return PassengerCustom.get();
                        }],
                        airlines : ['AirlineCustom', function(AirlineCustom) {
                        	return AirlineCustom.get();
                        }],
                        Currencies : ['CurrencyCustom', function(CurrencyCustom) {
                        	return CurrencyCustom.get();
                        }],
                        fare_type : [ 'FareTypeCustom', function(FareTypeCustom) {
                            return FareTypeCustom.get();
                        }],
                        routing_tariff_sabre : ['RoutingTariffSabreCustom', function(RoutingTariffSabreCustom) {
                            return RoutingTariffSabreCustom.get();
                        }],
        	            season_type : ['SeasonTypeCustom', function(SeasonTypeCustom) {
        					return SeasonTypeCustom.get();
        				}]
                    }
                }).result.then(function() {
                	$state.go('contract.distribution', null, { reload : true });
                }, function() {
                    $state.go('contract.distribution');
                });
            }]
		})
	    .state('contract.distribution.edit', {
	        parent : 'contract.distribution',
	        url : '/edit/{index}',
	        data : {
	            authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
	            pageTitle : 'americanoApp.form.detail.title'
	        },
	        onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	            $uibModal.open({
	                templateUrl : 'app/entities/contract/distribution/form-create-distribution.html',
	                controller : 'FormCreateDistributionController',
	                controllerAs : 'vm',
	                backdrop : 'static',
	                size : 'lg',
	                resolve : {
	                    translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
	                    	$translatePartialLoader.addPart('distribution');
	                        return $translate.refresh();
	                 	}],
	                    formVendorSetDefaultAssignPermission : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
	                    	return FormContract.get({
	                    		tag : "distribution_vendor_set_default_assign_permission"
	                    	});
	                    }],
	                    formDistributionSpecificTicketDetailPlateStock : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
	                    	return FormContract.get({
	                    		tag : "distribution_specific_ticket_detail_plate_stock"
	                    	});
	                    }],
	                    formDistributionSpecificTicketDetailTicketingSegment : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
	                    	return FormContract.get({
	                    		tag : "distribution_specific_ticket_detail_ticketing_segment"
	                    	});
	                    }],
                        formDistributionSpecificTicketDetailAdditionalTicketingInstruction : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
	                    	return FormContract.get({
	                    		tag : "distribution_specific_ticket_detail_additional_ticketing_instruction"
	                    	});
	                    }],
	                    formDistributionSpecificTicketDetailCommision : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
	                    	return FormContract.get({
	                    		tag : "distribution_specific_ticket_detail_commision"
	                    	});
	                    }],
	                    formDistributionSpecificTicketDetailBaggage : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
	                    	return FormContract.get({
	                    		tag : "distribution_specific_ticket_detail_baggage"
	                    	});
	                    }],
	                    formDistributionFareCreator : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
	                    	return FormContract.get({
	                    		tag : "distribution_fare_creator"
	                    	});
	                    }],
	                    formDistributionFareCreatorFareCalculation : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
	                    	return FormContract.get({
	                    		tag : "distribution_fare_creator_fare_calculation"
	                    	});
	                    }],
	                    formDistributionFareBox : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
	                    	return FormContract.get({
	                    		tag : "distribution_farebox"
	                    	});
	                    }],
	                    formDistributionTicketedFaresSabre : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({
                        		tag : "distribution_ticketed_fares_sabre"
                        	});
                        }],
	                    formDistributionFareBoxPayment : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
	                    	return FormContract.get({
	                    		tag : "distribution_payment"
	                    	});
	                    }],

                        formDistributionSpecificTicketDetailPassenger : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_specific_ticket_detail_passenger"
                            });
                        }],
                        formDistributionTextInformation : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "	distribution_text_information"
                            });
                        }],
                        passengers : [ 'PassengerCustom',function(PassengerCustom) {
                            return PassengerCustom.get();
                        }],
	                    airlines : ['AirlineCustom', function(AirlineCustom) {
	                    	return AirlineCustom.get();
	                    }],
	                    Currencies : ['CurrencyCustom', function(CurrencyCustom) {
	                    	return CurrencyCustom.get();
	                    }],
                        fare_type : [ 'FareTypeCustom', function(FareTypeCustom) {
                            return FareTypeCustom.get();
                        }],
                        routing_tariff_sabre : ['RoutingTariffSabreCustom', function(RoutingTariffSabreCustom) {
                            return RoutingTariffSabreCustom.get();
                        }],
        	            season_type : ['SeasonTypeCustom', function(SeasonTypeCustom) {
        					return SeasonTypeCustom.get();
        				}]
	                }
	            }).result.then(function() {
	            	$state.go('contract.distribution', null, { reload : true });
	            }, function() {
	                $state.go('contract.distribution');
	            });
	        }]
	    })
        .state('contract.distribution.copy', {
            parent : 'contract.distribution',
            url : '/{index}/copy',
            data : {
                authorities : [ 'ROLE_ADMIN', 'ROLE_USER','ROLE_ADMIN_USER' ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/distribution/form-distribution-copy-dialog.html',
                    controller : 'FormDistributionCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {
                    }
                }).result.then(function(result) {
                    $state.go('contract.distribution.edit', {index : result, id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('^');
                })
            }]
        })
	    .state('contract.distribution.delete', {
            parent : 'contract.distribution',
            url : '/{index}/{id}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/distribution/form-distribution-delete-dialog.html',
                    controller : 'FormDistributionDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                	$state.go('contract.distribution', {id_contract : $stateParams.id_contract}, { reload : true });
             	}, function() {
             		$state.go('^');
             	})
            }]
        })
    }
})();
