(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('GdsFormMapping', GdsFormMapping);

    GdsFormMapping.$inject = ['$resource', 'DateUtils'];

    function GdsFormMapping ($resource, DateUtils) {
        var resourceUrl =  'api/gds-form-mappings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.mappingCreatedDate = DateUtils.convertLocalDateFromServer(data.mappingCreatedDate);
                    data.mappingModifiedDate = DateUtils.convertLocalDateFromServer(data.mappingModifiedDate);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.mappingCreatedDate = DateUtils.convertLocalDateToServer(data.mappingCreatedDate);
                    data.mappingModifiedDate = DateUtils.convertLocalDateToServer(data.mappingModifiedDate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.mappingCreatedDate = DateUtils.convertLocalDateToServer(data.mappingCreatedDate);
                    data.mappingModifiedDate = DateUtils.convertLocalDateToServer(data.mappingModifiedDate);
                    return angular.toJson(data);
                }
            }
        });
    }
})();
