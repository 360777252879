(function() {
    'use strict';

   
//    var Date = function(arg){
//    	if(arg == null){
//      	   return new Date();
//    	}else{
//      	   return new Date(arg);
//    	}
// 	};
//     
// 	
// 	var opened = [];
// 	var openDatePicker = function($event, index) {
//        $event.preventDefault();
//        $event.stopPropagation();
//
//        opened[index] = true;
//    };
//    
//    var dateOptions = {
//	    minDate: new Date()
//	};
    var formRender = {
    		controller: function() {
    			 
    		},
    		template: '<div ng-repeat="item in $ctrl.form.form_items">'    		
    			
    			+'<div ng-if="item.type == \'text\'">'
		        +    '<label class="control-label">{{item.label}}</label>'
		        +    '<div class="input-group">'
		        +        '<input type="text" class="form-control" name="data{{$index}}" ng-model="item.value" validation-form />'
		        +    '</div>'
		        +'</div>'    			
    			
		        +'<div ng-if="item.type == \'dropdown\'">'
	            +    '<label class="control-label">{{item.label}}</label> '	
	            +   '<div class="input-group">'
	            +        '<select name="data{{$index}}" class="form-control" '
	            +        '   ng-model="item.value" validation-form>'
	            +        '    <option ng-repeat="options in item.options">{{options}}</option>'
	            +        '</select>'
	            +    '</div>'
	            +'</div>'
		        
	            +'<div ng-if="item.type == \'radio\'">'
				+	'<label ng-repeat="options in item.options" >'
				+	'<ul><input type="radio" name="data{{$index}}" class="form-control" ng-model="item.value" value="{{options}}" validation-form>'
				+	    '{{options}}'
				+	'</ul>'
				+	'</label>'
				+'</div>'				
	            
				+'<div ng-if="item.type == \'date\'" ng-init="item.value=vm.Date(item.value);">'
	            +    '<label class="control-label">{{item.label}}</label>'	                
	            +    '<div class="input-group">'
	            +        '<input '
	            +        'id="datePickerItem_{{$index}}"'
	            +        'type="text" class="form-control" name="data{{$index}}" uib-datepicker-popup="dd-MMM-yyyy" ng-model="item.value"'
	            +        'is-open="opened[$index]"'
	            +           'validation-form />'
	            +       '<span class="input-group-btn">'
	            +           '<button type="button" class="btn btn-default" ng-click="openDatePicker($event, $index)"><i class="glyphicon glyphicon-calendar"></i></button>'
	            +       '</span>'
	            +    '</div>'
	            +    
	            +   '<input type="date" name="data{{$index}}" class="form-control" ng-model="item.value" validation-form />'
	            +'</div>'
	            
	            /*
	            +'<div ng-if="item.type == \'dropdown_auto\'">'
	            +'    <label class="control-label">{{item.label}} </label>'	                
	            +'    <span ng-if="item.data[0].value == \'Airlines\'">'
	            +'    <div class="input-group">'
	            +'    	<input type="text" name="data{{$index}}" ng-model="item.value" validation-form uib-typeahead="o as o.airline_code+' - '+o.airline_name for o in vm.airlines | filter:$viewValue | limitTo:8" class="form-control">'
	            +'    </div>'
	            +'    </span>'
	            +'</div>'
	            
	            */
	            
	            +'<div class="alert-rules">'
	            +    '<span ng-show="editForm.data{{$index}}.$error.checkboxGroupMinRequired">Field cannot be empty</span>'
	            +    '<span ng-show="editForm.data{{$index}}.$error.mandatory">Field cannot be empty</span>'
	            +    '<span ng-show="editForm.data{{$index}}.$error.date">Not a valid date</span>'
	            +    '<span ng-show="editForm.data{{$index}}.$error.no_backdate">Should not back date</span>'
	            +    '<span ng-show="editForm.data{{$index}}.$error.valid">Field is not valid</span>'
	            +'</div>'
	            
		        +'</div>',

    	bindings: {
//            page: '<',
//            queryCount: '<total',
//            size: '<size'
        	form: '<',
        	index: '@'
        }
    };
    
    angular
        .module('americanoApp')
        .component('formRender', formRender);
})();
