(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('ContractUploaderExcelTemplate', ContractUploaderExcelTemplate);

    ContractUploaderExcelTemplate.$inject = ['$resource'];

    function ContractUploaderExcelTemplate ($resource) {
        var resourceUrl =  'api/contract-uploader-excel-template/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getFormTree': { url: 'api/contract-uploader-excel-template/form-tree', method: 'GET', isArray: true },
        });
    }
})();
