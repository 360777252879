(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('StayMeasurementFromAmadeusDeleteController',StayMeasurementFromAmadeusDeleteController);

    StayMeasurementFromAmadeusDeleteController.$inject = ['$uibModalInstance', 'entity', 'StayMeasurementFromAmadeus'];

    function StayMeasurementFromAmadeusDeleteController($uibModalInstance, entity, StayMeasurementFromAmadeus) {
        var vm = this;
        vm.stay_measurement_from_amadeus = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
        	StayMeasurementFromAmadeus.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
