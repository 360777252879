(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('PenaltyCopyDialogController', PenaltyCopyDialogController);

    PenaltyCopyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'ContractPenalties'];

    function PenaltyCopyDialogController ($scope, $stateParams, $uibModalInstance, ContractPenalties) {
    	var vm = this;
    	vm.id = $stateParams.id;
    	vm.index = $stateParams.index;

    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };

        vm.confirmCopy = function (id, index, lastIndex) {

            ContractPenalties.copy({id:$stateParams.id_contract, index:index}, function(data){
                $uibModalInstance.close(data.id);
            });

        	// Contract.get({id:$stateParams.id_contract}, function(data){
        	//
        	// 	data.data[18].pages.push(data.data[18].pages[index]);
            //
        	// 	Contract.update(data, function(result){
    	    // 		console.log("Update sukses");
    	    // 		$uibModalInstance.close(true);
    	    // 	}, function(error){
    	    // 		console.log("Update error ");
    	    // 		console.log(error);
    	    // 	});
        	// });
	    };
    }
})();
