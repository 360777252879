'use strict';

angular.module('americanoApp')
    .controller('LoginController', function ($rootScope, $scope, $state, $timeout, Auth) {
        $scope.user = {};
        $scope.errors = {};
		$scope.isFilled = false;
        $scope.rememberMe = true;
        $timeout(function (){angular.element('[ng-model="username"]').focus();});
        $scope.login = function (event) {
            event.preventDefault();
            Auth.login({
                username: $scope.username,
                password: $scope.password,
                rememberMe: $scope.rememberMe
            }).then(function () {
                $scope.authenticationError = false;
                $state.go('home', null,  { reload: true });
//                if ($rootScope.previousStateName === 'register') {
//                    $state.go('home');
//                } else {
//                    $rootScope.back();
//                }
            }).catch(function () {
                $scope.authenticationError = true;
            });
        };
		
		$scope.checkIsFilled = function() {
			if ($scope.username != null && $scope.username != '' && $scope.username != undefined) {
				if ($scope.username.length > 0)
					$scope.isFilled = true;
				else
					$scope.isFilled = false;	
					
				$scope.isFilled = true;	
			} else
				$scope.isFilled = false;
		};
    });
