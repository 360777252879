(function() {
	'use strict';

	angular.module('americanoApp').controller('GdsTemplateDialogController',
			GdsTemplateDialogController);

	GdsTemplateDialogController.$inject = [ '$scope', '$stateParams',
			'$uibModalInstance', 'entity', 'GdsTemplate', 'gdstypes',
			'DataUtils' ];

	function GdsTemplateDialogController($scope, $stateParams,
			$uibModalInstance, entity, GdsTemplate, gdstypes, DataUtils) {
		var vm = this;
		vm.gdsTemplate = entity;
		vm.gdstype = gdstypes;
		vm.load = function(id) {
			GdsTemplate.get({
				id : id
			}, function(result) {
				vm.gdsTemplate = result;
			});
		};

		var onSaveSuccess = function(result) {
			$scope.$emit('americanoApp:gdsTemplateUpdate', result);
			$uibModalInstance.close(result);
			vm.isSaving = false;
		};

		var onSaveError = function() {
			vm.isSaving = false;
		};

		vm.save = function() {
			vm.isSaving = true;
			if (vm.gdsTemplate.id !== null) {
				vm.gdsTemplate.templateModifiedDate = "0000-00-00";
				vm.gdsTemplate.templateModifiedBy = "-";
				GdsTemplate.update(vm.gdsTemplate, onSaveSuccess, onSaveError);
			} else {
				vm.gdsTemplate.templateCreatedDate = "0000-00-00";
				vm.gdsTemplate.templateCreatedBy = "-";
				GdsTemplate.save(vm.gdsTemplate, onSaveSuccess, onSaveError);
			}
		};

		vm.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};

		vm.datePickerOpenStatus = {};
		vm.datePickerOpenStatus.templateCreatedDate = false;
		vm.datePickerOpenStatus.templateModifiedDate = false;

		vm.openCalendar = function(date) {
			vm.datePickerOpenStatus[date] = true;
		};

		vm.setGdsFile = function($file, gdsTemplate) {
			if ($file) {
				vm.gdsTemplate.templateFileName = $file.name;

				DataUtils.toBase64($file, function(base64Data) {
					// console.log($file);
					$scope.$apply(function() {
						gdsTemplate.gdsFile = base64Data;
						gdsTemplate.gdsFileContentType = $file.type;
					});
				});
			}
		};

		vm.openFile = DataUtils.openFile;
		vm.byteSize = DataUtils.byteSize;

		$scope.$watch('vm.gdsTemplate.gdsTag', function() {
			try {
				if (typeof vm.gdsTemplate.gdsTag == 'undefined') {
					// does not exist
				} else {
					// does exist
					resultTemplateTag();
				}
			} catch (error) { /* ignore */
			}
		});

		$scope.$watch('vm.gdsTemplate.templateTag',
				function(newvalue, oldvalue) {
					if (vm.gdsTemplate.gdsTag != null
							&& vm.gdsTemplate.gdsTag.length > 0) {

						if (vm.gdsTemplate.templateName != null
								&& vm.gdsTemplate.templateName.length > 0) {
							if (newvalue != null
									&& newvalue.includes(vm.gdsTemplate.gdsTag
											+ "_")) {
								// nothing to do
							} else {
								vm.gdsTemplate.templateTag = oldvalue;
							}
						} else {

						}

					}
				}, true);

	
		function resultTemplateTag() {
			var rslt = "";
			if (vm.gdsTemplate.gdsTag != null
					&& vm.gdsTemplate.gdsTag.length > 0) {
				rslt = rslt + vm.gdsTemplate.gdsTag;
			}

			if (vm.gdsTemplate.gdsTag != null
					&& vm.gdsTemplate.gdsTag.length > 0) {
				rslt = rslt + "_";
			}

			vm.gdsTemplate.templateTag = rslt;
		}

	}
})();
