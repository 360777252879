(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('EndorsementController', EndorsementController);

    EndorsementController.$inject = [
           '$scope', '$parse', '$state', '$stateParams', '$uibModal',
           'ContractEndorsement', 'form1', 'ContractValue', '$uibModalInstance'];

    function EndorsementController (
    		$scope, $parse, $state, $stateParams, $uibModal,
    		ContractEndorsement, form1, ContractValue, $uibModalInstance) {

    	var vm = this;
    	vm.contractValue = ContractValue;
    	vm.entity = ContractEndorsement.get({ id : $stateParams.id_contract });
		vm.form1 = form1;
		vm.id_contract = $stateParams.id_contract;
//		vm.itemsPerPage = $stateParams.items_per_page;
//		vm.currentPage = 0;
//        vm.pageContent = [];
		vm.index = $stateParams.index;
//		 vm.index = 0;

		vm.entity.$promise.then(function(contract) {
    		vm.endorsmentRules = contract.data[13].pages;
//    		vm.totalItems = vm.endorsmentRules.length;
//    		vm.totalPages = Math.ceil(vm.totalItems / vm.itemsPerPage);
//            vm.lastIndex = vm.totalItems - 1;
//    		vm.setPageTo(1);

    		if (vm.index < vm.endorsmentRules.length)
    			vm.editEndorsements(vm.endorsmentRules[vm.index]);
    	});
		
		vm.setPageTo = function(page) {
			vm.pageContent = [];
			vm.limit = 0;
			vm.currentPage = page;
			vm.calculatePage = vm.currentPage - 1;

			vm.startIndex = (vm.totalItems - (vm.calculatePage * vm.itemsPerPage)) - 1;
			vm.limit = vm.startIndex - vm.itemsPerPage;

			if (vm.limit < 0)
				vm.limit = -1;

			for (var i = vm.startIndex; i > vm.limit; i--) {
				vm.pageContentStructure = {
						hasError: null,
						hasException : false,
						rule: vm.endorsmentRules[i],
                        index: vm.endorsmentRules[i].pagesIndex,
				}

				var hasError = false;
				
				if(vm.endorsmentRules[i].exception != null)
					vm.pageContentStructure.hasException = true;

				for (var j = 0; j < vm.endorsmentRules[i].forms.length; j++) {
					for (var k = 0; k < vm.endorsmentRules[i].forms[j].form_items.length; k++) {
						if (vm.endorsmentRules[i].forms[j].form_items[k].errorAPF.length > 0) {
							hasError = true;
							break;
						}
						if (vm.endorsmentRules[i].forms[j].form_items[k].errorAmadeus.length > 0) {
							hasError = true;
							break;
						}
						if (vm.endorsmentRules[i].forms[j].form_items[k].errorSabre.length > 0) {
							hasError = true;
							break;
						}
					}

					if (hasError)
						break;
				}

				vm.pageContentStructure.hasError = hasError;

				vm.pageContent.push(vm.pageContentStructure);
			}
		}

		vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
	    	};

     	vm.addEndorsements = function(){
			vm.entity.data[13].pages.push({
				forms:[
					angular.copy(vm.form1)
				]
			});
		}

		vm.editEndorsements = function(x){
     	    vm.form1.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            });
            // ContractValue.setValueFromDatabase(x.forms[0], vm.form1);

            // vm.form1 = angular.copy(x.forms[0]);

			vm.editObjectEndorsements = x;
			vm.isEndorsementsEdit = true;
		};

		vm.pushDataEndorsements = function(x, y){

            y[0].value = vm.dataEndorsements.rules_endorsements_endorsement_box_value
        	if(vm.form1.form_items[x].value == null){
        		vm.form1.form_items[x].value = [];
        	}
        	vm.form1.form_items[x].value.push(y);
        	var z = angular.copy(vm.form1.form_items[x].value);
        	vm.form1.form_items[x].value = z;

            vm.dataEndorsements = [];

        };

        vm.removeDataEndorsements = function(x, y){
        	vm.form1.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form1.form_items[x].value);
        	vm.form1.form_items[x].value = z;
        }

        vm.copyDataEndorsements = function(x, y){
        	vm.data = angular.copy(vm.form1.form_items[x].value[y]);        	
        	vm.form1.form_items[x].value.push(vm.data);
        	
        	var newItemIndex = vm.form1.form_items[x].value.length - 1;        	
        	
        	if (vm.isEdit == undefined || vm.isEdit == null) {
        		vm.isEdit = {}
        	}        	
        	
        	vm.isEdit[newItemIndex] = true;
        };
        
		vm.removeEndorsements = function(x){
			vm.entity.data[13].pages.splice(vm.entity.data[13].pages.indexOf(x), 1);
		};

		vm.saveEndorsementsEdit = function(){
			vm.entity.data[13].pages[vm.entity.data[13].pages.indexOf(vm.editObjectEndorsements)].forms[0] = angular.copy(vm.form1);
			vm.editObjectEndorsements = null;
			vm.isEndorsementsEdit = false;
		};

		vm.save = function(){
            var forms = [vm.form1];

            if (vm.isEndorsementsEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractEndorsement.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractEndorsement.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                });
            }

            // if(vm.isEndorsementsEdit)
				// vm.saveEndorsementsEdit();
            // else
				// vm.addEndorsements();
            //
            // ContractEndorsement.update(vm.entity, function(result){
	    	// 	$uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
        	$uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.countLetter = function(itemtag) {
        	if (vm.dataEndorsements[itemtag].length > 75)
        		vm.dataEndorsements[itemtag] = vm.dataEndorsements[itemtag].substring(0, 75);
        }

    }
})();
