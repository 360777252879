(function() {
    'use strict';
    /* globals SockJS, Stomp */

    angular
        .module('americanoApp')
        .factory('WebsocketService', WebsocketService);

    WebsocketService.$inject = ['$rootScope', '$window', '$cookies', '$http', '$q', '$localStorage'];

    function WebsocketService ($rootScope, $window, $cookies, $http, $q, $localStorage) {
        var stompClient = null;
        var notificationSubscriber = null;
        var activityListener = $q.defer();
        var notificationListener = $q.defer();
        var connected = $q.defer();
        var alreadyConnectedOnce = false;
        var currentLogin = null;

        var service = {
            connect: connect,
            disconnect: disconnect,
            receiveNotification: receiveNotification,
            sendNotification: sendNotification,
            subscribeNotification: subscribeNotification,
            unsubscribeNotification: unsubscribeNotification
        };

        return service;

        function connect(account) {
            if($localStorage.authenticationToken != undefined || $localStorage.authenticationToken != null) {
                //building absolute path so that websocket doesnt fail when deploying with a context path
                var loc = $window.location;
                var url = '//' + loc.host + loc.pathname + 'websocket/services';
                /*jshint camelcase: false */
                var authToken = angular.fromJson($localStorage.authenticationToken).access_token;
                url += '?access_token=' + authToken;
                var socket = new SockJS(url);
                stompClient = Stomp.over(socket);
                stompClient.debug = null;
                var stateChangeStart;
                var headers = {};
                stompClient.connect(headers, function () {
                    connected.resolve('success');
                    if (!alreadyConnectedOnce) {
                        stateChangeStart = $rootScope.$on('$stateChangeStart', function () {
                        });
                        alreadyConnectedOnce = true;
                    }
                });

                $rootScope.$on('$destroy', function () {
                    if(angular.isDefined(stateChangeStart) && stateChangeStart !== null){
                        stateChangeStart();
                    }
                });

                currentLogin = account;
            }
        }

        function disconnect() {
            if (stompClient !== null) {
                stompClient.disconnect();
                stompClient = null;
            }
        }

        //Receivers
        function receiveNotification() {
            return notificationListener.promise;
        }

        //Senders
        function sendNotification(notification) {
            if (stompClient !== null && stompClient.connected) {
                stompClient.send('/topic/notification/' + notification.user.login, {}, angular.toJson(notification));
            }
        }

        //Subscribers
        function subscribeNotification() {
            connected.promise.then(function() {
            	notificationSubscriber = stompClient.subscribe('/topic/notification/' + currentLogin.login, function(data) {
            		notificationListener.notify(angular.fromJson(data.body));
                });
            }, null, null);
        }

        //Unsubscribers
        function unsubscribeNotification() {
            if (notificationSubscriber !== null) {
            	notificationSubscriber.unsubscribe();
            }

            notificationListener = $q.defer();
        }
    }
})();
