(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('DiscountPassengerTypeDetailController', DiscountPassengerTypeDetailController);

    DiscountPassengerTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'DiscountPassengerType'];

    function DiscountPassengerTypeDetailController($scope, $rootScope, $stateParams, entity, DiscountPassengerType) {
        var vm = this;
        vm.discountPassengerType = entity;
        
        var unsubscribe = $rootScope.$on('americanoApp:discountPassengerTypeUpdate', function(event, result) {
            vm.discountPassengerType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
