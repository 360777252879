(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormFareDialogController', FormFareDialogController);

    FormFareDialogController.$inject = [
    '$scope', '$stateParams', 'ContractValue',
    'airlines', 'form1', 'form2', 'form3',
    'passengers', 'currencies', 'season_type', 'routing_tariff_amadeus', 'fare_type', 'fare_type_apf', 'transporting_class', '$uibModalInstance', 'ContractFare'];

    function FormFareDialogController (
    		$scope, $stateParams, ContractValue,airlines, form1, form2, form3,
    		passengers, currencies, season_type, routing_tariff_amadeus, fare_type, fare_type_apf, transporting_class, $uibModalInstance, ContractFare) {

    	var vm = this;

        vm.index = $stateParams.index;
    	vm.passengers = passengers;
		vm.airlines = airlines;
		vm.currencies = currencies;
		vm.season_type = season_type;
		vm.routing_tariff_amadeus = routing_tariff_amadeus;
		vm.fare_type = fare_type;
		vm.fare_type_apf = fare_type_apf;
		vm.transporting_class = transporting_class;
		vm.form1 = form1;
		vm.form2 = form2;
		vm.form3 = form3;
		vm.currentStep = "Flight Information";
		vm.contractValue = ContractValue;
		vm.classValue = "";
		vm.fareGroup = $stateParams.group;
        vm.isFareEdit = false;

		if (vm.index != undefined && vm.index != null && vm.index != "") {
            vm.isFareEdit = true;

        	vm.forms = ContractFare.get({ id : $stateParams.id_contract, index: vm.index });

        	vm.forms.$promise.then(function() {
    			if(vm.index != null){
                    vm.form1.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.forms.forms[0], vm.form1);
                    });
                    vm.form2.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.forms.forms[1], vm.form2);
                        console.log(vm.form2)
                    });
                    vm.form3.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.forms.forms[2], vm.form3);
                    });
    			}
    		});
        }

		vm.passengers.$promise.then(function(group) {
        	group.forEach(function(passenger) {
        		if (passenger.code == "ADT") {
        			vm.form1.$promise.then(function(form) {
        				for (var i = 0; i < form.form_items.length; i++) {
        					if (form.form_items[i].tag == "fare_flight_information_passenger_type") {
            					form.form_items[i].value = passenger;
            					break;
            				}
        				}
        			});
        		}
        	});
        });

		vm.uppercase = function(item){
		    if(item.tag == "fare_flight_information_origin" || item.tag == "fare_flight_information_destination"){
		        if(item.value != null) {
                    item.value = item.value.toUpperCase();
                }
            }
        }

		vm.save = function(){
            if(vm.isFareEdit) {
            	var forms = [vm.form1,vm.form2,vm.form3];

            	var newFare = {
            			forms: forms,
            			contractId: $stateParams.id_contract,
            			index: vm.index,
            			group: vm.fareGroup
            	};

                ContractFare.update(newFare, function (result, header) {
                    $uibModalInstance.close(result);
                }, function (response) {
                    alert(response.headers()["x-americanoapp-error"]);
                });
            }
            else {
            	var forms = [vm.form1,vm.form2,vm.form3];

            	var newFare = {
            			forms: forms,
            			contractId: $stateParams.id_contract,
            			group: vm.fareGroup
            	};

                ContractFare.save(newFare, function (result) {
                    $uibModalInstance.close(result);
                }, function (response) {
                    alert(response.headers()["x-americanoapp-error"]);
                });
            }
        };

        vm.separateClass = function(event, value) {
        	if (event.keyCode >= 65 && event.keyCode <= 90 && value.length < 3) {
        		var letter = event.key.toUpperCase();

        		if (value.length > 1) {
        			value = value.slice(0, -1);
        			value = value.trim();
        			value = value + "/" + letter;
        		} else
        			value = value.toUpperCase();
        	} else {
        		if (event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 16) {
            		if (value != undefined && value.charAt(value.length - 1) == "/")
            			value = value.substring(0, value.length - 1);

            	} else if (value != undefined && event.keyCode != 32)
        			value = value.substring(0, value.length - 1);
        	}

        	if (value != undefined) {
        		value.split("/").forEach(function(letter) {
            		if(letter.length > 1)
            			value = vm.classValue;
            	});
        	}

        	if (value == undefined || value == "")
        		vm.classValue = "";
        	else
        		vm.classValue = value;

        	return value;
        }

        vm.clear = function() {
            if($stateParams.duplicateFare){
                vm.confirmDelete($stateParams.id_contract, $stateParams.index);
                $uibModalInstance.dismiss('cancel');
            } else{
                $uibModalInstance.dismiss('cancel');
            }
        };

        $scope.test = function() {
        	console.log("called");
        }

        vm.confirmDelete = function (id, index) {
            ContractFare.delete({id:id, index: index}, function(result){
                $uibModalInstance.close(true);
            }, function(error){
            });
        };

        vm.Date = function(tag, arg){
            if(!vm.isFareEdit){
                if(arg == null){
                    if(tag == "fare_fare_information_effective_date"){
                        return new Date();
                    }
                    else if(tag == "fare_fare_information_discontinue_date"){
                        return "indef";
                    }
                }
            }
            else{
                if(arg == null){
                    return "indef";
                }else{
                    if(arg != "indef"){
                        return new Date(arg);
                    }else{
                        return "indef";
                    }
                }
            }
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened = [];
            $scope.opened[index] = true;
        };

        vm.checkValueApplyTo = function(value) {
            if (value == null || value == "" || value == undefined)
                return "From";

            return value;
        }
    }
})();
