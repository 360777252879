(function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('gds-form-mapping', {
            parent: 'entity',
            url: '/gds-form-mapping?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.gdsFormMapping.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/gds-form-mapping/gds-form-mappings.html',
                    controller: 'GdsFormMappingController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gdsFormMapping');
                    $translatePartialLoader.addPart('enumYesNo');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('gds-form-mapping-detail', {
            parent: 'entity',
            url: '/gds-form-mapping/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.gdsFormMapping.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/gds-form-mapping/gds-form-mapping-detail.html',
                    controller: 'GdsFormMappingDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gdsFormMapping');
                    $translatePartialLoader.addPart('enumYesNo');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'GdsFormMapping', function($stateParams, GdsFormMapping) {
                    return GdsFormMapping.get({id : $stateParams.id});
                }]
            }
        })
        .state('gds-form-mapping.new', {
            parent: 'gds-form-mapping',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gds-form-mapping/gds-form-mapping-dialog.html',
                    controller: 'GdsFormMappingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                mappingTag: null,
                                mappingName: null,
                                mappingFinalContent: null,
                                mappingCreatedDate: null,
                                mappingCreatedBy: null,
                                mappingModifiedDate: null,
                                mappingModifiedBy: null,
                                mappingActive: null,
                                gdsTag: null,
                                templateTag: null,
                                formTag: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('gds-form-mapping', null, { reload: true });
                }, function() {
                    $state.go('gds-form-mapping');
                });
            }]
        })
        .state('gds-form-mapping.edit', {
            parent: 'gds-form-mapping',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gds-form-mapping/gds-form-mapping-dialog.html',
                    controller: 'GdsFormMappingDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['GdsFormMapping', function(GdsFormMapping) {
                            return GdsFormMapping.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('gds-form-mapping', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('gds-form-mapping.delete', {
            parent: 'gds-form-mapping',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gds-form-mapping/gds-form-mapping-delete-dialog.html',
                    controller: 'GdsFormMappingDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['GdsFormMapping', function(GdsFormMapping) {
                            return GdsFormMapping.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('gds-form-mapping', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
