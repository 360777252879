(function() {
    'use strict';

    angular.module('americanoApp').config(stateConfig);

    stateConfig.$inject = [ '$stateProvider' ];

    function stateConfig($stateProvider) {
    	$stateProvider
    	.state('contract.routing', {
            parent : 'entity',
            url : '/contract/routing/{id_contract}?page&sort&search',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/contract/routing/form-routing.html',
                    controller : 'FormRoutingController',
                    controllerAs : 'vm'
                }
            },
            params : {
                page : {
                    value : '1',
                    squash : true
                },
                sort : {
                    value : 'id,desc',
                    squash : true
                },
                search : null
            },
            resolve : {
                translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('routing');
                	return $translate.refresh();
                }],
                entity : ['ContractRouting', '$stateParams', function(ContractRouting, $stateParams) {
                	return ContractRouting.get({id : $stateParams.id_contract});
                }],
                form : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
                	return FormContract.get({tag : "new_routing"});
                }],
                form_routing : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
                	return FormContract.get({tag : "routing_new"});
                }],
                form_routing_tariff : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
                	return FormContract.get({tag : "routing_new_routing_tariff"});
                }],
                form_routing_number : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
                	return FormContract.get({tag : "routing_new_routing_number"});
                }],
                form_routing_path : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
                	return FormContract.get({tag : "routing_new_routing_path"});
                }],
                form_routing_bottom : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
                	return FormContract.get({tag : "routing_new_bottom"});
                }],
                form_routing_restriction : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
                	return FormContract.get({tag : "routing_new_routing_restriction"});
                }],
                InfoText : [ 'InfoTextCustom', function(InfoTextsCustom) {
                	return InfoTextsCustom.get({tag : 'routing'});
             	}],
                pagingParams : ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                	return {
                		page : PaginationUtil.parsePage($stateParams.page),
                     	sort : $stateParams.sort,
                     	predicate : PaginationUtil.parsePredicate($stateParams.sort),
                      	ascending : PaginationUtil.parseAscending($stateParams.sort),
                       	search : $stateParams.search
                	};
                }],
            }
        })
        .state('contract.routing.new', {
            parent : 'contract.routing',
            url : '/new',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/routing/form-create-routing.html',
                    controller : 'FormCreateRoutingController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        entity : function() {
                            return { id : null };
                        },
                        airlines : ['AirlineCustom', function(AirlineCustom) {
                        	return AirlineCustom.get();
                     	}],
                        routing_tariff_amadeus : ['RoutingTariffAmadeusCustom', function(RoutingTariffAmadeusCustom) {
                        	return RoutingTariffAmadeusCustom.get();
                    	}],
                        routing_tariff_sabre : ['RoutingTariffSabreCustom', function(RoutingTariffSabreCustom) {
                        	return RoutingTariffSabreCustom.get();
                      	}],
                        form : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "new_routing" });
                    	}],
                        form_routing : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new" });
                      	}],
                        form_routing_tariff : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_tariff" });
                      	}],
                        form_routing_number : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_number" });
                        }],
                        form_routing_path : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_path" });
                      	}],
                        form_routing_bottom : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_bottom" });
                        }],
                        form_routing_restriction : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_restriction" });
                        }],
                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('routing');
                        	$translatePartialLoader.addPart('global');
                        	return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('contract.routing', null, { reload : true });
                }, function() {
                	$state.go('contract.routing');
                });
            }]
        })
        .state('contract.routing.edit', {
            parent : 'contract.routing',
            url : '/edit-routing/{id}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            	$uibModal.open({
                    templateUrl : 'app/entities/contract/routing/form-create-routing.html',
                    controller : 'FormCreateRoutingController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        entity : ['Routing', function(Routing) {
                        	return Routing.get({ id : $stateParams.id });
                    	}],
                    	airlines : ['AirlineCustom', function(AirlineCustom) {
                        	return AirlineCustom.get();
                     	}],
                        routing_tariff_amadeus : ['RoutingTariffAmadeusCustom', function(RoutingTariffAmadeusCustom) {
                        	return RoutingTariffAmadeusCustom.get();
                    	}],
                        routing_tariff_sabre : ['RoutingTariffSabreCustom', function(RoutingTariffSabreCustom) {
                        	return RoutingTariffSabreCustom.get();
                      	}],
                        form : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "new_routing" });
                    	}],
                        form_routing : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new" });
                      	}],
                        form_routing_tariff : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_tariff" });
                      	}],
                        form_routing_number : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_number" });
                        }],
                        form_routing_path : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_path" });
                      	}],
                        form_routing_bottom : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_bottom" });
                        }],
                        form_routing_restriction : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_restriction" });
                        }],
                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('routing');
                        	$translatePartialLoader.addPart('global');
                        	return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('contract.routing', null, { reload : true });
                }, function() {
                    $state.go('contract.routing');
                });
            }]
        })
        .state('contract.routing.contract-edit', {
            parent : 'contract.routing',
            url : '/edit/{index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/routing/form-create-routing.html',
                    controller : 'FormEditRoutingInContractController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        airlines : ['AirlineCustom', function(AirlineCustom) {
                        	return AirlineCustom.get();
                     	}],
                        routing_tariff_amadeus : ['RoutingTariffAmadeusCustom', function(RoutingTariffAmadeusCustom) {
                        	return RoutingTariffAmadeusCustom.get();
                    	}],
                        routing_tariff_sabre : ['RoutingTariffSabreCustom', function(RoutingTariffSabreCustom) {
                        	return RoutingTariffSabreCustom.get();
                      	}],
                        form : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "new_routing" });
                    	}],
                        form_routing : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new" });
                      	}],
                        form_routing_tariff : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_tariff" });
                      	}],
                        form_routing_number : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_number" });
                        }],
                        form_routing_path : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_path" });
                      	}],
                        form_routing_bottom : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_bottom" });
                        }],
                        form_routing_restriction : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({ tag : "routing_new_routing_restriction" });
                        }],
                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('routing');
                        	$translatePartialLoader.addPart('global');
                        	return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('contract.routing', null, { reload : true });
                }, function() {
                    $state.go('contract.routing');
                });
            }]
        })
        .state('contract.routing.delete', {
            parent : 'contract.routing',
            url : '/{id}/deactivate',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/routing/form-routing-delete-dialog.html',
                    controller : 'FormRoutingDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {
                        entity : ['Routing', function(Routing) {
                        	return Routing.get({ id : $stateParams.id });
                     	}]
                    }
                }).result.then(function(result) {
                	$state.go('contract.routing', { id_contract : $stateParams.id_contract }, { reload : true });
                }, function() {
                 	$state.go('^');
             	})
            }]
        })
        .state('contract.routing.copy', {
            parent : 'contract.routing',
            url : '/{id}/copy',
            data : {
                authorities : [ 'ROLE_ADMIN', 'ROLE_USER','ROLE_ADMIN_USER'],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
            	$uibModal.open({
                    templateUrl : 'app/entities/contract/routing/form-routing-copy-dialog.html',
                    controller : 'FormRoutingCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                	$state.go('contract.routing.edit', { id : result.id, id_contract : $stateParams.id_contract }, { reload : true });
                }, function() {
                    $state.go('^');
                })
            }]
        })     
    }
})();       