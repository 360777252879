(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TravelSegIndicatorDaysTimeDialogController', TravelSegIndicatorDaysTimeDialogController);

    TravelSegIndicatorDaysTimeDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'TravelSegIndicatorDaysTime'];

    function TravelSegIndicatorDaysTimeDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, TravelSegIndicatorDaysTime) {
        var vm = this;
        vm.travelSegIndicatorDaysTime = entity;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:travelSegIndicatorDaysTimeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.travelSegIndicatorDaysTime.id !== null) {
                TravelSegIndicatorDaysTime.update(vm.travelSegIndicatorDaysTime, onSaveSuccess, onSaveError);
            } else {
                TravelSegIndicatorDaysTime.save(vm.travelSegIndicatorDaysTime, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
