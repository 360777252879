(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TravelSegIndicatorAPFDeleteController',TravelSegIndicatorAPFDeleteController);

    TravelSegIndicatorAPFDeleteController.$inject = ['$uibModalInstance', 'entity', 'TravelSegIndicatorAPF'];

    function TravelSegIndicatorAPFDeleteController($uibModalInstance, entity, TravelSegIndicatorAPF) {
        var vm = this;
        vm.travelSegIndicatorAPF = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TravelSegIndicatorAPF.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
