(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormDialogController', FormDialogController);

    FormDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Form', 'Rule'];


    function FormDialogController ($scope, $stateParams, $uibModalInstance, entity, Form, Rule) {
        var vm = this;
        vm.form = entity;
        vm.load = function(id) {
            Form.get({id : id}, function(result) {
                vm.form = result;
            });
        };
        vm.inputs = [
   			'text', 'dropdown', 'date', 'textarea', 'radio', 'checkbox', 'number', 'datetime',
   			'dropdown_auto', 'multivalue', 'time'
   		];

        vm.inputsChild = [
            			'text', 'dropdown', 'date', 'textarea', 'radio', 'checkbox', 'number', 'datetime',
            			'dropdown_auto', 'multivalue'
            		];

        vm.inputsChildLvl2 = [
            			'text', 'dropdown', 'date', 'textarea', 'radio', 'checkbox', 'number', 'datetime',
            			'dropdown_auto'
            		];

        vm.dropdown_type = [
  		    'Airlines',
  		    'Time Zone',
  		    'Acting As',
  		    'Currency',
  		    'Discount Passenger Type',
  		    'Distribution Group',
  		    'Master Distribution Group',
  		    'Fare Type',
  		    'Fare Type APF',
  		    'Passenger',
  		    'Routing Tariff Sabre',
  		    'Routing Tariff Amadeus',
  		    'Season Type',
  		    'Surcharge Type',
  		    'Transporting Class',
  		    'Travel Segment Indicator Amadeus',
  		    'Travel Segment Indicator APF',
  		    'Travel Segment Indicator Sabre',
  		    'Stay Measured From Amadeus',
  		    'Stay Measured From APF - Minimum Stay',
  		    'Stay Measured From APF - Maximum Stay',
  		    'Stay Measured From Sabre',
  		    'Return Travel From Amadeus',
  		    'Return Travel From APF - Minimum Stay',
  		    'Return Travel From APF - Maximum Stay',
  		    'Return Travel From Sabre',
            'Zoning'
  		];

        vm.dropdown_model_distribution_group = ["name"];
        vm.dropdown_model_master_distribution_group = ["name"];
        
        vm.dropdown_model_stay_measured_from_amadeus = ["name", "description"];
        vm.dropdown_model_stay_measured_from_apf_minimum_stay = ["initial_code", "return_from_name"];
        vm.dropdown_model_stay_measured_from_apf_maximum_stay = ["initial_code", "return_from_name"];
        vm.dropdown_model_stay_measured_from_sabre = ["name", "description"];
        vm.dropdown_model_fare_type = ["initial_code", "fare_type"];
        vm.dropdown_model_fare_type_apf = ["initial_code", "fare_type_apf"];

        vm.dropdown_model_season_type = [
 	        'code',	
 	        'name'	
 	    ];
        vm.dropdown_model_acting_as = [
  	        'pcc_acting_as'
  	    ];
        
        vm.dropdown_model_time_zone = [
	        'country',
	        'value'
	    ];
        
        
        vm.dropdown_model_surcharge_type = [
	        'surcharge_type',
	    ];
        vm.dropdown_model_discount_passenger_type = [
	        'discount_passenger_type',
	    ];

        vm.dropdown_model_return_travel_apf_maximum_stay = [
          'initial_code',
          'return_from_name'
        ];
        vm.dropdown_model_return_travel_apf_minimum_stay = [
	          'initial_code',
	          'return_from_name'
	    ];

        vm.dropdown_model_return_travel_sabre = [
	          'return_travel_id',
	          'return_from_name'
	      ];

        vm.dropdown_model_return_travel_amadeus = [
	          'return_travel_id',
	          'return_from_name'
          ];

        vm.dropdown_model_currency = [
            'code',
            'name'
        ];

        vm.dropdown_model_passenger = [
            'code',
            'name'
        ];

        vm.dropdown_model_airlines = [
  		    'airline_code',
  		    'airline_name'
  		];

  		vm.dropdown_model_routing_tariff_amadeus = [
  		    'tariff_number',
    		'description'
  		];

  		vm.dropdown_model_routing_tariff_sabre = [
  		    'number',
  		    'description'
  		];
  		vm.dropdown_model_transporting_class = [
  		    'code',
  		    'description'
  		];
  		 vm.dropdown_model_travel_segment_amadeus = [
 		     'travel_segment_indicator_amadeus_id',
		     'travel_segment_indicator_amadeus_name'
 		 ];

  		 vm.dropdown_model_travel_segment_apf = [
  		    'travel_segment_indicator_apf_name',
  		];

  		 vm.dropdown_model_travel_segment_sabre = [
   		     'travel_segment_indicator_sabre_id',
  		     'travel_segment_indicator_sabre_name',
  		];

  		vm.input_options = ["a","b"];
  		vm.page = 1;

  		Rule.query({page: vm.page - 1, size: 99, query: vm.searchQuery},  function (result, headers) {
     		vm.validation_rules = result;
  		});

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:formUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.form.id !== null) {
                Form.update(vm.form, onSaveSuccess, onSaveError);
            } else {
                Form.save(vm.form, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

		vm.addInput = function(value){
			vm.form.form_items.push({type:value, options:[], form_items_child:[]});
		};

		vm.removeInput = function($index){
			vm.form.form_items.splice($index, 1);
		};

		vm.addInputChild = function(value, child){
			child.form_items_child.push({type:value, options:[]});
		};

		vm.removeInputChild = function($index, child){
			child.form_items_child.splice($index, 1);
		};

		vm.addOptions = function(index){
			var option = prompt("Add option");
			if(option){
				if(option != ""){
					vm.form.form_items[index].options.push(option);
				}else{
					alert("Option cannot be blank");
				}
			}
		};

		vm.removeOptions = function(parentIndex, index){
			vm.form.form_items[parentIndex].options.splice(index, 1);
		};

		vm.addOptionsChild = function(child){
			var option = prompt("Add option");
			if(option){
				if(option != ""){
					child.options.push(option);
				}else{
					alert("Option cannot be blank");
				}
			}
		};

		vm.removeOptionsChild = function(index, child){
			child.options.splice(index, 1);
		};
    }
})();
