(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FareTypeApfDeleteController',FareTypeApfDeleteController);

    FareTypeApfDeleteController.$inject = ['$uibModalInstance', 'entity', 'FareTypeApf'];

    function FareTypeApfDeleteController($uibModalInstance, entity, FareTypeApf) {
        var vm = this;
        vm.fareTypeApf = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            FareTypeApf.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
