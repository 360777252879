(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('DistributionGroup', DistributionGroup);

    DistributionGroup.$inject = ['$resource'];

    function DistributionGroup ($resource) {
        var resourceUrl =  'api/distribution-groups/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' },
            'copy': { method:'POST', url:'api/distribution-groups/copy/:id'}
        });
    }

    angular
        .module('americanoApp')
        .factory('DistributionGroupCustom', DistributionGroupCustom);

    DistributionGroupCustom.$inject = ['$resource'];

    function DistributionGroupCustom ($resource) {
        var resourceUrl =  'api/distribution-groups/getAllDistributionGroup';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
        .module('americanoApp')
        .factory('DistributionGroupSearch', DistributionGroupSearch);

    DistributionGroupSearch.$inject = ['$resource'];

    function DistributionGroupSearch ($resource) {
        var resourceUrl =  'api/_search/distribution-groups/:query/:type';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
        .module('americanoApp')
        .factory('DistributionSearchGds', DistributionSearchGds);

    DistributionSearchGds.$inject = ['$resource'];

    function DistributionSearchGds ($resource) {
        var resourceUrl =  'api/_search/distribution-groups/:query';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }


    angular
        .module('americanoApp')
        .factory('DistributionSearchMultiGds', DistributionSearchMultiGds);

    DistributionSearchMultiGds.$inject = ['$resource'];

    function DistributionSearchMultiGds ($resource) {
        var resourceUrl =  'api/_search/distribution-groups-multi/:query';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
    .module('americanoApp')
    .factory('DistributionGroupFindByGdsAndTypePcc', DistributionGroupFindByGdsAndTypePcc);

    DistributionGroupFindByGdsAndTypePcc.$inject = ['$resource'];

	function DistributionGroupFindByGdsAndTypePcc ($resource) {
	    var resourceUrl =  'api/distribution-groups/findAllByGdsAndTypePcc/:gds/:typePcc';

	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	                if (data) {
	                    data = angular.fromJson(data);
	                }
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
})();
