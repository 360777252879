(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('PenaltyDeleteDialogController', PenaltyDeleteDialogController);

    PenaltyDeleteDialogController.$inject = ['$scope', '$uibModalInstance', '$stateParams', 'ContractPenalties'];

    function PenaltyDeleteDialogController ($scope, $uibModalInstance, $stateParams, ContractPenalties) {
        var vm = this;
        vm.id = $stateParams.id_contract;
        // vm.index = JSON.parse("[" + $stateParams.index + "]");

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.confirmDelete = function () {
            ContractPenalties.delete({id:vm.id, index: $stateParams.index}, function(result){
                console.log("Update sukses");
                $uibModalInstance.close(true);
            }, function(error){
                console.log("Update error ");
                console.log(error);
            });
        };
    }
})();
