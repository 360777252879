(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormFareCalculatedDeleteDialogController', FormFareCalculatedDeleteDialogController);

    FormFareCalculatedDeleteDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'ContractFareCalculated'];

    function FormFareCalculatedDeleteDialogController ($scope, $stateParams, $uibModalInstance, ContractFareCalculated) {
    	var vm = this;
    	vm.id = $stateParams.id_contract;
    	vm.index = $stateParams.index;
    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };

        vm.confirmDelete = function () {
            ContractFareCalculated.delete({id:vm.id, index: vm.index}, function(result){
	    		console.log("Delete sukses");
	    		$uibModalInstance.close(true);
	    	}, function(error){
	    		console.log("Delete error ");
	    		console.log(error);
	    	});
	    };
    }
})();
