(function() {
	'use strict';

	angular.module('americanoApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider'];

	function stateConfig($stateProvider) {
		
		$stateProvider
			.state(
						'vendorAggregate',
						{
							parent : 'entity',
							url : '/vendorAggregate?page&sort&search',
							data : {
								authorities : [ 'ROLE_USER' ],
								pageTitle : 'americanoApp.form.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/vendor-aggregate/vendor-aggregates.html',
									controller : 'VendorAggregatesController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,desc',
									squash : true
								},
								search : null
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('vendor-aggregate');
											return $translate.refresh();
										} ],
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ],
							}
						})
				.state(
						'vendorAggregate.new',
						{
							parent : 'vendorAggregate',
							url : '/new',
							data : {
								authorities : [ 'ROLE_USER' ],
								pageTitle : 'americanoApp.form.detail.title'
							},
							onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				                $uibModal.open({
				                    templateUrl: 'app/entities/vendor-aggregate/vendor-aggregate-dialog.html',
				                    controller: 'VendorAggregateDialogController',
				                    controllerAs: 'vm',
				                    backdrop: 'static',
				                    size: 'lg',
				                    resolve : {
										translatePartialLoader : [
												'$translate',
												'$translatePartialLoader',
												function($translate,
														$translatePartialLoader) {
													$translatePartialLoader
															.addPart('vendor');
													$translatePartialLoader
															.addPart('vendor-aggregate');
													return $translate.refresh();
												} ],
										entity : [ '$stateParams',
												function($stateParams) {
													return {
														pcc : null,
														name : null,
														description : null,
														gds : null,
														option:null
													};
												}],
										pagingParams : [
														'$stateParams',
														'PaginationUtil',
														function($stateParams, PaginationUtil) {
															return {
																page : PaginationUtil
																		.parsePage($stateParams.page),
																sort : $stateParams.sort,
																currentStep : $stateParams.currentStep,
																predicate : PaginationUtil
																		.parsePredicate($stateParams.sort),
																ascending : PaginationUtil
																		.parseAscending($stateParams.sort),
																search : $stateParams.search
															};
														} ]
									}
				                }).result.then(function() {
				                    $state.go('vendorAggregate', null, { reload: true });
				                }, function() {
				                    $state.go('vendorAggregate');
				                });
				            }]
						})
				.state(
						'vendorAggregate.edit',
						{
							parent : 'vendorAggregate',
							url : '/edit/{id}',
							data : {
								authorities : [ 'ROLE_USER' ],
								pageTitle : 'americanoApp.form.detail.title'
							},
							onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				                $uibModal.open({
				                    templateUrl: 'app/entities/contract/vendor-aggregate.html',
				                    controller: 'VendorAggregateController',
				                    controllerAs: 'vm',
				                    backdrop: 'static',
				                    size: 'lg',
				                    resolve : {
										translatePartialLoader : [
												'$translate',
												'$translatePartialLoader',
												function($translate,
														$translatePartialLoader) {
													$translatePartialLoader
															.addPart('vendor');
													$translatePartialLoader
															.addPart('vendor-aggregate');
													return $translate.refresh();
												} ],
										entity : ['VendorAggregate',
												function(VendorAggregate) {
													return VendorAggregate.get({
														id : $stateParams.id
													});
												} ],
										pagingParams : [
														'$stateParams',
														'PaginationUtil',
														function($stateParams, PaginationUtil) {
															return {
																page : PaginationUtil
																		.parsePage($stateParams.page),
																sort : $stateParams.sort,
																currentStep : $stateParams.currentStep,
																predicate : PaginationUtil.parsePredicate($stateParams.sort),
																ascending : PaginationUtil.parseAscending($stateParams.sort),
																search : $stateParams.search
															};
														} ]
									}
				                }).result.then(function() {
				                    $state.go('vendorAggregate', null, { reload: true });
				                }, function() {
				                    $state.go('vendorAggregate');
				                });
				            }]
						})
				.state(
						'vendorAggregate.delete',
						{
							parent : 'vendorAggregate',
							url : '/{id}/delete',
							data : {
								authorities : [ 'ROLE_USER' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/vendor-aggregate/vendor-aggregate-delete-dialog.html',
													controller : 'VendorAggregateDeleteController',
													controllerAs : 'vm',
													size : 'md',
													resolve : {
														entity : [
																'VendorAggregate',
																function(
																		VendorAggregate) {
																	return VendorAggregate
																			.get({
																				id : $stateParams.id
																			});
																} ]
													}
												}).result
												.then(
														function() {
															$state
																	.go(
																			'vendorAggregate',
																			{
																				
																			},
																			{
																				reload : true
																			});
														}, function() {
															$state.go("^");
														});
									} ]
						});	
						
	}
})();