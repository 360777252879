(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('StopoverCopyDialogController', StopoverCopyDialogController);

    StopoverCopyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'ContractStopover'];

    function StopoverCopyDialogController ($scope, $stateParams, $uibModalInstance, ContractStopover) {
    	var vm = this;
    	vm.id = $stateParams.id;
    	vm.index = $stateParams.index;

    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };

        vm.confirmCopy = function (id, index, lastIndex) {
            ContractStopover.copy({id:$stateParams.id_contract, index:index}, function(data){
                $uibModalInstance.close(data.id);
            });
        	// Contract.get({id:$stateParams.id_contract}, function(data){
            //
        	// 	data.data[21].pages.push(data.data[21].pages[index]);
            //
        	// 	Contract.update(data, function(result){
    	    // 		console.log("Update sukses");
    	    // 		$uibModalInstance.close(true);
    	    // 	}, function(error){
    	    // 		console.log("Update error ");
    	    // 		console.log(error);
    	    // 	});
        	// });
	    };
    }
})();
