(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('LocationDialogController', LocationDialogController);

    LocationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Location'];

    function LocationDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Location) {
        var vm = this;

        vm.location = entity;
        vm.clear = clear;
        vm.save = save;

        var setParent = function(o){
  		     if(o.children != undefined){
  		    	 for(var x=0;x<o.children.length;x++){	   		    		 
//  		    		 var tempO = angular.copy(o);
//  		    		 tempO.children = null;
  		    		 o.children[x].parent = o;
  		    		 setParent(o.children[x]);
  		    	 }
  		     }
  		}
        
        var removeParent = function(o){
  		     if(o.children != undefined){
  		    	 for(var x=0;x<o.children.length;x++){	   		    		 
//  		    		 var tempO = angular.copy(o);
//  		    		 tempO.children = null;
  		    		 o.children[x].parent = null;
  		    		removeParent(o.children[x]);
  		    	 }
  		     }
  		}
        
        var removeData = function(o){
 		     if(o.children != undefined){
 		    	 for(var x=0;x<o.children.length;x++){	   		    		 
// 		    		 var tempO = angular.copy(o);
// 		    		 tempO.children = null;
 		    		 delete o.children[x].id;
 		    		 delete o.children[x].status;
 		    		removeParent(o.children[x]);
 		    	 }
 		     }
 		}
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function save () {
        	
        	removeParent($scope.roleList[0]);
	        
            vm.isSaving = true;
            if (vm.location.id !== null) {
                Location.update($scope.roleList[0], onSaveSuccess, onSaveError);
            } else {
                Location.save($scope.roleList[0], onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('americanoApp:locationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        /////////////////////////////////////////////////////////////////////////////////////////////////////////////
        
        //temporary node
        $scope.temporaryNode = {
            children: []
        };
    	
        
        
		$scope.roleList = [];
      	//test tree model
        if(vm.location.id !== null){
        	vm.location.$promise.then(function(loc){
//        		console.log(vm.location);
//        		delete loc.id;
        		delete loc.status;
        		
        		removeData(loc);
        		
        		
        		$scope.roleList.push(loc);
            	setParent($scope.roleList[0]);
            	
            	console.log($scope.roleList);
        	});
        }
        else{
	        $scope.roleList = [
	            { label : "TC1", type : "area", children : [
	              { label : "Central America", type : "zoning", children : [
	              	{ label : "Belize", type : "nation", children : [
	                   {label : "DTB", type : "city", children :[]}
	              	]},
	              	{ label : "Belize2", type : "nation", children : [
  	                   {label : "DTB2", type : "city", children :[]}
  	              	]}
	              ] },
	              { label : "Asia", type : "zoning", children : [
	              	{ label : "Indonesia", type : "nation", children : [
	                   {label : "Jakarta", type : "city", children :[]},
	                   {label : "Surabaya", type : "city", children :[]}
	              	]},
	              	{ label : "Singapura", type : "nation", children : [
   	                   {label : "Singapura", type : "city", children :[]},
   	                   {label : "China Town", type : "city", children :[]}
   	              	]},
   	              	
	              ] },
	            ]},
	        ];
	        
	        console.log($scope.roleList);
	        setParent($scope.roleList[0]);
        }
        $scope.done = function () {
              /* reset */
              $scope.mytree.currentNode.selected = undefined;
              $scope.mytree.currentNode = undefined;
              $scope.mode = undefined;
          };
          
          $scope.deleteChild = function(){
        	  
        	  var indexCity = 0;
        	  var indexZone = 0;
        	  var indexNation = 0;
        	  
        	  var objNation = null;
        	  var objZone = null;
        	  var objCity = null;
        	  
        	  var tempParent = $scope.mytree.currentNode.parent;
        	  while(tempParent != undefined){
//        		  console.log(tempParent.type);
        		  
        		  if(tempParent.type == 'nation'){
        			  objNation = tempParent;
        		  }
        		  else if(tempParent.type == 'zoning'){
        			  objZone = tempParent;
        		  }
        		  else if(tempParent.type == 'city'){
        			  objCity = tempParent;
        		  }
         		  tempParent = tempParent.parent;
         		 
//        		  console.log("INDEX "+indexOf());
        	  }
        	  
        	  console.log(objNation);
        	  console.log(objZone);
        	  console.log(objCity);
        	  
        	  
        	  
			  if($scope.mytree.currentNode.type == 'nation' || $scope.mytree.currentNode.type == 'NATION' || $scope.mytree.currentNode.type == 'Nation' ){
				  indexZone = $scope.roleList[0].children.indexOf(objZone);
				  indexNation = $scope.roleList[0].children[indexZone].children.indexOf($scope.mytree.currentNode);
	        	  
				  $scope.roleList[0].children[indexZone].children.splice(indexNation, 1);
        	  }
			  else if($scope.mytree.currentNode.type == 'zoning' || $scope.mytree.currentNode.type == 'ZONING' || $scope.mytree.currentNode.type == 'Zoning'){
        		  $scope.roleList[0].children.splice($scope.roleList[0].children.indexOf($scope.mytree.currentNode), 1);
        	  }
			  else if($scope.mytree.currentNode.type == 'city' || $scope.mytree.currentNode.type == 'CITY' || $scope.mytree.currentNode.type == 'City'){
	        	  indexZone = $scope.roleList[0].children.indexOf(objZone);
	        	  indexNation = $scope.roleList[0].children[indexZone].children.indexOf(objNation);
	        	  indexCity = $scope.roleList[0].children[indexZone].children[indexNation].children.indexOf($scope.mytree.currentNode);
	        	  
        		  $scope.roleList[0].children[indexZone].children[indexNation].children.splice(indexCity, 1);
        	  }
//        	  $scope.roleList[0].children[0].children.splice($scope.roleList[0].children.indexOf($scope.mytree.currentNode), 1);

         };
         
         
          $scope.addChildDone = function () {
              /* add child */
              if( $scope.temporaryNode.type && $scope.temporaryNode.label ) {
            	  console.log($scope.temporaryNode);
            	  $scope.mytree.currentNode.children.push( angular.copy($scope.temporaryNode) );
              }
              
              /* reset */
              $scope.temporaryNode.id = "";
              $scope.temporaryNode.label = "";
              
              $scope.done();
          };
      
    }
})();








