(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('ReturnTravelSabreDeleteController',ReturnTravelSabreDeleteController);

    ReturnTravelSabreDeleteController.$inject = ['$uibModalInstance', 'entity', 'ReturnTravelSabre'];

    function ReturnTravelSabreDeleteController($uibModalInstance, entity, ReturnTravelSabre) {
        var vm = this;
        vm.returnTravelSabre = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            ReturnTravelSabre.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
