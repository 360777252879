(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('AirlineTemplateRuleDeleteController',AirlineTemplateRuleDeleteController);

    AirlineTemplateRuleDeleteController.$inject = ['$uibModalInstance', 'entity', 'AirlineTemplateRule'];

    function AirlineTemplateRuleDeleteController($uibModalInstance, entity, AirlineTemplateRule) {
        var vm = this;
        vm.atr = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
        	AirlineTemplateRule.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
