(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('GdsTypeDetailController', GdsTypeDetailController);

    GdsTypeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'GdsType'];

    function GdsTypeDetailController($scope, $rootScope, $stateParams, entity, GdsType) {
        var vm = this;
        vm.gdsType = entity;
        vm.load = function (id) {
            GdsType.get({id: id}, function(result) {
                vm.gdsType = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:gdsTypeUpdate', function(event, result) {
            vm.gdsType = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
