(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('GdsTemplateDeleteController',GdsTemplateDeleteController);

    GdsTemplateDeleteController.$inject = ['$uibModalInstance', 'entity', 'GdsTemplate'];

    function GdsTemplateDeleteController($uibModalInstance, entity, GdsTemplate) {
        var vm = this;
        vm.gdsTemplate = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            GdsTemplate.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
