(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('StayMeasurementFromAmadeusDialogController', StayMeasurementFromAmadeusDialogController);

    StayMeasurementFromAmadeusDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'StayMeasurementFromAmadeus'];

    function StayMeasurementFromAmadeusDialogController ($scope, $stateParams, $uibModalInstance, entity, StayMeasurementFromAmadeus) {
        var vm = this;
        vm.stay_measurement_from_amadeus = entity;
        vm.load = function(id) {
        	StayMeasurementFromAmadeus.get({id : id}, function(result) {
                vm.stay_measurement_from_amadeus = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:stay_measurement_from_amadeusUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.stay_measurement_from_amadeus.id !== null) {
            	StayMeasurementFromAmadeus.update(vm.stay_measurement_from_amadeus, onSaveSuccess, onSaveError);
            } else {
            	StayMeasurementFromAmadeus.save(vm.stay_measurement_from_amadeus, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
