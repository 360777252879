(function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('contract-uploader-excel-template', {
            parent: 'entity',
            url: '/contract-uploader-excel-template?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Contract Uploader Excel Template'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract-uploader-excel-template/contract-uploader-excel-templates.html',
                    controller: 'ContractUploaderExcelTemplateController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('contract-uploader-excel-template.new', {
            parent: 'contract-uploader-excel-template',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract-uploader-excel-template/contract-uploader-excel-template-dialog.html',
                    controller: 'ContractUploaderExcelTemplateDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                category: null,
                                tagCriterias: []
                            };
                        },
                        formTree : ['ContractUploaderExcelTemplate', function(ContractUploaderExcelTemplate) {
                    		return ContractUploaderExcelTemplate.getFormTree();
                     	}]
                    }
                }).result.then(function() {
                    $state.go('contract-uploader-excel-template', null, { reload: true });
                }, function() {
                    $state.go('contract-uploader-excel-template');
                });
            }]
        })
//        .state('atr.edit', {
//            parent: 'atr',
//            url: '/{id}/edit',
//            data: {
//                authorities: ['ROLE_ADMIN']
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/entities/airline-template-rule/airline-template-rule-dialog.html',
//                    controller: 'AirlineTemplateRuleDialogController',
//                    controllerAs: 'vm',
//                    backdrop: 'static',
//                    size: 'lg',
//                    resolve: {
//                        entity: ['AirlineTemplateRule', function(AirlineTemplateRule) {
//                            return AirlineTemplateRule.get({id : $stateParams.id});
//                        }],
//                        airlines : ['AirlineCustom', function(AirlineCustom) {
//                    		return AirlineCustom.get();
//                     	}] 
//                    }
//                }).result.then(function() {
//                    $state.go('atr', null, { reload: true });
//                }, function() {
//                    $state.go('^');
//                });
//            }]
//        })
//        .state('atr.delete', {
//            parent: 'atr',
//            url: '/{id}/delete',
//            data: {
//                authorities: ['ROLE_ADMIN']
//            },
//            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//                $uibModal.open({
//                    templateUrl: 'app/entities/airline-template-rule/airline-template-rule-delete-dialog.html',
//                    controller: 'AirlineTemplateRuleDeleteController',
//                    controllerAs: 'vm',
//                    size: 'md',
//                    resolve: {
//                        entity: ['AirlineTemplateRule', function(AirlineTemplateRule) {
//                            return AirlineTemplateRule.get({id : $stateParams.id});
//                        }]
//                    }
//                }).result.then(function() {
//                    $state.go('atr', null, { reload: true });
//                }, function() {
//                    $state.go('^');
//                });
//            }]
//        });
    }

})();
