(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormRulesController', FormRulesController);

    FormRulesController.$inject = ['$q', '$scope', '$state', '$filter', '$rootScope', '$stateParams', 'FareSearch', 'ParseLinks', 'FareCustom', 'pagingParams', 'paginationConstants', 'AlertService', 'Contract', 'ContractRuleExist', 'PassengerTypeIsInFare'];

    function FormRulesController ($q, $scope, $state, $filter, $rootScope, $stateParams, FareSearch, ParseLinks, FareCustom, pagingParams, paginationConstants, AlertService, Contract, ContractRuleExist, PassengerTypeIsInFare) {
        var vm = this;
        vm.disableDiscountRule= false;
        vm.rulesData = [];
        vm.id_contract = $stateParams.id_contract;
        
        ContractRuleExist.get({contract_id : vm.id_contract}, 
        	function onSuccess(data, headers) {
        		angular.forEach(data, function(value, key){
        			vm.rulesData[key] = value;
        		});
			}
		);

        PassengerTypeIsInFare.get({contract_id : vm.id_contract, passenger_type:"student"}, 
        	function onSuccess(data) {
        		vm.disableDiscountRule = data.customBooleanVariableType;
			}
		);
        
        vm.ruleIsValidated = function(rule) {
        	return vm.rulesData[rule + "IsValidated"];
        }
        
        vm.ruleHasError = function(rule) {
        	return vm.rulesData[rule + "HasError"];
        }
        
        vm.ruleHasRecords = function(rule){
        	return vm.rulesData[rule];
        };

       vm.next = function(){
    	   console.log($rootScope.isCalculated);
    	   if($rootScope.isCalculated) {
               $("#tab4calculated").trigger("click");
           }
           else {
        	   $( "#tab5" ).trigger( "click" );
           }
       };
       
       vm.back = function(){
    	   if($rootScope.isCalculated) {
               $("#tab2calculated").trigger("click");
           }
           else {
        	   $( "#tab3" ).trigger( "click" );
           }
       };
    }
})();