(function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(paginationConfig);

    //Initial parameters uibPaginationConfig, paginationConstants
    function paginationConfig(uibPaginationConfig) {
        //uibPaginationConfig.itemsPerPage = paginationConstants.itemsPerPage;
        uibPaginationConfig.maxSize = 5;
        uibPaginationConfig.boundaryLinks = true;
//        uibPaginationConfig.firstText = '«';
//        uibPaginationConfig.previousText = '‹';
//        uibPaginationConfig.nextText = '›';
//        uibPaginationConfig.lastText = '»';
        uibPaginationConfig.firstText = 'First';
        uibPaginationConfig.previousText = 'Prev';
        uibPaginationConfig.nextText = 'Next';
        uibPaginationConfig.lastText = 'Last';
    }
})();
