(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('Form', Form);

    Form.$inject = ['$resource'];

    function Form ($resource) {
        var resourceUrl =  'api/forms/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
	    .module('americanoApp')
	    .factory('FormContract', FormContract);
	
	FormContract.$inject = ['$resource'];
	
	function FormContract ($resource) {
		var resourceUrl =  'api/forms/tag/:tag';
		
		return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	angular
	    .module('americanoApp')
	    .factory('FormExport', FormExport);
	
	 FormExport.$inject = ['$resource'];
	
	function FormExport ($resource) {
		var resourceUrl =  'api/forms/export';
		
		return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	angular
	    .module('americanoApp')
	    .factory('FormImport', FormImport);
	
	 FormImport.$inject = ['$resource'];
	
	function FormImport ($resource) {
		var resourceUrl =  'api/forms/import';
		
		return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	angular
	    .module('americanoApp')
	    .factory('FormPageAll', FormPageAll);
	
	FormPageAll.$inject = ['$resource'];
	
	function FormPageAll($resource) {
		var resourceUrl =  'api/forms/getAllForm';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	angular
	.module('americanoApp')
    .factory('FormSearch', FormSearch);

	FormSearch.$inject = ['$resource'];

	function FormSearch($resource) {
		var resourceUrl = 'api/_search/form/:query'; 
		return $resource(resourceUrl, {}, {
			'query': { method: 'GET', isArray: true}
		});
	}
})();
