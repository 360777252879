(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormDetailController', FormDetailController);

    FormDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Form',
                                    'airlines', 'routing_tariff_amadeus', 'routing_tariff_sabre'
                                    ];

    function FormDetailController($scope, $rootScope, $stateParams, entity, Form,
    		airlines, routing_tariff_amadeus, routing_tariff_sabre
    		) {
        var vm = this;
        vm.form = entity;
        vm.airlines = airlines;
		vm.routing_tariff_sabre = routing_tariff_sabre;
		vm.routing_tariff_amadeus = routing_tariff_amadeus;
		
        vm.load = function (id) {
            Form.get({id: id}, function(result) {
                vm.form = result;
            });
        };
        
        
        $scope.validate = function(){
        	console.log($scope.form);
        };
        
        
//        AirlineCustom.get(function(result){
//        	$scope.airlines = result;
//        });
//        
//        RoutingTariffSabreCustom.get(function(result){
//        	$scope.routing_tariff_sabre = result;
//        });
//        
//        RoutingTariffAmadeusCustom.get(function(result){
//        	$scope.routing_tariff_amadeus = result;
//        });
        
        
        var differenceInDays = function(firstdate, seconddate) {
            var dt1 = firstdate.split('/'),
                dt2 = seconddate.split('/'),
                one = new Date(dt1[2], dt1[1]-1, dt1[0]),
                two = new Date(dt2[2], dt2[1]-1, dt2[0]);

            var millisecondsPerDay = 1000 * 60 * 60 * 24;
            var millisBetween = two.getTime() - one.getTime();
            var days = millisBetween / millisecondsPerDay;

            return Math.floor(days);      
        };
        var validateTag = function(index, tag, value){
        	if(tag == "mandatory"){
        		if (value == undefined) {
        			pushError("Field "+vm.form.form_items[index].label+" cannot be empty");
                }
        		console.log("mandatory");
        	}
        	else if(tag == "no_backdate"){
        		var secondDate = $filter('date')(value, 'dd/MM/yyyy');
        		var firstDate = $filter('date')(new Date(), 'dd/MM/yyyy');
        		if(differenceInDays(firstDate, secondDate) < 0){
        			pushError("Field "+vm.form.form_items[index].label+" should not back date");
        		}
        	}
        };
        var pushError = function(message){
        	vm.alerts.push(
		    		AlertService.add(
		                    {
		                        type: "danger",
		                        msg: message,
		                        params: "",
		                        timeout: 5000,
		                        toast: AlertService.isToast(),
		                        scoped: true
		                    },
		                    $scope.alerts
		                )
		    	);
        };
        
        var unsubscribe = $rootScope.$on('americanoApp:formUpdate', function(event, result) {
            vm.form = result;
        });
        $scope.$on('$destroy', unsubscribe);

//        vm.datePickerOpenStatus = {};
//        vm.datePickerOpenStatus.data0 = false;
//        vm.datePickerOpenStatus.data1 = false;
//        vm.datePickerOpenStatus.data2 = false;
//        vm.datePickerOpenStatus.data3 = false;
//        vm.datePickerOpenStatus.data4 = false;
//        vm.datePickerOpenStatus.data5 = false;
//
//        vm.openCalendar = function(date) {
//            vm.datePickerOpenStatus[date] = true;
//        };
//        
        vm.pushData = function(x, y){
        	vm.form.form_items[x].value.push(y);
        	var z = angular.copy(vm.form.form_items[x].value);
        	vm.form.form_items[x].value = z;
        };
        
        vm.removeData = function(x, y){
        	vm.form.form_items[x].value.splice(vm.form.form_items[x].value.indexOf(y), 1);
        	var z = angular.copy(vm.form.form_items[x].value);
        	vm.form.form_items[x].value = z;
        }
        
        vm.Date = function(arg){
      	   return new Date(arg);
      	};
          
      	
         $scope.opened = [];
         $scope.openDatePicker = function($event, index) {
             $event.preventDefault();
             $event.stopPropagation();

             $scope.opened[index] = true;
         };
         
    }
})();
