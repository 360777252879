(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('AddOnController', AddOnController);

    AddOnController.$inject = ['$scope', '$state', 'AddOn', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'FormValue','AddOnSearch','AddOnSearch2'];

    function AddOnController ($scope, $state, AddOn, ParseLinks, AlertService, pagingParams, paginationConstants, FormValue,AddOnSearch,AddOnSearch2) {
        var vm = this;
        vm.sizeAmadeus = 5;
        vm.itemsPerPageAmadeus = 5;
        vm.sizeAPF = 5;
        vm.itemsPerPageAPF = 5;
        vm.searchResultItemsPerPage = 10;
        vm.loadAllAmadeus = loadAllAmadeus;
        vm.loadAllAPF = loadAllAPF;
        vm.transitionAmadeus = transitionAmadeus;
        vm.transitionAPF = transitionAPF;
        vm.formValue = FormValue;
        vm.addOnAmadeus = [];
        vm.addOnAPF = [];
        
        vm.loadAllAmadeus();
        vm.loadAllAPF();

        vm.searchTypeOptionsAPF =   [{key:'supplierID', value:'Supplier ID'},
                                     {key:'addOnID', value:'Add-on ID'},
                                     {key:'fareBasisCode', value:'Fare Basis Code'},
                                     {key:'ruleIDS', value:'Rule ID'} ];
        vm.currentSearch = "";
        
        
        vm.searchTypeOptionsAmadeus =   [{key:'typeOfJourney', value:'Type Of Journey'},
                                     {key:'endPointCityZone', value:'End Point'},
                                     {key:'locationGatewayCityZone', value:'Location'},
                                     {key:'gatewayCityZone', value:'Gateway'},
                                     {key:'ruleIDS', value:'Rule ID'} ];
        vm.currentSearchAmadeus = "";
        
        
        vm.setCurrentSearch = function(search) {
        	vm.currentSearch = search;
        }
        
        
        vm.setCurrentSearchAmadeus = function(search) {
        	vm.currentSearchAmadeus = search;
        }
        
        vm.runSearch = function() {
        	if (vm.searchQuery != "") {
        		vm.search();
        	}else{
        		vm.loadAllAPF();
        	}
        }
        
        vm.runSearchAmadeus = function() {
        	if (vm.searchQueryAmadeus != "") {
        		vm.searchAmadeus();
        	}else{
        		vm.loadAllAmadeus();
        	}
        }
        
        
        vm.search = function () {
        	AddOnSearch.query({
        		page: vm.pageAPF - 1,
                size: vm.itemsPerPageAPF,
                 query: encodeURIComponent(vm.searchQuery),
                 type: encodeURIComponent(vm.searchType)
        	}, onSuccess, onError);
        	
        	function onSuccess(data, headers) {
        		vm.linksAPF = ParseLinks.parse(headers('link'));
                vm.totalItemsAPF = headers('X-Total-Count');
                vm.queryCountAPF = vm.totalItemsAPF;
                vm.addOnAPF = data;
                vm.searchQuery = vm.currentSearch;
            }
            function onError(error) {
                vm.loadAllAPF();
            }
            
        };
        
        
        vm.searchAmadeus = function () {
        	AddOnSearch2.query({
        		page: vm.pageAmadeus - 1,
                size: vm.itemsPerPageAmadeus,
                 query: encodeURIComponent(vm.searchQueryAmadeus),
                 type: encodeURIComponent(vm.searchTypeAmadeus)
        	}, onSuccess, onError);
        	
        	function onSuccess(data, headers) {
        		vm.linksAmadeus = ParseLinks.parse(headers('link'));
                vm.totalItemsAmadeus = headers('X-Total-Count');
                vm.queryCountAmadeus = vm.totalItemsAmadeus;
                vm.addOnAmadeus = data;
                vm.searchQueryAmadeus = vm.currentSearchAmadeus;
            }
            function onError(error) {
                vm.loadAllAmadeus();
            }
            
        };
               
        
        function loadAllAmadeus () {
        	AddOn.getAmadeus({
                page: vm.pageAmadeus - 1,
                size: vm.itemsPerPageAmadeus
            }, onSuccess, onError);
            
            function onSuccess(data, headers) {
                vm.linksAmadeus = ParseLinks.parse(headers('link'));
                vm.totalItemsAmadeus = headers('X-Total-Count');
                vm.queryCountAmadeus = vm.totalItemsAmadeus;
                vm.addOnAmadeus = data;               
            }
            
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionAmadeus () {
        	if(vm.currentSearchAmadeus != ""){
        		vm.searchAmadeus();
        	}else{
        		vm.loadAllAmadeus();         	
            }
        }
        
     
        
        function loadAllAPF () {
        	AddOn.getAPF({
                page: vm.pageAPF - 1,
                size: vm.itemsPerPageAPF
            }, onSuccess, onError);
            
            function onSuccess(data, headers) {
                vm.linksAPF = ParseLinks.parse(headers('link'));
                vm.totalItemsAPF = headers('X-Total-Count');
                vm.queryCountAPF = vm.totalItemsAPF;
                vm.addOnAPF = data;
            }
            
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transitionAPF () {
        	if(vm.currentSearch != ""){
        		vm.search();
        	}else{
        		vm.loadAllAPF();
        	}
        	
        }

    }
})();
