(function () {
    'use strict';

    angular
        .module('americanoApp')
        .controller('RuleExceptionErrorController', RuleExceptionErrorController);

    RuleExceptionErrorController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$uibModalInstance'];

    function RuleExceptionErrorController ($rootScope, $scope, $state, $stateParams, $uibModalInstance) {
        var vm = this;
        
        vm.parent = $scope.$parent.vm;
        
        vm.ruleExceptions = [];
        vm.selectedRules = vm.parent.selectedRules;
        
        var exception = null;
        var rules = [];
        var object = {
        	rules: null,
        	exception: null
        }
        var exceptionInJSON = null;
        var i = null;
        var exist = false;
       
        vm.selectedRules.forEach(function(rule) {
        	exception = rule.exception;
        	exist = false;
        	exceptionInJSON = JSON.stringify(exception);
        	
        	for (i = 0; i < vm.ruleExceptions.length; i++) {
        		if (JSON.stringify(vm.ruleExceptions[i].exception) == exceptionInJSON){
        			exist = true;
        			break;
        		}
        	}
        	
        	if (!exist) {
        		rules.push(rule);
        		vm.temp = angular.copy(object);
        		vm.temp.rules = rules;
        		vm.temp.exception = exception;
        		vm.ruleExceptions.push(vm.temp);
        		rules = [];
        	} else 
        		vm.ruleExceptions[i].rules.push(rule);
        });
        
        vm.getRuleIndex = function(rule) {
        	return vm.parent.totalItems - vm.parent.getRuleIndex(rule);
        }
        
        vm.modifyException = function(ruleException){
        	vm.parent.selectedRules = ruleException.rules;
        	$uibModalInstance.dismiss();
        	vm.parent.openExceptionModal();
        }
        
        vm.modifyAllException = function(){
        	vm.parent.selectedRules = vm.selectedRules;
        	$uibModalInstance.dismiss();
        	vm.parent.openExceptionModal();
        }
        
        vm.close = function() {
        	$uibModalInstance.dismiss();
        	
        	vm.parent.resetCheckbox();
        }
    }
})();
