(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TourController', TourController);

    TourController.$inject = ['$scope', '$parse', '$state', '$stateParams', '$uibModal', '$uibModalInstance',
        'tours', 'package1', 'package2', 'tour_price', 'ground_cruise_segment','ContractValue', 'ContractTours', 'airlines', 'currencies', 'passenger_type', 'travelSegmentIndicatorSabre'];

    function TourController ($scope, $parse, $state, $stateParams, $uibModal, $uibModalInstance,
    		tours, package1, package2, tour_price, ground_cruise_segment, ContractValue, ContractTours, airlines, currencies, passenger_type, travelSegmentIndicatorSabre) {
    	var vm = this;
    	vm.travelSegmentIndicatorSabre = travelSegmentIndicatorSabre;
    	vm.airlines = airlines;
        vm.currencies = currencies;
        vm.passengers = passenger_type;
    	vm.contractValue = ContractValue;
    	vm.entity = ContractTours.get({ id : $stateParams.id_contract });
		vm.tours = tours;
		vm.package1 = package1;
		vm.package2 = package2;
		vm.tour_price = tour_price;
		vm.ground_cruise_segment = ground_cruise_segment;
		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;

		vm.entity.$promise.then(function(contract) {
    		vm.toursRules = contract.data[29].pages;

    		if (vm.index < vm.toursRules.length)
    			vm.editTours(vm.toursRules[vm.index]);
    	});

		vm.addTours = function(){
			vm.entity.data[29].pages.push({
				forms:[
					angular.copy(vm.tours),
					angular.copy(vm.package1),
					angular.copy(vm.package2),
					angular.copy(vm.tour_price),
                    angular.copy(vm.ground_cruise_segment)
				]
			});
		};

		vm.editTours = function(x){
		    vm.tours.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[0], vm.tours);
            });
		    vm.package1.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[1], vm.package1);
            });
		    vm.package2.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[2], vm.package2);
            });
		    vm.tour_price.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[3], vm.tour_price);
            });
		    vm.ground_cruise_segment.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[4], vm.ground_cruise_segment);
            });
			vm.editObjectTours = x;
			vm.isToursEdit = true;
		};

		vm.saveToursEdit = function(){
			vm.entity.data[29].pages[vm.entity.data[29].pages.indexOf(vm.editObjectTours)].forms[0] = angular.copy(vm.tours);
			vm.entity.data[29].pages[vm.entity.data[29].pages.indexOf(vm.editObjectTours)].forms[1] = angular.copy(vm.package1);
			vm.entity.data[29].pages[vm.entity.data[29].pages.indexOf(vm.editObjectTours)].forms[2] = angular.copy(vm.package2);
			vm.entity.data[29].pages[vm.entity.data[29].pages.indexOf(vm.editObjectTours)].forms[3] = angular.copy(vm.tour_price);
			vm.entity.data[29].pages[vm.entity.data[29].pages.indexOf(vm.editObjectTours)].forms[4] = angular.copy(vm.ground_cruise_segment);

			vm.editObjectTours = null;
			vm.isToursEdit = false;

		};

		vm.save = function(){
            var forms = [
                         vm.tours,
                         vm.package1,
                         vm.package2,
                         vm.tour_price,
                         vm.ground_cruise_segment
                         ];

            if (vm.isToursEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractTours.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                });
            }
            else{
                console.log("CREATE");

                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                console.log(newData);

                ContractTours.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                });
            }

        };

        vm.pushDataGroundCruiseSegments= function(x, y){
        	y[0].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_amount1;
            y[1].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_currency1;
            y[2].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_amount2;
            y[3].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_currency2;
            y[4].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_amount_apply_tag;
            y[5].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_min_num_of_ground_transfers;
            y[6].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_min_num_of_rental_days;
            y[7].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_min_num_of_days_at_resort;
            y[8].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_min_num_of_days_on_ship;
            y[9].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_min_num_of_days_to_be_spent;
            y[10].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_max_num_consecutive_free_days_permitted;
            y[11].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_tsi;
            y[12].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_type_location;
            y[13].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_location1;
            y[14].value = vm.dataGroundCruiseSegments.ground_cruise_segments_multivalue_location2;

            if(vm.ground_cruise_segment.form_items[x].value == null){
            	vm.ground_cruise_segment.form_items[x].value= [];
            }
            vm.ground_cruise_segment.form_items[x].value.push(y);
            var z = angular.copy(vm.ground_cruise_segment.form_items[x].value);
            vm.ground_cruise_segment.form_items[x].value = z;

            vm.dataGroundCruiseSegments = [];
        };

        vm.removeDataGroundCruiseSegments = function(x, y){
            vm.ground_cruise_segment.form_items[x].value.splice(y, 1);
            var z = angular.copy(vm.ground_cruise_segment.form_items[x].value);
            vm.ground_cruise_segment.form_items[x].value = z;
        };
        
        vm.copyDataGroundCruiseSegments = function(x, y){
        	vm.data = angular.copy(vm.ground_cruise_segment.form_items[x].value[y]);        	
        	vm.ground_cruise_segment.form_items[x].value.push(vm.data);
        	
        	var newItemIndex = vm.ground_cruise_segment.form_items[x].value.length - 1;        	
        	
        	if (vm.isEdit == undefined || vm.isEdit == null) {
        		vm.isEdit = {}
        	}        	
        	
        	vm.isEdit[newItemIndex] = true;
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.resetMinAppl = function() {
            vm.package1.form_items[0].value = null;
        };

        vm.resetAdditionalAppl = function() {
            vm.package1.form_items[3].value = null;
        };

        vm.resetPackage2MinAppl = function() {
            vm.package2.form_items[0].value = null;
        };

        vm.resetPackage2AdditionalAppl = function() {
            vm.package2.form_items[2].value = null;
        };

        vm.resetAmtApplTag = function() {
            vm.tour_price.form_items[4].value = null;
        };

        vm.resetRefundInd = function() {
            vm.tour_price.form_items[8].value = null;
        };

        vm.resetCruiseAmtApplTag = function() {
            vm.ground_cruise_segment.form_items[0].form_items_child[4].value = null;
        };

        vm.percentageCheck = function(tag) {
            var status = false;

            if(tag == 'master_tours_package1_package_percentage'){
                if(vm.package1.form_items[0].value != null && vm.package1.form_items[0].value != ''){
                    status = true;
                } else if(vm.package1.form_items[1].value != null && vm.package1.form_items[1].value != ''){
                    status = true;
                }
            } else if(tag == 'master_tours_package1_minimum_apply' || tag == 'master_tours_package1_minimum_amount'){
                if(vm.package1.form_items[2].value != null && vm.package1.form_items[2].value != ''){
                    status = true;
                }
            }

            return status;
        };

   }
})();
