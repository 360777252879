(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('VendorAggregateDeleteController',VendorAggregateDeleteController);

    VendorAggregateDeleteController.$inject = ['$uibModalInstance', 'entity', 'VendorAggregate'];

    function VendorAggregateDeleteController($uibModalInstance, entity, VendorAggregate) {
        var vm = this;
        vm.vendorAggregate = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.confirmDelete = function (id) {
        	VendorAggregate.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
