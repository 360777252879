(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('VoluntaryRefundController', VoluntaryRefundController);

    VoluntaryRefundController.$inject = ['$scope', '$parse', '$state', '$stateParams', '$uibModal', '$uibModalInstance',
        'voluntary_refund', 'advance_cancellation', 'originally_scheduled_flight_element', 'customer_first_element', 'condition_matching_element',
        'cancellation_indicator_element', 'flown_portion_repricing_element', 'repricing_rules_element', 'fare_class_or_family_element',
        'fare_type_application', 'charges_and_application_element', 'refund_carrier_application', 'time_matching_information', 'information_only',
        'ContractValue', 'ContractVoluntaryRefund', 'airlines', 'currencies', 'passenger_type', 'fare_type'];

    function VoluntaryRefundController ($scope, $parse, $state, $stateParams, $uibModal, $uibModalInstance,
                                        voluntary_refund, advance_cancellation, originally_scheduled_flight_element, customer_first_element, condition_matching_element,
                                        cancellation_indicator_element, flown_portion_repricing_element, repricing_rules_element, fare_class_or_family_element,
                                        fare_type_application, charges_and_application_element, refund_carrier_application, time_matching_information, information_only,
                                        ContractValue, ContractVoluntaryRefund, airlines, currencies, passenger_type, fare_type) {

    	var vm = this;
        vm.airlines = airlines;
        vm.currencies = currencies;
        vm.passengers = passenger_type;
    	vm.contractValue = ContractValue;
        vm.fare_type = fare_type;
    	vm.entity = ContractVoluntaryRefund.get({ id : $stateParams.id_contract });

		vm.voluntary_refund = voluntary_refund;
		vm.time_matching_information = time_matching_information;
		vm.advance_cancellation = advance_cancellation;
		vm.originally_scheduled_flight_element = originally_scheduled_flight_element;
		vm.customer_first_element = customer_first_element;
		vm.condition_matching_element = condition_matching_element;
		vm.cancellation_indicator_element = cancellation_indicator_element;
		vm.flown_portion_repricing_element = flown_portion_repricing_element;
		vm.repricing_rules_element = repricing_rules_element;
		vm.fare_class_or_family_element = fare_class_or_family_element;
		vm.fare_type_application = fare_type_application;
		vm.charges_and_application_element = charges_and_application_element;
		vm.refund_carrier_application = refund_carrier_application;
		vm.information_only = information_only;

		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;

		vm.entity.$promise.then(function(contract) {
    		vm.voluntaryRules = contract.data[27].pages
    		if (vm.index < vm.voluntaryRules.length)
    			vm.editVoluntary(vm.voluntaryRules[vm.index]);
    	});

		vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
	    	};

     	//Voluntary Refund
		vm.addVoluntary = function(){
			vm.entity.data[27].pages.push({
				forms:[
					angular.copy(vm.voluntary_refund),
					angular.copy(vm.time_matching_information),
					angular.copy(vm.advance_cancellation),
					angular.copy(vm.originally_scheduled_flight_element),
                    angular.copy(vm.customer_first_element),
                    angular.copy(vm.condition_matching_element),
                    angular.copy(vm.cancellation_indicator_element),
                    angular.copy(vm.flown_portion_repricing_element),
                    angular.copy(vm.repricing_rules_element),
                    angular.copy(vm.fare_class_or_family_element),
                    angular.copy(vm.fare_type_application),
                    angular.copy(vm.charges_and_application_element),
                    angular.copy(vm.refund_carrier_application),
                    angular.copy(vm.information_only)
				]
			});
		};

		vm.editVoluntary = function(x){
		    vm.voluntary_refund.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[0], vm.voluntary_refund);
            });
		    vm.time_matching_information.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[1], vm.time_matching_information);
            });
		    vm.advance_cancellation.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[2], vm.advance_cancellation);
            });
		    vm.originally_scheduled_flight_element.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[3], vm.originally_scheduled_flight_element);
            });
		    vm.customer_first_element.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[4], vm.customer_first_element);
            });
		    vm.condition_matching_element.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[5], vm.condition_matching_element);
            });
		    vm.cancellation_indicator_element.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[6], vm.cancellation_indicator_element);
            });
		    vm.flown_portion_repricing_element.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[7], vm.flown_portion_repricing_element);
            });
		    vm.repricing_rules_element.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[8], vm.repricing_rules_element);
            });
		    vm.fare_class_or_family_element.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[9], vm.fare_class_or_family_element);
            });
		    vm.fare_type_application.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[10], vm.fare_type_application);
            });
		    vm.charges_and_application_element.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[11], vm.charges_and_application_element);
            });
		    vm.refund_carrier_application.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[12], vm.refund_carrier_application);
            });
		    vm.information_only.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[13], vm.information_only);
            });

			vm.editObjectVoluntary = x;
			vm.isVoluntaryEdit = true;
		};

		vm.saveVoluntaryEdit = function(){
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[0] = angular.copy(vm.voluntary_refund);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[1] = angular.copy(vm.time_matching_information);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[2] = angular.copy(vm.advance_cancellation);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[3] = angular.copy(vm.originally_scheduled_flight_element);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[4] = angular.copy(vm.customer_first_element);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[5] = angular.copy(vm.condition_matching_element);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[6] = angular.copy(vm.cancellation_indicator_element);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[7] = angular.copy(vm.flown_portion_repricing_element);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[8] = angular.copy(vm.repricing_rules_element);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[9] = angular.copy(vm.fare_class_or_family_element);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[10] = angular.copy(vm.fare_type_application);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[11] = angular.copy(vm.charges_and_application_element);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[12] = angular.copy(vm.refund_carrier_application);
			vm.entity.data[27].pages[vm.entity.data[27].pages.indexOf(vm.editObjectVoluntary)].forms[13] = angular.copy(vm.information_only);

			vm.editObjectVoluntary = null;
			vm.isVoluntaryEdit = false;

		};

		//END Voluntary Refund

		vm.save = function(){
            var forms = [
                vm.voluntary_refund,
                vm.time_matching_information,
                vm.advance_cancellation,
                vm.originally_scheduled_flight_element,
                vm.customer_first_element,
                vm.condition_matching_element,
                vm.cancellation_indicator_element,
                vm.flown_portion_repricing_element,
                vm.repricing_rules_element,
                vm.fare_class_or_family_element,
                vm.fare_type_application,
                vm.charges_and_application_element,
                vm.refund_carrier_application,
                vm.information_only
            ];

            if (vm.isVoluntaryEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractVoluntaryRefund.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractVoluntaryRefund.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

        };

        // Multivalue Voluntary Refund Carrier Application
        vm.pushDataVRCarrierApp = function(x, y){
            y[0].value = vm.dataChangeCondition.rules_voluntary_refund_carrier_permitted;
            y[1].value = vm.dataChangeCondition.rules_voluntary_refund_carrier;

            if(vm.refund_carrier_application.form_items[x].value == null){
                vm.refund_carrier_application.form_items[x].value = [];
            }
            vm.refund_carrier_application.form_items[x].value.push(y);
            var z = angular.copy(vm.refund_carrier_application.form_items[x].value);
            vm.refund_carrier_application.form_items[x].value = z;

            vm.dataChangeCondition = [];
        };

        vm.removeDataVRCarrierApp = function(x, y){
            vm.refund_carrier_application.form_items[x].value.splice(y, 1);
            var z = angular.copy(vm.refund_carrier_application.form_items[x].value);
            vm.refund_carrier_application.form_items[x].value = z;
        };
        
        vm.copyDataVRCarrierApp = function(x, y){
        	vm.data = angular.copy(vm.refund_carrier_application.form_items[x].value[y]);        	
        	vm.refund_carrier_application.form_items[x].value.push(vm.data);
        	
        	var newItemIndex = vm.refund_carrier_application.form_items[x].value.length - 1;        	
        	
        	if (vm.isEditEmbeddedSurfaceSectors == undefined || vm.isEditEmbeddedSurfaceSectors == null) {
        		vm.isEditEmbeddedSurfaceSectors = {}
        	}        	
        	
        	vm.isEditEmbeddedSurfaceSectors[newItemIndex] = true;
        };
        // End Multivalue Voluntary Refund Carrier Application

        // Multivalue Refund Fare Type
        vm.pushDataRefundFareType = function(x, y){
            console.log(x);
            console.log(y);
            y[0].value = vm.dataConditionFareType.rules_voluntary_refund_fare_type_repricing;
            y[1].value = vm.dataConditionFareType.rules_voluntary_refund_fare_type;

            if(vm.fare_type_application.form_items[x].value == null){
                vm.fare_type_application.form_items[x].value = [];
            }
            vm.fare_type_application.form_items[x].value.push(y);
            var z = angular.copy(vm.fare_type_application.form_items[x].value);
            vm.fare_type_application.form_items[x].value = z;

            vm.dataConditionFareType = [];
        };

        vm.removeDataConditionFareType = function(x, y){
            vm.fare_type_application.form_items[x].value.splice(y, 1);
            var z = angular.copy(vm.fare_type_application.form_items[x].value);
            vm.fare_type_application.form_items[x].value = z;
        };
        
        vm.copyDataConditionFareType = function(x, y){
        	vm.data = angular.copy(vm.fare_type_application.form_items[x].value[y]);        	
        	vm.fare_type_application.form_items[x].value.push(vm.data);
        	
        	var newItemIndex = vm.fare_type_application.form_items[x].value.length - 1;        	
        	
        	if (vm.isEditEmbeddedSurfaceSectorsFT == undefined || vm.isEditEmbeddedSurfaceSectorsFT == null) {
        		vm.isEditEmbeddedSurfaceSectorsFT = {}
        	}        	
        	
        	vm.isEditEmbeddedSurfaceSectorsFT[newItemIndex] = true;
        };
        // End Multivalue Refund Fare Type

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.checkValueApplyTo = function(value) {
        	if (!vm.isVoluntaryEdit) {
        		if (value == null || value == "" || value == undefined)
                    return "Outbound and Inbound";
        	}

            return value;
        };

        vm.resetTicketValidityIndicator = function() {
            vm.time_matching_information.form_items[0].value = null;
        }

        vm.resetDepartureOfJourney = function() {
            vm.time_matching_information.form_items[6].value = null;
        }

        vm.resetDepartureOfFareComponent = function() {
            vm.time_matching_information.form_items[7].value = null;
        }

        vm.resetDepartureOfPricingUnit = function() {
            vm.time_matching_information.form_items[8].value = null;
        }

        vm.resetAdvanceReservationValidation = function() {
            vm.time_matching_information.form_items[9].value = null;
        }
        vm.findFormItemByTag = function(form, tag){
            var val = null;
            var found = false;
            form.form_items.forEach(function(data){
                if(data.tag == tag && !found){
                    val = data;
                    found = true;
                }
            });
            return val;
        };

      //Time
        $scope.ismeridian = false;
        $scope.hstep = 1;
        $scope.mstep = 15;

        $scope.options = {
          hstep: [1, 2, 3],
          mstep: [1, 5, 10, 15, 25, 30]
        };

        $scope.toggleMode = function() {
          $scope.ismeridian = ! $scope.ismeridian;
        };

        $scope.update = function() {
          var d = new Date();
          d.setHours( 14 );
          d.setMinutes( 0 );
          $scope.mytime = d;
        };

        $scope.changed = function () {
//          $log.log('Time changed to: ' + $scope.mytime);
        };

        $scope.clear = function() {
          $scope.mytime = null;
        };
    }
})();
