(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('CityController', CityController);

    CityController.$inject = ['$scope', '$state', 'City', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'CitySearch'];

    function CityController ($scope, $state, City, ParseLinks, AlertService, pagingParams, paginationConstants, CitySearch) {
        var vm = this;
        vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll();

        vm.search = function () {
        	CitySearch.query({
        		page: pagingParams.page - 1,
                size: vm.searchResultItemsPerPage,
                sort: sort(),
                query: vm.searchQuery
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.cities = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                vm.loadAll();
            }
        };
        
        function loadAll () {
            City.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.cities = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
        	pagingParams.page = vm.page;
        	
        	if (vm.searchQuery != undefined && vm.searchQuery != '') {
        		vm.search();
        	} else {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        	}
        }
        vm.checkSearchQuery = function() {
        	if (vm.searchQuery == "")
        		vm.loadAll();
        }
    }
})();
