(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('PenaltyController', PenaltyController);

    PenaltyController.$inject = ['$scope', '$parse', '$state', '$stateParams', '$uibModal', 'form1', 'form2', 'form3', 'form4', 'form5', 'currencies', '$uibModalInstance', 'ContractValue', 'ContractPenalties'];

    function PenaltyController ($scope, $parse, $state, $stateParams, $uibModal, form1, form2, form3, form4, form5, currencies, $uibModalInstance, ContractValue, ContractPenalties) {
    	var vm = this;
    	vm.contractValue = ContractValue;
    	vm.entity = ContractPenalties.get({ id : $stateParams.id_contract });
		vm.form1 = form1;
		vm.form2 = form2;
		vm.form3 = form3;
		vm.form4 = form4;
		vm.form5 = form5;
		vm.currencies = currencies;
		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;

		vm.entity.$promise.then(function(contract) {
    		vm.penaltiesRules = contract.data[18].pages;

    		if (vm.index < vm.penaltiesRules.length)
    			vm.editPenalty(vm.penaltiesRules[vm.index]);
    	});

		vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
	    	};

     	//PENALTIES
		vm.addPenalty = function(){
			vm.entity.data[18].pages.push({
				forms:[
					angular.copy(vm.form1),
					angular.copy(vm.form2),
					angular.copy(vm.form3),
					angular.copy(vm.form4),
					angular.copy(vm.form5)
				]
			});
		};

		vm.editPenalty = function(x){
            ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            ContractValue.setValueFromDatabase(x.forms[1], vm.form2);
            ContractValue.setValueFromDatabase(x.forms[2], vm.form3);
            ContractValue.setValueFromDatabase(x.forms[3], vm.form4);
            ContractValue.setValueFromDatabase(x.forms[4], vm.form5);

            // vm.form1 = angular.copy(x.forms[0]);
			// vm.form2 = angular.copy(x.forms[1]);
			// vm.form3 = angular.copy(x.forms[2]);
			// vm.form4 = angular.copy(x.forms[3]);
			// vm.form5 = angular.copy(x.forms[4]);

			vm.editObjectPenalty = x;
			vm.isPenaltyEdit = true;
		};

		vm.savePenaltyEdit = function(){
			vm.entity.data[18].pages[vm.entity.data[18].pages.indexOf(vm.editObjectPenalty)].forms[0] = angular.copy(vm.form1);
			vm.entity.data[18].pages[vm.entity.data[18].pages.indexOf(vm.editObjectPenalty)].forms[1] = angular.copy(vm.form2);
			vm.entity.data[18].pages[vm.entity.data[18].pages.indexOf(vm.editObjectPenalty)].forms[2] = angular.copy(vm.form3);
			vm.entity.data[18].pages[vm.entity.data[18].pages.indexOf(vm.editObjectPenalty)].forms[3] = angular.copy(vm.form4);
			vm.entity.data[18].pages[vm.entity.data[18].pages.indexOf(vm.editObjectPenalty)].forms[4] = angular.copy(vm.form5);
			vm.editObjectPenalty = null;
			vm.isPenaltyEdit = false;
		};

		//END PENALTIES

		vm.save = function(){
            var forms = [vm.form1, vm.form2, vm.form3, vm.form4, vm.form5];

            if (vm.isPenaltyEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractPenalties.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractPenalties.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isPenaltyEdit)
				// vm.savePenaltyEdit();
            // else
				// vm.addPenalty();
            //
            // ContractPenalties.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.resetForm2 = function() {
        	vm.form2.form_items.forEach(function(item) {
        		item.value = "";
        	});
        }

        vm.resetForm3 = function() {
        	vm.form3.form_items.forEach(function(item) {
        		item.value = "";
        	});
        }

        vm.resetForm5 = function() {
        	vm.form5.form_items.forEach(function(item) {
        		item.value = "";
        	});
        }

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.tagTab1 = ["rule_penalties_sector_type"];
        vm.tagTabTextOnly = ["rule_penalties_text_information_only", "rule_penalties_note_amadeus", "rule_penalties_note_apf", "rule_penalties_note_sabre", "rule_penalties_note", "rule_penalties_override"];
    }
})();
