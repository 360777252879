(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormEditRoutingInContractController', FormEditRoutingInContractController);

    FormEditRoutingInContractController.$inject = [
    '$scope', '$stateParams', '$state', 'airlines', 'routing_tariff_amadeus', 'routing_tariff_sabre','ContractRouting',
        'ContractFareOriginDestination',
        'form',
        'form_routing',
        'form_routing_tariff',
        'form_routing_number',
        'form_routing_path',
        'form_routing_bottom',
        'form_routing_restriction',
        '$uibModalInstance', 'ContractValue'];

    function FormEditRoutingInContractController (
    		$scope, $stateParams, $state, airlines, routing_tariff_amadeus, routing_tariff_sabre, ContractRouting,
            ContractFareOriginDestination,
            form, // tag: new_routing
            form_routing, // tag: routing_new
            form_routing_tariff,
            form_routing_number,
            form_routing_path,
            form_routing_bottom,
            form_routing_restriction,
            $uibModalInstance,
            ContractValue) {
    	var vm = this;

    	vm.form = form;
        vm.form_routing = form_routing;
        vm.form_routing_tariff = form_routing_tariff;
        vm.form_routing_number = form_routing_number;
        vm.form_routing_path = form_routing_path;
        vm.form_routing_bottom = form_routing_bottom;
        vm.form_routing_restriction = form_routing_restriction;

    	vm.index = $stateParams.index;
        vm.contract =  ContractRouting.get({ id : $stateParams.id_contract });
		vm.airlines = airlines;
		vm.routing_tariff_sabre = routing_tariff_sabre;
		vm.routing_tariff_amadeus = routing_tariff_amadeus;

		if(vm.contract.$promise!=null){
	        vm.contract.$promise.then(function(result){
	        	if(vm.contract.data[2].pages.length != 0){
					// vm.form = vm.contract.data[2].pages[vm.index].forms[0];
                    vm.form_routing.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[0], vm.form_routing);
                    });
                    vm.form_routing_tariff.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[1], vm.form_routing_tariff);
                    });
                    vm.form_routing_number.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[2], vm.form_routing_number);
                    });
                    vm.form_routing_path.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[3], vm.form_routing_path);
                    });
                    vm.form_routing_bottom.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[4], vm.form_routing_bottom);
                    });
                    vm.form_routing_restriction.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[5], vm.form_routing_restriction);
                    });

                    // ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[0], vm.form_routing);
                    // ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[1], vm.form_routing_tariff);
                    // ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[2], vm.form_routing_number);
                    // ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[3], vm.form_routing_path);
                    // ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[4], vm.form_routing_bottom);
                    // ContractValue.setValueFromDatabase(vm.contract.data[2].pages[vm.index].forms[5], vm.form_routing_restriction);

                    // vm.form_routing = vm.contract.data[2].pages[vm.index].forms[0];
                    // vm.form_routing_tariff = vm.contract.data[2].pages[vm.index].forms[1];
                    // vm.form_routing_number = vm.contract.data[2].pages[vm.index].forms[2];
					// vm.form_routing_path = vm.contract.data[2].pages[vm.index].forms[3];
					// vm.form_routing_bottom = vm.contract.data[2].pages[vm.index].forms[4];
					// vm.form_routing_restriction = vm.contract.data[2].pages[vm.index].forms[5];
				}
			});
		}

        vm.save = function(){
			if(vm.contract.$promise != null){
				// vm.contract.data[2].pages[vm.index].forms[0] = vm.form_routing;
				// vm.contract.data[2].pages[vm.index].forms[1] = vm.form_routing_tariff;
				// vm.contract.data[2].pages[vm.index].forms[2] = vm.form_routing_number;
				// vm.contract.data[2].pages[vm.index].forms[3] = vm.form_routing_path;
				// vm.contract.data[2].pages[vm.index].forms[4] = vm.form_routing_bottom;
				// vm.contract.data[2].pages[vm.index].forms[5] = vm.form_routing_restriction;

                var forms = [vm.form_routing, vm.form_routing_tariff, vm.form_routing_number, vm.form_routing_path, vm.form_routing_bottom, vm.form_routing_restriction];

                var routingObject = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

				ContractRouting.update(routingObject, function(result){
					$uibModalInstance.close(result);
		    		console.log("Save sukses");
		    	}, function(error){
		    		console.log("Save error ");
		    		console.log(error);
		    	});
			}
        };

        vm.check = function(field){
            if(vm.dataRoutingRestriction != null) {
                if (vm.dataRoutingRestriction.restriction == "1. Travel Between Via City") {
                    if (field == "a" || field == "b" || field == "e" || field == "f") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "2. Travel Via City") {
                    if (field == "e" || field == "f") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "3. Travel Nonstop/Direct") {
                    if (field == "e" || field == "g") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "4. Travel Between Nonstop/Direct") {
                    if (field == "a" || field == "b" || field == "e" || field == "g") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "5. Travel To/From Via City") {
                    if (field == "c" || field == "e" || field == "f") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "6. Travel To/From Nonstop/Direct") {
                    if (field == "c" || field == "e" || field == "g") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "7. Stopover") {
                    if (field == "a" || field == "b" || field == "h" || field == "i") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "8. Surface/Air") {
                    if (field == "a" || field == "b" || field == "j" || field == "i") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "9. Carrier/Alliance") {
                    if (field == "d") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "10. Stopover (In)") {
                    if (field == "h" || field == "i") {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        };

        vm.generateMapDetail = function() {
        	if (document.getElementById("generated_route_textarea").className != null) {
    			document.getElementById("generated_route_textarea").className = document.getElementById("generated_route_textarea").className.replace(/(?:^|\s)hasData(?!\S)/g , '');
        		document.getElementById("generated_route_textarea").className += " hasData";
    		}

            var leftToRight = false;
            for(var x=0;x<vm.form_routing_path.form_items.length;x++){
                var tag = vm.form_routing_path.form_items[x].tag;
                if(tag == "routing_new_routing_path_left_to_right"){
                    var value = vm.form_routing_path.form_items[x].value;
                    if(value == 'Map Applies Left to Right Only'){
                        leftToRight = true;
                    }
                }
            }

            for(var x=0;x<vm.form_routing_path.form_items.length;x++){
                var tag = vm.form_routing_path.form_items[x].tag;
                if(tag == "routing_new_route_path_multivalue"){
                    var multiValue = vm.form_routing_path.form_items[x].value;
                    if(multiValue != null) {
                        var origin = [];
                        var via = [];
                        var destination = [];
                        for(var y=0;y<multiValue.length;y++){
                            angular.forEach(multiValue[y], function(value, key) {
                                if(value.tag == "routing_new_route_path_boarding_point"){
                                    if(value.value != null  || value.value != undefined) {
                                        origin.push(value.value);
                                    }
                                }
                                else if(value.tag == "routing_new_route_path_map_via") {
                                    via.push(value.value);
                                }
                                else if(value.tag == "routing_new_route_path_off_point"){
                                    if(value.value != null  || value.value != undefined)
                                        destination.push(value.value);
                                }
                            });
                        }
                        if(!leftToRight){
                            //get from fare
                            ContractFareOriginDestination.query({ id : $stateParams.id_contract },
                                function(data){
                                    for(var x=0;x<data.length;x++){
                                        origin.push(data[x].origin);
                                        destination.push(data[x].destination);
                                    }

                                    var size = origin.length > destination.length ? origin.length :   destination.length;
                                    var stringLine = [];
                                    for(var i=0;i<origin.length;i++){
                                        stringLine.push("*"+origin[i]+"-|");
                                    }
                                    for(var i=0;i<size;i++){
                                        if(via[i] != null && via[i] != "'") {
                                            stringLine[i] = stringLine[i] + "-" + via[i]+"-|";
                                        }
                                        else{
                                            stringLine[i] = stringLine[i] + "     |";
                                        }
                                    }
                                    for(var i=0;i<destination.length;i++){
                                        if(destination[i] != null) {
                                            stringLine[i] = stringLine[i] + "-" + destination[i]+"*";
                                        }
                                    }

                                    vm.mapDetail = "";
                                    for(var i=0;i<stringLine.length;i++){
                                    	if (vm.mapDetail.length == 0)
                                    		vm.mapDetail = vm.mapDetail+stringLine[i];
                                    	else
                                    		vm.mapDetail = vm.mapDetail+"\n"+stringLine[i];
                                    }
                                }, function(err){
                                }
                            );
                        }
                        else{
                            var stringLine = [];
                            for(var i=0;i<origin.length;i++){
                                stringLine.push("*"+origin[i]+"-|");
                            }
                            for(var i=0;i<via.length;i++){
                                if(via[i] != null && via[i] != "'") {
                                    stringLine[i] = stringLine[i] + "-" + via[i]+"-|";
                                }
                                else{
                                    stringLine[i] = stringLine[i] + "     |";
                                }
                            }
                            for(var i=0;i<destination.length;i++){
                                if(destination[i] != null) {
                                    stringLine[i] = stringLine[i] + "-" + destination[i]+"*";
                                }
                            }

                            vm.mapDetail = "";
                            for(var i=0;i<stringLine.length;i++){
                            	if (vm.mapDetail.length == 0)
                            		vm.mapDetail = vm.mapDetail+stringLine[i];
                            	else
                            		vm.mapDetail = vm.mapDetail+"\n"+stringLine[i];
                            }
                        }
                    }
                }
            }
        }


        /* Routing Restriction */
        vm.pushDataRoutingRestriction = function(x, y){
            y[0].value = vm.dataRoutingRestriction.restriction;
            y[1].value = vm.dataRoutingRestriction.a;
            y[2].value = vm.dataRoutingRestriction.b;
            y[3].value = vm.dataRoutingRestriction.c;
            y[4].value = vm.dataRoutingRestriction.d;
            y[5].value = vm.dataRoutingRestriction.e;
            y[6].value = vm.dataRoutingRestriction.f;
            y[7].value = vm.dataRoutingRestriction.g;
            y[8].value = vm.dataRoutingRestriction.h;
            y[9].value = vm.dataRoutingRestriction.i;
            y[10].value = vm.dataRoutingRestriction.j;

            if(vm.form_routing_restriction.form_items[0].value == null) {
                vm.form_routing_restriction.form_items[0].value = [];
            }
            vm.form_routing_restriction.form_items[0].value.push(y);
            var z = angular.copy(vm.form_routing_restriction.form_items[0].value);
            vm.form_routing_restriction.form_items[0].value = z;

            vm.dataRoutingRestriction = [];
        };

        vm.removeDataRoutingRestriction = function(x, y){
            vm.form_routing_restriction.form_items[0].value.splice(y, 1);
            var z = angular.copy(vm.form_routing_restriction.form_items[0].value);
            vm.form_routing_restriction.form_items[0].value = z;
        };
        /* End Routing Restriction */

        /* Route Path */
        vm.pushRoutePath = function(x, y){

            y[0].value = vm.routePath.origin;
            y[1].value = vm.routePath.via;
            y[2].value = vm.routePath.destination;

            if(vm.routePath.via != undefined)
                y[3].value = vm.routePath.origin + '-' + vm.routePath.via + '-' + vm.routePath.destination;
            else
                y[3].value = vm.routePath.origin + '-' + vm.routePath.destination;

            if(vm.form_routing_path.form_items[1].value == null) {
                vm.form_routing_path.form_items[1].value = [];
            }
            vm.form_routing_path.form_items[1].value.push(y);
            var z = angular.copy(vm.form_routing_path.form_items[1].value);
            vm.form_routing_path.form_items[1].value = z;

            vm.routePath = [];
        };

        vm.removeRoutePath = function(x, y){
            vm.form_routing_path.form_items[1].value.splice(y, 1);
            var z = angular.copy(vm.form_routing_path.form_items[1].value);
            vm.form_routing_path.form_items[1].value = z;
        };
        
        vm.editRoutePath = function(x, y){
//        	vm.routing_new_route_path_multivalue
        	
        	if(vm.form_routing_path.form_items[1].value == null) {
                vm.form_routing_path.form_items[1].value = [];
            }
            vm.form_routing_path.form_items[1].value.push(y);
            var z = angular.copy(vm.form_routing_path.form_items[1].value);
            vm.form_routing_path.form_items[1].value = z;
        };
        /* End Route Path */

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.$watch('vm.form.form_items[6].value', function() {
        	try {
    		  if(typeof vm.form.form_items[6] == 'undefined') {
    			  // does not exist
    		  }
    		  else {
    			  // does exist
    			  resultMapDetail();
    		  }
    		}
    		catch (error){ /* ignore */ }
        });

        $scope.$watch('vm.form.form_items[7].value', function() {
        	try {
      		  if(typeof vm.form.form_items[7] == 'undefined') {
      		    // does not exist
      		  }
      		  else {
      		  // does exist
      	        	resultMapDetail();
      		  }
      		}
      		catch (error){ /* ignore */ }
        });

        $scope.$watch('vm.form.form_items[8].value', function() {
        	try {
      		  if(typeof vm.form.form_items[8] == 'undefined') {
      		    // does not exist
      		  }
      		  else {
      		  // does exist
      	        	resultMapDetail();
      		  }
      		}
      		catch (error){ /* ignore */ }
        });

        function resultMapDetail (){
        	var rslt ="";

//        	if(vm.form.form_items[5].value != null || vm.form.form_items[6].value != null || vm.form.form_items[7].value != null){
//        		rslt = rslt + "*";
//        	}

        	if(vm.form.form_items[6].value != null || vm.form.form_items[8].value != null){
        		rslt = rslt + "*";
        	}

        	if(vm.form.form_items[6].value != null){
        		rslt = rslt + vm.form.form_items[6].value;

        		if(vm.form.form_items[7].value != null && vm.form.form_items[7].value != ""){
        			rslt = rslt + "-";
        		}
        	}

//        	if(vm.form.form_items[6].value != null){
//        		if(rslt.length > 0 && vm.form.form_items[5].value != null){
//        			rslt = rslt + "-";
//        		}
//        		rslt = rslt + vm.form.form_items[6].value;
//        	}

//        	if(vm.form.form_items[7].value != null){
//        		if(rslt.length > 0 && (vm.form.form_items[5].value != null || vm.form.form_items[6].value != null)){
//        			rslt = rslt + "-";
//        		}
//        		rslt = rslt + vm.form.form_items[7].value;
//        	}

        	if (vm.form.form_items[7].value != null) {
        		rslt = rslt + vm.form.form_items[7].value;
        	}


        	if(vm.form.form_items[8].value != null){
        		if(rslt.length > 0 && vm.form.form_items[6].value != null){
        			rslt = rslt + "-";
        		}
        		rslt = rslt + vm.form.form_items[8].value;
        	}

//        	if(vm.form.form_items[5].value != null || vm.form.form_items[6].value != null || vm.form.form_items[7].value != null){
//        		rslt = rslt + "*";
//        	}

        	if(vm.form.form_items[6].value != null || vm.form.form_items[8].value != null){
        		rslt = rslt + "*";
        	}

        	vm.form.form_items[9].value = rslt;
        }

        vm.checkHasMultivalueRoute = function() {
        	if (vm.form_routing_path.form_items[1].value != undefined && vm.form_routing_path.form_items[1].value != null && vm.form_routing_path.form_items[1].value.length > 0) {
        		return false;
        	} else {
        		vm.mapDetail = "";
        		if (document.getElementById("generated_route_textarea").className != null)
        			document.getElementById("generated_route_textarea").className = document.getElementById("generated_route_textarea").className.replace(/(?:^|\s)hasData(?!\S)/g , '');
        		return true;
        	}
        }
        
        vm.copyRoutePath = function(x, y){
        	vm.data = angular.copy(vm.form_routing_path.form_items[1].value[y]);        	
        	vm.form_routing_path.form_items[1].value.push(vm.data);
        	
        	var newItemIndex = vm.form_routing_path.form_items[1].value.length - 1;        	
        	
        	if (vm.isEditRoutePath == undefined || vm.isEditRoutePath == null) {
        		vm.isEditRoutePath = {}
        	}        	
        	
        	vm.isEditRoutePath[newItemIndex] = true;
        };
        
        vm.checkMapDetail = function(data) {
        	var ori = data[0].value;
        	var via = data[1].value;
        	var dest = data[2].value;

        	console.log(via);
        	
            if(via != undefined && via != null && via != "")
            	data[3].value = ori + '-' + via + '-' + dest;
            else
            	data[3].value = ori + '-' + dest;
        };
        
        vm.copyDataRoutingRestriction = function(x, y){
        	vm.data = angular.copy(vm.form_routing_restriction.form_items[0].value[y]);        	
        	vm.form_routing_restriction.form_items[0].value.push(vm.data);
        	
        	var newItemIndex = vm.form_routing_restriction.form_items[0].value.length - 1;        	
        	
        	if (vm.isEditDataRoutingRestriction == undefined || vm.isDataRoutingRestriction == null) {
        		vm.isEditDataRoutingRestriction = {}
        	}        	
        	
        	vm.isEditDataRoutingRestriction[newItemIndex] = true;
        };

    }
})();
