(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormFareCalculatedDialogController', FormFareCalculatedDialogController);

    FormFareCalculatedDialogController.$inject = [
    '$scope', '$stateParams', 'ContractValue',
    'airlines', 'form1', 'form2', 'form3', 'form4',
    'passengers', 'currencies', 'season_type', 'routing_tariff_amadeus', 'routing_tariff_sabre', 'fare_type', 'fare_type_apf', 'zonings', '$uibModalInstance', 'ContractFareCalculated', '$rootScope'];

    function FormFareCalculatedDialogController (
    		$scope, $stateParams, ContractValue,
            airlines, form1, form2, form3, form4,
    		passengers, currencies, season_type, routing_tariff_amadeus, routing_tariff_sabre, fare_type, fare_type_apf, zonings, $uibModalInstance, ContractFareCalculated, $rootScope) {

    	var vm = this;

        vm.index = $stateParams.index;
    	vm.passengers = passengers;
		vm.airlines = airlines;
		vm.currencies = currencies;
		vm.season_type = season_type;
		vm.routing_tariff_amadeus = routing_tariff_amadeus;
		vm.fare_type = fare_type;
		vm.fare_type_apf = fare_type_apf;
		vm.zonings = zonings;
        vm.routing_tariff_sabre = routing_tariff_sabre;

		vm.form1 = form1;
		vm.form2 = form2;
		vm.form3 = form3;
		vm.form4 = form4;
		vm.currentStep = "Flight Information";
		vm.contractValue = ContractValue;
		vm.classValue = "";
		vm.fareGroup = $stateParams.group;
        vm.isFareEdit = false;
        vm.typeDisc = null;
        vm.isSelected = null;

        vm.isInclude = true;

		if (vm.index != undefined && vm.index != null && vm.index != "") {
            vm.isFareEdit = true;           
            
        	vm.forms = ContractFareCalculated.get({ id : $stateParams.id_contract, index: vm.index });

        	vm.forms.$promise.then(function() {
    			if(vm.index != null){
                    vm.form1.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.forms.forms[0], vm.form1);
                    });
                    vm.form2.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.forms.forms[1], vm.form2);
                    });
                    vm.form3.$promise.then(function(form) {
                        ContractValue.setValueFromDatabase(vm.forms.forms[2], vm.form3);
                      
                        vm.isSelected = vm.form3.form_items[57].value;
                        if (vm.isSelected != null && vm.isSelected != undefined)
                        	vm.changeType(vm.isSelected);
                    });
                    vm.form4.$promise.then(function() {
                        ContractValue.setValueFromDatabase(vm.forms.forms[3], vm.form4);
                    });
                }
    		});
        }

		vm.uppercase = function(item){
		    if(item.tag == "fare_flight_information_origin" || item.tag == "fare_flight_information_destination"){
		        if(item.value != null) {
                    item.value = item.value.toUpperCase();
                }
            }
        }

		vm.save = function(){
            if(vm.isFareEdit) {
            	var forms = [vm.form1,vm.form2,vm.form3, vm.form4];

            	var newFare = {
            			forms: forms,
            			contractId: $stateParams.id_contract,
            			index: vm.index,
            			group: vm.fareGroup
            	};

                ContractFareCalculated.update(newFare, function (result, header) {
                    $uibModalInstance.close(result);
                }, function (response) {
                    alert(response.headers()["x-americanoapp-error"]);
                });
            }
            else {
            	var forms = [vm.form1,vm.form2,vm.form3, vm.form4];

            	var newFare = {
            			forms: forms,
            			contractId: $stateParams.id_contract,
            			group: vm.fareGroup
            	};
            	
                ContractFareCalculated.save(newFare, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    alert(response.headers()["x-americanoapp-error"]);
                });
            }
        };

        vm.separateClass = function(event, value) {
        	if (event.keyCode >= 65 && event.keyCode <= 90 && value.length < 3) {
        		var letter = event.key.toUpperCase();

        		if (value.length > 1) {
        			value = value.slice(0, -1);
        			value = value.trim();
        			value = value + "/" + letter;
        		} else
        			value = value.toUpperCase();
        	} else {
        		if (event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 16) {
            		if (value != undefined && value.charAt(value.length - 1) == "/")
            			value = value.substring(0, value.length - 1);

            	} else if (value != undefined && event.keyCode != 32)
        			value = value.substring(0, value.length - 1);
        	}

        	if (value != undefined) {
        		value.split("/").forEach(function(letter) {
            		if(letter.length > 1)
            			value = vm.classValue;
            	});
        	}

        	if (value == undefined || value == "")
        		vm.classValue = "";
        	else
        		vm.classValue = value;

        	return value;
        }

        vm.clear = function() {
            if($stateParams.duplicateFare){
                vm.confirmDelete($stateParams.id_contract, $stateParams.index);
                $uibModalInstance.dismiss('cancel');
            } else{
                $uibModalInstance.dismiss('cancel');
            }
        };
       
        vm.confirmDelete = function (id, index) {
            ContractFareCalculated.delete({id:id, index: index}, function(result){
                $uibModalInstance.close(true);
            }, function(error){
            });
        };

        vm.Date = function(tag, arg){
            if(!vm.isFareEdit){
                if(arg == null){
                    if(tag == "fare_fare_information_effective_date"){
                        return new Date();
                    }
                    else if(tag == "fare_fare_information_discontinue_date"){
                        return "indef";
                    }
                }
            }
            else{
                if(arg == null){
                    return "indef";
                }else{
                    if(arg != "indef"){
                        return new Date(arg);
                    }else{
                        return "indef";
                    }
                }
            }
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened = [];
            $scope.opened[index] = true;
        };

        vm.checkValueApplyTo = function(value) {
            if (value == null || value == "" || value == undefined)
                return "From";

            return value;
        }


        vm.pushBaseFare = function(x,y){
            if(vm.isInclude){
                if(vm.form1.form_items[x].value == null){
                    vm.form1.form_items[x].value = [];
                }

                for(var a=0;a<y.length;a++){
                    var val = vm.baseFare[y[a].tag];
                    if(val != null && val != undefined && val != "") {
                        y[a].value = val;
                    }
                }

                vm.form1.form_items[x].value.push(y);
                var z = angular.copy(vm.form1.form_items[x].value);
                vm.form1.form_items[x].value = z;
            }
            else if(!vm.isInclude) {
                if(vm.form2.form_items[x].value == null){
                    vm.form2.form_items[x].value = [];
                }

                for(var a=0;a<y.length;a++){
                    var val = vm.baseFare[y[a].tag];
                    if(val != null && val != undefined && val != "") {
                        y[a].value = val;
                    }
                }

                vm.form2.form_items[x].value.push(y);
                var z = angular.copy(vm.form2.form_items[x].value);
                vm.form2.form_items[x].value = z;
            }
            vm.baseFare = [];
        };

        vm.removeBaseFare = function(x, y){
            if(vm.isInclude) {
                vm.form1.form_items[x].value.splice(y, 1);
                var z = angular.copy(vm.form1.form_items[x].value);
                vm.form1.form_items[x].value = z;
            }
            else if(!vm.isInclude) {
                vm.form2.form_items[x].value.splice(y, 1);
                var z = angular.copy(vm.form2.form_items[x].value);
                vm.form2.form_items[x].value = z;
            }
        }
        
        
        vm.copyBaseFare = function(x, y){
        	vm.data = angular.copy(vm.form1.form_items[x].value[y]);        	
        	vm.form1.form_items[x].value.push(vm.data);
        	
        	var newItemIndex = vm.form1.form_items[x].value.length - 1;        	
        	
        	if (vm.isEditBaseFare == undefined || vm.isEditBaseFare == null) {
        		vm.isEditBaseFare = {}
        	}        	
        	
        	vm.isEditBaseFare[newItemIndex] = true;
        };

        vm.checkValueDropdownOption = function(tag, value, options) {
            if(tag == 'base_fare_fare' || tag == 'base_fare_owrt' || tag == 'base_fare_day_week' || tag == 'base_fare_global'){
                if (value == null || value == "" || value == undefined){
                    return options;
                }else{
                    return value;
                }
            }
        };

        vm.box1 = ["calculated_fare_detail_calculation", "calculated_fare_detail_zoning", "calculated_fare_detail_zoning_2","calculated_fare_detail_directionallity",
        "calculated_fare_detail_type_1", "calculated_fare_detail_location_1", "calculated_fare_detail_type_2", "calculated_fare_detail_location_2"];

        vm.box2 = [
            "calculated_fare_detail_alt_method_type"
        ];
        vm.box2_standard = [
                   "calculated_fare_detail_standard_calculation_methods",
                   "calculated_fare_detail_apply_first",
                   "calculated_fare_detail_increase_decrease",
                   "calculated_fare_detail_percent",
                   "calculated_fare_detail_increase_decrease_2",
                   "calculated_fare_detail_specified_fare_amount_1",
                   "calculated_fare_detail_specified_fare_amount_2",
                   "calculated_fare_detail_currency_1",
                   "calculated_fare_detail_currency_2"
        ];
        
        vm.box2_additional = [
                   "calculated_fare_detail_additional_calculation_methods",
                   "calculated_fare_detail_additional_percent_1",
                   "calculated_fare_detail_additional_percent_2",
                   "calculated_fare_detail_additional_specified_fare_amount_1",
                   "calculated_fare_detail_additional_specified_fare_amount_2",
                   "calculated_fare_detail_additional_currency_1",
                   "calculated_fare_detail_additional_currency_2"
        ];
        
        vm.box3 = [
            "calculated_fare_detail_other_discount_qualified",
            "calculated_fare_detail_other_discount_applies_min",
            "calculated_fare_detail_other_discount_applies_max",
            "calculated_fare_detail_other_discount_apply_lowest",
            "calculated_fare_detail_other_discount_psg_status",
            "calculated_fare_detail_other_discount_type",
            "calculated_fare_detail_other_discount_location",
            "calculated_fare_detail_other_discount_permitted"
        ];
        
        vm.box4 = [
            "calculated_fare_detail_result_fare_basis",
            "calculated_fare_detail_result_fare_type_sabre",
            "calculated_fare_detail_result_psg_type",
            "calculated_fare_detail_result_owrt",
            "calculated_fare_detail_result_ticketing_code",
            "calculated_fare_detail_result_ticketing_designator",

            "calculated_fare_detail_result_season",
            "calculated_fare_detail_result_dayweek",
            "calculated_fare_detail_result_booking_code",
            "calculated_fare_detail_result_global",
            "calculated_fare_detail_result_normal_special",
            "calculated_fare_detail_result_display_type",
            "calculated_fare_detail_result_routing",
            "calculated_fare_detail_result_routing_vendor",
            "calculated_fare_detail_result_routing_number",
            "calculated_fare_detail_result_routing_tariff",
            "calculated_fare_detail_result_directionallity",
            "calculated_fare_detail_result_allow_hip"
        ];
        
        vm.box4security = [
                   "calculated_fare_detail_result_fare_basis",
                   "calculated_fare_detail_result_fare_type_sabre",
                   "calculated_fare_detail_result_psg_type",
                   "calculated_fare_detail_result_owrt",
                   "calculated_fare_detail_result_ticketing_code",
                   "calculated_fare_detail_result_ticketing_designator",

                   "calculated_fare_detail_result_season",
                   "calculated_fare_detail_result_dayweek",
                   "calculated_fare_detail_result_booking_code",
                   "calculated_fare_detail_result_global",
                   "calculated_fare_detail_result_normal_special",
                   "calculated_fare_detail_result_display_type_Fixed_Price_Fare_Security",
                   "calculated_fare_detail_result_routing",
                   "calculated_fare_detail_result_routing_vendor",
                   "calculated_fare_detail_result_routing_number",
                   "calculated_fare_detail_result_routing_tariff",
                   "calculated_fare_detail_result_directionallity",
                   "calculated_fare_detail_result_allow_hip"
               ];

        vm.box5 = [
            "calculated_fare_detail_min_amount",
            "calculated_fare_detail_max_amount",
            "calculated_fare_detail_currency",
            "calculated_fare_detail_alt_min_amount",
            "calculated_fare_detail_alt_max_amount",
            "calculated_fare_detail_alt_currency"
        ];
        
        vm.changeType = function(options) {
			if (options == "STANDARD CALCULATION METHODS") {
				vm.typeDisc = "standard";
			} else if (options == "ADDITIONAL CALCULATION METHODS") {
				vm.typeDisc = "additional";
			}
		}
        
        vm.cekSecurityType = $rootScope.contract_type_security;
               
        vm.checkValueApplyTo = function(value, tag) {
            if(tag == "rrc_eligibility"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_advance_reservation"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_transfer"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_accompanied_travel"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_endorsement"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_dow"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_min_stay"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_combination"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_travel_date"){
                if(value == null || value == "" || value == undefined)
                    return "Both"
            }else if(tag == "rrc_discount"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_season"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_max_stay"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_blackout"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_sales_date"){
                if(value == null || value == "" || value == undefined)
                    return "Both"
            }else if(tag == "rrc_voluntary_changes"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_flight_apps"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_stopover"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_surcharge"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }else if(tag == "rrc_penalties"){
                if(value == null || value == "" || value == undefined)
                    return "Base"
            }
            /*if (!vm.isBlackoutEdit) {
                if (value == null || value == "" || value == undefined)
                    return "Outbound And Inbound";
            }

            return value;*/
        }
    }
})();
