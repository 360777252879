(function() {
    'use strict';

    var jhiItemCount = {
    		template: 
    			
    		  '<div class="info" ng-if="$ctrl.page != undefined && $ctrl.queryCount != undefined && $ctrl.size != undefined">' +
		          'Showing {{(($ctrl.page-1) * $ctrl.size)==0 ? ($ctrl.queryCount < 1 ? 0 : 1):(($ctrl.page-1) * $ctrl.size) + 1}} - ' +
		          '{{($ctrl.page * $ctrl.size) < $ctrl.queryCount ? ($ctrl.page * $ctrl.size) : $ctrl.queryCount}} ' +
		          'of {{$ctrl.queryCount}} items.' +
		      '</div>',
        bindings: {
            page: '<',
            queryCount: '<total',
            size: '<size'
        }
    };
    
    angular
        .module('americanoApp')
        .component('jhiItemCount', jhiItemCount);
})();
