(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('MasterAccount', MasterAccount);

    MasterAccount.$inject = ['$resource'];

    function MasterAccount ($resource) {
        var resourceUrl =  'api/master-accounts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'search': { method:'GET', isArray:true, url:"api/_search/master-accounts/:query"}
        });
    }
})();
