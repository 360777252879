(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FieldPageDetailController', FieldPageDetailController);

    FieldPageDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'FieldPage'];

    function FieldPageDetailController($scope, $rootScope, $stateParams, entity, FieldPage) {
        var vm = this;
        vm.fieldPage = entity;
        vm.load = function (id) {
            FieldPage.get({id: id}, function(result) {
                vm.fieldPage = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:fieldPageUpdate', function(event, result) {
            vm.fieldPage = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
