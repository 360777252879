(function() {
	'use strict';

	angular.module('americanoApp').controller('FormContractHeaderController',
			FormContractHeaderController);

	FormContractHeaderController.$inject = [ '$scope', '$filter', '$rootScope',
			'$stateParams', 'AlertService', 'entity', 'c1', 'c2', 'c3', 'c4', 'c5',
			'FormContract', 'ContractHeader', 'Contract', '$state', 'airlines',
			'ContractValue', '$q' ];

	function FormContractHeaderController($scope, $filter, $rootScope,
			$stateParams, AlertService, entity, c1, c2, c3, c4, c5, FormContract,
			ContractHeader, Contract, $state, airlines, ContractValue, $q) {
		var vm = this;
		vm.entity = entity;
		console.log(vm.entity);
		vm.c1 = c1;
		vm.c2 = c2;
		vm.c3 = c3;
		vm.c4 = c4;
		vm.c5 = c5;
		vm.airlines = airlines;
		vm.travelDateTypeOptions = [ "Expiration", "Commencement", "Completion" ];
		vm.operation = $stateParams.operation;
		vm.isSaving = false;

		vm.status = true;
		vm.ContractTypeOptions = [{key:'standard_contract', value:'Standard Contract'}, {key:'calculated_contract', value:'Calculated Contract'}];
        vm.CalculatedContractOptions = [{key:'fixed_price_fare_security', value:'Fixed Price Fare Security'}, {key:'net_fare_security', value:'Net Fare Security'}];

        vm.operation = $stateParams.operation;
        
        vm.clearField = function (condition) {
            if(condition == 'Standard Contract'){
                vm.contract_type_security = '';
                vm.status = false;
            } else {
                vm.status = true;
            }
        };

        vm.check = function (){
            vm.status = false;
        };

        vm.goMainPage = function (){
            vm.current = "header";
        };

        vm.back = function() {
        	vm.current = "option";
        }

		if (vm.entity.$promise != null) {
			vm.entity.$promise.then(function() {
				vm.contract_type = vm.entity.contract_type;
				
				if (vm.entity.contract_type == undefined || vm.entity.contract_type == null)
		        	vm.current = "option";
		        else
		        	vm.current = "header";

				vm.contractStatus = vm.entity.status;

				if (vm.entity.data[0].pages.length != 0) {
					vm.c1.$promise.then(function() {
						ContractValue.setValueFromDatabase(
								vm.entity.data[0].pages[0].forms[0], vm.c1);
					});
					vm.c2.$promise.then(function() {
						ContractValue.setValueFromDatabase(
								vm.entity.data[0].pages[0].forms[1], vm.c2);
					});
					vm.c3.$promise.then(function() {
						ContractValue.setValueFromDatabase(
								vm.entity.data[0].pages[0].forms[2], vm.c3);
					});
                    vm.c4.$promise.then(function() {
                        ContractValue.setValueFromDatabase(
                            vm.entity.data[0].pages[0].forms[3], vm.c4);
                    });
                    vm.c5.$promise.then(function() {
                        ContractValue.setValueFromDatabase(
                            vm.entity.data[0].pages[0].forms[4], vm.c5);
                    });
				}
			});
		} else
			vm.current = "option";

		vm.next = function(type) {
			vm.save(type);
		};

		vm.cancel = function() {
			$state.go("contract", null, {reload : true});
		}

		vm.save = function(type) {
			vm.isSaving = true;
			vm.entity.contract_type = vm.contract_type;
			vm.entity.contract_type_security = vm.contract_type_security;

			if (vm.entity.id != null) {
				vm.entity.data[0].pages[0].forms[0] = vm.c1;
				vm.entity.data[0].pages[0].forms[1] = vm.c2;
				vm.entity.data[0].pages[0].forms[2] = vm.c3;
                vm.entity.data[0].pages[0].forms[3] = vm.c4;
                vm.entity.data[0].pages[0].forms[4] = vm.c5;

				ContractHeader.update(vm.entity, function(result) {
					vm.entity = result;
					$rootScope.contractId = result.id;

					if (type == 'update') {
						if($rootScope.isCalculated) {
                            $("#tab2calculated").trigger("click");
                        }
                        else {
                            $("#tab2").trigger("click");
                        }
                    }
				}, function(error) {
				});
			} else {

				vm.entity.data[0].pages.push({
					forms : [ vm.c1, vm.c2, vm.c3, vm.c4, vm.c5 ]
				});

				Contract.save(vm.entity, function(result) {
					vm.entity = result;
					$rootScope.contractId = result.id;

                    if(vm.entity.contract_type == "calculated_contract") {
                    	$rootScope.isCalculated = true;
                    	$state.go('contract.fare-calculated', {
                            id_contract:$rootScope.contractId
                        }, { reload: true });
                    } else if (vm.entity.contract_type == "standard_contract") {
                    	$("#tab2").trigger("click");
                    }
				}, function(error) {
				});

			}
		}

		vm.Date = function(tag, arg) {
			if (vm.operation != null && vm.operation == 'NEW') {
				if (arg == null) {
                    if (tag == "c2_discontinue_date") {
                        return "indef";
                    }
                    if (tag == "c2_effective_date") {
                        return new Date();
                    }
                    if (tag == "c2_first_travel") {
                        return new Date();
                    }
                    if (tag == "c2_first_ticket") {
                        return new Date();
                    }
                }
			} else {
				if (arg != "indef" && arg != "" && arg != undefined) {
					return new Date(arg);
				} else {
					if (tag == "c2_discontinue_date") {
                        return "indef";
                    }
                    if (tag == "c2_effective_date") {
                        return new Date();
                    }
                    if (tag == "c2_first_travel") {
                        return new Date();
                    }
                    if (tag == "c2_first_ticket") {
                        return new Date();
                    }
				}
			}
		};

		$scope.opened = [];
		$scope.openDatePicker = function($event, index) {
			$event.preventDefault();
			$event.stopPropagation();
			$scope.opened = [];
			$scope.opened[index] = true;
		};

		$scope.dateOptions = {
			minDate : new Date()
		};

        vm.checkTicketingRules = function (value) {
            if(value != null || value != undefined || value != ""){
                if(value == 'Apply Airline Ticket Rules'){
                    vm.c5.form_items[1].value = null;
                    vm.c5.form_items[2].value = null;
                }
            }
        }
	}
})();
