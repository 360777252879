(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('BlackoutsController', BlackoutsController);

    BlackoutsController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', '$uibModal', 'entity', 'ContractBlackout', 'ContractValue'];

    function BlackoutsController (
    		$scope, $parse, $state, $stateParams, $uibModal, entity, ContractBlackout, ContractValue) {

    	var vm = this;
    	vm.entity = entity;
    	vm.id_contract = $stateParams.id_contract;
        vm.itemsPerPage = $stateParams.items_per_page;
        vm.currentPage = 0;
        vm.pageContent = [];
        vm.contractValue = ContractValue;
        vm.index = 0;

        // Initialize Rule Exception Variables
        var exceptionModalInstance = null, errorModalInstance = null;
        vm.selectedRules = [];
        vm.selectedStatus = [];
        vm.selectedRow = [];

    	vm.entity.$promise.then(function(contract) {
    		vm.blackoutRules = contract.data[7].pages;
    		vm.totalItems = vm.blackoutRules.length;
    		vm.totalPages = Math.ceil(vm.totalItems / vm.itemsPerPage);
            vm.lastIndex = vm.totalItems - 1;
    		vm.setPageTo(1);
    	});

		vm.removeBlackout = function(type, rule){
			var indexes = [];
			
        	if (type == 'single') {
        		indexes.push(rule.index);
        	}
        	
        	vm.selectedRules.forEach(function(rule) {
        		console.log(rule);
        		indexes.push(rule.index);
        	});
        	
        	console.log(indexes);
            $state.go('contract.blackout.delete', {
                index: indexes,
                id_contract:$stateParams.id_contract
            }, { reload: true });
		};

        vm.setItemsPerPage = function(value) {
        	vm.itemsPerPage = value;
        }

        vm.reloadPage = function() {
        	 $state.transitionTo($state.$current, {
                 items_per_page:vm.itemsPerPage,
                 id_contract:vm.id_contract
             });
        }

		vm.setPageTo = function(page) {
			vm.pageContent = [];
			vm.limit = 0;
			vm.currentPage = page;
			vm.calculatePage = vm.currentPage - 1;

			vm.startIndex = (vm.totalItems - (vm.calculatePage * vm.itemsPerPage)) - 1;
			vm.limit = vm.startIndex - vm.itemsPerPage;

			if (vm.limit < 0)
				vm.limit = -1;

			for (var i = vm.startIndex; i > vm.limit; i--) {
				vm.pageContentStructure = {
						hasError: null,
						hasException : false,
						rule: vm.blackoutRules[i],
						index:vm.blackoutRules[i].pagesIndex
				}

				var hasError = false;
				
				if(vm.blackoutRules[i].exception != null)
					vm.pageContentStructure.hasException = true;

				for (var j = 0; j < vm.blackoutRules[i].forms.length; j++) {
					for (var k = 0; k < vm.blackoutRules[i].forms[j].form_items.length; k++) {
						if (vm.blackoutRules[i].forms[j].form_items[k].type.toLowerCase() == "multivalue") {
							if (vm.blackoutRules[i].forms[j].form_items[k].value != null) {
								for (var l = 0; l < vm.blackoutRules[i].forms[j].form_items[k].value.length; l++) {
									for (var m = 0; m < vm.blackoutRules[i].forms[j].form_items[k].value[l].length; m++) {
										if (vm.blackoutRules[i].forms[j].form_items[k].value[l][m].errorAPF.length > 0) {
											hasError = true;
											break;
										}
										if (vm.blackoutRules[i].forms[j].form_items[k].value[l][m].errorAmadeus.length > 0) {
											hasError = true;
											break;
										}
										if (vm.blackoutRules[i].forms[j].form_items[k].value[l][m].errorSabre.length > 0) {
											hasError = true;
											break;
										}
									}

									if (hasError)
										break;
								}
							}
						}

						if (vm.blackoutRules[i].forms[j].form_items[k].errorAPF.length > 0) {
							hasError = true;
							break;
						}
						if (vm.blackoutRules[i].forms[j].form_items[k].errorAmadeus.length > 0) {
							hasError = true;
							break;
						}
						if (vm.blackoutRules[i].forms[j].form_items[k].errorSabre.length > 0) {
							hasError = true;
							break;
						}
					}

					if (hasError)
						break;
				}

				vm.pageContentStructure.hasError = hasError;

				vm.pageContent.push(vm.pageContentStructure);
			}
		}

		vm.checkDate = function(arg) {
    	   var date = Date.parse(arg);

    	   if (isNaN(date) == false)
    	       return arg;
    	   else
    		   return "Invalid Date";
		}

       vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
    	}

       // Rule Exception
		vm.registerAllRules = function(isChecked) {
       	if (isChecked) {
       		vm.selectedRow = [];
       		vm.selectedRules = [];
       		for (var i = 0; i < vm.blackoutRules.length; i++) {
           		vm.selectedRules.push(vm.blackoutRules[i]);
           		vm.selectedStatus[i] = true;
           		vm.selectedRow.push(vm.lastIndex - i);
           	}

       	} else {
       		vm.selectedRow = [];
       		vm.selectedRules = [];
       		for (var i = 0; i < vm.selectedStatus.length; i++) {
       			vm.selectedStatus[i] = false;
       		}
       	}
       }

		vm.rowHighlighted = function(row, rule, index) {
			var selectedExist = false;

			for (var i = 0; i < vm.selectedRow.length; i++) {
				if (vm.selectedRow[i] == row) {
					vm.selectedRow.splice(i, 1);
					selectedExist = true;
					break;
				}
			}

			if (selectedExist)
				vm.selectedStatus[index] = false;
			else {
				vm.selectedStatus[index] = true;
				vm.selectedRow.push(row);
			}

			vm.registerRule(rule, index);
	 	}

		vm.registerRule = function(rule, index) {
       	if (vm.selectedStatus[index]) {
       		vm.selectedRules.push(rule.rule);
           	vm.selectedStatus[index] = true;
       	} else {
       		vm.selectedRules.splice(vm.selectedRules.indexOf(rule), 1);
       		vm.selectedStatus[index] = false;
       	}

       	if (vm.selectedRules.length == vm.blackoutRules.length)
       		vm.master = true;
       	else
       		vm.master = false;
       }

	 	vm.getSelectedRowStatus = function(row) {
	 		var status = false;

	 		if (vm.master) {
	 			status = true;
	 		} else {
				for (var i = 0; i < vm.selectedRow.length; i++) {
		 			if (vm.selectedRow[i] == row) {
		 				status = true;
		 				break;
		 			}
		 		}
	 		}

	 		return status;
	 	}

       vm.resetCheckbox = function() {
       	vm.selectedRules = [];
       	vm.selectedRow = [];
	    	for (var i = 0; i < vm.selectedStatus.length; i++) {
	    		vm.selectedStatus[i] = false;
	    	}
	    	vm.master = false;
       }

       vm.viewException = function(event, rule, index) {
           vm.index = index;

            event.stopPropagation();
            vm.resetCheckbox();
            vm.selectedRules.push(rule.rule);
            vm.runException();
       }

       vm.runException = function() {
       	if (!vm.checkExceptionSelection())
       		vm.openExceptionModal();
       	else
       		vm.openErrorModal();
       }

       vm.checkExceptionSelection = function() {
       	var currentException = null;
       	var isDifferentException = false;

       	for (var x = 0; x < vm.selectedRules.length; x++) {
       		if (vm.selectedRules[x].exception != null && vm.selectedRules[x].exception != undefined) {
       			currentException = vm.selectedRules[x].exception;
       			break;
       		}
       	}

       	for (var i = 0; i < vm.selectedRules.length; i++) {
       		var exception = vm.selectedRules[i].exception;

       		if (exception != null && exception != undefined) {
           		if (JSON.stringify(currentException) != JSON.stringify(exception)){
           			isDifferentException = true;
           			break;
           		}
       		}
       	}

       	return isDifferentException;
       }

       vm.resetExceptionModal = function () {
       	exceptionModalInstance = null;
       };

       vm.openExceptionModal = function() {
       	if (exceptionModalInstance !== null) return;

       	exceptionModalInstance = $uibModal.open({
       		data: {
       			authorities: ["ROLE_USER"]
       		},
       		animation: true,
       		templateUrl: 'app/entities/contract/rules/rule-exception.html',
       		controller: 'RuleExceptionController',
       		controllerAs: 'vm',
       		scope: $scope.$new(),
       		backdrop: 'static',
       		size: 'lg',
       		resolve: {
       			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
       				$translatePartialLoader.addPart('global');
       				return $translate.refresh();
       			}]
       		}
       	});

       	exceptionModalInstance.result.then(vm.resetExceptionModal, vm.resetExceptionModal);
       }

       vm.resetErrorModal = function () {
       	errorModalInstance = null;
       };

       vm.openErrorModal = function() {
       	if (errorModalInstance !== null) return;

       	errorModalInstance = $uibModal.open({
       		data: {
       			authorities: ["ROLE_USER"]
       		},
       		animation: true,
       		templateUrl: 'app/entities/contract/rules/rule-exception-error.html',
       		controller: 'RuleExceptionErrorController',
       		controllerAs: 'vm',
       		scope: $scope.$new(),
       		backdrop: 'static',
       		size: 'lg',
       		resolve: {
       			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
       				$translatePartialLoader.addPart('global');
       				return $translate.refresh();
       			}]
       		}
       	});

       	errorModalInstance.result.then(vm.resetErrorModal, vm.resetErrorModal);
       }

       vm.getRuleIndex = function(rule) {
       	return vm.blackoutRules.indexOf(rule);
       }

       vm.saveException = function(exception) {
    	   var object = {
       			exception: exception,
       			contractId: vm.id_contract,
       			index: []
	       	}

	           for (var i = 0; i < vm.selectedRules.length; i++) {
	           	object.index.push(vm.getRuleIndex(vm.selectedRules[i]));
	           }

    	   ContractBlackout.updateException(object, function(result){
	               $state.go($state.current, {id_contract:vm.id_contract}, {reload:true});
	           }, function(error){});
       }
       
    // Checkbox
       vm.selectedRules = [];
       vm.selectedStatus = [];
       vm.currentPage = [];
       vm.selectedRow = [];

       vm.registerAllRules = function(isChecked) {
       	if (isChecked) {
       		vm.selectedRow = [];
       		vm.selectedRules = [];
       		for (var i = 0; i < vm.currentPage.length; i++) {
           		vm.selectedRules.push(vm.currentPage[i]);
           		vm.selectedStatus[i] = true;
           		vm.selectedRow.push(vm.lastIndex - i);
           	}

       	} else {
       		vm.selectedRow = [];
       		vm.selectedRules = [];
       		for (var i = 0; i < vm.selectedStatus.length; i++) {
       			vm.selectedStatus[i] = false;
       		}
       	}
       }

       vm.registerRule = function(transactionIndex, rule, index) {
       	if (vm.selectedStatus[index]) {
       		vm.selectedRules.push(rule);
           	vm.selectedStatus[index] = true;

       	} else {
       		vm.selectedRules.splice(vm.selectedRules.indexOf(rule), 1);
       		vm.selectedStatus[index] = false;
       	}

       	if (vm.selectedRules.length == vm.currentPage.length) {
       		vm.master = true;
       	} else
       		vm.master = false;
       }

       vm.rowHighlighted = function(row, rule, index) {
       	var selectedExist = false;

			for (var i = 0; i < vm.selectedRow.length; i++) {
				if (vm.selectedRow[i] == row) {
					vm.selectedRow.splice(i, 1);
					selectedExist = true;
					break;
				}
			}

			if (selectedExist)
				vm.selectedStatus[index] = false;
			else {
				vm.selectedStatus[index] = true;
				vm.selectedRow.push(row);
			}

			vm.registerRule(row, rule, index);
	 	}


       vm.resetCheckbox = function() {
	       	vm.selectedRules = [];
	       	vm.selectedRow  = [];
           for (var i = 0; i < vm.selectedStatus.length; i++) {
          	 vm.selectedStatus[i] = false;
           }
           vm.master = false;
      }
       //End Checkbox
       
    }
})();
