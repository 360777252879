(function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('fare', {
            parent: 'entity',
            url: '/fare?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.fare.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/fare/fares.html',
                    controller: 'FareController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('fare-detail', {
            parent: 'entity',
            url: '/fare/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.fare.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/fare/fare-detail.html',
                    controller: 'FareDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Fare', function($stateParams, Fare) {
                    return Fare.get({id : $stateParams.id});
                }]
            }
        })
        .state('fare.new', {
            parent: 'fare',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/fare/fare-dialog.html',
                    controller: 'FareDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                origin: null,
                                via: null,
                                destination: null,
                                passengerType: null,
                                tariffNumber: null,
                                fareBasisCode: null,
                                amountDouble: null,
                                amountPercentage: null,
                                adjustment: null,
                                currency: null,
                                ticketType: null,
                                seasonType: null,
                                ticketCode: null,
                                ticketDesignator: null,
                                routingType: null,
                                routingNumber: null,
                                bookingClass: null,
                                airline: null,
                                contract: null,
                                id: null
                            };
                        },
                        Airlines:['AirlineCustom', function(AirlineCustom) {
                        	return AirlineCustom.get();
                        }],
                        Contracts:['Contract', function(Contract) {
                        	return Contract.query();
                        }],
                        Passengers:['PassengerCustom', function(PassengerCustom) {
                        	return PassengerCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('fare', null, { reload: true });
                }, function() {
                    $state.go('fare');
                });
            }]
        })
        .state('fare.edit', {
            parent: 'fare',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/fare/fare-dialog.html',
                    controller: 'FareDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Fare', function(Fare) {
                            return Fare.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('fare', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('fare.delete', {
            parent: 'fare',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/fare/fare-delete-dialog.html',
                    controller: 'FareDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Fare', function(Fare) {
                            return Fare.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('fare', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
