(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('BlackoutController', BlackoutController);

    BlackoutController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', '$uibModal',
    'ContractBlackout', 'travelSegmentIndicatorSabre', 'form5', 'form6', '$uibModalInstance', 'ContractValue'];

    function BlackoutController (
    		$scope, $parse, $state, $stateParams, $uibModal,
    		ContractBlackout, travelSegmentIndicatorSabre, form5, form6, $uibModalInstance, ContractValue) {

    	var vm = this;
    	vm.entity = ContractBlackout.get({ id : $stateParams.id_contract });
		vm.form5 = form5;
		vm.form6 = form6;
		vm.id_contract = $stateParams.id_contract;
		vm.travelSegmentIndicatorSabre = travelSegmentIndicatorSabre;
		vm.isBlackoutEdit = false;
		vm.index = $stateParams.index;
		vm.isEdit = [];
		vm.isEditDateRange = [];

		vm.entity.$promise.then(function(contract) {
    		vm.blackoutRules = contract.data[7].pages;

    		if (vm.index < vm.blackoutRules.length)
    			vm.editBlackout(vm.blackoutRules[vm.index]);
    	});

		//BLACKOUT
		vm.addBlackout = function(){
			vm.entity.data[7].pages.push({
				forms:[
					angular.copy(vm.form5),
					angular.copy(vm.form6),
				]
			});
		}

		vm.editBlackout = function(x){
            vm.form5.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form5);
            });

            vm.form6.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[1], vm.form6);
            });
			// vm.form5 = angular.copy(x.forms[0]);
			// vm.form6 = angular.copy(x.forms[1]);

			vm.editObjectBlackout = x;
			vm.isBlackoutEdit = true;
		};
		//END BLACKOUT

		//Date
		 vm.pushDataDate = function(x, y){
            y[0].value = vm.dateBlackout.blackout_date_first_date_format;
            y[1].value = vm.dateBlackout.blackout_date_value;

        	if(vm.form6.form_items[x].value == null){
        		vm.form6.form_items[x].value = [];
        	}
        	vm.form6.form_items[x].value.push(y);
        	var z = angular.copy(vm.form6.form_items[x].value);
        	vm.form6.form_items[x].value = z;

            vm.dateBlackout = [];
        };

        vm.removeDataDate = function(x, y){
        	vm.form6.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form6.form_items[x].value);
        	vm.form6.form_items[x].value = z;

        }
		//End Date

        //Date Range
		vm.pushDataDateRange = function(x, y){
            y[0].value = vm.dateRangeBlackout.blackout_date_range_first_date_format;
            y[1].value = vm.dateRangeBlackout.blackout_date_range_date_1;
            y[2].value = vm.dateRangeBlackout.blackout_date_range_second_date_format;
            y[3].value = vm.dateRangeBlackout.blackout_date_range_date_2;

	       	if(vm.form6.form_items[x].value == null){
	       		vm.form6.form_items[x].value = [];
	       	}
	       	vm.form6.form_items[x].value.push(y);
	       	var z = angular.copy(vm.form6.form_items[x].value);
	       	vm.form6.form_items[x].value = z;

			vm.dateRangeBlackout = [];
			// vm.dateRangeBlackout['blackout_date_range_first_date_format'] = "ddMMMyy";
			// vm.dateRangeBlackout['blackout_date_range_second_date_format'] = "ddMMMyy";
		 };

       vm.removeDataDateRange = function(x, y){
	       	vm.form6.form_items[x].value.splice(y, 1);
	       	var z = angular.copy(vm.form6.form_items[x].value);
	       	vm.form6.form_items[x].value = z;
       }

       vm.editDataDateRange = function(x, y){
	       	vm.form6.form_items[x].value.splice(y, 1);
	       	var z = angular.copy(vm.form6.form_items[x].value);
	       	vm.form6.form_items[x].value = z;
      }
       //End Date Range

	   vm.checkDateFormat = function(tag, formItems){
		   if(formItems){
				for(var x=0;x<formItems.length;x++){					
					if(tag == "blackout_date_value" && formItems[x].tag == "blackout_date_first_date_format") {
						if(formItems[x].value != null)
							return formItems[x].value;
					}
					else if(tag == "blackout_date_range_date_1" && formItems[x].tag == "blackout_date_range_first_date_format"){
						if(formItems[x].value != null)
							return formItems[x].value;
					}
					else if(tag == "blackout_date_range_date_2" && formItems[x].tag == "blackout_date_range_second_date_format"){
						if(formItems[x].value != null)
							return formItems[x].value;
					}					
				}	
			}	
			return "ddMMMyy";
		}

		vm.formatDate = function(date, tag, formItems){
        	if(date instanceof Date){        		
        		if(tag == "blackout_date_value"){
        			var format = vm.checkDateFormat("blackout_date_value", formItems);
        			if(format == "ddMMM"){
        				date.setFullYear(new Date().getFullYear());
        			}
        		}
        		else if(tag == "blackout_date_range_date_1"){
        			var format = vm.checkDateFormat("blackout_date_range_date_1", formItems);
        			if(format == "ddMMM"){
        				date.setFullYear(new Date().getFullYear());
        			}
				}
				else if(tag == "blackout_date_range_date_2"){
        			var format = vm.checkDateFormat("blackout_date_range_date_2", formItems);
        			if(format == "ddMMM"){
        				date.setFullYear(new Date().getFullYear());
        			}
        		}
        		return date;
        	}
        };

       vm.checkDate = function(arg) {
    	   var date = Date.parse(arg)

    	   if (isNaN(date) == false)
    	       return arg;
    	   else
    		   return "Invalid Date";
       }

       vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
    	};

     	vm.saveBlackoutEdit = function(){
			vm.entity.data[7].pages[vm.entity.data[7].pages.indexOf(vm.editObjectBlackout)].forms[0] = angular.copy(vm.form5);
			vm.entity.data[7].pages[vm.entity.data[7].pages.indexOf(vm.editObjectBlackout)].forms[1] = angular.copy(vm.form6);

			vm.editObjectBlackout = null;
			vm.isBlackoutEdit = false;
		};

		vm.save = function(){
            var forms = [vm.form5, vm.form6];

            if (vm.isBlackoutEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractBlackout.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractBlackout.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isBlackoutEdit)
				// vm.saveBlackoutEdit();
            // else
				// vm.addBlackout();
            //
            // ContractBlackout.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened = [];
			$scope.opened[index] = true;
			console.log('Open : '+index);
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.checkValueApplyTo = function(value) {
        	if (!vm.isBlackoutEdit) {
        		if (value == null || value == "" || value == undefined)
                    return "Outbound And Inbound";
        	}

            return value;
        }

        vm.tagTab1 = ["blackout_apply_to"];
		vm.tagTabTextOnly = ["blackout_text_information_only", "blackout_note_amadeus", "blackout_note_apf", "blackout_note_sabre", "blackout_note", "blackout_override"];
		
		vm.copyBlackoutDate = function(val, list){
			var value = angular.copy(val);
			list.push(value);
		}
    }
})();
