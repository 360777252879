(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TransportingClassDeleteController',TransportingClassDeleteController);

    TransportingClassDeleteController.$inject = ['$uibModalInstance', 'entity', 'TransportingClass'];

    function TransportingClassDeleteController($uibModalInstance, entity, TransportingClass) {
        var vm = this;

        vm.transportingClass = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            TransportingClass.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
