(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('RoleDeleteController',RoleDeleteController);

    RoleDeleteController.$inject = ['$uibModalInstance', 'entity', 'Authority'];

    function RoleDeleteController($uibModalInstance, entity, Authority) {
        var vm = this;

        vm.role = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (name) {
        	Authority.delete({id: name},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
