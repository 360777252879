(function() {
    'use strict';

    angular.module('americanoApp').config(stateConfig);

    stateConfig.$inject = [ '$stateProvider' ];

    function stateConfig($stateProvider) {
    	$stateProvider
    	.state('contract.fare-calculated', {
            parent : 'entity',
            url : '/contract/fare-calculated/{id_contract}?page&sort&search&group&newGroup',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/contract/fare-calculated/form-fare-calculated.html',
                    controller : 'FormFareCalculatedController',
                    controllerAs : 'vm'
                }
            },
            params : {
                page : {
                    value : '1',
                    squash : true
                },
                sort : {
                    value : 'id,desc',
                    squash : true
                },
                search : null,
                group : "DEFAULT",
                newGroup: "0",
                duplicateFare: false
            },
            resolve : {
                translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('fareCalculated');
                		return $translate.refresh();
                }],
                pagingParams : ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page : PaginationUtil
                            .parsePage($stateParams.page),
                        sort : $stateParams.sort,
                        predicate : PaginationUtil
                            .parsePredicate($stateParams.sort),
                        ascending : PaginationUtil
                            .parseAscending($stateParams.sort),
                        search : $stateParams.search
                    };
                }]
            }
    	})
        // .state('contract.fare.view', {
        //     parent : 'contract.fare',
        //     url : '/view/{index}',
        //     data : {
        //         authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
        //         pageTitle : 'americanoApp.form.detail.title'
        //     },
        //     views : {
        //         'content@' : {
        //             templateUrl : 'app/entities/contract/fare/form-fare-view.html',
        //             controller : 'FormViewFareController',
        //             controllerAs : 'vm'
        //         }
        //     },
        //     resolve : {
        //         translatePartialLoader : ['$translate','$translatePartialLoader',
        //             function($translate, $translatePartialLoader) {
        //                 $translatePartialLoader.addPart('fare');
        //                 return $translate.refresh();
        //             }],
        //         form1 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        //         	return FormContract.get({tag : "fare_flight_information"});
        //        	}],
        //         form2 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        //         	return FormContract.get({tag : "fare_fare_information"});
        //         }],
        //         form3 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        //         	return FormContract.get({tag : "fare_ticket_information"});
        //      	}],
        //     }
        // })
        .state('contract.fare-calculated.new', {
            parent : 'contract.fare-calculated',
            url : '/new',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/fare-calculated/form-fare-calculated-dialog.html',
                    controller : 'FormFareCalculatedDialogController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('fareCalculated');
                        	return $translate.refresh();
                       	}],
                        form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({tag : "base_fare"}).$promise;
                        }],
                        form2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({tag : "base_fare"}).$promise;
                   	 	}],
                        form3 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({tag : "calculated_fare_detail"}).$promise;
                        }],
                        form4 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({tag : "rrc"}).$promise;
                        }],
                    	airlines : ['AirlineCustom', function(AirlineCustom) {
                    		return AirlineCustom.get();
                     	}],
                        zonings : ['Zoning', function(Zoning) {
                            return Zoning.getAll();
                        }],
                        passengers : ['PassengerCustom', function(PassengerCustom) {
                        	return PassengerCustom.get();
                        }],
                        currencies : ['CurrencyCustom', function(CurrencyCustom) {
                          	return CurrencyCustom.get();
                      	}],
                        season_type : ['SeasonTypeCustom', function(SeasonTypeCustom) {
                        	return SeasonTypeCustom.get();
                        }],
                        routing_tariff_amadeus : ['RoutingTariffAmadeusCustom', function(RoutingTariffAmadeusCustom) {
                        	return RoutingTariffAmadeusCustom.get();
                     	}],

                        routing_tariff_sabre : ['RoutingTariffSabreCustom', function(RoutingTariffSabreCustom) {
                            return RoutingTariffSabreCustom.get();
                        }],
                        fare_type : ['FareTypeCustom', function(FareTypeCustom) {
                        	return FareTypeCustom.get();
                        }],
                        fare_type_apf : ['FareTypeAPFCustom', function(FareTypeAPFCustom) {
                        	return FareTypeAPFCustom.get();
                        }]
                    }
                }).result.then(function() {
                	$state.go('contract.fare-calculated', {newGroup: "0"}, {reload : true});
                }, function() {
                	$state.go('contract.fare-calculated', {group : 'DEFAULT', newGroup: "0"}, {reload : true});
                });
            }]
        })
        .state('contract.fare-calculated.edit', {
            parent : 'contract.fare-calculated',
            url : '/edit/{index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/fare-calculated/form-fare-calculated-dialog.html',
                    controller : 'FormFareCalculatedDialogController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    keyboard: false,
                    size : 'lg',
                    resolve : {
                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fareCalculated');
                            return $translate.refresh();
                        }],
                        form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({tag : "base_fare"}).$promise;
                        }],
                        form2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({tag : "base_fare_exclude"}).$promise;
                        }],
                        form3 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({tag : "calculated_fare_detail"}).$promise;
                        }],
                        form4 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({tag : "rrc"}).$promise;
                        }],
                        airlines : ['AirlineCustom', function(AirlineCustom) {
                            return AirlineCustom.get();
                        }],
                        zonings : ['Zoning', function(Zoning) {
                            return Zoning.getAll();
                        }],
                        passengers : ['PassengerCustom', function(PassengerCustom) {
                            return PassengerCustom.get();
                        }],
                        currencies : ['CurrencyCustom', function(CurrencyCustom) {
                            return CurrencyCustom.get();
                        }],
                        season_type : ['SeasonTypeCustom', function(SeasonTypeCustom) {
                            return SeasonTypeCustom.get();
                        }],
                        routing_tariff_amadeus : ['RoutingTariffAmadeusCustom', function(RoutingTariffAmadeusCustom) {
                            return RoutingTariffAmadeusCustom.get();
                        }],

                        routing_tariff_sabre : ['RoutingTariffSabreCustom', function(RoutingTariffSabreCustom) {
                            return RoutingTariffSabreCustom.get();
                        }],
                        fare_type : ['FareTypeCustom', function(FareTypeCustom) {
                            return FareTypeCustom.get();
                        }],
                        fare_type_apf : ['FareTypeAPFCustom', function(FareTypeAPFCustom) {
                            return FareTypeAPFCustom.get();
                        }]
                    }
                }).result.then(function() {
                	$state.go('contract.fare-calculated', null, {reload : true});
                }, function() {
                	$state.go('contract.fare-calculated', null, {reload : true});
                });
            }]
        })
        .state('contract.fare-calculated.delete', {
            parent : 'contract.fare-calculated',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/fare-calculated/form-fare-calculated-delete-dialog.html',
                    controller : 'FormFareCalculatedDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {
                    	translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                         	$translatePartialLoader.addPart('fareCalculated');
                         	return $translate.refresh();
                      	}]
                    }
                }).result.then(function(result) {
                	$state.go('contract.fare-calculated', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                	$state.go('^');
            	})
            }]
        })
         .state('contract.fare-calculated.copy', {
         	parent : 'contract.fare-calculated',
         	url : '/{index}/copy',
         	data : {
         		authorities : [ 'ROLE_ADMIN', 'ROLE_USER','ROLE_ADMIN_USER' ],
         	},
         	onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                 $uibModal.open({
                     templateUrl : 'app/entities/contract/fare-calculated/form-fare-calculated-copy-dialog.html',
                     controller : 'FormFareCalculatedCopyDialogController',
                     controllerAs : 'vm',
                     size : 'md',
                     resolve : {
                     }
                 }).result.then(function(result) {
                 	$state.go('contract.fare-calculated.edit', {index : result, id_contract : $stateParams.id_contract, duplicateFare : true}, {reload : true});
                 }, function() {
                 	$state.go('^');
                 })
             }]
         })
        // .state('contract.fare.upload', {
        //     parent : 'contract.fare',
        //     url : '/upload',
        //     data : {
        //         authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
        //         pageTitle : 'americanoApp.form.detail.title'
        //     },
        //     onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        //     	$uibModal.open({
        //             templateUrl : 'app/entities/contract/fare/form-upload-fare.html',
        //             controller : 'FormUploadFareController',
        //             controllerAs : 'vm',
        //             backdrop : 'static',
        //             size : 'md',
        //             resolve : {
        //                 entity : function() {
        //                 	return {
	     //                        file : null,
	     //                        contractId : null,
	     //                        contentType : null,
	     //                        containError : false,
	     //                        fareList : null,
	     //                        userList : [],
	     //                        group : null
        //                     };
        //                 },
        //                 translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
        //                 	$translatePartialLoader.addPart('fare');
        //                 	return $translate.refresh();
        //              	}]
        //             }
        //         }).result.then(function() {
	     //            $state.go('contract.fare', null, { reload : true });
        //         }, function() {
        //             $state.go('contract.fare', null, { reload : true });
        //         });
        //     }]
        // })
    }
})();
