(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TravelSegIndicatorSabreDeleteController',TravelSegIndicatorSabreDeleteController);

    TravelSegIndicatorSabreDeleteController.$inject = ['$uibModalInstance', 'entity', 'TravelSegIndicatorSabre'];

    function TravelSegIndicatorSabreDeleteController($uibModalInstance, entity, TravelSegIndicatorSabre) {
        var vm = this;
        vm.travelSegIndicatorSabre = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TravelSegIndicatorSabre.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
