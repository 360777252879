(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormDistributionDeleteDialogController', FormDistributionDeleteDialogController);

    FormDistributionDeleteDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'Contract'];

    function FormDistributionDeleteDialogController ($scope, $stateParams, $uibModalInstance, Contract) {
    	var vm = this; 
    	vm.id = $stateParams.id;
    	vm.index = $stateParams.index;
    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };
        
        vm.confirmDelete = function () {
        	Contract.get({id:$stateParams.id_contract}, function(data){

        		data.data[3].pages.splice(vm.index, 1);  
        		
        		Contract.update(data, function(result){
    	    		console.log("Update sukses");
    	    		$uibModalInstance.close(true);
    	    	}, function(error){
    	    		console.log("Update error ");
    	    		console.log(error);
    	    	});
        	});
	    };
    }
})();