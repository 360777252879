(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TourAgentsOtherDiscountController', TourAgentsOtherDiscountController);

    TourAgentsOtherDiscountController.$inject = ['$scope', '$parse', '$state', '$stateParams', '$uibModal', '$uibModalInstance',
        'tour_agents_other_discount','ContractValue', 'ContractTourAgentsOtherDiscount', 'currencies', 'passenger_type', 'fare_type'];

    function TourAgentsOtherDiscountController ($scope, $parse, $state, $stateParams, $uibModal, $uibModalInstance,
    		tour_agents_other_discount, ContractValue, ContractTourAgentsOtherDiscount, currencies, passenger_type, fare_type) {
    	var vm = this;
        vm.currencies = currencies;
        vm.passengers = passenger_type;
    	vm.contractValue = ContractValue;
        vm.fare_type = fare_type;
    	vm.entity = ContractTourAgentsOtherDiscount.get({ id : $stateParams.id_contract });
		vm.tour_agents_other_discount = tour_agents_other_discount;
		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;
		vm.identifier = null;
		
		vm.entity.$promise.then(function(contract) {
    		vm.tourAgentsOtherDiscountRules = contract.data[28].pages;
    		
    		if (vm.index < vm.tourAgentsOtherDiscountRules.length)
    			vm.editTourAgentsOtherDiscount(vm.tourAgentsOtherDiscountRules[vm.index]);
    	});

		
		
		vm.editTourAgentsOtherDiscount = function(x){
		    vm.tour_agents_other_discount.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[0], vm.tour_agents_other_discount);
            });
			vm.editObjectTourAgentsOtherDiscount = x;
			vm.isTourAgentsOtherDiscountEdit = true;
		};
		
		
		vm.saveTourAgentsOtherDiscountEdit = function(){
			vm.entity.data[28].pages[vm.entity.data[28].pages.indexOf(vm.editObjectTourAgentsOtherDiscount)].forms[0] = angular.copy(vm.tour_agents_other_discount);

			vm.editObjectTourAgentsOtherDiscount = null;
			vm.isTourAgentsOtherDiscountEdit = false;

		};

		
		

     	//Tour Agents Other Discount
		vm.addTourAgentsOtherDiscount = function(){
			vm.entity.data[28].pages.push({
				forms:[
					angular.copy(vm.tour_agents_other_discount)
				]
			});
		};

		vm.save = function(){
            var forms = [vm.tour_agents_other_discount  ];

            if (vm.isTourAgentsOtherDiscountEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractTourAgentsOtherDiscount.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractTourAgentsOtherDiscount.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                });
            }

        };

      
        vm.pushDataDiscountSegments = function(x, y){
        	y[0].value = vm.dataDiscountSegments.rules_tour_agents_other_discount_discount_segments_multivalue_fare_class
        	y[1].value = vm.dataDiscountSegments.rules_tour_agents_other_discount_discount_segments_multivalue_booking_code
        	y[2].value = vm.dataDiscountSegments.rules_tour_agents_other_discount_discount_segments_multivalue_min_discount_passenger
        	y[3].value = vm.dataDiscountSegments.rules_tour_agents_other_discount_discount_segments_multivalue_max_discount_passenger
        	y[4].value = vm.dataDiscountSegments.rules_tour_agents_other_discount_discount_segments_multivalue_passenger_type
        	y[5].value = vm.dataDiscountSegments.rules_tour_agents_other_discount_discount_segments_multivalue_min_age
        	y[6].value = vm.dataDiscountSegments.rules_tour_agents_other_discount_discount_segments_multivalue_max_age
        	       	
        	if(vm.tour_agents_other_discount.form_items[x].value == null){
        		vm.tour_agents_other_discount.form_items[x].value = [];
        	}
        	vm.tour_agents_other_discount.form_items[x].value.push(y);
        	var z = angular.copy(vm.tour_agents_other_discount.form_items[x].value);
        	vm.tour_agents_other_discount.form_items[x].value = z;

            vm.dataDiscountSegments = [];
        };

        vm.removeDataDiscountSegments = function(x, y){
        	vm.tour_agents_other_discount.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.tour_agents_other_discount.form_items[x].value);
        	vm.tour_agents_other_discount.form_items[x].value = z;
        };
      
        vm.copyDataDiscountSegments = function(x, y){
        	vm.data = angular.copy(vm.tour_agents_other_discount.form_items[x].value[y]);        	
        	vm.tour_agents_other_discount.form_items[x].value.push(vm.data);
        	
        	var newItemIndex = vm.tour_agents_other_discount.form_items[x].value.length - 1;        	
        	
        	if (vm.isEdit == undefined || vm.isEdit == null) {
        		vm.isEdit = {}
        	}        	
        	
        	vm.isEdit[newItemIndex] = true;
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

                
        vm.checkIdentifier = function(tag, value){
        	if(tag == "rules_tour_agents_other_discount_base_fare_identifier"){
        		vm.identifier = value;
        		console.log(vm.identifier);
        	}
        };
   }
})();
