(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TravelSegIndicatorAmadeusDetailController', TravelSegIndicatorAmadeusDetailController);

    TravelSegIndicatorAmadeusDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TravelSegIndicatorAmadeus'];

    function TravelSegIndicatorAmadeusDetailController($scope, $rootScope, $stateParams, entity, TravelSegIndicatorAmadeus) {
        var vm = this;
        vm.travelSegIndicatorAmadeus = entity;
        
        var unsubscribe = $rootScope.$on('americanoApp:travelSegIndicatorAmadeusUpdate', function(event, result) {
            vm.travelSegIndicatorAmadeus = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
