(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormCreateDistributionController', FormCreateDistributionController);

    FormCreateDistributionController.$inject = [
    '$scope', '$parse', '$state', '$stateParams',
    'airlines', 'ActingAs',
    'passengers','fare_type','routing_tariff_sabre','season_type',
    //Tab 1
    'formVendorSetDefaultAssignPermission',

    //Tab 2
    'formDistributionSpecificTicketDetailPassenger',
    'formDistributionSpecificTicketDetailPlateStock',
    'formDistributionSpecificTicketDetailTicketingSegment',
    'formDistributionSpecificTicketDetailAdditionalTicketingInstruction',
    'formDistributionSpecificTicketDetailCommision',
    'formDistributionSpecificTicketDetailBaggage',

    //Tab 3
    'formDistributionFareCreator',
    'formDistributionFareCreatorFareCalculation',

    //Tab 4
    'formDistributionFareBox',
    'formDistributionTicketedFaresSabre',
    'formDistributionFareBoxPayment',
    'formDistributionTextInformation',
    '$filter',
    'Fare', 'ContractDistribution', 'DistributionGroupFindByGdsAndTypePcc', 'DistributionGroupMaster', 'Currencies', '$uibModalInstance', 'ContractValue'];

    function FormCreateDistributionController (
    		$scope, $parse, $state, $stateParams,
    		airlines,ActingAs,
            passengers,fare_type,routing_tariff_sabre,season_type,
    		formVendorSetDefaultAssignPermission,


            formDistributionSpecificTicketDetailPassenger,
    		formDistributionSpecificTicketDetailPlateStock,
    		formDistributionSpecificTicketDetailTicketingSegment,
    		formDistributionSpecificTicketDetailAdditionalTicketingInstruction,
    		formDistributionSpecificTicketDetailCommision,
    		formDistributionSpecificTicketDetailBaggage,
    		formDistributionFareCreator,
    		formDistributionFareCreatorFareCalculation,
    		formDistributionFareBox,
    		formDistributionTicketedFaresSabre,
    		formDistributionFareBoxPayment,
    		formDistributionTextInformation,
    		$filter,
    		Fare, ContractDistribution, DistributionGroupFindByGdsAndTypePcc, DistributionGroupMaster, Currencies, $uibModalInstance, ContractValue) {

    	var vm = this;
        vm.index = $stateParams.index;
        vm.forms = null;

        if (vm.index != null && vm.index != undefined) {
        	ContractDistribution.get({ id: $stateParams.id_contract,  index:vm.index}, function(data) {
        		vm.forms = data;
                vm.isDistributionEdit = true;

        		for(var i=0;i<vm.forms.forms[0].form_items.length;i++){
            		if(vm.forms.forms[0].form_items[i].tag == "distribution_vendor_distribution_type"){
            			vm.selectVendorType(vm.forms.forms[0].form_items[i].value);
            		}
            	}

                vm.formVendorSetDefaultAssignPermission.$promise.then(function() {
                	ContractValue.setValueFromDatabase(vm.forms.forms[0], vm.formVendorSetDefaultAssignPermission);


                });
                vm.formDistributionSpecificTicketDetailPlateStock.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[1], vm.formDistributionSpecificTicketDetailPlateStock);
                });
                vm.formDistributionSpecificTicketDetailTicketingSegment.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[2], vm.formDistributionSpecificTicketDetailTicketingSegment);
                });
                vm.formDistributionSpecificTicketDetailCommision.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[3], vm.formDistributionSpecificTicketDetailCommision);
                });
                vm.formDistributionSpecificTicketDetailBaggage.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[4], vm.formDistributionSpecificTicketDetailBaggage);
                });
                vm.formDistributionFareCreator.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[5], vm.formDistributionFareCreator);
                });
                vm.formDistributionFareCreatorFareCalculation.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[6], vm.formDistributionFareCreatorFareCalculation);
                });
                vm.formDistributionFareBox.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[7], vm.formDistributionFareBox);
                });
                vm.formDistributionTicketedFaresSabre.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[8], vm.formDistributionTicketedFaresSabre);
                });
                vm.formDistributionFareBoxPayment.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[9], vm.formDistributionFareBoxPayment);
                });

                vm.formDistributionSpecificTicketDetailPassenger.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[10], vm.formDistributionSpecificTicketDetailPassenger);
                });
                vm.formDistributionTextInformation.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[11], vm.formDistributionTextInformation);
                });
                vm.formDistributionSpecificTicketDetailAdditionalTicketingInstruction.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[12], vm.formDistributionSpecificTicketDetailAdditionalTicketingInstruction);
                });

        	}, function(error) {});
        } else
        	vm.isDistributionEdit = false;


		vm.airlines = airlines;
		vm.passengers = passengers;
		vm.fare_type=fare_type;
		vm.routing_tariff_sabre=routing_tariff_sabre;
		vm.season_type=season_type;
		vm.currencies = Currencies;
		vm.formVendorSetDefaultAssignPermission = formVendorSetDefaultAssignPermission;
		vm.formDistributionSpecificTicketDetailPlateStock = formDistributionSpecificTicketDetailPlateStock;
		vm.formDistributionSpecificTicketDetailTicketingSegment = formDistributionSpecificTicketDetailTicketingSegment;
		vm.formDistributionSpecificTicketDetailAdditionalTicketingInstruction = formDistributionSpecificTicketDetailAdditionalTicketingInstruction;
		vm.formDistributionSpecificTicketDetailCommision = formDistributionSpecificTicketDetailCommision;
		vm.formDistributionSpecificTicketDetailBaggage = formDistributionSpecificTicketDetailBaggage;
		vm.formDistributionFareCreator = formDistributionFareCreator;
		vm.formDistributionFareCreatorFareCalculation = formDistributionFareCreatorFareCalculation;
		vm.formDistributionFareBox = formDistributionFareBox;
		vm.formDistributionTicketedFaresSabre = formDistributionTicketedFaresSabre;
		vm.formDistributionFareBoxPayment = formDistributionFareBoxPayment;
		vm.formDistributionSpecificTicketDetailPassenger = formDistributionSpecificTicketDetailPassenger;
		vm.formDistributionTextInformation = formDistributionTextInformation;
        vm.isDistributionEdit = false;

		//Function Tab 1
		vm.typeSelected = function(gds, type){
			if(gds == 'travelport'){
				vm.pccTravelport = [];
				DistributionGroupFindByGdsAndTypePcc.query({gds:"travelport", typePcc:type}, function(data){
					vm.pccTravelport = data;
				});
			}
			else if(gds == 'amadeus'){
				vm.pccAmadeus = [];
				DistributionGroupFindByGdsAndTypePcc.query({gds:"amadeus", typePcc:type}, function(data){
					vm.pccAmadeus = data;
				});
			}
			else if(gds == 'sabre'){
				vm.pccSabre = [];
				DistributionGroupFindByGdsAndTypePcc.query({gds:"sabre", typePcc:type}, function(data){
					vm.pccSabre = data;
				});
			}
		};

		DistributionGroupMaster.findByGds({gds:"travelport"}, function(data){
			vm.pccTravelportMaster = data;
			console.log(data);
		});
		DistributionGroupMaster.findByGds({gds:"amadeus"}, function(data){
			vm.pccAmadeusMaster = data;
			console.log(data);
		});
		DistributionGroupMaster.findByGds({gds:"sabre"}, function(data){
			vm.pccSabreMaster = data;
			console.log(data);
		});

		//acting as
		ActingAs.getAll({gds:"APF"}, function(data){
			vm.pccActingAsTravelport= data;
			console.log(data);
		});
		ActingAs.getAll({gds:"AMADEUS"}, function(data){
			vm.pccActingAsAmadeus = data;
			console.log(data);
		});
		ActingAs.getAll({gds:"SABRE"}, function(data){
			vm.pccActingAsSabre= data;
			console.log(data);
		});


		vm.pushMultivalue = function(x, y){
        	if(x.value == null){
        		x.value = [];
        	}
        	x.value.push(y);
        	var z = angular.copy(x.value);
        	x.value = z;
        };

        vm.removeMultivalue = function(item, y){
        	item.value.splice(y, 1);
        	var z = angular.copy(item.value);
        	item.value = z;
        }

        vm.copyMultivalueT = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditTravelport == undefined || vm.isEditTravelport == null) {
        		vm.isEditTravelport = {}
        	}

        	vm.isEditTravelport[newItemIndex] = true;
        };

        vm.copyMultivalueA = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditAmadeus == undefined || vm.isEditAmadeus == null) {
        		vm.isEditAmadeus = {}
        	}

        	vm.isEditAmadeus[newItemIndex] = true;
        };

        vm.copyMultivalueS = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditSabre == undefined || vm.isEditSabre == null) {
        		vm.isEditSabre = {}
        	}

        	vm.isEditSabre[newItemIndex] = true;
        };

        vm.copyMultivalueTravelportMaster = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditTravelportMaster == undefined || vm.isEditTravelportMaster == null) {
        		vm.isEditTravelportMaster = {}
        	}

        	vm.isEditTravelportMaster[newItemIndex] = true;
        };

        vm.copyMultivalueSabreMaster = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditSabreMaster == undefined || vm.isEditSabreMaster == null) {
        		vm.isEditSabreMaster = {}
        	}

        	vm.isEditSabreMaster[newItemIndex] = true;
        };

        vm.copyMultivalueAmadeusMaster = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditAmadeusMaster == undefined || vm.isEditAmadeusMaster == null) {
        		vm.isEditAmadeusMaster = {}
        	}

        	vm.isEditAmadeusMaster[newItemIndex] = true;
        };


        vm.copyCommision = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditDistributionCommission == undefined || vm.isEditDistributionCommission == null) {
        		vm.isEditDistributionCommission = {}
        	}

        	vm.isEditDistributionCommission[newItemIndex] = true;
        };

        vm.copyFareCalculation = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditFareCreator == undefined || vm.isEditFareCreator == null) {
        		vm.isEditFareCreator = {}
        	}

        	vm.isEditFareCreator[newItemIndex] = true;
        };


        vm.checkDistributionTypeValue = function() {
        	var disabled = true;

        	if (vm.formVendorSetDefaultAssignPermission.form_items != null) {
	        	if (vm.formVendorSetDefaultAssignPermission.form_items[1].value != null){
	        		if (vm.formVendorSetDefaultAssignPermission.form_items[1].value.length > 0)
	    				disabled = false;
	        	}
	        	if (vm.formVendorSetDefaultAssignPermission.form_items[2].value != null){
	        		if (vm.formVendorSetDefaultAssignPermission.form_items[2].value.length > 0)
	    				disabled = false;
	        	}
	        	if (vm.formVendorSetDefaultAssignPermission.form_items[3].value != null){
	        		if (vm.formVendorSetDefaultAssignPermission.form_items[3].value.length > 0)
	    				disabled = false;
	        	}
	        	if (vm.formVendorSetDefaultAssignPermission.form_items[4].value != null){
	        		if (vm.formVendorSetDefaultAssignPermission.form_items[4].value.length > 0)
	    				disabled = false;
	        	}
	        	if (vm.formVendorSetDefaultAssignPermission.form_items[5].value != null){
	        		if (vm.formVendorSetDefaultAssignPermission.form_items[5].value.length > 0)
	    				disabled = false;
	        	}
	        	if (vm.formVendorSetDefaultAssignPermission.form_items[6].value != null){
	        		if (vm.formVendorSetDefaultAssignPermission.form_items[6].value.length > 0)
	    				disabled = false;
	        	}
        	}

        	return disabled;
        }
		//End Function Tab 1

        //Function Tab 2
        vm.checkPlateStock = function(tag){
            if(tag == "distribution_specific_ticket_detail_plate_stock_required_indicator" || tag == "distribution_specific_ticket_detail_plate_stock_carrier"){
                for(var i=0;i<vm.formDistributionSpecificTicketDetailPlateStock.form_items.length;i++){
                    if(vm.formDistributionSpecificTicketDetailPlateStock.form_items[i].tag == "distribution_specific_ticket_detail_plate_stock_restriction"){
                        if(vm.formDistributionSpecificTicketDetailPlateStock.form_items[i].value != null && vm.formDistributionSpecificTicketDetailPlateStock.form_items[i].value != "" && vm.formDistributionSpecificTicketDetailPlateStock.form_items[i].value != []){
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                }
            }
            return true;
        };

        //End Function Tab 2
		vm.currentStep = 1;
		vm.dataPcc = [];
		vm.fieldDisabled = [true, true, true, true, true];
		vm.showBetweenAndOrder = false;
		vm.methods = [];


		vm.checkHideBetweenAndOrder = function(value) {
			if (value == "All Routes in This Contract")
				vm.showBetweenAndOrder = false;
			else if (value == "Between - And")
				vm.showBetweenAndOrder = true;
		}

		vm.changeMethodsList = function(value) {
			vm.methods = [];

			if (value != "" && value != null && value != undefined) {
				if (value == "Calculated")
					vm.methods.push("Percentage");
				else if (value == "Specified")
					vm.methods.push("Amount");
				else {
					vm.methods.push("Percentage");
					vm.methods.push("Amount");
				}
			}
		}

		vm.save = function(){
            var forms = [
                vm.formVendorSetDefaultAssignPermission,
                vm.formDistributionSpecificTicketDetailPlateStock,
                vm.formDistributionSpecificTicketDetailTicketingSegment,
                vm.formDistributionSpecificTicketDetailCommision,
                vm.formDistributionSpecificTicketDetailBaggage,
                vm.formDistributionFareCreator,
                vm.formDistributionFareCreatorFareCalculation,
                vm.formDistributionFareBox,
                vm.formDistributionTicketedFaresSabre,
                vm.formDistributionFareBoxPayment,
                vm.formDistributionSpecificTicketDetailPassenger,
                vm.formDistributionTextInformation,
                vm.formDistributionSpecificTicketDetailAdditionalTicketingInstruction
            ];

            if (vm.isDistributionEdit){
//                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractDistribution.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
//                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractDistribution.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.pushCommision = function(x, y){
        	if(x.value == null){
        		x.value = [];
        	}
        	x.value.push(y);
        	var z = angular.copy(x.value);
        	x.value = z;
        };

        vm.pushTicketedFareSabre = function(x, y){
        	if(x.value == null){
        		x.value = [];
        	}
        	x.value.push(y);
        	var z = angular.copy(x.value);
        	x.value = z;
        };

        vm.copyTicketedFareSabre = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditTicketedFareSabre == undefined || vm.isEditTicketedFareSabre == null) {
        		vm.isEditTicketedFareSabre = {}
        	}

        	vm.isEditTicketedFareSabre[newItemIndex] = true;
        };

        vm.removeTicketedFareSabre = function(item, y){
        	item.value.splice(y, 1);
        	var z = angular.copy(item.value);
        	item.value = z;
        }

        vm.removeCommision = function(item, y){
        	item.value.splice(y, 1);
        	var z = angular.copy(item.value);
        	item.value = z;
        }

        vm.pushFareCalculation = function(x, y){
        	if(x.value == null){
        		x.value = [];
        	}
        	x.value.push(y);
        	var z = angular.copy(x.value);
        	x.value = z;
        };

        vm.removeFareCalculation = function(item, y){
        	item.value.splice(y, 1);
        	var z = angular.copy(item.value);
        	item.value = z;
        }

        vm.pushCalculationOrder = function(x, y){
        	if(vm.form8.form_items[x].value == null){
        		vm.form8.form_items[x].value = [];
        	}
        	vm.form8.form_items[x].value.push(y);
        	var z = angular.copy(vm.form8.form_items[x].value);
        	vm.form8.form_items[x].value = z;
        };

        vm.removeCalculationOrder = function(x, y){
        	vm.form8.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form8.form_items[x].value);
        	vm.form8.form_items[x].value = z;
        }

        vm.resetFarebox = function() {
        	vm.formDistributionFareBox.form_items.forEach(function(item) {
        		item.value = "";
        	});
        }

        vm.resetValue = function(element, item) {
//        	if(element.isHide()){
//
//        	}
//        	console.log(item.value);
//        	item.value = "";
//			console.log(item.value);
        	console.log(element);
		}

        vm.selectType = function(item){
        	vm.formDistributionFareBox.form_items.forEach(function(item) {
        		if(item.tag == "distribution_farebox_amount" || item.tag == "distribution_farebox_currency" ||  item.tag == "distribution_farebox_ticketed_fare" || item.tag == "distribution_farebox_use_yy_fare"){
        			item.value = "";
        		}
        	});
        }

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.dropdownFareboxChange = function(tag){
    		if(tag == "distribution_farebox_reporting_method"){
	    		for(var a=0;a<vm.formDistributionFareBox.form_items.length;a++){
	        		if(vm.formDistributionFareBox.form_items[a].tag == "distribution_farebox_amount_1"){
	        			vm.formDistributionFareBox.form_items[a].value = null;
	        		}
	        		else if(vm.formDistributionFareBox.form_items[a].tag == "distribution_farebox_currency_1"){
	        			vm.formDistributionFareBox.form_items[a].value = null;
	        		}
	        		else if(vm.formDistributionFareBox.form_items[a].tag == "distribution_farebox_method"){
	        			vm.formDistributionFareBox.form_items[a].value = null;
	        		}
	        	}
    		}
        }

        vm.checkShowFarebox = function(tag){
        	var val = null;
        	for(var a=0;a<vm.formDistributionFareBox.form_items.length;a++){
        		if(vm.formDistributionFareBox.form_items[a].tag == "distribution_farebox_reporting_method"){
        			val = vm.formDistributionFareBox.form_items[a].value;
        		}
        	}



        	if(tag == "distribution_farebox_reporting_method" || tag == "distribution_farebox_ticketed_indicator"){
    			return true;
    		}
        	if(val == "IT" || val == "BT"){
        		if(tag == "distribution_farebox_method"){
        			return true;
        		}
        	}
        	if(val == "Net Remit/Ticketed Fare"){
        		if(tag == "distribution_farebox_method"  || tag == "distribution_farebox_amount_1" || tag == "distribution_farebox_currency_1"){
        			return true;
        		}
        	}
        	return false;
        };

        vm.checkShowFareBoxOptions = function(tag){

        	if(tag == "distribution_farebox_ticketed_indicator" || tag == "distribution_farebox_create_discounted_fares"){
        		return true;
        	}


        	//check options condition
        	var val = null;
        	var ticketedIndicator = null;

        	for(var a=0;a<vm.formDistributionFareBox.form_items.length;a++){
        		if(vm.formDistributionFareBox.form_items[a].tag == "distribution_farebox_options"){
        			val = vm.formDistributionFareBox.form_items[a].value;
        		}
        	}
        	for(var a=0;a<vm.formDistributionFareBox.form_items.length;a++){
        		if(vm.formDistributionFareBox.form_items[a].tag == "distribution_farebox_ticketed_indicator"){
        			ticketedIndicator = vm.formDistributionFareBox.form_items[a].value;
        			break;
        		}
        	}

        	if(val != null){
	        	if(val == "Retrieve fare basis or fare family and amount" || val == "Use Contract fare basis or fare family and retrieve fare amount" ||
	         			val == "Retrieve the fare basis/family specified and amount, if not found retrieve the public fare that matches the contract fare basis/family, if not found use the contract selling fare" ||
	         			val == "Retrieve fare basis or fare or fare family and use contract fare amount"){

	         		if(tag == "distribution_farebox_ticketed_fare" || tag == "distribution_farebox_use_yy_fare"){
	         			return true;
	         		}
	         	}

	         	else if(val == "Use specified fare basis and amount"){
	         		if(tag == "distribution_farebox_ticketed_fare" || tag == "distribution_farebox_currency" || tag == "distribution_farebox_amount"){
	         			return true;
	         		}
	         	}
	         	else if(val == "Use specified pricing and ticket fare"){
	         		if(tag == "distribution_farebox_ticketed_fare" || tag == "distribution_farebox_pricing_fare"){
	         			return true;
	         		}
	         	}
         	}

     		//check ticketedIndicator condition
     		if(ticketedIndicator != null){
     			if(ticketedIndicator == "Manual Ticketed Amount"){
     				if(tag == "distribution_farebox_currency" || tag == "distribution_farebox_amount"){
             			return true;
             		}
     			}
     			else if(ticketedIndicator == "Public Fare Basis & Amount From" || ticketedIndicator == "Nego Fare Basis & Public Amount From" ){
     				if(tag == "distribution_farebox_ticketed_fare" || tag == "distribution_farebox_use_yy_fare"){
             			return true;
             		}
     			}
     		}

         	return false;
        };

        vm.checkReportingMethod = function(){
            var reportingMethod = "";
            for(var x=0;x<vm.formDistributionFareBox.form_items.length;x++){
                if(vm.formDistributionFareBox.form_items[x].tag == "distribution_farebox_reporting_method"){
                    reportingMethod = vm.formDistributionFareBox.form_items[x].value;
                    break;
                }
            }
            var disable = false;
            /*
            if(reportingMethod == "IT" || reportingMethod == "BT"){
                vm.formDistributionFareBox.form_items.forEach(function(item) {
                    if(item.tag != "distribution_farebox_reporting_method" || item.tag != "distribution_farebox_method") {
                        item.value = "";
                    }
                });
                disable = true;
            }
            */
            return disable;
        }

        vm.commisionTypeChange = function(){

        }

        vm.checkShowFareCalculationMethod = function(tag){
        	if(tag == "distribution_fare_creator_location_1" || tag == "distribution_fare_creator_location_2"){
        		return true;
        	}

        	var str = vm.formDistributionFareCreatorFareCalculation.form_items[0].form_items_child[5].value;
        	if(str != null){
            	var res = str.substring(0,1);
            	if(res == "C"){
            		if(tag == "distribution_fare_creator_fare_calculation_method_percentage")
            			return true;
            	}
            	if(res == "S"){
            		if(tag == "distribution_fare_creator_fare_calculation_method_specified_amount" || tag == "distribution_fare_creator_fare_calculation_method_currency_1")
            			return true;
            	}
            	if(res == "A"){
            		if(tag == "distribution_fare_creator_fare_calculation_method_percentage" ||
            				tag == "distribution_fare_creator_fare_calculation_method_specified_amount" || tag == "distribution_fare_creator_fare_calculation_method_currency_1")
            			return true;
            	}
            	if(res == "M"){
            		if(tag == "distribution_fare_creator_fare_calculation_method_percentage" ||
            				tag == "distribution_fare_creator_fare_calculation_method_specified_amount" || tag == "distribution_fare_creator_fare_calculation_method_currency_1")
            			return true;
            	}
            	if(res == "P"){
            		if(tag == "distribution_fare_creator_fare_calculation_method_minimum_percentage" || tag == "distribution_fare_creator_fare_calculation_method_maximum_percentage")
            			return true;
            	}
            	if(res == "R"){
            		if(tag == "distribution_fare_creator_fare_calculation_method_minimum_amount_1" ||
            				tag == "distribution_fare_creator_fare_calculation_method_maximum_amount_1" ||
            				tag == "distribution_fare_creator_fare_calculation_method_currency_2" ||
            				tag == "distribution_fare_creator_fare_calculation_method_minimum_amount_2" ||
            				tag == "distribution_fare_creator_fare_calculation_method_maximum_amount_2" ||
            				tag == "distribution_fare_creator_fare_calculation_method_currency_3"
            		)
            			return true;
            	}
            	if(res == "N"){
            		if(tag == "distribution_fare_creator_fare_calculation_method_minimum_percentage" || tag == "distribution_fare_creator_fare_calculation_method_maximum_percentage" ||
            				tag == "distribution_fare_creator_fare_calculation_method_minimum_amount_1" ||
            				tag == "distribution_fare_creator_fare_calculation_method_maximum_amount_1" ||
            				tag == "distribution_fare_creator_fare_calculation_method_currency_2" ||
            				tag == "distribution_fare_creator_fare_calculation_method_minimum_amount_2" ||
            				tag == "distribution_fare_creator_fare_calculation_method_maximum_amount_2" ||
            				tag == "distribution_fare_creator_fare_calculation_method_currency_3"
            		)
            			return true;
            	}
            	if(res == "T"){
            		if(tag == "distribution_fare_creator_fare_calculation_method_minimum_percentage" || tag == "distribution_fare_creator_fare_calculation_method_maximum_percentage" ||
            				tag == "distribution_fare_creator_fare_calculation_method_minimum_amount_1" ||
            				tag == "distribution_fare_creator_fare_calculation_method_maximum_amount_1" ||
            				tag == "distribution_fare_creator_fare_calculation_method_currency_2" ||
            				tag == "distribution_fare_creator_fare_calculation_method_minimum_amount_2" ||
            				tag == "distribution_fare_creator_fare_calculation_method_maximum_amount_2" ||
            				tag == "distribution_fare_creator_fare_calculation_method_currency_3"
            		)
            			return true;
            	}
        	}

        	return false;
        };

        vm.selectVendorType = function(a){
        	console.log("SELECT VENDOR TYPE : "+a);
        	if(a == "Vendor / Distribution Group"){
        		vm.isDistributionGroup = true;
        	}
        	else if(a == "Vendor Aggregate / M.Distribution Group"){
        		vm.isDistributionGroup = false;
        	}
        	else{
        		vm.isDistributionGroup = null;
        	}
        }
    }
})();
