(function() {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('americanoApp')
        .factory('FormUtil', FormUtil);
    
    FormUtil.$inject = ['$parse'];
    function FormUtil ($parse) {
    	
    	var service = {
    		bindFormToEntity:bindFormToEntity,
    		bindEntityToForm:bindEntityToForm
    	};
    	
    	return service;
    	
    	//save
    	function bindFormToEntity(forms, entity, vm, onBind){
    		var filter = function(input, tag) {
    		    var k=0, form=input.length;
    		    for(;k<form;k++){
    			    var i = 0, len = input[k].form_items.length;
    		    	for (; i<len; i++) {
    		    		if(input[k].form_items[i].tag == tag){
    			    		return input[k].form_items[i].value;
    			    	}
    			    }
    		    }
    		    return null;
    		 };
    		 
    		for(var key in entity){
    			console.log(key);
				var exclude = [
				  "id",
				  "contract",
				];

				if(exclude.indexOf(key) == -1){
					var forms = forms;
					var found = filter(forms, key);
					var ref = 'entity.'+key;
				    var getter = $parse(ref);
//				    console.log(found);
					getter.assign(vm, found);
				}
			}
//    		console.log(entity);
    		onBind(entity);
    	}
    	
    	//update
    	function bindEntityToForm(entity, scope, onBind){
    		var forms = entity;
    		
        	var form = forms.length;
			  for(var k=0;k<form;k++){
				  for(var l=0;l<forms[k].form_items.length;l++){
					  var tag = forms[k].form_items[l].tag;
					  console.log(tag);
					  forms[k].form_items[l].value = scope.$eval("vm.entity."+tag);
				  }
			  }
			  onBind(forms);
    	}
    }
})();