(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('RuleDetailController', RuleDetailController);

    RuleDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Rule'];

    function RuleDetailController($scope, $rootScope, $stateParams, entity, Rule) {
        var vm = this;
        vm.rule = entity;
        vm.load = function (id) {
            Rule.get({id: id}, function(result) {
                vm.rule = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:ruleUpdate', function(event, result) {
            vm.rule = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
