(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('RoutingDialogController', RoutingDialogController);

    RoutingDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Routing'];

    function RoutingDialogController ($scope, $stateParams, $uibModalInstance, entity, Routing) {
        var vm = this;
        vm.routing = entity;
        vm.load = function(id) {
            Routing.get({id : id}, function(result) {
                vm.routing = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:routingUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.routing.id !== null) {
                Routing.update(vm.routing, onSaveSuccess, onSaveError);
            } else {
                Routing.save(vm.routing, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
