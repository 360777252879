(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('InfoText', InfoText);

    InfoText.$inject = ['$resource'];

    function InfoText ($resource) {
        var resourceUrl =  'api/info-texts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
	    .module('americanoApp')
	    .factory('InfoTextCustom', InfoTextCustom);
	
    InfoTextCustom.$inject = ['$resource'];
	
	function InfoTextCustom ($resource) {
	    var resourceUrl =  'api/info-texts/tag/:tag';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	 angular
	 	.module('americanoApp')
	     .factory('InfoTextSearch', InfoTextSearch);
	 
	 InfoTextSearch.$inject = ['$resource'];
	
	 function InfoTextSearch($resource) {
	 	var resourceUrl = 'api/_search/info-texts/:query'; 
	 	return $resource(resourceUrl, {}, {
	         'query': { method: 'GET', isArray: true}
	     });
	 }
	 
})();
