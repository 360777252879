(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('GdsFormMappingDetailsDetailController', GdsFormMappingDetailsDetailController);

    GdsFormMappingDetailsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'GdsFormMappingDetails'];

    function GdsFormMappingDetailsDetailController($scope, $rootScope, $stateParams, entity, GdsFormMappingDetails) {
        var vm = this;
        vm.gdsFormMappingDetails = entity;
        vm.load = function (id) {
            GdsFormMappingDetails.get({id: id}, function(result) {
                vm.gdsFormMappingDetails = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:gdsFormMappingDetailsUpdate', function(event, result) {
            vm.gdsFormMappingDetails = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
