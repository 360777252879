(function() {
	'use strict';

	angular.module('americanoApp').controller(
			'VendorAggregateDialogController',
			VendorAggregateDialogController);

	VendorAggregateDialogController.$inject = [ '$scope', '$filter',
			'$rootScope', '$stateParams', '$state',  "entity",
			"VendorAggregate", "$parse", 'Vendor', 'pagingParams',
			'paginationConstants', 'ParseLinks' , 'VendorAggregateMember', 'VendorAggregateMemberDeleteByVendor', 'VendorAggregateMemberFindByVendorAggregate', '$uibModalInstance'];

	function VendorAggregateDialogController($scope, $filter, $rootScope,
			$stateParams, $state, entity, VendorAggregate, $parse,
			Vendor, pagingParams, paginationConstants, ParseLinks, VendorAggregateMember, VendorAggregateMemberDeleteByVendor, 
			VendorAggregateMemberFindByVendorAggregate, $uibModalInstance) {
		
		var vm = this;
		vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;
		vm.currentStep = 1;
		vm.vendorAggregate = entity;
		
		vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll();
        
		vm.listMember = [];
		vm.vendors = [];
		vm.removedMember = [];
		
		if(vm.vendorAggregate.$promise != null){
			vm.vendorAggregate.$promise.then(function(vendorAggregate){
				VendorAggregateMemberFindByVendorAggregate.query({id:vendorAggregate.id}, function(result){
					result.forEach(function(data){
						vm.listMember.push(data.vendor);
					});
//					loadAllVendor();
				});
			});
		}
		
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
		vm.addToGroup = function(vendorEntity) {
			//cek vendor apakah sudah masuk grup tertentu
//			console.log(vendorEntity.group);
			
			var addToGrp = function (vendorEntity){
				vm.removedMember.forEach(function(check){
					if(check.id == vendorEntity.id){
						vm.removedMember.splice(vm.removedMember.indexOf(check), 1);
					}
				});
				vm.listMember.push(vendorEntity);
				vm.vendors.splice(vm.vendors.indexOf(vendorEntity), 1);
			};
			
			if(vendorEntity.group != null){
				if (confirm('You are about changing group of this grouped vendor. Are you sure?')) {
					addToGrp(vendorEntity);
				} else {
				    // Do nothing!
				}
			}else{
				addToGrp(vendorEntity);
			}
		}

		vm.removeFromGroup = function(vendorEntity) {
			vm.removedMember.push(vendorEntity);
			vm.listMember.splice(vm.vendors.indexOf(vendorEntity), 1);
//			vm.loadAllVendor();
			vm.loadAll();
		}


//		function transition() {
//			/*
//			 * $state.transitionTo($state.$current, { page: vm.page, sort:
//			 * vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'), search:
//			 * vm.currentSearch });
//			 */
//			$state.transitionTo($state.$current, {
//				page : vm.page,
//				sort : vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
//				search : vm.currentSearch,
//				id_contract : $stateParams.id_contract,
//				currentStep : vm.currentStep
//			});
//		
//			console.log($state.$current);
//		}

		function loadAll() {
			Vendor.query({
				page : pagingParams.page - 1,
				size : vm.itemsPerPage,
				sort : sort()
			}, onSuccess, onError);
			function sort() {
				var result = [ vm.predicate + ','
						+ (vm.reverse ? 'asc' : 'desc') ];
				if (vm.predicate !== 'id') {
					result.push('id');
				}
				return result;
			}
			function onSuccess(data, headers) {
				vm.links = ParseLinks.parse(headers('link'));
				vm.totalItems = headers('X-Total-Count');
				vm.queryCount = vm.totalItems;
				vm.vendors = [];
				
				data.forEach(function(x) {
					var isAllowedInsert = true;
					vm.listMember.forEach(function(y) {
						
						if (x.id === y.id) {
							isAllowedInsert = false;
						}
					});

					if (isAllowedInsert) {
						vm.vendors.push(x);
					}
				});
				
				vm.page = pagingParams.page;

			}

			function onError(error) {
				vm.loadAll();
			}
		}
		
		function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
         	   	id_contract: $stateParams.id_contract,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
				currentStep : vm.currentStep
            });
        }
		
		vm.save = function() {
			if (vm.vendorAggregate.id == null) {
				VendorAggregate.save(vm.vendorAggregate, function(result) {
					vm.listMember.forEach(function(member){
						var vendorAggregateMember = {
							vendor:member,
							vendorAggregate:result
						};
						VendorAggregateMember.save(vendorAggregateMember);
					});
					
					$uibModalInstance.close(result);
				}, function(error) {
					console.log(error);
				});
			} else {
				VendorAggregate.update(vm.vendorAggregate, function(result) {
					vm.listMember.forEach(function(member){
						var vendorAggregateMember = {
							vendor:member,
							vendorAggregate:result
						};
						VendorAggregateMember.save(vendorAggregateMember);
					});
					
					vm.removedMember.forEach(function(member){
						VendorAggregateMemberDeleteByVendor.delete({id:member.id});
					});
					
					$uibModalInstance.close(result);
				}, function(error) {
					console.log(error);
				});
			}
		};
	}

})();