(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('AdvanceReservationController', AdvanceReservationController);

    AdvanceReservationController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', '$uibModal',
    'ContractAdvanceReservation', 'form2', 'form3', 'form4', '$uibModalInstance', 'travelSegmentIndicatorSabre','ContractValue'];

    function AdvanceReservationController (
    		$scope, $parse, $state, $stateParams, $uibModal,
    		ContractAdvanceReservation, form2, form3, form4, $uibModalInstance, travelSegmentIndicatorSabre, ContractValue) {

    	var vm = this;
    	vm.entity = ContractAdvanceReservation.get({ id : $stateParams.id_contract });
		vm.form2 = form2;
		vm.form3 = form3;
		vm.form4 = form4;
		vm.travelSegmentIndicatorSabre = travelSegmentIndicatorSabre;
		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;

		console.log(vm.travelSegmentIndicatorSabre);

		vm.entity.$promise.then(function(contract) {
    		vm.advReservationRules = contract.data[6].pages;

    		if (vm.index < vm.advReservationRules.length)
    			vm.editAdvanceReservation(vm.advReservationRules[vm.index]);
    	});

		//ADVANCE RESERVATION
		vm.addAdvanceReservation = function(){
			vm.entity.data[6].pages.push({
				forms:[
					angular.copy(vm.form2),
					angular.copy(vm.form3),
					angular.copy(vm.form4)
				]
			});
		};

		vm.editAdvanceReservation = function(x){
		    vm.form2.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form2);
            });
		    vm.form3.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[1], vm.form3);
            });
		    vm.form4.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[2], vm.form4);
            });
            // ContractValue.setValueFromDatabase(x.forms[0], vm.form2);
            // ContractValue.setValueFromDatabase(x.forms[1], vm.form3);
            // ContractValue.setValueFromDatabase(x.forms[2], vm.form4);

			//vm.form2 = angular.copy(x.forms[0]);
			//vm.form3 = angular.copy(x.forms[1]);
			//vm.form4 = angular.copy(x.forms[2]);

			vm.editObjectAdvanceReservation = x;
			vm.isAdvanceReservationEdit = true;
		};

		vm.saveAdvanceReservationEdit = function(){
			vm.entity.data[6].pages[vm.entity.data[6].pages.indexOf(vm.editObjectAdvanceReservation)].forms[0] = angular.copy(vm.form2);
			vm.entity.data[6].pages[vm.entity.data[6].pages.indexOf(vm.editObjectAdvanceReservation)].forms[1] = angular.copy(vm.form3);
			vm.entity.data[6].pages[vm.entity.data[6].pages.indexOf(vm.editObjectAdvanceReservation)].forms[2] = angular.copy(vm.form4);

			vm.editObjectAdvanceReservation = null;
			vm.isAdvanceReservationEdit = false;
		};
		//END ADVANCE RESERVATION

		vm.Date = function(arg){
        	if(arg == null){
          	   return new Date();
        	}else{
        		if(arg != "indef"){
        			return new Date(arg);
        		}else{
        			return "indef";
        		}
        	}
     	};

     	vm.save = function(){
            var forms = [vm.form2, vm.form3, vm.form4];

            if (vm.isAdvanceReservationEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractAdvanceReservation.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractAdvanceReservation.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isAdvanceReservationEdit)
				// vm.saveAdvanceReservationEdit();
            // else
				// vm.addAdvanceReservation();
            //
            // ContractAdvanceReservation.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.resetReservationTimeRuleDataChange = function(tag) {
        	if (tag.toLowerCase() == "reservation_time_rule_type") {
        		if (vm.form3.form_items[7] != undefined && vm.form3.form_items[7] != null)
        			vm.form3.form_items[7].value = null;
        		if (vm.form3.form_items[5] != undefined && vm.form3.form_items[5] != null)
        			vm.form3.form_items[5].value = null;
        		if (vm.form3.form_items[6] != undefined && vm.form3.form_items[6] != null)
        			vm.form3.form_items[6].value = null;
        	}
        }

        vm.resetTicketingTimeRuleDataChange = function(tag) {
        	if (tag.toLowerCase() == "ticketing_time_rule") {
        		if (vm.form4.form_items[6] != undefined && vm.form4.form_items[6] != null)
        			vm.form4.form_items[6].value = null;
        		if (vm.form4.form_items[7] != undefined && vm.form4.form_items[7] != null)
        			vm.form4.form_items[7].value = null;
        		if (vm.form4.form_items[1] != undefined && vm.form4.form_items[1] != null)
        			vm.form4.form_items[1].value = null;
        		if (vm.form4.form_items[2] != undefined && vm.form4.form_items[2] != null)
        			vm.form4.form_items[2].value = null;
        		if (vm.form4.form_items[3] != undefined && vm.form4.form_items[3] != null)
        			vm.form4.form_items[3].value = null;
        		if (vm.form4.form_items[4] != undefined && vm.form4.form_items[4] != null)
        			vm.form4.form_items[4].value = null;
        		if (vm.form4.form_items[5] != undefined && vm.form4.form_items[5] != null)
        			vm.form4.form_items[5].value = null;
        	}
        }

        vm.tagTab1 = ["rules_advance_reservation_1_inbound_outbound"];
        vm.tagTabTextOnly = ["rules_advance_reservation_1_text_information_only", "rules_advance_reservation_1_note_amadeus", "rules_advance_reservation_1_note_sabre", "rules_advance_reservation_1_note", "rules_advance_reservation_1_note_apf", "rules_advance_reservation_1_override"];
    }
})();
