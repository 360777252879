(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('NotificationDialogController', NotificationDialogController);

    NotificationDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Notification', 'Principal'];

    function NotificationDialogController ($scope, $stateParams, $uibModalInstance, entity, Notification, Principal) {
        var vm = this;
        vm.notification = entity;
        vm.load = function(id) {
            Notification.get({id : id}, function(result) {
                vm.notification = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:notificationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
        	Principal.identity().then(function(account) {
	        	vm.notification.user.push(account);
	            vm.isSaving = true;
	            if (vm.notification.id !== null) {
	                Notification.update(vm.notification, onSaveSuccess, onSaveError);
	            } else {
	                Notification.save(vm.notification, onSaveSuccess, onSaveError);
	            }
        	});
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
