(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('StayMeasurementFromSabreDeleteController',StayMeasurementFromSabreDeleteController);

    StayMeasurementFromSabreDeleteController.$inject = ['$uibModalInstance', 'entity', 'StayMeasurementFromSabre'];

    function StayMeasurementFromSabreDeleteController($uibModalInstance, entity, StayMeasurementFromSabre) {
        var vm = this;
        vm.stay_measurement_from_sabre = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
        	StayMeasurementFromSabre.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
