(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('ReturnTravelMaxStayAPFDialogController', ReturnTravelMaxStayAPFDialogController);

    ReturnTravelMaxStayAPFDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ReturnTravelMaxStayAPF'];

    function ReturnTravelMaxStayAPFDialogController ($scope, $stateParams, $uibModalInstance, entity, ReturnTravelMaxStayAPF) {
        var vm = this;
        vm.returnTravelMaxStayAPF = entity;
        vm.load = function(id) {
            ReturnTravelMaxStayAPF.get({id : id}, function(result) {
                vm.returnTravelMaxStayAPF = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:returnTravelMaxStayAPFUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.returnTravelMaxStayAPF.id !== null) {
                ReturnTravelMaxStayAPF.update(vm.returnTravelMaxStayAPF, onSaveSuccess, onSaveError);
            } else {
                ReturnTravelMaxStayAPF.save(vm.returnTravelMaxStayAPF, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
