(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('DistributionGroupMasterDialogController', DistributionGroupMasterDialogController);

    DistributionGroupMasterDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity',
        'ParseLinks', 'pagingParams',
        'DistributionGroupMaster',
        'DistributionGroupSearch', 'DistributionSearchGds',
        'DistributionSearchMultiGds', 'DistributionGroupCustom'];

    function DistributionGroupMasterDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, ParseLinks, pagingParams,
                                                      DistributionGroupMaster,
                                                      DistributionGroupSearch, DistributionSearchGds,
                                                      DistributionSearchMultiGds, DistributionGroupCustom) {

        var vm = this;
        vm.distributionGroupMaster = entity;
         vm.dataDistributionGroup = [];
        vm.clear = clear;
        vm.save = save;
        vm.selectedRoutes = [];
        vm.routeIndex = [];
        vm.selectedStatus = [];
        vm.index = 0;
        vm.selectedRow = [];
        vm.selectedRoutesInContract = [];
        vm.selection = [];

        /*vm.loadAll = loadAll;
        vm.loadAll();*/
        vm.getSelectedRoutesInContract = getSelectedRoutesInContract;
        vm.getSelectedRoutesInContract();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            console.log(vm.distributionGroupMaster.form_items_child.length);
            console.log(vm.selectedDistributionMasterSaved);
            for(var x=0; x<vm.distributionGroupMaster.form_items_child.length; x++){
            	if(vm.distributionGroupMaster.form_items_child[x] != null){
	                if(vm.selectedDistributionMasterSaved[x].sell != undefined) {
	                    vm.distributionGroupMaster.form_items_child[x].sell = vm.selectedDistributionMasterSaved[x].sell;
	                }
	                if(vm.selectedDistributionMasterSaved[x].ticket != undefined) {
	                    vm.distributionGroupMaster.form_items_child[x].ticket = vm.selectedDistributionMasterSaved[x].ticket;
	                }
	                if(vm.selectedDistributionMasterSaved[x].update != undefined) {
	                    vm.distributionGroupMaster.form_items_child[x].update = vm.selectedDistributionMasterSaved[x].update;
	                }
	                if(vm.selectedDistributionMasterSaved[x].redistribute != undefined) {
	                    vm.distributionGroupMaster.form_items_child[x].redistribute = vm.selectedDistributionMasterSaved[x].redistribute;
	                }
            	}
            }
            if (vm.distributionGroupMaster.id !== null) {
                DistributionGroupMaster.update(vm.distributionGroupMaster, onSaveSuccess, onSaveError);
            } else {
                DistributionGroupMaster.save(vm.distributionGroupMaster, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('americanoApp:distributionGroupMasterUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        /*function loadAll() {
            DistributionGroupCustom.get({
                id_contract: $stateParams.id_contract,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.dataDistributionGroup = data;
                vm.page = pagingParams.page;
                vm.lastIndex = vm.queryCount - 1;
                vm.index = vm.page - 1;
            }
            function onError(error) {
                vm.loadAll();
            }

            /!*if(vm.distributionGroupMaster.gdsMaster == 'Travelport'){
                vm.filterMultiGds(vm.distributionGroupMaster.gdsMaster);
            } else if(vm.distributionGroupMaster.gdsMaster == 'Sabre' || vm.distributionGroupMaster.gdsMaster == 'Amadeus'){
                vm.filterGds(vm.distributionGroupMaster.gdsMaster);
            }*!/
        };*/

        function getSelectedRoutesInContract() {
            var hasError = false;
            vm.selectedDistributionMasterSaved = [];
            angular.forEach(vm.distributionGroupMaster.form_items_child, function (value, key) {
                if(value != null){
	                if(value.update == "true"){
	                    value.update = true;
	                }
	                if(value.redistribute == "true"){
	                    value.redistribute = true;
	                }
	                if(value.sell == "true"){
	                    value.sell = true;
	                }
	                if(value.ticket == "true"){
	                    value.ticket = true;
	                }
	                
	                var copyValue = {};
	                angular.copy(value, copyValue);
	                vm.selectedDistributionMasterSaved.push(copyValue);
                }
                else{
//                	value.update = false;
//                	value.redistribute = false;
//                	value.sell = false;
//                	value.ticket = false;
                }
                
                
            });
        }

        vm.checkAll = function(type){
            if(type == 'update'){
                if(vm.checkboxUpdate){
                    for(var i=0;i<vm.selectedDistributionMasterSaved.length;i++){
                        vm.selectedDistributionMasterSaved[i].update = true;
                    }
                }
                else{
                    for(var i=0;i<vm.selectedDistributionMasterSaved.length;i++){
                        vm.selectedDistributionMasterSaved[i].update = false;
                    }
                }
            }
            if(type == 'sell'){
                if(vm.checkboxSell){
                    for(var i=0;i<vm.selectedDistributionMasterSaved.length;i++){
                        vm.selectedDistributionMasterSaved[i].sell = true;
                    }
                }
                else{
                    for(var i=0;i<vm.selectedDistributionMasterSaved.length;i++){
                        vm.selectedDistributionMasterSaved[i].sell = false;
                    }
                }
            }
            if(type == 'ticket'){
                if(vm.checkboxTicket){
                    for(var i=0;i<vm.selectedDistributionMasterSaved.length;i++){
                        vm.selectedDistributionMasterSaved[i].ticket = true;
                    }
                }
                else{
                    for(var i=0;i<vm.selectedDistributionMasterSaved.length;i++){
                        vm.selectedDistributionMasterSaved[i].ticket = false;
                    }
                }
            }
            if(type == 'redistribute'){
                if(vm.checkboxRedistribute){
                    for(var i=0;i<vm.selectedDistributionMasterSaved.length;i++){
                        vm.selectedDistributionMasterSaved[i].redistribute = true;
                    }
                }
                else{
                    for(var i=0;i<vm.selectedDistributionMasterSaved.length;i++){
                        vm.selectedDistributionMasterSaved[i].redistribute = false;
                    }
                }
            }
        }

        vm.registerAllRoutes = function(isChecked) {
            if (isChecked) {
                vm.selectedRow = [];
                vm.selectedRoutes = [];
                vm.routeIndex = [];
                for (var i = 0; i < vm.dataDistributionGroup.length; i++) {
                    vm.selectedRoutes.push(vm.dataDistributionGroup[i]);
                    vm.routeIndex.push(vm.lastIndex - i - (vm.index * vm.size));
                    vm.selectedStatus[i] = true;
                    vm.selectedRow.push(vm.lastIndex - i);
                }

            } else {
                vm.selectedRow = [];
                vm.selectedRoutes = [];
                vm.routeIndex = [];
                for (var i = 0; i < vm.selectedStatus.length; i++) {
                    vm.selectedStatus[i] = false;
                }
            }

        }

        vm.registerRoute = function(transactionIndex, distribution, index) {
            if (vm.selectedStatus[index]) {
                vm.selectedRoutes.push(distribution);
                vm.routeIndex.push(transactionIndex - (vm.index * vm.size));
                vm.selectedStatus[index] = true;

            } else {
                vm.selectedRoutes.splice(vm.selectedRoutes.indexOf(distribution), 1);
                vm.routeIndex.splice(vm.routeIndex.indexOf(transactionIndex), 1);
                vm.selectedStatus[index] = false;
            }

            if (vm.selectedRoutes.length == vm.dataDistributionGroup.length) {
                vm.master = true;
            } else
                vm.master = false;

        }

        vm.rowHighlighted = function(row, distribution, index) {
        	if (vm.distributionGroupMaster.gdsMaster != null || vm.distributionGroupMaster.gdsMaster != undefined) {
        		 var selectedExist = false;

                 for (var i = 0; i < vm.selectedRow.length; i++) {
                     if (vm.selectedRow[i] == row) {
                         vm.selectedRow.splice(i, 1);
                         selectedExist = true;
                         break;
                     }
                 }

                 if (selectedExist)
                     vm.selectedStatus[index] = false;
                 else {
                     vm.selectedStatus[index] = true;
                     vm.selectedRow.push(row);
                 }

                 vm.registerRoute(row, distribution, index);
        	}
        }

        vm.getSelectedRowStatus = function(row) {
            var status = false;

            if (vm.master) {
                status = true;
            } else {
                for (var i = 0; i < vm.selectedRow.length; i++) {
                    if (vm.selectedRow[i] == row) {
                        status = true;
                        break;
                    }
                }
            }

            return status;
        }

        vm.resetCheckbox = function() {
            vm.selectedRoutes = [];
            for (var i = 0; i < vm.selectedStatus.length; i++) {
                vm.selectedStatus[i] = false;
            }
            vm.master = false;
        }

        vm.addSelectedRoutes = function() {

            if(!vm.distributionGroupMaster.form_items_child){
                vm.distributionGroupMaster.form_items_child = [];
            }

            console.log("ADD SELECTED ROUTES");
            console.log(vm.selectedRoutes);
            vm.selectedRoutes.forEach(function(distribution) {
            	if(distribution != null){
            		vm.distributionGroupMaster.form_items_child.push(distribution);
            	}
            });
            vm.getSelectedRoutesInContract();
            vm.resetCheckbox();
        }

        vm.deleteRoutingInContract = function(index) {
            vm.distributionGroupMaster.form_items_child.splice(index, 1);
            vm.selectedDistributionMasterSaved.splice(index, 1);
        }

        vm.searchTypeOptions = [
            {key:'', value:'Select criteria to be search'},
                {key:'pcc', value:'PCC'},
                {key:'name', value:'Name'},
                {key:'description', value:'Description'}
            ];

        vm.search = function () {
            vm.rowHighlighted(null, null);

            if(vm.searchQuery != undefined && vm.searchType != ''){
            	DistributionGroupSearch.query({
                    query: vm.searchQuery,
                    type: vm.searchType
                }, onSuccess, onError);
            } else {
                // vm.loadAll();
            }

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data) {
                vm.dataDistributionGroup = data;

                console.log(vm.dataDistributionGroup);
                vm.page = pagingParams.page;
            }
            function onError(error) {
                // vm.loadAll();
            }

        };

        // vm.emptySearchCheck = function() {
        //     if (!vm.searchQuery)
        //         vm.loadAll();
        // }

        vm.filterGds = function (gds) {
            DistributionSearchGds.query({
                page: pagingParams.page - 1,
                size: vm.searchResultItemsPerPage,
                sort: sort(),
                query: gds
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.dataDistributionGroup = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                // vm.loadAll();
            }
        }

        vm.filterMultiGds = function (gds) {
            DistributionSearchMultiGds.query({
                page: pagingParams.page - 1,
                size: vm.searchResultItemsPerPage,
                sort: sort(),
                query: gds
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.dataDistributionGroup = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                // vm.loadAll();
            }
        }

        vm.toggleSelection = function (gds) {
        	if(gds != null && gds != ""){
	            var idx = vm.selection.indexOf(gds);
	            if(idx > -1){
	                vm.selection.splice(idx,1);
	            } else {
	                vm.selection.push(gds);
	            }

	            vm.filterMultiGds(vm.selection);
        	}
        };

        vm.resetTravelport = function () {
            vm.filterMultiGds('empty');
            vm.selection = [];
        }

    }
})();
