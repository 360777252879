(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('StopoverDeleteDialogController', StopoverDeleteDialogController);

    StopoverDeleteDialogController.$inject = ['$scope', '$uibModalInstance', '$stateParams', 'ContractStopover'];

    function StopoverDeleteDialogController ($scope, $uibModalInstance, $stateParams, ContractStopover) {
        var vm = this;
        vm.id = $stateParams.id_contract;
        // vm.index = JSON.parse("[" + $stateParams.index + "]");

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.confirmDelete = function () {
            ContractStopover.delete({id:vm.id, index: $stateParams.index}, function(result){
                console.log("Update sukses");
                $uibModalInstance.close(true);
            }, function(error){
                console.log("Update error ");
                console.log(error);
            });
        };
    }
})();
