(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('AccompaniedTravelController', AccompaniedTravelController);

    	AccompaniedTravelController.$inject = [
        '$scope', '$parse', '$state', '$stateParams', '$uibModal',
        'ContractAccompaniedTravels', 'form1', 'form2', 'ContractFareBasisCode', 'ContractFareBookingCode', 'ContractFarePassengerType', '$uibModalInstance', 'ContractValue'];

    function AccompaniedTravelController (
        $scope, $parse, $state, $stateParams, $uibModal,
        ContractAccompaniedTravels, form1, form2, ContractFareBasisCode, ContractFareBookingCode, ContractFarePassengerType, $uibModalInstance, ContractValue) {

        var vm = this;
        vm.entity = ContractAccompaniedTravels.get({ id : $stateParams.id_contract });
        vm.form1 = form1;
        vm.form2 = form2;
        vm.id_contract = $stateParams.id_contract;
        vm.index = $stateParams.index;
        vm.fareSelectionType = null;
        vm.farePassengerTypeMaster = [];

        vm.entity.$promise.then(function(contract) {
            vm.ruleAppRules = contract.data[20].pages;

            if (vm.index < vm.ruleAppRules.length)
                vm.editRuleAccompaniedTravel(vm.ruleAppRules[vm.index]);

            vm.farePassengerTypeMaster = ContractFarePassengerType.get({ id : $stateParams.id_contract });
        });

        //RULES ACCOMPANIED TRAVEL
        vm.addRuleAccompaniedTravel = function(){
            vm.entity.data[20].pages.push({
                forms:[
                    angular.copy(vm.form1),
                    angular.copy(vm.form2)
                ]
            });
        }

        vm.editRuleAccompaniedTravel = function(x){
            vm.form1.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            });
            vm.form2.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[1], vm.form2);
            });
            // ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            // ContractValue.setValueFromDatabase(x.forms[1], vm.form2);

            vm.editObjectRuleAccompaniedTravel = x;
            vm.isRuleAccompaniedTravelEdit = true;
        };

        vm.saveAccompaniedTravelEdit = function(){
            vm.entity.data[20].pages[vm.entity.data[20].pages.indexOf(vm.editObjectRuleAccompaniedTravel)].forms[0] = angular.copy(vm.form1);
            vm.entity.data[20].pages[vm.entity.data[20].pages.indexOf(vm.editObjectRuleAccompaniedTravel)].forms[1] = angular.copy(vm.form2);

            vm.editObjectRuleAccompaniedTravel = null;
            vm.isRuleAccompaniedTravelEdit = false;
        };
        //END RULES ACCOMPANIED TRAVEL

        //FARE BASIS/CLASS (VALUE)
        vm.pushFareBasisClassValue = function(x, y){
        	if (vm.fareBasisClassValue == undefined || vm.fareBasisClassValue.rule_accompanied_travel_restriction_fare_selection_value == null || vm.fareBasisClassValue.rule_accompanied_travel_restriction_fare_selection_value == "")
        		return;

        	if(vm.form2.form_items[x].value == null){
        		vm.form2.form_items[x].value = [];
        	}

        	if (vm.fareSelectionType.toLowerCase() == "fare basis" || vm.fareSelectionType.toLowerCase() == "booking code / class") {
        		y[0].value = vm.fareBasisClassValue.rule_accompanied_travel_restriction_fare_selection_value;

            	var exist = false;

            	for (var i = 0; i < vm.form2.form_items[x].value.length; i++) {
        			if (vm.form2.form_items[x].value[i][0].value.toLowerCase() == y[0].value.toLowerCase()) {
            			exist = true;
            			break;
                	}
            	}

            	if (!exist) {
            		vm.form2.form_items[x].value.push(y);
                	var z = angular.copy(vm.form2.form_items[x].value);
                	vm.form2.form_items[x].value = z;
            	}
        	} else if (vm.fareSelectionType.toLowerCase() == "fare family") {
        		var fareFamilyChar = vm.fareBasisClassValue.rule_accompanied_travel_restriction_fare_selection_value;
        		var exist = false;

        		vm.fareBasisCodeMaster.forEach(function(fbc) {
        			if (fbc.charAt(0).toUpperCase() == fareFamilyChar.charAt(0).toUpperCase()) {
        				exist = false;

        				for (var i = 0; i < vm.form2.form_items[x].value.length; i++) {
        					if (vm.form2.form_items[x].value[i][0].value.toLowerCase() == fbc.toLowerCase()) {
        						exist = true;
        						break;
        					}
        				}

        				if (!exist) {
        					y[0].value = fbc;

            				vm.form2.form_items[x].value.push(y);
                        	var z = angular.copy(vm.form2.form_items[x].value);
                        	vm.form2.form_items[x].value = z;
        				}
        			}
        		});
        	} else if (vm.fareSelectionType.toLowerCase() == "fare group") {

        	}

            vm.fareBasisClassValue = [];
        };

        vm.removeFareBasisClassValue = function(x, y){
        	vm.form2.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form2.form_items[x].value);
        	vm.form2.form_items[x].value = z;
        }
                
        vm.copyFareBasisClassValue = function(x, y){
        	vm.data = angular.copy(vm.form2.form_items[x].value[y]);        	
        	vm.form2.form_items[x].value.push(vm.data);
        	
        	var newItemIndex = vm.form2.form_items[x].value.length - 1;        	
        	
        	if (vm.isEditFareBasis == undefined || vm.isEditFareBasis == null) {
        		vm.isEditFareBasis = {}
        	}        	
        	
        	vm.isEditFareBasis[newItemIndex] = true;
        };
        //END FARE BASIS/CLASS (VALUE)

        vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
    	};

		vm.save = function(){
            var forms = [vm.form1, vm.form2];

            if (vm.isRuleAccompaniedTravelEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractAccompaniedTravels.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractAccompaniedTravels.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isRuleAccompaniedTravelEdit)
				// vm.saveAccompaniedTravelEdit();
            // else
				// vm.addRuleAccompaniedTravel();
            //
            // ContractAccompaniedTravels.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        }

        vm.changeFareBasisClassType = function(type) {
        	vm.form2.form_items[2].value = [];

        	vm.fareBasisClassMaster = [];
        	vm.fareSelectionType = type;

        	if (type.toLowerCase() == "fare basis") {
        		ContractFareBasisCode.get({id: vm.id_contract}, function(success) {
    	           	vm.fareBasisClassMaster = success.customStringListVariableType;
    	       	}, function(error) {});
        	} else if (type.toLowerCase() == "fare family") {
        		ContractFareBasisCode.get({id: vm.id_contract}, function(success) {
    	           	vm.fareBasisCodeMaster = success.customStringListVariableType;

    	           	vm.fareBasisCodeMaster.forEach(function(fbc) {
    	           		if (vm.fareBasisClassMaster.indexOf(fbc.charAt(0) + "*") == -1)
    	           			vm.fareBasisClassMaster.push(fbc.charAt(0) + "*");
    	           	});
    	       	}, function(error) {});
        	} else if (type.toLowerCase() == "fare group") {

        	} else if (type.toLowerCase() == "booking code / class") {
        		ContractFareBookingCode.get({id: vm.id_contract}, function(success) {
    	           	vm.fareBasisClassMaster = success.customStringListVariableType;
    	       	}, function(error) {});
        	}
        }
    }
})();
