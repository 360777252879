(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('GdsFormMappingDetailsDeleteController',GdsFormMappingDetailsDeleteController);

    GdsFormMappingDetailsDeleteController.$inject = ['$uibModalInstance', 'entity', 'GdsFormMappingDetails'];

    function GdsFormMappingDetailsDeleteController($uibModalInstance, entity, GdsFormMappingDetails) {
        var vm = this;
        vm.gdsFormMappingDetails = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            GdsFormMappingDetails.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
