(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('VendorAggregate', VendorAggregate);

    VendorAggregate.$inject = ['$resource'];

    function VendorAggregate ($resource) {
        var resourceUrl =  'api/vendor-aggregates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
    .module('americanoApp')
    .factory('VendorAggregateCustom', VendorAggregateCustom);

    VendorAggregateCustom.$inject = ['$resource'];
	
	function VendorAggregateCustom($resource) {
		var resourceUrl =  'api/vendor-aggregates/getAllVendorAggregates';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
})();
