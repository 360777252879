/**
 * Created by rendito on 20-Sep-16.
 */

(function() {
    'use strict';

    angular
        .module('americanoApp')
        .filter('newline', function($sce) {
            return function(text) {
            	if (text != null && text != undefined) {
            		text = text.replace(/\n/g, '<br />');
            	}
                return $sce.trustAsHtml(text);
            }
        });
})();
