(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('NotificationDetailController', NotificationDetailController);

    NotificationDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'entity', 'Notification', 'ContractValue'];

    function NotificationDetailController($scope, $state, $rootScope, $stateParams, entity, Notification, ContractValue) {
        var vm = this;
        vm.notification = entity;

        vm.contractValue = ContractValue;
        vm.load = function (id) {
            Notification.get({id: id}, function(result) {
                vm.notification = result;
            });
        };
        
        vm.checkContractStatus = function(contract) {
        	var status = false;
        	
        	if (contract.target_system != null) {
        		if (contract.target_system.length < 1)
            		status = true;
        	} else
        		status = true;
        	
        	return status;
        }
        
        var unsubscribe = $rootScope.$on('americanoApp:notificationUpdate', function(event, result) {
            vm.notification = result;
        });
        
        $scope.$on('$destroy', unsubscribe);
    }
})();
