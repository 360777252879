(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormContractPublishMultipleController', FormContractPublishMultipleController);

    FormContractPublishMultipleController.$inject = ['$scope', '$stateParams', '$interval', 'ContractMultiple', 'ContractValue', '$uibModalInstance', 'ContractGDSStatus', 'ContractValidate', 'ContractPublish'];

    function FormContractPublishMultipleController ($scope, $stateParams, $interval, ContractMultiple, ContractValue, $uibModalInstance, ContractGDSStatus, ContractValidate, ContractPublish) {
    	var vm = this; 
    	var validateInterval, publishInterval;
    	
    	vm.idURL = $stateParams.id_list;
        vm.contractValue = ContractValue;
        vm.contract = [];
        vm.targetSystem = [];
        vm.GDSOption = ['Sabre', 'Travelport', 'Amadeus'];
        vm.checkContractStatus = checkContractStatus;
        vm.initialCheck = initialCheck;
        vm.showValidateTable = false;
        vm.showPublishTable = false;
        vm.statusOkToPublishNow = false;
        vm.hasBeenValidated = false;
        vm.validateTravelportResult = [];
        vm.validateSabreResult = [];
        vm.validateAmadeusResult = [];
        vm.totalValidateError = 0;
        
    	vm.idList = vm.idURL.split(',');
    	
    	var contractIDs = {
    			customStringVariableType : null,
            	customLongVariableType : null,
            	customIntegerVariableType : null,
    			customStringListVariableType : vm.idList
    	}
    	
    	ContractMultiple.get(contractIDs).$promise.then(function(result) {
        	vm.contract = result;
        	
        	vm.initialCheck();
        });
    	
    	function initialCheck() {
    		ContractGDSStatus.get(contractIDs, function(data){
        		angular.forEach(data, function(value, key){
        			for (var j = 0; j < vm.contract.length; j++) {
        				if (vm.contract[j].id == key) {
        					vm.temporaryGDSStatus = value;
        					
        					if (vm.temporaryGDSStatus.length > 0) {
        						for (var k = 0; k < vm.temporaryGDSStatus.length; k++) {
        							if (vm.temporaryGDSStatus[k].validateStatus != undefined || vm.temporaryGDSStatus[k].validateStatus != null) {
            							vm.showValidateTable = true;
            							break;
            						}
        						}
        						
        						for (var k = 0; k < vm.temporaryGDSStatus.length; k++) {
        							if (vm.temporaryGDSStatus[k].currentPublishStatus != undefined || vm.temporaryGDSStatus[k].currentPublishStatus != null) {
                	    				vm.showPublishTable = true;
                	    				break;
            						}
        						}
        					}
        					
        					break;
        				}
        			}
	    		});
        		
        		if (vm.showValidateTable) {
        			if (angular.isDefined(validateInterval)) {
        				$interval.cancel(validateInterval);
        			}
        			
        			validateInterval = $interval(checkContractStatus, 3000);
//        			console.log("Validate Interval Running");
        		}
        			
//        		if (vm.showPublishTable) {
//        			if (angular.isDefined(publishInterval)) {
//        				$interval.cancel(publishInterval);
//        			}
//        			
//        			publishInterval = $interval(checkContractStatus, 3000);
//        			console.log("Publish Interval");
//        			console.log(publishInterval);
//        		}
			});
    		
    		checkContractStatus();
        }
    	
    	function checkContractStatus() {
    		console.log("Refreshing Status...");
    		
    		vm.totalValidateError = 0;
        	
    		ContractGDSStatus.get(contractIDs, function(data){
        		var allValidated = true, allPublished = true;
    			vm.hasValidateError = false;
    			vm.hasPublishError = false;
    			
	    		angular.forEach(data, function(value, key){
	    			for (var j = 0; j < vm.contract.length; j++) {
        				if (vm.contract[j].id == key) {
        					vm.contract[j].contractGdsStatus = value;
        					
        					if (vm.contract[j].contractGdsStatus.length < 1) {
        		    			allValidated = false;
        		    			allPublished = false;
        		    		}
        					
        					if (vm.contract[j].contractGdsStatus.length < vm.GDSOption.length)
        		    			allValidated = true;
        		    		else {
        		    			for (var k = 0; k < vm.contract[j].contractGdsStatus.length; k++) {
        		    				if (vm.contract[j].contractGdsStatus[k].validateStatus != null) {
        		    					vm.contract[j].contractGdsStatus[k].validateStatus = vm.contract[j].contractGdsStatus[k].validateStatus.toLowerCase();
        		    					
        		    					if (vm.contract[j].contractGdsStatus[k].validateStatus == "error") {
        		    						vm.hasValidateError = true;
        		    						if (vm.contract[j].contractGdsStatus[k].validateMessage != null && vm.contract[j].contractGdsStatus[k].validateMessage != '')
        		    							vm.totalValidateError = parseInt(vm.totalValidateError) + parseInt(vm.contract[j].contractGdsStatus[k].validateMessage);
        		    					}
        		    						
        		    					if (vm.contract[j].contractGdsStatus[k].validateStatus != "success" &&
        										vm.contract[j].contractGdsStatus[k].validateStatus != "notselected" && 
        										vm.contract[j].contractGdsStatus[k].validateStatus != "error" &&
        										vm.contract[j].contractGdsStatus[k].validateStatus != "failed")
        		    						allValidated = false;
        		    				}
        		    			}
        		    		}
        				}
	    			}
	    		});
	    		
	    		if (allValidated) {
    				if (angular.isDefined(validateInterval)) {
    					$interval.cancel(validateInterval);
//    					console.log("Destroyed validateInterval");
    				}	
    				
    				vm.hasBeenValidated = true;
    			}
				
//	    		
//	    		for (var i = 0; i < vm.contract[0].contractGdsStatus.length; i++) {
//					if(vm.contract[0].contractGdsStatus[i].currentPublishStatus != undefined) {
//						vm.contract[0].contractGdsStatus[i].currentPublishStatus = vm.contract[0].contractGdsStatus[i].currentPublishStatus.toLowerCase();
//						
//						if (vm.contract[0].contractGdsStatus[i].currentPublishStatus == "error")
//    						vm.hasPublishError = true;
//						
//						if (vm.contract[0].contractGdsStatus[i].currentPublishStatus != "success" &&
//								vm.contract[0].contractGdsStatus[i].currentPublishStatus != "notselected" && 
//								vm.contract[0].contractGdsStatus[i].currentPublishStatus != "error" &&
//								vm.contract[0].contractGdsStatus[i].currentPublishStatus != "failed")
//							allPublished = false;
//					} else 
//						allPublished = false;
//				}
//	    		
    			
//    			
//    			if (allPublished) {
//    				if (angular.isDefined(publishInterval))
//    					$interval.cancel(publishInterval);
//    			}
//    			
//    			if (vm.currentCheckingType == "publish" && allValidated) 
//	    			vm.statusOkToPublishNow = true;
	    	}, function(error){});
        }
    	
    	vm.validate = function(type) {
    		vm.currentCheckingType = type;
        	
        	vm.validateTravelportResult = [];
            vm.validateSabreResult = [];
            vm.validateAmadeusResult = [];
        	
        	vm.initialValidateGDSstatus = [ 
               {
                   gdsname : "Sabre",
                   validateStatus : "waiting",
               }, 
               {
            	   gdsname : "Amadeus",
                   validateStatus : "waiting",
               }, 
               {
            	   gdsname : "Travelport",
                   validateStatus : "waiting",
               }
            ];
        	
        	vm.contract.$promise.then(function(contractList){
        		for (var i = 0; i < contractList.length; i++) {
        			var contract = contractList[i];
        			
        			if (contract.contractGdsStatus.length < 1) {
        				contract.contractGdsStatus = vm.initialValidateGDSstatus;
    	        	}
        			
        			vm.GDSOption.forEach(function(gds) {
            			if (!vm.checkGDSExists(contract.contractGdsStatus, gds))
            				contract.contractGdsStatus.push({gdsname:gds, validateStatus: "waiting"});		
            		});
        			
        			for (var j = 0; j < contract.contractGdsStatus.length ; j++) {
            			contract.contractGdsStatus[j].validateStatus = "notselected";
            			
            			if (contract.contractGdsStatus[j].gdsname == "Sabre" && vm.checkGDSExists(vm.targetSystem, "Sabre", "array"))
            				contract.contractGdsStatus[j].validateStatus = "waiting";
            				
            			if (contract.contractGdsStatus[j].gdsname == "Amadeus" && vm.checkGDSExists(vm.targetSystem, "Amadeus", "array"))
            				contract.contractGdsStatus[j].validateStatus = "waiting";
            			
            			if (contract.contractGdsStatus[j].gdsname == "Travelport" && vm.checkGDSExists(vm.targetSystem, "APF", "array"))
            				contract.contractGdsStatus[j].validateStatus = "waiting";
            		}
        			
        			contract.target_system = vm.targetSystem;
        		}
        		
        		ContractMultiple.update(contractList, function(result) {
        			contractList = result;
        			
        			var data = {
                   		contract : contractList
                   	};
            		
            		vm.showValidateTable = true;
            		
            		ContractValidate.validate(data, function(resp){
            			console.log("Validate Message: " + resp.customStringListVariableType);
            			
            			var validateResult = null;
            			
            			if (resp.customStringListVariableType != null && resp.customStringListVariableType != undefined)  {
            				validateResult = resp.customStringListVariableType;
                			
                			validateResult.forEach(function(resultList) {
                				var result = JSON.parse(resultList);
                				
                				for (var i = 0; i < result.length; i++) {
                					if (result[i].Travelport != undefined && result[i].Travelport.toLowerCase() != 'success' && result[i].Travelport.toLowerCase() != 'error') 
                						vm.validateTravelportResult.push(result[i].Travelport);
                					
	                				if (result[i].Sabre != undefined && result[i].Sabre.toLowerCase() != 'success' && result[i].Sabre.toLowerCase() != 'error') 
	                					vm.validateSabreResult.push(result[i].Sabre);
	                				
	                				if (result[i].Amadeus != undefined && result[i].Amadeus.toLowerCase() != 'success' && result[i].Amadeus.toLowerCase() != 'error') 
	                					vm.validateAmadeusResult.push(result[i].Amadeus);
                				}
                			});
            			}
            			
            			vm.checkContractStatus();
           			
            			if (angular.isDefined(validateInterval)) 
            				$interval.cancel(validateInterval);
            			
            			validateInterval = $interval(checkContractStatus, 3000);
    		        }, function(error){});
        		});
            });
        }
        
    	vm.publish = function() {
    		for (var i = 0; i < vm.contract.length; i++) {
    			vm.GDSOption.forEach(function(gds) {
        			if (!vm.checkGDSExists(vm.contract[i].contractGdsStatus, gds))
        				vm.contract[i].contractGdsStatus.push({gdsname:gds, currentPublishStatus: "waiting"});		
        		});
    			
    			for (var j = 0; j < vm.contract[i].contractGdsStatus.length ; j++) {
        			if (vm.contract[i].contractGdsStatus[j].gdsname == "Sabre") 
        				vm.contract[i].contractGdsStatus[j].currentPublishStatus = "waiting";
        				
        			if (vm.contract[i].contractGdsStatus[j].gdsname == "Amadeus") 
        				vm.contract[i].contractGdsStatus[j].currentPublishStatus = "waiting";
        			
        			if (vm.contract[i].contractGdsStatus[j].gdsname == "Travelport") 
        				vm.contract[i].contractGdsStatus[j].currentPublishStatus = "waiting";
        		}
    			
    			Contract.update(vm.contract[i], function(result){}, function(error){});
    		}
    		
        		
    		var data = {
           		contract: vm.contract
           	};
    		
    		vm.showPublishTable = true;
    		
    		ContractPublish.publish(data, function(resp){
    			console.log("Publish Message: " + resp.customStringVariableType);
    			vm.checkContractStatus();
    			
    			if (angular.isDefined(publishInterval)) 
    				$interval.cancel(publishInterval);
    			
    			publishInterval = $interval(checkContractStatus, 3000);
	        }, function(error){
	        });
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }
        
        vm.checkGDSExists = function(gdsList, gds, type) {
        	var exist = false;
        	
        	for (var i = 0; i < gdsList.length; i++) {
        		if (type == 'array') {
        			if (gdsList[i] == gds) {
            			exist = true;
            			break;
            		}
        		} else {
        			if (gdsList[i].gdsname == gds) {
            			exist = true;
            			break;
            		}
        		}
        		
        	}
        	
        	return exist;
        }
        
        $scope.$on('$destroy', function () { 
        	if (angular.isDefined(publishInterval))
			$interval.cancel(publishInterval);
		
	        if (angular.isDefined(validateInterval))
				$interval.cancel(validateInterval); 
        });
    }
})();