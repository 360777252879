(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('StopoverController', StopoverController);

    StopoverController.$inject = ['$scope', '$parse', '$state', '$stateParams', '$uibModal', '$uibModalInstance', 'stopover', 'stopoverspec', 'stopovercharg', 'stopoverapp', 'stopovertotal', 'ContractValue', 'ContractStopover', 'airlines', 'currencies'];

    function StopoverController ($scope, $parse, $state, $stateParams, $uibModal, $uibModalInstance, stopover, stopoverspec, stopovercharg, stopoverapp, stopovertotal, ContractValue, ContractStopover, airlines, currencies) {
    	var vm = this;
        vm.airlines = airlines;
        vm.currencies = currencies;
    	vm.contractValue = ContractValue;
    	vm.entity = ContractStopover.get({ id : $stateParams.id_contract });
		vm.stopover = stopover;
		vm.stopoverspec = stopoverspec;
		vm.stopovercharg = stopovercharg;
		vm.stopoverapp = stopoverapp;
		vm.stopovertotal = stopovertotal;
		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;

		vm.entity.$promise.then(function(contract) {
    		vm.stopoverRules = contract.data[21].pages;

    		if (vm.index < vm.stopoverRules.length)
    			vm.editStopover(vm.stopoverRules[vm.index]);
    	});

		vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
	    	};

     	//Stop Over
		vm.addStopover = function(){
			vm.entity.data[21].pages.push({
				forms:[
					angular.copy(vm.stopover),
					angular.copy(vm.stopoverspec),
					angular.copy(vm.stopovercharg),
					angular.copy(vm.stopoverapp),
					angular.copy(vm.stopovertotal)
				]
			});

            console.log("Inbound & Outbound Radio "+vm.stopovertotal.value);
            console.log("Number Outbound "+vm.stopovertotal.value);
            console.log("Number Inbound"+vm.stopovertotal.value);
            console.log("Online Only "+vm.stopovertotal.value);
            console.log("Max Permitted "+vm.stopovertotal.value);
            console.log("Minimum Required "+vm.stopovertotal.value);
		};

		vm.editStopover = function(x){
            vm.stopover.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[0], vm.stopover);
            });
            vm.stopoverspec.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[1], vm.stopoverspec);
            });
            vm.stopovercharg.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[2], vm.stopovercharg);
            });
            vm.stopoverapp.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[3], vm.stopoverapp);
            });
            vm.stopovertotal.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[4], vm.stopovertotal);
            });

            // ContractValue.setValueFromDatabase(x.forms[0], vm.stopover);
            // ContractValue.setValueFromDatabase(x.forms[1], vm.stopoverspec);
            // ContractValue.setValueFromDatabase(x.forms[2], vm.stopovercharg);
            // ContractValue.setValueFromDatabase(x.forms[3], vm.stopoverapp);
            // ContractValue.setValueFromDatabase(x.forms[4], vm.stopovertotal);

            // vm.stopover = angular.copy(x.forms[0]);
			// vm.stopoverspec = angular.copy(x.forms[1]);
			// vm.stopovercharg = angular.copy(x.forms[2]);
			// vm.stopoverapp = angular.copy(x.forms[3]);
			// vm.stopovertotal = angular.copy(x.forms[4]);


			vm.editObjectStopover = x;
			vm.isStopoverEdit = true;
		};

		vm.saveStopoverEdit = function(){
			vm.entity.data[21].pages[vm.entity.data[21].pages.indexOf(vm.editObjectStopover)].forms[0] = angular.copy(vm.stopover);
			vm.entity.data[21].pages[vm.entity.data[21].pages.indexOf(vm.editObjectStopover)].forms[1] = angular.copy(vm.stopoverspec);
			vm.entity.data[21].pages[vm.entity.data[21].pages.indexOf(vm.editObjectStopover)].forms[2] = angular.copy(vm.stopovercharg);
			vm.entity.data[21].pages[vm.entity.data[21].pages.indexOf(vm.editObjectStopover)].forms[3] = angular.copy(vm.stopoverapp);
			vm.entity.data[21].pages[vm.entity.data[21].pages.indexOf(vm.editObjectStopover)].forms[4] = angular.copy(vm.stopovertotal);

			vm.editObjectStopover = null;
			vm.isStopoverEdit = false;

		};

		//END Stop Over

		vm.save = function(){
            var forms = [vm.stopover, vm.stopoverspec, vm.stopovercharg, vm.stopoverapp, vm.stopovertotal];

            if (vm.isStopoverEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractStopover.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractStopover.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isStopoverEdit)
				// vm.saveStopoverEdit();
            // else
				// vm.addStopover();
            //
            // ContractStopover.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.pushDataStopover = function(x, y){
            y[0].value = vm.dataSpecifiedStopover.rules_stopover_number_of_stopover;
            y[1].value = vm.dataSpecifiedStopover.rules_stopover_application;
            y[2].value = vm.dataSpecifiedStopover.rules_stopover_outbound_inbound;
            y[3].value = vm.dataSpecifiedStopover.rules_stopover_specified_type;
            y[4].value = vm.dataSpecifiedStopover.rules_stopover_location_1;
            y[5].value = vm.dataSpecifiedStopover.rules_stopover_location_2;
            y[6].value = vm.dataSpecifiedStopover.rules_stopover_same_carrier;
            y[7].value = vm.dataSpecifiedStopover.rules_stopover_arrival_carrier;
            y[8].value = vm.dataSpecifiedStopover.rules_stopover_departure_carrier;
            y[9].value = vm.dataSpecifiedStopover.rules_stopover_override;
            y[10].value = vm.dataSpecifiedStopover.rules_stopover_relationship;
            y[11].value = vm.dataSpecifiedStopover.rules_stopover_charge;

            if(vm.stopoverspec.form_items[x].value == null){
                vm.stopoverspec.form_items[x].value = [];
            }
            vm.stopoverspec.form_items[x].value.push(y);
            var z = angular.copy(vm.stopoverspec.form_items[x].value);
            vm.stopoverspec.form_items[x].value = z;

            vm.dataSpecifiedStopover = [];
        };

        vm.removeDataStopover = function(x, y){
            vm.stopoverspec.form_items[x].value.splice(y, 1);
            var z = angular.copy(vm.stopoverspec.form_items[x].value);
            vm.stopoverspec.form_items[x].value = z;
        };


        vm.copyDataStopover = function(x, y){
        	vm.data = angular.copy(vm.stopoverspec.form_items[x].value[y]);
        	vm.stopoverspec.form_items[x].value.push(vm.data);

        	var newItemIndex = vm.stopoverspec.form_items[x].value.length - 1;

        	if (vm.isEditEmbeddedSurfaceSectors == undefined || vm.isEditEmbeddedSurfaceSectors == null) {
        		vm.isEditEmbeddedSurfaceSectors = {}
        	}

        	vm.isEditEmbeddedSurfaceSectors[newItemIndex] = true;
        };


        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.checkValueApplyTo = function(value) {
        	if (!vm.isStopoverEdit) {
        		if (value == null || value == "" || value == undefined)
                    return "Outbound & Inbound";
        	}

            return value;
        }

        vm.resetNumberInboundOutbound = function() {
            vm.stopovertotal.form_items[0].value = null;
            vm.stopovertotal.form_items[1].value = null;
            vm.stopovertotal.form_items[2].value = null;
            vm.stopovertotal.form_items[3].value = null;
        };

        vm.setValue = function () {
            if(vm.stopovertotal.form_items[0].value == 'Outbound or Inbound but Not Both'){
                vm.stopovertotal.form_items[1].value = '';
                vm.stopovertotal.form_items[2].value = '';
            } else if(vm.stopovertotal.form_items[0].value == 'Number Outbound and Number Inbound'){
                vm.stopovertotal.form_items[3].value = '';
            }
        };

        vm.tagTab1 = ["rules_stopover_apply_to"];
        vm.tagTabTextOnly = ["rules_stopover_text_information_only", "rules_stopover_note_amadeus", "rules_stopover_note_apf", "rules_stopover_note_sabre", "rules_stopover_note", "rules_stopover_override"];
    }
})();
