(function() {
    'use strict';

    angular
        .module('americanoApp')
        .directive('pageRibbon', pageRibbon);

    function pageRibbon(ProfileService, $rootScope, $translate) {
        var directive = {
            replace : true,
            restrict : 'AE',
            template : '<div class="ribbon hidden"><a href="" translate="global.ribbon.{{ribbonEnv}}">{{ribbonEnv}}</a></div>',
            link : linkFunc
        };

        return directive;

        function linkFunc(scope, element, attrs) {
        	 scope.ribbonEnv = "prod";
             element.addClass("prod");
             element.removeClass('hidden');
        }
    }
})();
