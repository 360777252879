(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TravelRestrictionController', TravelRestrictionController);

    TravelRestrictionController.$inject = [
        '$scope', '$parse', '$state', '$stateParams', '$uibModal',
        'ContractTravelRestriction', 'form25', '$uibModalInstance', 'travelSegmentIndicatorSabre', 'ContractValue'];

    function TravelRestrictionController (
        $scope, $parse, $state, $stateParams, $uibModal,
        ContractTravelRestriction, form25, $uibModalInstance, travelSegmentIndicatorSabre, ContractValue) {

        var vm = this;
        vm.entity = ContractTravelRestriction.get({ id : $stateParams.id_contract });
        vm.form25 = form25;
        vm.travelSegmentIndicatorSabre = travelSegmentIndicatorSabre;
        vm.id_contract = $stateParams.id_contract;
        vm.index = $stateParams.index;

        vm.entity.$promise.then(function(contract) {
            vm.ruleAppRules = contract.data[19].pages;

            if (vm.index < vm.ruleAppRules.length)
                vm.editRuleTravelRestriction(vm.ruleAppRules[vm.index]);
        });

        //RULES TRAVEL RESTRICTION
        vm.addRuleTravelRestriction = function(){
            vm.entity.data[19].pages.push({
                forms:[
                    angular.copy(vm.form25)
                ]
            });
        }

        vm.editRuleTravelRestriction = function(x){
            vm.form25.$promise.then(function(){
                ContractValue.setValueFromDatabase(x.forms[0], vm.form25);
            });

            // vm.form25 = angular.copy(x.forms[0]);

            vm.editObjectRuleTravelRestriction = x;
            vm.isRuleTravelRestrictionEdit = true;
        };

        vm.saveTravelRestrictionEdit = function(){
            vm.entity.data[19].pages[vm.entity.data[19].pages.indexOf(vm.editObjectRuleTravelRestriction)].forms[0] = angular.copy(vm.form25);

            vm.editObjectRuleTravelRestriction = null;
            vm.isRuleTravelRestrictionEdit = false;
        };
        //END RULES TRAVEL RESTRICTION

        vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
    	};

		vm.save = function(){

            var forms = [vm.form25];

            if (vm.isRuleTravelRestrictionEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractTravelRestriction.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractTravelRestriction.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isRuleTravelRestrictionEdit)
				// vm.saveTravelRestrictionEdit();
            // else
				// vm.addRuleTravelRestriction();
            //
            // // ContractRuleApplicationOnly.update(vm.entity, function(result){
            // ContractTravelRestriction.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };
    }
})();
