(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormContractCopyDialogController', FormContractCopyDialogController);

    FormContractCopyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'ContractCopy'];

    function FormContractCopyDialogController ($scope, $stateParams, $uibModalInstance, ContractCopy) {
    	var vm = this;

    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };

        vm.confirmCopy = function () {
            vm.contract.id = $stateParams.id_contract;
            ContractCopy.copy(vm.contract, function(data){
                $uibModalInstance.close(data);
            }, function(error){
                console.log("Save error ");
                console.log(error);
            });

        	// Contract.get({id : $stateParams.id_contract}, function(data) {
        	// 	data.id = null;
        	//
        	// 	Contract.save(data, function(result) {$uibModalInstance.close(result);}, function(error) {});
        	// });
	    };
    }
})();
