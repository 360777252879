(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FieldPageDialogRegisterController',FieldPageDialogRegisterController);

    FieldPageDialogRegisterController.$inject = ['$uibModalInstance',  'FieldPage', '$stateParams', 'FieldPageTag', 'FormPage', 'FormContract', 'Airline'];

    function FieldPageDialogRegisterController($uibModalInstance, FieldPage, $stateParams, FieldPageTag, FormPage, FormContract, Airline) {
        var vm = this;
        vm.itemsPerPage
        
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.confirmRegister = function () {
        	console.log('isi tag: '+$stateParams.tag);
        	
        	FieldPageTag.get({tag : $stateParams.tag},
				function(data){
        			console.log(data);
					FieldPage.delete(data);
					vm.saveFieldPage();
				}, 
				function(error){
					vm.saveFieldPage();
				}
			);
        };
        
        vm.saveFieldPage = function(){
        	FormContract.get({tag : $stateParams.tag}, function(data){
				var fields = [];
				FormPage.get({id : $stateParams.id}).$promise.then(function(formPageData){
					angular.forEach(data.form_items, function(x) {
						var fieldPage = {
								fieldTag:x.tag,
								fieldName:x.label,
								fieldType:"STRING",
								fieldActive:"YES",
								formTag:formPageData.formTag
						};
						fields.push(fieldPage);
					});
					
					var currentfieldPage = {
							formPage:formPageData,
							fields:fields
					};

					FieldPage.save(currentfieldPage, function(x){
						$uibModalInstance.close(true);						
					});
				});                		        	 
			});
        };
    }
})();
