(function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('atr', {
            parent: 'entity',
            url: '/airline-template-rule?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN'],
                pageTitle: 'Airline Template Rule'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/airline-template-rule/airline-template-rules.html',
                    controller: 'AirlineTemplateRuleController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('atr.new', {
            parent: 'atr',
            url: '/new',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/airline-template-rule/airline-template-rule-dialog.html',
                    controller: 'AirlineTemplateRuleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                airline: null,
                                airlineCode: null,
                                readingOrientation: null,
                                rules: []
                            };
                        },
                        airlines : ['AirlineCustom', function(AirlineCustom) {
                    		return AirlineCustom.get();
                     	}]
                    }
                }).result.then(function() {
                    $state.go('atr', null, { reload: true });
                }, function() {
                    $state.go('atr');
                });
            }]
        })
        .state('atr.edit', {
            parent: 'atr',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/airline-template-rule/airline-template-rule-dialog.html',
                    controller: 'AirlineTemplateRuleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AirlineTemplateRule', function(AirlineTemplateRule) {
                            return AirlineTemplateRule.get({id : $stateParams.id});
                        }],
                        airlines : ['AirlineCustom', function(AirlineCustom) {
                    		return AirlineCustom.get();
                     	}] 
                    }
                }).result.then(function() {
                    $state.go('atr', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('atr.delete', {
            parent: 'atr',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_ADMIN']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/airline-template-rule/airline-template-rule-delete-dialog.html',
                    controller: 'AirlineTemplateRuleDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AirlineTemplateRule', function(AirlineTemplateRule) {
                            return AirlineTemplateRule.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('atr', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
