(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('EligibilityCopyDialogController', EligibilityCopyDialogController);

    EligibilityCopyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'ContractEligibility'];

    function EligibilityCopyDialogController ($scope, $stateParams, $uibModalInstance, ContractEligibility) {
    	var vm = this;
    	vm.id_contract = $stateParams.id_contract;
    	vm.index = $stateParams.index;

    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };

        vm.confirmCopy = function (id, index, lastIndex) {
            ContractEligibility.copy({id:$stateParams.id_contract, index:index}, function(data){
                $uibModalInstance.close(data.id);
            });
	    };
    }
})();
