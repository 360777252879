(function() {
    'use strict';

    angular
    .module('americanoApp')
    .factory('ContractFareException', ContractFareException);

    ContractFareException.$inject = ['$resource'];

	function ContractFareException ($resource) {

		return $resource("", {}, {
		    'getBookingCode': { method:'GET', url: 'api/contracts/fare/booking-code/:id/:index/:group'},
		    'createUpdateBookingCode': { method:'PUT', url: 'api/contracts/fare/booking-code'},
		    'copyBookingCode': {method: 'PUT', url: 'api/contracts/fare/copy-booking-code'},
		    'deleteBookingCode': { method:'DELETE', url: 'api/contracts/fare/booking-code/:id/:index'}, 
		    'getTotalErrorPages': { method:'GET', url: 'api/contracts/fare/total-error-pages-booking-code/:id'}
	    });
	}
})();

