(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormRoutingController', FormRoutingController);

    FormRoutingController.$inject = ['$scope', '$state', '$filter', '$rootScope', '$stateParams',
        // 'form',
        'form_routing',
        'form_routing_tariff',
        'form_routing_number',
        'form_routing_path',
        'form_routing_bottom',
        'form_routing_restriction',
        'InfoText', 'Routing', 'RoutingActivated', 'RoutingSearch', 'ParseLinks', 'pagingParams', 'paginationConstants', 'FormContract', 'ContractRouting',  'entity'];

    function FormRoutingController ($scope, $state, $filter, $rootScope, $stateParams,
                                    // form, // tag: new_routing
                                    form_routing, // tag: routing_new
                                    form_routing_tariff,
                                    form_routing_number,
                                    form_routing_path,
                                    form_routing_bottom,
                                    form_routing_restriction,
                                    InfoText, Routing, RoutingActivated, RoutingSearch, ParseLinks, pagingParams, paginationConstants, FormContract, ContractRouting, entity) {
        var vm = this;
        vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;
        vm.id_contract = $stateParams.id_contract;

        // vm.form = form;
        vm.form_routing = form_routing;
        vm.form_routing_tariff = form_routing_tariff;
        vm.form_routing_number = form_routing_number;
        vm.form_routing_path = form_routing_path;
        vm.form_routing_bottom = form_routing_bottom;
        vm.form_routing_restriction = form_routing_restriction;

        vm.infotext = InfoText;
        vm.entity = entity;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.selectedRoutes = [];
        vm.routeIndex = [];
        vm.selectedStatus = [];
        vm.index = 0;
        vm.selectedRow = [];
        vm.selectedRoutesInContract = [];
        vm.filterData = {
            activated:true
        };

        vm.searchTypeOptions = [{key:'carrier', value:'Carrier'},
            {key:'routing_number', value:'Routing Number'},
            {key:'routing_tariff_amadeus', value:'Routing Tariff Amadeus'},
            {key:'routing_tariff_sabre', value:'Routing Tariff Sabre'},
            {key:'routing_description', value:'Routing Description'},
            {key:'origin', value:'Origin'},
            {key:'via', value:'Via'},
            {key:'destination', value:'Destination'}];

        vm.loadAll = loadAll;
        vm.loadAll();
        vm.getSelectedRoutesInContract = getSelectedRoutesInContract;
        vm.getSelectedRoutesInContract();

        function loadAll() {
            RoutingActivated.query({
                id_contract: $stateParams.id_contract,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.routes = data;
                vm.page = pagingParams.page;
                vm.lastIndex = vm.queryCount - 1;
                vm.index = vm.page - 1;
            }
            function onError(error) {
                vm.loadAll();
            }
        };

        function getSelectedRoutesInContract() {
            var hasError = false;
            vm.selectedRoutesInContract = [];
            vm.entity.$promise.then(function(contract) {
                contract.data[2].pages.forEach(function(page) {
                    vm.tempForm = page.forms[0];
                    vm.tempForm1 = page.forms[1];
                    vm.tempForm2 = page.forms[2];
                    vm.tempForm3 = page.forms[3];
                    vm.tempForm4 = page.forms[4];
                    vm.tempForm5 = page.forms[5];
                    hasError = false;

                    for (var j = 0; j < page.forms.length; j++) {
                    	for (var k = 0; k < page.forms[j].form_items.length; k++) {
                    		if (page.forms[j].form_items[k].type.toLowerCase() == "multivalue") {
                    			if (page.forms[j].form_items[k].value != null) {
                    				for (var l = 0; l < page.forms[j].form_items[k].value.length; l++) {
                    					for (var m = 0; m < page.forms[j].form_items[k].value[l].length; m++) {
                    						var temp = page.forms[j].form_items[k].value[l][m];

                    						if (temp.errorAPF.length > 0) {
    											hasError = true;
    											break;
    										}
    										if (temp.errorAmadeus.length > 0) {
    											hasError = true;
    											break;
    										}
    										if (temp.errorSabre.length > 0) {
    											hasError = true;
    											break;
    										}
                    					}

                    					if (hasError)
    										break;
                    				}
                    			}
                    		}

                    		if (page.forms[j].form_items[k].errorAPF.length > 0) {
    							hasError = true;
    							break;
    						}

                    		if (page.forms[j].form_items[k].errorAmadeus.length > 0) {
    							hasError = true;
    							break;
    						}

                    		if (page.forms[j].form_items[k].errorSabre.length > 0) {
    							hasError = true;
    							break;
    						}
                    	}
                    }

                    vm.temp = {
                        pagesIndex:page.pagesIndex,
                        /* tempForm */
                        airline : vm.tempForm.form_items[0].value,
                        airlineIsError : vm.checkFormItemHasError(vm.tempForm, 0),
                        routing_description : vm.tempForm.form_items[1].value,
                        routingDescriptionIsError : vm.checkFormItemHasError(vm.tempForm, 1),

                        /* tempForm1 */
                        routing_tariff_amadeus : vm.tempForm1.form_items[0].value,
                        routingfTariffAmadeusIsError : vm.checkFormItemHasError(vm.tempForm1, 0),
                        routing_tariff_sabre : vm.tempForm1.form_items[1].value,
                        routingfTariffSabreIsError : vm.checkFormItemHasError(vm.tempForm1, 1),
                        routing_tariff : vm.tempForm1.form_items[2].value,
                        routingTariffIsError : vm.checkFormItemHasError(vm.tempForm1, 2),

                        /* tempForm2 */
                        routing_number : vm.tempForm2.form_items[0].value,
                        routingNumberIsError : vm.checkFormItemHasError(vm.tempForm2, 0),
                        route_number : vm.tempForm2.form_items[1].value,
                        routeNumberIsError : vm.checkFormItemHasError(vm.tempForm2, 1),

                        /* tempForm3 */
                        left_to_right : vm.tempForm3.form_items[0].value,
                        leftToRightIsError : vm.checkFormItemHasError(vm.tempForm3, 0),
                        map_details : vm.tempForm3.form_items[1].value,
                        mapDetailsIsError : vm.checkFormItemHasError(vm.tempForm3, 1),
                        map_detail : vm.tempForm4.form_items[2].value,
                        mapDetailIsError : vm.checkFormItemHasError(vm.tempForm3, 2),

                        /* tempForm4 */
                        domestic_route_validation : vm.tempForm4.form_items[0].value,
                        domesticRouteIsIError : vm.checkFormItemHasError(vm.tempForm4, 0),
                        entry_exit_point : vm.tempForm4.form_items[1].value,
                        entryExitPointIsError : vm.checkFormItemHasError(vm.tempForm4, 1),
                        ticketed_unticketed_point : vm.tempForm4.form_items[2].value,
                        ticketedUnticketedPointIsError : vm.checkFormItemHasError(vm.tempForm4, 2),

                        /* tempForm5 */
                        routing_restriction : vm.tempForm5.form_items[0].value,
                        routingRestrictionIsError : vm.checkFormItemHasError(vm.tempForm5, 0),

                        /*origin  : vm.tempForm.form_items[6].value,
                         originIsError : vm.checkFormItemHasError(vm.tempForm, 6),
                         via : vm.tempForm.form_items[7].value,
                         viaIsError : vm.checkFormItemHasError(vm.tempForm, 7),
                         destination : vm.tempForm.form_items[8].value,
                         destinationIsError : vm.checkFormItemHasError(vm.tempForm, 8),
                         map_detail : vm.tempForm.form_items[9].value,
                         mapDetailIsError : vm.checkFormItemHasError(vm.tempForm, 9),*/
                         hasError: hasError
                    };

                    vm.selectedRoutesInContract.push(vm.temp);
                });
            });
        }

        vm.checkFormItemHasError = function(form, index) {
            var hasError = false;

            if (form.form_items[index].errorAPF.length > 0)
                hasError = true;

            if (form.form_items[index].errorAmadeus.length > 0)
                hasError = true;

            if (form.form_items[index].errorSabre.length > 0)
                hasError = true;

            return hasError;
        }

        vm.addSelectedRoutes = function() {
            vm.entity.$promise.then(function(contract) {
                vm.selectedRoutes.forEach(function(route) {
                    // vm.tempForm = angular.copy(vm.form);
                    vm.tempForm = angular.copy(vm.form_routing);
                    vm.tempForm1 = angular.copy(vm.form_routing_tariff);
                    vm.tempForm2 = angular.copy(vm.form_routing_number);
                    vm.tempForm3 = angular.copy(vm.form_routing_path);
                    vm.tempForm4 = angular.copy(vm.form_routing_bottom);
                    vm.tempForm5 = angular.copy(vm.form_routing_restriction);

                    var form = {
                        forms:[
                            vm.tempForm,
                            vm.tempForm1,
                            vm.tempForm2,
                            vm.tempForm3,
                            vm.tempForm4,
                            vm.tempForm5
                        ]};

                    vm.tempForm.form_items[0].value = route.airline;
                    vm.tempForm.form_items[1].value = route.routing_description;
                    vm.tempForm1.form_items[0].value = route.routing_tariff_amadeus;
                    vm.tempForm1.form_items[1].value = route.routing_tariff_sabre;
                    vm.tempForm1.form_items[2].value = route.routing_tariff;
                    vm.tempForm2.form_items[0].value = route.routing_number;
                    vm.tempForm2.form_items[1].value = route.route_number;
                    vm.tempForm3.form_items[0].value = route.left_to_right;
                    vm.tempForm3.form_items[2].value = route.map_detail;

                    // vm.tempForm3.form_items[1].value = route.map_detail;
                    angular.forEach(route.map_details, function (value, key) {
                        var path = angular.copy(vm.tempForm3.form_items[1].form_items_child);

                        if(vm.tempForm3.form_items[1].value == null){
                            vm.tempForm3.form_items[1].value = [];
                        }

                        angular.forEach(path, function (key, v3) {
                            if(key.tag == "routing_new_route_path_boarding_point")
                                key.value = value.boardingPoint;
                            else if(key.tag == "routing_new_route_path_map_via")
                                key.value = value.via;
                            else if(key.tag == "routing_new_route_path_off_point")
                                key.value =  value.offPoint;
                            else if(key.tag == "routing_new_route_path_multivalue_data")
                                key.value = value.routePath;
                        });

                        vm.tempForm3.form_items[1].value.push(path);

                    });

                    vm.tempForm4.form_items[0].value = route.domestic_route_validation;
                    vm.tempForm4.form_items[1].value = route.entry_exit_point;
                    vm.tempForm4.form_items[2].value = route.ticketed_unticketed_point;

                    // vm.tempForm5.form_items[0].value = route.routing_restriction;

                    angular.forEach(route.routing_restriction, function(value, key) {
                        var d = angular.copy(vm.tempForm5.form_items[0].form_items_child);

                        if(vm.tempForm5.form_items[0].value == null){
                            vm.tempForm5.form_items[0].value = [];
                        }

                        angular.forEach(d, function(key, v2){
                            if(key.tag == "routing_new_routing_restriction_value")
                                key.value = value.routingRestriction;
                            else if(key.tag == "routing_new_routing_restriction_travel_between_a")
                                key.value = value.travelBetweenA;
                            else if(key.tag == "routing_new_routing_restriction_travel_between_b")
                                key.value = value.travelBetweenB;
                            else if(key.tag == "routing_new_routing_restriction_to_from_c")
                                key.value = value.toFrom;
                            else if(key.tag == "routing_new_routing_restriction_to_from_d")
                                key.value = value.mustBeCarrierAlliance;
                            else if(key.tag == "routing_new_routing_restriction_must_be_must_not_be_e")
                                key.value = value.mustBeMustNotBe;
                            else if(key.tag == "routing_new_routing_restriction_must_be_must_not_be_f")
                                key.value = value.via;
                            else if(key.tag == "routing_new_routing_restriction_nonstop_direct_g")
                                key.value = value.nonStopDirect;
                            else if(key.tag == "routing_new_routing_restriction_nonstop_direct_h")
                                key.value = value.stopoverIn;
                            else if(key.tag == "routing_new_routing_restriction_nonstop_direct_i")
                                key.value = value.requiredNotRequired;
                            else if(key.tag == "routing_new_routing_restriction_surface_sector")
                                key.value = value.surfaceSector;

                        });

                        vm.tempForm5.form_items[0].value.push(d);
                    });

                    var routingObject = {
                        forms: angular.copy(form.forms),
                        contractId: $stateParams.id_contract
                    };

                    ContractRouting.save(routingObject, function(success){
                    	alert('Add routing success');
                        vm.entity = success;
                        getSelectedRoutesInContract();
                        vm.resetCheckbox();
                    }, function(error){});
                });
            });
        }

        vm.deleteRoutingInContract = function(index) {

            if(confirm('Do you want to delete this routing?')) {
                ContractRouting.delete({id: vm.id_contract, index: index}, function (success) {
                	for(var x=0;x<vm.selectedRoutesInContract.length;x++){
                		if(vm.selectedRoutesInContract[x].pagesIndex == index){
                			 vm.selectedRoutesInContract.splice(x, 1);
                			 break;
                		}
                	}
                    alert('Delete success');
                }, function (error) {
                    alert('Delete failed');
                    console.log(error);
                });
            }
            /*vm.entity.$promise.then(function(contract) {
                contract.data[2].pages.splice(index, 1);

                ContractRouting.update(contract, function(success){}, function(error){});
            });*/
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                id_contract: $stateParams.id_contract,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        vm.search = function () {
//            vm.form.form_items[0].value = "";
            vm.rowHighlighted(null, null);

            RoutingSearch.query({
                page: pagingParams.page - 1,
                size: vm.searchResultItemsPerPage,
                sort: sort(),
                query: vm.searchQuery,
                type: vm.searchType
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.routes = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                vm.loadAll();
            }
        };

        vm.emptySearchCheck = function() {
            if (!vm.searchQuery)
                vm.loadAll();
        }

        vm.registerAllRoutes = function(isChecked) {
            if (isChecked) {
                vm.selectedRow = [];
                vm.selectedRoutes = [];
                vm.routeIndex = [];
                for (var i = 0; i < vm.routes.length; i++) {
                    vm.selectedRoutes.push(vm.routes[i]);
                    vm.routeIndex.push(vm.lastIndex - i - (vm.index * vm.size));
                    vm.selectedStatus[i] = true;
                    vm.selectedRow.push(vm.lastIndex - i);
                }

            } else {
                vm.selectedRow = [];
                vm.selectedRoutes = [];
                vm.routeIndex = [];
                for (var i = 0; i < vm.selectedStatus.length; i++) {
                    vm.selectedStatus[i] = false;
                }
            }

        }

        vm.registerRoute = function(transactionIndex, route, index) {
            if (vm.selectedStatus[index]) {
                vm.selectedRoutes.push(route);
                vm.routeIndex.push(transactionIndex - (vm.index * vm.size));
                vm.selectedStatus[index] = true;

            } else {
                vm.selectedRoutes.splice(vm.selectedRoutes.indexOf(route), 1);
                vm.routeIndex.splice(vm.routeIndex.indexOf(transactionIndex), 1);
                vm.selectedStatus[index] = false;
            }

            if (vm.selectedRoutes.length == vm.routes.length) {
                vm.master = true;
            } else
                vm.master = false;

        }

        vm.rowHighlighted = function(row, route, index) {
            var selectedExist = false;

            for (var i = 0; i < vm.selectedRow.length; i++) {
                if (vm.selectedRow[i] == row) {
                    vm.selectedRow.splice(i, 1);
                    selectedExist = true;
                    break;
                }
            }

            if (selectedExist)
                vm.selectedStatus[index] = false;
            else {
                vm.selectedStatus[index] = true;
                vm.selectedRow.push(row);
            }

            vm.registerRoute(row, route, index);
        }

        vm.getSelectedRowStatus = function(row) {
            var status = false;

            if (vm.master) {
                status = true;
            } else {
                for (var i = 0; i < vm.selectedRow.length; i++) {
                    if (vm.selectedRow[i] == row) {
                        status = true;
                        break;
                    }
                }
            }

            return status;
        }

        vm.resetCheckbox = function() {
            vm.selectedRoutes = [];
            vm.selectedRow = [];
            for (var i = 0; i < vm.selectedStatus.length; i++) {
                vm.selectedStatus[i] = false;
            }
            vm.master = false;
        }

        vm.back = function(){
            $( "#tab1" ).trigger( "click" );
        };

        vm.next = function(){
            $( "#tab3" ).trigger( "click" );
        };
    }
})();
