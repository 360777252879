(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('DistributionGroupCopyDialogController', DistributionGroupCopyDialogController);

    DistributionGroupCopyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'DistributionGroup'];

    function DistributionGroupCopyDialogController ($scope, $stateParams, $uibModalInstance, DistributionGroup) {
    	var vm = this;

    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };

        vm.confirmCopy = function () {
            DistributionGroup.copy({id:$stateParams.id}, null, function(data){
        		$uibModalInstance.close(data.id);
        	});
	    };
    }
})();
