(function() {
    'use strict';
    
    angular
    .module('americanoApp')
    .factory('ContractDistributionException', ContractDistributionException);

    ContractDistributionException.$inject = ['$resource'];
	
	function ContractDistributionException ($resource) {
		
		return $resource("", {}, {
			'updateException' : { method:'PUT', url: 'api/contracts/distribution/exception'}
	    });
	}
})();

