(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormFareCopyDialogController', FormFareCopyDialogController);

    FormFareCopyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'ContractFareCopy'];

    function FormFareCopyDialogController ($scope, $stateParams, $uibModalInstance, ContractFareCopy) {
    	var vm = this; 
    	
    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };
        
        vm.confirmCopy = function () {
        	ContractFareCopy.copy({id:$stateParams.id_contract, index:$stateParams.index}, function(data){
        		$uibModalInstance.close(data.id);
        	});        	
	    };
    }
})();