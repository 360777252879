(function() {
    'use strict';

    angular
        .module('americanoApp')        
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('addOn', {
            parent: 'entity',
            url: '/addOn?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.addOn.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/addOn/addOns.html',
                    controller: 'AddOnController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('addOn');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('addOn-detail', {
            parent: 'entity',
            url: '/addOn/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.addOn.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/addOn/addOn-detail.html',
                    controller: 'AddOnDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('addOn');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'AddOn', function($stateParams, AddOn) {
                    return AddOn.get({id : $stateParams.id});
                }]
            }
        })
        .state('addOn.new', {
            parent: 'addOn',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/addOn/addOn-dialog.html',
                    controller: 'AddOnDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	entity: {
                    		addOnID : null,
                    		description : null,
                    		carrier : null,
                    		typeOfJourney : null,
                    		effectiveDate : null,
                    		discontinueDate : null,
                    		currencyAmount : {
                    			currency: null,
                    			amount: null
                    		},
                    		interiorCityZone : null,
                    		locationInteriorCityZone : null,
                    		gatewayCityZone : null,
                    		locationGatewayCityZone : null,
                    		permittedCarrier : [],
                    		ruleIDS : null,
                    		fareTypeoption : null,
                    		fareBasisCodeoption : null,
                    		fareType : [],
                    		fareBasisCode : null,
                    		secondaryPassengerType : [],
                    	},
                    	form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on"
        					});
        				}],
        				form1_amadeus : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_amadeus"
        					});
        				}],
        				form2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_date"
        					});
        				}],
        				form3 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_information"
        					});
        				}],
        				form3_amadeus : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_end_point"
        					});
        				}],
        				form4 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_interior"
        					});
        				}],
        				form5 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_permitted"
        					});
        				}],
        				form6 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_link"
        					});
        				}],
        				form6_amadeus : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_link_amadeus"
        					});
        				}],
        				form7 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_passenger"
        					});
        				}],
        				airlines :[ 'AirlineCustom', function(AirlineCustom) {
        					return AirlineCustom.get();
        				}],
        				currencies : [ 'CurrencyCustom',function(CurrencyCustom) {
        					return CurrencyCustom.get();
        				}],
        				passenger_type : [ 'PassengerCustom',function(PassengerCustom) {
        					return PassengerCustom.get();
        				}]
                    }
                }).result.then(function() {
                    $state.go('addOn', null, { reload: true });
                }, function() {
                    $state.go('addOn');
                });
            }]
        })
        .state('addOn.edit', {
            parent: 'addOn',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/addOn/addOn-dialog.html',
                    controller: 'AddOnDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['AddOn', function(AddOn) {
                            return AddOn.get({id : $stateParams.id}).$promise;
                        }],
                    	form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on"
        					});
        				}],
        				form1_amadeus : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_amadeus"
        					});
        				}],
        				form2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_date"
        					});
        				}],
        				form3 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_information"
        					});
        				}],
        				form3_amadeus : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_end_point"
        					});
        				}],
        				form4 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_interior"
        					});
        				}],
        				form5 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_permitted"
        					});
        				}],
        				form6 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_link"
        					});
        				}],
        				form6_amadeus : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_link_amadeus"
        					});
        				}],
        				form7 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "master_add_on_passenger"
        					});
        				}],
        				airlines :[ 'AirlineCustom', function(AirlineCustom) {
        					return AirlineCustom.get();
        				}],
        				currencies : [ 'CurrencyCustom',function(CurrencyCustom) {
        					return CurrencyCustom.get();
        				}],
        				passenger_type : [ 'PassengerCustom',function(PassengerCustom) {
        					return PassengerCustom.get();
        				}]
                    }
                }).result.then(function() {
                    $state.go('addOn', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('addOn.delete', {
            parent: 'addOn',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/addOn/addOn-delete-dialog.html',
                    controller: 'AddOnDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['AddOn', function(AddOn) {
                            return AddOn.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('addOn', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }
})();
