(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormGDSSystemController', FormGDSSystemController);


    FormGDSSystemController.$inject = ['$scope', '$state', '$interval', '$filter',  '$rootScope', '$stateParams', 'ContractHeader', 'Principal', '$uibModalInstance', 'ContractValue', 'ContractValidate', 'ContractGDSStatus', 'ContractPublish', 'ContractTargetSystem'];

    function FormGDSSystemController ($scope, $state, $interval, $filter,  $rootScope, $stateParams, ContractHeader, Principal, $uibModalInstance, ContractValue, ContractValidate, ContractGDSStatus, ContractPublish, ContractTargetSystem) {
        var vm = this;
        var validateInterval, publishInterval;

        vm.contractValue = ContractValue;
        vm.contract = [];
        vm.GDSOption = ['Sabre', 'Travelport', 'Amadeus'];
        vm.checkContractStatus = checkContractStatus;
        vm.initialCheck = initialCheck;
        vm.showValidateTable = false;
        vm.showPublishTable = false;
        vm.statusOkToPublishNow = false;
        vm.hasBeenValidated = false;
        vm.validateTravelportResult = [];
        vm.validateSabreResult = [];
        vm.validateAmadeusResult = [];
        vm.validateGeneralTotalError = null;
        vm.totalValidateError = 0;
        vm.publishTravelportResult = "";
        vm.publishSabreResult = "";
        vm.publishAmadeusResult = "";

        vm.contractIDList = {
        	customStringVariableType : null,
        	customLongVariableType : null,
        	customIntegerVariableType : null,
        	customStringListVariableType : [$stateParams.id_contract]
        };

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };
        
        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
        });
        
        ContractHeader
		.get({
			id : $stateParams.id_contract
		}).$promise.then(function(contract) {
        	vm.entity = contract;

        	vm.targetSystem = vm.entity.target_system;

            vm.contract.push(vm.entity);

            vm.initialCheck();
        });

        function initialCheck() {
        	ContractGDSStatus.get(vm.contractIDList, function(data){
        		angular.forEach(data, function(value, key){
	        		if (vm.contract[0].id == key) {
	        			vm.temporaryGDSStatus = value;
	        		}
	    		});

        		if (vm.temporaryGDSStatus.length > 0) {
        			if (vm.temporaryGDSStatus[0].validateStatus != undefined || vm.temporaryGDSStatus[0].validateStatus != null) {
	    				vm.showValidateTable = true;

	    				if (angular.isDefined(validateInterval)) {
	        				$interval.cancel(validateInterval);
	        			}

	        			validateInterval = $interval(checkContractStatus, 3000);
	    			}

	    			if (vm.temporaryGDSStatus[0].currentPublishStatus != undefined || vm.temporaryGDSStatus[0].currentPublishStatus != null) {
	    				vm.showPublishTable = true;

	    				if (angular.isDefined(publishInterval)) {
	        				$interval.cancel(publishInterval);
	        			}

	        			publishInterval = $interval(checkContractStatus, 3000);
	    			}
	    		}

        		checkContractStatus();
        	}, function(error){});
        }

        function checkContractStatus() {
//        	console.log("Refreshing Status...");

        	vm.totalValidateError = 0;

        	ContractGDSStatus.get(vm.contractIDList, function(data){
	    		angular.forEach(data, function(value, key){
	        		if (vm.contract[0].id == key) {
	        			vm.contract[0].contractGdsStatus = value;
	        		}
	    		});

	    		var allValidated = true, allPublished = true;
    			vm.hasValidateError = false;
    			vm.hasPublishError = false;

	    		if (vm.contract[0].contractGdsStatus.length < 1) {
	    			allValidated = false;
	    			allPublished = false;
	    		}

	    		if (vm.contract[0].contractGdsStatus.length < vm.GDSOption.length)
	    			allValidated = false;
	    		else {
	    			for (var i = 0; i < vm.contract[0].contractGdsStatus.length; i++) {
	    				if (vm.contract[0].contractGdsStatus[i].validateStatus != null) {
	    					vm.contract[0].contractGdsStatus[i].validateStatus = vm.contract[0].contractGdsStatus[i].validateStatus.toLowerCase();

	    					if (vm.contract[0].contractGdsStatus[i].validateStatus == "error") {
	    						vm.hasValidateError = true;
	    						if (vm.contract[0].contractGdsStatus[i].validateMessage != null && vm.contract[0].contractGdsStatus[i].validateMessage != '')
	    							vm.totalValidateError = parseInt(vm.totalValidateError) + parseInt(vm.contract[0].contractGdsStatus[i].validateMessage);
	    					}


	    					if (vm.contract[0].contractGdsStatus[i].validateStatus != "success" &&
									vm.contract[0].contractGdsStatus[i].validateStatus != "notselected" &&
									vm.contract[0].contractGdsStatus[i].validateStatus != "error" &&
									vm.contract[0].contractGdsStatus[i].validateStatus != "failed")
	    						allValidated = false;
	    				}

	    			}
	    		}

	    		for (var i = 0; i < vm.contract[0].contractGdsStatus.length; i++) {
					if(vm.contract[0].contractGdsStatus[i].currentPublishStatus != undefined) {
						vm.contract[0].contractGdsStatus[i].currentPublishStatus = vm.contract[0].contractGdsStatus[i].currentPublishStatus.toLowerCase();

						if (vm.contract[0].contractGdsStatus[i].currentPublishStatus == "error")
    						vm.hasPublishError = true;

						if (vm.contract[0].contractGdsStatus[i].currentPublishStatus != "success" &&
								vm.contract[0].contractGdsStatus[i].currentPublishStatus != "notselected" &&
								vm.contract[0].contractGdsStatus[i].currentPublishStatus != "error" &&
								vm.contract[0].contractGdsStatus[i].currentPublishStatus != "failed")
							allPublished = false;
					} else
						allPublished = false;
				}

    			if (allValidated) {
    				if (angular.isDefined(validateInterval)) {
    					$interval.cancel(validateInterval);
    				}

    				vm.hasBeenValidated = true;
    				$scope.$emit('americanoApp:contractTabUpdate', null);
    			}

    			if (allPublished) {
    				if (angular.isDefined(publishInterval))
    					$interval.cancel(publishInterval);
    			}

    			if (vm.currentCheckingType == "publish" && allValidated)
	    			vm.statusOkToPublishNow = true;
	    	}, function(error){});
        }

        vm.validate = function(type) {
        	vm.currentCheckingType = type;

        	vm.validateTravelportResult = [];
            vm.validateSabreResult = [];
            vm.validateAmadeusResult = [];

        	vm.initialValidateGDSstatus = [
        	                       {
        	                           gdsname : "Sabre",
        	                           validateStatus : "waiting",
        	                       },
        	                       {
        	                    	   gdsname : "Amadeus",
        	                           validateStatus : "waiting",
        	                       },
        	                       {
        	                    	   gdsname : "Travelport",
        	                           validateStatus : "waiting",
        	                       }
        	                   ];

        	vm.entity.$promise.then(function(contract){
	        	if (contract.contractGdsStatus.length < 1) {
	        		contract.contractGdsStatus = vm.initialValidateGDSstatus;
	        	}

        		vm.GDSOption.forEach(function(gds) {
        			if (!vm.checkGDSExists(contract.contractGdsStatus, gds))
        				contract.contractGdsStatus.push({gdsname:gds, validateStatus: "waiting"});
        		});

        		for (var i = 0; i < contract.contractGdsStatus.length ; i++) {
        			contract.contractGdsStatus[i].validateStatus = "notselected";

        			if (contract.contractGdsStatus[i].gdsname == "Sabre" && vm.checkGDSExists(vm.targetSystem, "Sabre", "array"))
        				contract.contractGdsStatus[i].validateStatus = "waiting";

        			if (contract.contractGdsStatus[i].gdsname == "Amadeus" && vm.checkGDSExists(vm.targetSystem, "Amadeus", "array"))
        				contract.contractGdsStatus[i].validateStatus = "waiting";

        			if (contract.contractGdsStatus[i].gdsname == "Travelport" && vm.checkGDSExists(vm.targetSystem, "APF", "array"))
        				contract.contractGdsStatus[i].validateStatus = "waiting";
        		}

	        	contract.target_system = vm.targetSystem;

	        	ContractTargetSystem.update(contract, function(result){
	        		contract = result;

	        		var data = {
	                   		contract:[contract]
	                   	};

	            		vm.showValidateTable = true;

	            		ContractValidate.validate(data, function(resp){
	            			var validateResult = null;
	            			
	            			vm.validateGeneralTotalError = null;
	            			
	            			if (resp.customStringListVariableType != null && resp.customStringListVariableType != undefined)  {
	            				validateResult = resp.customStringListVariableType;

	                			validateResult.forEach(function(resultList) {
	                				var result = JSON.parse(resultList);
	                				
	                				for (var i = 0; i < result.length; i++) {
	                					if (result[i].Travelport != undefined && result[i].Travelport.toLowerCase() != 'success' && result[i].Travelport.toLowerCase() != 'error')
	                						vm.validateTravelportResult.push(result[i].Travelport);

		                				if (result[i].Sabre != undefined && result[i].Sabre.toLowerCase() != 'success' && result[i].Sabre.toLowerCase() != 'error')
		                					vm.validateSabreResult.push(result[i].Sabre);

		                				if (result[i].Amadeus != undefined && result[i].Amadeus.toLowerCase() != 'success' && result[i].Amadeus.toLowerCase() != 'error')
		                					vm.validateAmadeusResult.push(result[i].Amadeus);
		                				
		                				if (result[i].TotalError != undefined) {
		                					vm.validateGeneralTotalError = result[i].TotalError;
		                				}
	                				}
	                			});
	            			}

	            			vm.checkContractStatus();

	            			if (angular.isDefined(validateInterval))
	            				$interval.cancel(validateInterval);

	            			validateInterval = $interval(checkContractStatus, 3000);
	    		        }, function(error){});
	        	}, function(error){});
            });
        }

        vm.publish = function() {
        	vm.entity.$promise.then(function(contract){
        		vm.GDSOption.forEach(function(gds) {
        			if (!vm.checkGDSExists(contract.contractGdsStatus, gds))
        				contract.contractGdsStatus.push({gdsname:gds, currentPublishStatus: "waiting"});
        		});

        		for (var i = 0; i < contract.contractGdsStatus.length ; i++) {
        			contract.contractGdsStatus[i].currentPublishStatus = "notselected";

        			if (contract.contractGdsStatus[i].gdsname == "Sabre" && vm.checkGDSExists(vm.targetSystem, "Sabre", "array"))
        				contract.contractGdsStatus[i].currentPublishStatus = "waiting";

        			if (contract.contractGdsStatus[i].gdsname == "Amadeus" && vm.checkGDSExists(vm.targetSystem, "Amadeus", "array"))
        				contract.contractGdsStatus[i].currentPublishStatus = "waiting";

        			if (contract.contractGdsStatus[i].gdsname == "Travelport" && vm.checkGDSExists(vm.targetSystem, "APF", "array"))
        				contract.contractGdsStatus[i].currentPublishStatus = "waiting";
        		}

        		contract.target_system = vm.targetSystem;
        		ContractTargetSystem.update(contract, function(result){
	        		contract = result;

	        		var data = {
                   		contract:[contract]
                   	};

            		vm.showPublishTable = true;

            		ContractPublish.publish(data, function(resp){
//            			console.log("Publish Message: " + resp.customStringVariableType);

            			var publishResult = JSON.parse(resp.customStringVariableType);

            			publishResult.forEach(function(result) {
            				if (result.Travelport != undefined  && result.Travelport.toLowerCase() != 'success' && result.Travelport.toLowerCase() != 'error')
            					vm.publishTravelportResult =  result.Travelport;

            				if (result.Amadeus != undefined  && result.Amadeus.toLowerCase() != 'success' && result.Amadeus.toLowerCase() != 'error')
            					vm.publishAmadeusResult = result.Amadeus;

            				if (result.Sabre != undefined  && result.Sabre.toLowerCase() != 'success' && result.Sabre.toLowerCase() != 'error')
            					vm.publishSabreResult = result.Sabre;

            			})

            			vm.checkContractStatus();

            			if (angular.isDefined(publishInterval))
            				$interval.cancel(publishInterval);

            			publishInterval = $interval(checkContractStatus, 3000);
    		        }, function(error){
    		        });
	        	}, function(error){});


        	});
        }
       
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.fixError = function() {
        	$uibModalInstance.dismiss('fix');
        }

        vm.checkGDSExists = function(gdsList, gds, type) {
        	var exist = false;

        	for (var i = 0; i < gdsList.length; i++) {
        		if (type == 'array') {
        			if (gdsList[i] == gds) {
            			exist = true;
            			break;
            		}
        		} else {
        			if (gdsList[i].gdsname == gds) {
            			exist = true;
            			break;
            		}
        		}

        	}

        	return exist;
        }

        $scope.$on('$destroy', function () {
        	if (angular.isDefined(publishInterval))
        		$interval.cancel(publishInterval);

	        if (angular.isDefined(validateInterval))
				$interval.cancel(validateInterval);
        });
    }
})();
