(function() {
    'use strict';
    
    angular
        .module('americanoApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', 'LoginService', 'AlertService', 'ContractCustom', 'pagingParams', 'paginationConstants', 'ParseLinks', '$timeout'];

    function HomeController ($scope, Principal, LoginService, AlertService, ContractCustom, pagingParams, paginationConstants, ParseLinks, $timeout) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.login = LoginService.open;
        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
            });
        }
        
        vm.loadAll = loadAll;
        vm.processChartContractData = processChartContractData;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll();
  
        function loadAll () {
//            vm.contracts = ContractCustom.get();            
//            vm.processChartContractData();
        }

        function processChartContractData() {
        	vm.barChartData = [];
        	vm.pieChartData = [];
        	vm.pieChartLabel = [];
        	
        	var contractGroupObject = {
        		month:"",
        		contracts: []
        	};
        	
        	var monthGroup = [];
        	var contractGroup = [];
        	
        	var currentMonthYearInString = "";
        	var monthYearInString = "";
        	var totalContracts = 0;
        	
        	var totalAPFContracts = 0;
        	var totalSabreContracts = 0;
        	var totalAmadeusContracts = 0;
        	
        	vm.contracts.$promise.then(function(contracts) {
        		totalContracts = contracts.length;
        		
        		contracts.forEach(function(contract) {
            		if (convertMonthYearString(contract.createdDate) != currentMonthYearInString) {
            			monthGroup.push(contract.createdDate);
            			currentMonthYearInString = convertMonthYearString(contract.createdDate);
            		}
            		
            		if (contract.target_system != null) {
            			contract.target_system.forEach(function(targetSystem) {
            				switch (targetSystem) {
            					case "Amadeus" : totalAmadeusContracts = totalAmadeusContracts + 1;
            								break;
            					case "Sabre" : totalSabreContracts = totalSabreContracts + 1;
    									    break;
            					case "APF" : totalAPFContracts = totalAPFContracts + 1;
    									    break;
            				}
            			});
            		}
            	});
        		
        		var i = 0;
            	var current = 0;
            	
            	monthGroup.forEach(function(group) {
            		monthYearInString = convertMonthYearString(group);
            		contractGroup = [];
            		
            		for (i = current; i < contracts.length; i++) {
            			if (convertMonthYearString(contracts[i].createdDate) == monthYearInString) {
            				contractGroup.push(contracts[i]);
            			} else {
            				contractGroupObject = {month: convertMonthYearString(group), contracts: contractGroup};
            				vm.barChartData.push(contractGroupObject);
            				current = i;
            				break;
            			}
            			
            			if (i == contracts.length-1) {
            				contractGroupObject = {month: convertMonthYearString(group), contracts: contractGroup};
            				vm.barChartData.push(contractGroupObject);
            			}
            		}
            	});
            	
            	
            	vm.annualContractDataInner = [];
            	vm.annualContractLabel = [];
            	
            	vm.barChartData.forEach(function(data) {
            		vm.annualContractDataInner.push(data.contracts.length);
            		vm.annualContractLabel.push(data.month);
            	});
            	
            	vm.annualContractData = [];
            	vm.annualContractData.push(vm.annualContractDataInner);
            	
            	vm.pieChartData.push(totalAmadeusContracts);
            	vm.pieChartData.push(totalSabreContracts);
            	vm.pieChartData.push(totalAPFContracts);
            	
            	vm.pieChartLabel.push("Amadeus");
            	vm.pieChartLabel.push("Sabre");
            	vm.pieChartLabel.push("APF");
            	
        	});
        	
        	
        }
        
        function convertMonthYearString(date) {
        	var monthNames = ["January", "February", "March",
        	                  "April", "May", "June", "July",
        	                  "August", "September", "October",
        	                  "November", "December"];
        	
        	var date = new Date(date);
        	
        	return monthNames[date.getMonth()] + " " + date.getFullYear(); 
        }
        
        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        
        

//        $scope.onClick = function (points, evt) {
//          console.log(points, evt);
//        };
//        
//        // Simulate async data update
//        $timeout(function () {
//          $scope.data = [
//            [28, 48, 40, 19, 86, 27, 90],
//            [65, 59, 80, 81, 56, 55, 40]
//          ];
//        }, 3000);
    }
})();