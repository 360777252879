(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('FareTypeApf', FareTypeApf);

    FareTypeApf.$inject = ['$resource'];

    function FareTypeApf ($resource) {
        var resourceUrl =  'api/fare-type-apfs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
        .module('americanoApp')
        .factory('FareTypeAPFCustom', FareTypeAPFCustom);

    FareTypeAPFCustom.$inject = ['$resource'];

    function FareTypeAPFCustom($resource) {
        var resourceUrl =  'api/fare-type-apfs/getAllFareTypeApf';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
