(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('ContractDetailController', ContractDetailController);

    ContractDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity',  'fare', 'Contract', 'FormValue', 'ContractValue'];

    function ContractDetailController($scope, $rootScope, $stateParams, entity, fare, Contract, FormValue, ContractValue) {
        var vm = this;
        vm.contract = entity;
        vm.formValue = FormValue;
        vm.contractValue = ContractValue;
        vm.id_contract = $stateParams.id_contract;
        vm.fare = fare;

        var unsubscribe = $rootScope.$on('americanoApp:contractUpdate', function(event, result) {
            vm.contract = result;
        });

        $scope.$on('$destroy', unsubscribe);

        vm.ruleExceptionIsEmpty = function(index) {
        	var rules = vm.contract.data[index].pages;
        	var isEmpty = true;

        	for (var i = 0; i < rules.length; i++) {
        		var exception = rules[i].exception;

        		if (exception != null) {
        			isEmpty = false;
        			break;
        		}
        	}
        	return isEmpty;
        }
    }
})();
