(function() {
    'use strict';

    angular
        .module('americanoApp')
        .factory('ContractTabError', ContractTabError);

    ContractTabError.$inject = ['$resource'];

    function ContractTabError ($resource) {
        var resourceUrl =  'api/contract-tab-error/:id';

        return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true}
        });
    }
})();
