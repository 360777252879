(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TravelSegIndicatorDaysTimeDetailController', TravelSegIndicatorDaysTimeDetailController);

    TravelSegIndicatorDaysTimeDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TravelSegIndicatorDaysTime'];

    function TravelSegIndicatorDaysTimeDetailController($scope, $rootScope, $stateParams, entity, TravelSegIndicatorDaysTime) {
        var vm = this;
        vm.travelSegIndicatorDaysTime = entity;
        
        var unsubscribe = $rootScope.$on('americanoApp:travelSegIndicatorDaysTimeUpdate', function(event, result) {
            vm.travelSegIndicatorDaysTime = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
