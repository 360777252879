(function() {
    'use strict';

    angular.module('americanoApp').config(stateConfig);

    stateConfig.$inject = [ '$stateProvider' ];

    function stateConfig($stateProvider) {
    	$stateProvider
    	.state('contract.exception-fare', {
            parent : 'entity',
            url : '/contract/fare-exception/{id_contract}?page&sort&search&group',
            // url : '/contract/fare-exception/{id_contract}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/contract/fare/exception/form-fare-exception.html',
                    controller : 'FormFareExceptionController',
                    controllerAs : 'vm'
                }
            },
            params : {
                page : {
                    value : '1',
                    squash : true
                },
                sort : {
                    value : 'id,desc',
                    squash : true
                },
                search : null,
                group : "DEFAULT",
                newGroup: "0",
                duplicateFare: false
            },
            resolve : {
                translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('fare');
                	return $translate.refresh();
                }]
            }
        })
        .state('contract.exception-fare-dialog', {
            parent : 'contract.exception-fare',
            url : '/{operation}/{index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/fare/exception/form-fare-exception-dialog.html',
                    controller: 'FormFareExceptionDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({tag : "fare_booking_code_exception_primary"});
        				}],
                        form2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({tag : "fare_booking_code_exception_secondary"});
        				}],
        				airlineMaster : ['AirlineCustom', function(AirlineCustom) {
        					return AirlineCustom.get();
                     	}],
                     	passengerMaster : ['PassengerCustom', function(PassengerCustom) {
                        	return PassengerCustom.get();
                     	}],
                        currencies : ['CurrencyCustom', function(CurrencyCustom) {
                        	return CurrencyCustom.get();
                        }],
                        transporting_class : ['TransportingClassCustom', function(TransportingClassCustom) {
                        	return TransportingClassCustom.get();
                        }],
        				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('contract.exception-fare', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.exception-fare', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })
        .state('contract.exception-fare-copy', {
            parent : 'contract.exception-fare',
            url : '/copy/exception/{index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                function($stateParams, $state, $uibModal) { $uibModal.open({
                    templateUrl : 'app/entities/contract/fare/exception/form-fare-exception-copy-dialog.html',
                    controller : 'FormFareExceptionCopyController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
            }).result.then(function(result) {
            }, function() {
            	$state.go('contract.exception-fare', {id_contract:$stateParams.id_contract}, null)})
           	}]
        })
    }
})();
