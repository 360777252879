(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('DistributionGroupDialogController', DistributionGroupDialogController);

    DistributionGroupDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'DistributionGroup'];

    function DistributionGroupDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, DistributionGroup) {
        var vm = this;

        vm.distributionGroup = entity;

        if(vm.distributionGroup.effectiveDate != null || vm.distributionGroup.effectiveDate != undefined)
            vm.distributionGroup.effectiveDate = new Date(vm.distributionGroup.effectiveDate);

        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;

            vm.distributionGroup.effectiveDate = new Date(vm.distributionGroup.effectiveDate);
            if (vm.distributionGroup.id !== null) {
                DistributionGroup.update(vm.distributionGroup, onSaveSuccess, onSaveError);
            } else {
                DistributionGroup.save(vm.distributionGroup, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('americanoApp:distributionGroupUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        $scope.dateOptions = {
            minDate: new Date()
        };

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.gdsOptions = [
            {key:'', value:'Select GDS'},
            {key:'Amadeus', value:'Amadeus'},
            {key:'Sabre', value:'Sabre'},
            {key:'Galileo', value:'Galileo'},
            {key:'Worldspan', value:'Worldspan'},
            {key:'Apollo', value:'Apollo'}
        ];

        vm.modifierOptions = [
            {key:'', value:'Please Select'},
            {key:'01 - Airline System User', value:'01 - Airline System User'},
            {key:'02 - Airline Non System User', value:'02 - Airline Non System User'},
            {key:'03 - Travel Agency', value:'03 - Travel Agency'},
            {key:'04 - Travel Agency', value:'04 - Travel Agency'}
        ];

        /* Multivalue */
        vm.pushData = function(y){
            if(y != null){
                if(vm.distributionGroup.form_items_child == null) {
                    vm.distributionGroup.form_items_child = [];
                }
                vm.distributionGroup.form_items_child.push(y);
                vm.dataDistributionGroup = {};
            }
        };

        vm.removeData = function(x, y){
            vm.distributionGroup.form_items_child.splice(y, 1);
        };
        /* End Multivalue */

        vm.clearField = function (condition) {
            if(condition == 'listGeo' && vm.dataDistributionGroup != undefined){
                vm.dataDistributionGroup.listGeo = '';
            }

            if(condition == 'listPcc' && vm.dataDistributionGroup != undefined){
                vm.dataDistributionGroup.listPcc = '';
            }

        }

        vm.clearFieldAmadeus = function (condition) {
            if(condition == 'iata' && vm.distributionGroup != undefined){
                vm.distributionGroup.corporateId = '';
                vm.distributionGroup.modifier = '';
                vm.distributionGroup.distCountry = '';
                vm.distributionGroup.distCityAirport = '';
                vm.distributionGroup.distOfficeNumber = '';
            }

            if(condition == 'officeId' && vm.distributionGroup != undefined){
                vm.distributionGroup.iata = '';
            }
        }

        vm.clearFieldSabre = function (condition) {
            if(condition == 'pcc' && vm.distributionGroup != undefined){
                // vm.distributionGroup.pcc = '';
                vm.distributionGroup.homePcc = '';
                vm.distributionGroup.iata = '';
                vm.distributionGroup.homeIata = '';
                vm.distributionGroup.area = '';
                vm.distributionGroup.zone = '';
                vm.distributionGroup.nation = '';
                vm.distributionGroup.stateProvince = '';
                vm.distributionGroup.cityAirport = '';
                vm.distributionGroup.department = '';
            }

            if(condition == 'homePcc' && vm.distributionGroup != undefined){
                vm.distributionGroup.pcc = '';
                // vm.distributionGroup.homePcc = '';
                vm.distributionGroup.iata = '';
                vm.distributionGroup.homeIata = '';
                vm.distributionGroup.area = '';
                vm.distributionGroup.zone = '';
                vm.distributionGroup.nation = '';
                vm.distributionGroup.stateProvince = '';
                vm.distributionGroup.cityAirport = '';
                vm.distributionGroup.department = '';
            }

            if(condition == 'iata' && vm.distributionGroup != undefined){
                vm.distributionGroup.pcc = '';
                vm.distributionGroup.homePcc = '';
                // vm.distributionGroup.iata = '';
                vm.distributionGroup.homeIata = '';
                vm.distributionGroup.area = '';
                vm.distributionGroup.zone = '';
                vm.distributionGroup.nation = '';
                vm.distributionGroup.stateProvince = '';
                vm.distributionGroup.cityAirport = '';
                vm.distributionGroup.department = '';
            }

            if(condition == 'homeIata' && vm.distributionGroup != undefined){
                vm.distributionGroup.pcc = '';
                vm.distributionGroup.homePcc = '';
                vm.distributionGroup.iata = '';
                // vm.distributionGroup.homeIata = '';
                vm.distributionGroup.area = '';
                vm.distributionGroup.zone = '';
                vm.distributionGroup.nation = '';
                vm.distributionGroup.stateProvince = '';
                vm.distributionGroup.cityAirport = '';
                vm.distributionGroup.department = '';
            }

            if(condition == 'area' && vm.distributionGroup != undefined){
                vm.distributionGroup.pcc = '';
                vm.distributionGroup.homePcc = '';
                vm.distributionGroup.iata = '';
                vm.distributionGroup.homeIata = '';
                // vm.distributionGroup.area = '';
                vm.distributionGroup.zone = '';
                vm.distributionGroup.nation = '';
                vm.distributionGroup.stateProvince = '';
                vm.distributionGroup.cityAirport = '';
                vm.distributionGroup.department = '';
            }

            if(condition == 'zone' && vm.distributionGroup != undefined){
                vm.distributionGroup.pcc = '';
                vm.distributionGroup.homePcc = '';
                vm.distributionGroup.iata = '';
                vm.distributionGroup.homeIata = '';
                vm.distributionGroup.area = '';
                // vm.distributionGroup.zone = '';
                vm.distributionGroup.nation = '';
                vm.distributionGroup.stateProvince = '';
                vm.distributionGroup.cityAirport = '';
                vm.distributionGroup.department = '';
            }

            if(condition == 'nation' && vm.distributionGroup != undefined){
                vm.distributionGroup.pcc = '';
                vm.distributionGroup.homePcc = '';
                vm.distributionGroup.iata = '';
                vm.distributionGroup.homeIata = '';
                vm.distributionGroup.area = '';
                vm.distributionGroup.zone = '';
                // vm.distributionGroup.nation = '';
                vm.distributionGroup.stateProvince = '';
                vm.distributionGroup.cityAirport = '';
                vm.distributionGroup.department = '';
            }

            if(condition == 'cityAirport' && vm.distributionGroup != undefined){
                vm.distributionGroup.pcc = '';
                vm.distributionGroup.homePcc = '';
                vm.distributionGroup.iata = '';
                vm.distributionGroup.homeIata = '';
                vm.distributionGroup.area = '';
                vm.distributionGroup.zone = '';
                vm.distributionGroup.nation = '';
                vm.distributionGroup.stateProvince = '';
                // vm.distributionGroup.cityAirport = '';
                vm.distributionGroup.department = '';
            }

            if(condition == 'stateProvince' && vm.distributionGroup != undefined){
                vm.distributionGroup.pcc = '';
                vm.distributionGroup.homePcc = '';
                vm.distributionGroup.iata = '';
                vm.distributionGroup.homeIata = '';
                vm.distributionGroup.area = '';
                vm.distributionGroup.zone = '';
                vm.distributionGroup.nation = '';
                // vm.distributionGroup.stateProvince = '';
                vm.distributionGroup.cityAirport = '';
                vm.distributionGroup.department = '';
            }

            if(condition == 'department' && vm.distributionGroup != undefined){
                vm.distributionGroup.pcc = '';
                vm.distributionGroup.homePcc = '';
                vm.distributionGroup.iata = '';
                vm.distributionGroup.homeIata = '';
                vm.distributionGroup.area = '';
                vm.distributionGroup.zone = '';
                vm.distributionGroup.nation = '';
                vm.distributionGroup.stateProvince = '';
                vm.distributionGroup.cityAirport = '';
                // vm.distributionGroup.department = '';
            }
        }

        /*vm.Date = function(arg){
            if(arg == null){
                return new Date();
            }else{
                if(arg != "indef"){
                    return new Date(arg);
                }else{
                    return "indef";
                }
            }
        };*/

    }
})();
