(function() {
    'use strict';
    
    angular
    .module('americanoApp')
    .factory('ContractRouting', ContractRouting);

	ContractRouting.$inject = ['$resource'];
	
	function ContractRouting ($resource) {
	    var resourceUrl =  'api/contracts/routing/:id/:index';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
})();

