(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('TravelSegIndicatorDaysTime', TravelSegIndicatorDaysTime);

    TravelSegIndicatorDaysTime.$inject = ['$resource'];

    function TravelSegIndicatorDaysTime ($resource) {
        var resourceUrl =  'api/travel-seg-indicator-days-times/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
    .module('americanoApp')
    .factory('TravelSegIndicatorDaysTimeCustom', TravelSegIndicatorDaysTimeCustom);

    TravelSegIndicatorDaysTimeCustom.$inject = ['$resource'];
	
	function TravelSegIndicatorDaysTimeCustom ($resource) {
	    var resourceUrl =  'api/travel-seg-indicator-days-time/getAllTravelSegIndicatorDaysTime';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	angular
	.module('americanoApp')
    .factory('TravelSegDayTimeSearch', TravelSegDayTimeSearch);

	TravelSegDayTimeSearch.$inject = ['$resource'];

	function TravelSegDayTimeSearch($resource) {
		var resourceUrl = 'api/_search/travel-seg-daytime/:query'; 
		return $resource(resourceUrl, {}, {
			'query': { method: 'GET', isArray: true}
		});
	}
})();
