(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormUploadFareController', FormUploadFareController);

    FormUploadFareController.$inject = ['$scope', '$stateParams', '$state', '$uibModal', '$uibModalInstance', 'DataUtils', 'entity', 'ContractFareUpload', 'FileSaver', 'Principal'];

    function FormUploadFareController ($scope, $stateParams, $state, $uibModal, $uibModalInstance, DataUtils, entity, ContractFareUpload, FileSaver, Principal) {
    	var vm = this;
    	var fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    	var errorFilename = "Error_Fare_File.xlsx";
    	var templateFilename = "Fare_Template.xlsx";
    	vm.fareFile = entity;
    	vm.fareFileContentType = null;
    	vm.fareFileName = null;
		vm.uploadIsInProgress = false;
    	vm.fareFile.contractId = $stateParams.id_contract;

    	Principal.identity().then(function(account) {
    		vm.fareFile.userList.push(account);
    	});

    	vm.setFile = function ($file, fareFile) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                    	fareFile.file = base64Data;
                    	fareFile.contentType = $file.type;
                    	fareFile.containError = false;
                    	fareFile.fareList = null;
                    	vm.fareFileContentType = $file.type;
                    	vm.fareFileName = $file.name;
                    });
                });
            }
        };

        vm.upload = function() {
        	if (vm.fareFile.file && vm.fareFileContentType == fileType) {
				vm.uploadIsInProgress = true;
				vm.fareFile.group = $stateParams.group;

        		ContractFareUpload.save(vm.fareFile, function(result){
					vm.uploadIsInProgress = false;
        			$uibModalInstance.close(result);
        			$scope.$emit('americanoApp:fareUpload', "");
        		}, function(error){
					vm.uploadIsInProgress = false;
        			vm.fareFile = error.data;
        			$scope.$emit('americanoApp:fareUpload', "");
        		});
        	}
    	}

        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

    	vm.download = function() {
    		ContractFareUpload.get({id: $stateParams.id_contract}, function(result) {
    			var blob = b64toBlob(result.file, fileType);
    			FileSaver.saveAs(blob, templateFilename);
    		}, function(error) {})
    	}

    	vm.downloadErrorFile = function() {
    		var blob = b64toBlob(vm.fareFile.file, fileType);
    		FileSaver.saveAs(blob, errorFilename);
    	}

    	function b64toBlob(b64Data, contentType, sliceSize) {
			  contentType = contentType || '';
			  sliceSize = sliceSize || 512;

			  var byteCharacters = atob(b64Data);
			  var byteArrays = [];

			  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			    var slice = byteCharacters.slice(offset, offset + sliceSize);

			    var byteNumbers = new Array(slice.length);
			    for (var i = 0; i < slice.length; i++) {
			      byteNumbers[i] = slice.charCodeAt(i);
			    }

			    var byteArray = new Uint8Array(byteNumbers);

			    byteArrays.push(byteArray);
			  }

			  var blob = new Blob(byteArrays, {type: contentType});

			  return blob;
		}

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
