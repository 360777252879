(function() {
    'use strict';

    angular
        .module('americanoApp')
        .factory('Contract', Contract);

    Contract.$inject = ['$resource'];

    function Contract ($resource) {
        var resourceUrl =  'api/contracts/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'getType': { method:'GET', url: 'api/contracts/getType/:id' },
            'expire': {method:'POST', url: 'api/contracts/expire'},
            'updateStatus': { method:'PUT', url: 'api/contracts/status'}
        });
    }

    angular
    .module('americanoApp')
    .factory('ContractUpload', ContractUpload);

    ContractUpload.$inject = ['$resource'];

	function ContractUpload ($resource) {
	    var resourceUrl =  'api/contract/upload';

	    return $resource(resourceUrl, {}, {
	        'upload': { method: 'POST'},
	    });
	}
	
	angular
    .module('americanoApp')
    .factory('ContractUploadV2', ContractUploadV2);

	ContractUploadV2.$inject = ['$resource'];

	function ContractUploadV2 ($resource) {
	    var resourceUrl =  'api/contract-uploader-excel-template/upload-validate';

	    return $resource(resourceUrl, {}, {
	        'uploadAndValidate': { method: 'POST' },
	        'uploadAndFile': { method: 'POST', url: 'api/contract-uploader-excel-template/upload-filling' }
	    });
	}

    angular
        .module('americanoApp')
        .factory('ContractCopy', ContractCopy);

    ContractCopy.$inject = ['$resource'];

    function ContractCopy ($resource) {
        var resourceUrl =  'api/contracts/copy';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'copy': { method:'POST' }
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractMultiple', ContractMultiple);

    ContractMultiple.$inject = ['$resource'];

    function ContractMultiple ($resource) {
        var resourceUrl =  'api/contracts-multiple/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true },
            'get': { method: 'POST', isArray: true },
            'update': { method:'PUT', isArray: true }
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractHeader', ContractHeader);

    ContractHeader.$inject = ['$resource'];

    function ContractHeader ($resource) {
        var resourceUrl =  'api/contracts/header/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
		.module('americanoApp')
		.factory('ContractTargetSystem', ContractTargetSystem);

    ContractTargetSystem.$inject = ['$resource'];

	function ContractTargetSystem ($resource) {
	    var resourceUrl =  'api/contracts/update-target-system/:id';

	return $resource(resourceUrl, {}, {
	    'query': { method: 'GET', isArray: true},
	    'get': {
	        method: 'GET',
	        transformResponse: function (data) {
	        	if(data)
	        		data = angular.fromJson(data);
	            return data;
	        }
	    },
	    'update': { method:'PUT' }
	    });
	}

    angular
        .module('americanoApp')
        .factory('ContractRuleApplication', ContractRuleApplication);

    ContractRuleApplication.$inject = ['$resource'];

    function ContractRuleApplication ($resource) {
        var resourceUrl =  'api/contracts/rule-application/:id/:search/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/rule-application/exception'},
            'copy': { method:'POST', url: 'api/contracts/rule-application/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractRuleApplicationOnly', ContractRuleApplicationOnly);

    ContractRuleApplicationOnly.$inject = ['$resource'];

    function ContractRuleApplicationOnly ($resource) {
        var resourceUrl =  'api/contracts/rule-application-data/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/rule-application-data/exception'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractFlightApplication', ContractFlightApplication);

    ContractFlightApplication.$inject = ['$resource'];

    function ContractFlightApplication ($resource) {
        var resourceUrl =  'api/contracts/flight-application/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/flight-application/exception'},
            'copy': { method:'POST', url: 'api/contracts/flight-application/copy/:id/:index'}
        });
    }

    angular
    .module('americanoApp')
    .factory('ContractMoveFlightApplication', ContractMoveFlightApplication);

	ContractMoveFlightApplication.$inject = ['$resource'];

	function ContractMoveFlightApplication ($resource) {
	    var resourceUrl =  'api/contracts/flight-application/move/:id';

		return $resource(resourceUrl, {}, {
		    'query': { method: 'GET', isArray: true},
		    'get': {
		        method: 'GET',
		        transformResponse: function (data) {
		        	if(data)
		        		data = angular.fromJson(data);
		            return data;
		        }
		    },
		    'update': { method:'PUT' },
		    'move': {method:'PUT'}
	    });
	}

    angular
        .module('americanoApp')
        .factory('ContractAdvanceReservation', ContractAdvanceReservation);

    ContractAdvanceReservation.$inject = ['$resource'];

    function ContractAdvanceReservation ($resource) {
        var resourceUrl =  'api/contracts/advance-reservation/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/advance-reservation/exception'},
            'copy': { method:'POST', url: 'api/contracts/advance-reservation/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractBlackout', ContractBlackout);

    ContractBlackout.$inject = ['$resource'];

    function ContractBlackout ($resource) {
        var resourceUrl =  'api/contracts/blackout/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/blackout/exception'},
            'copy': { method:'POST', url: 'api/contracts/blackout/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractSurcharge', ContractSurcharge);

    ContractSurcharge.$inject = ['$resource'];

    function ContractSurcharge ($resource) {
        var resourceUrl =  'api/contracts/surcharge/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/surcharge/exception'},
            'copy': { method:'POST', url: 'api/contracts/surcharge/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractDiscount', ContractDiscount);

    ContractDiscount.$inject = ['$resource'];

    function ContractDiscount ($resource) {
        var resourceUrl =  'api/contracts/discount/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/discount/exception'},
            'copy': { method:'POST', url: 'api/contracts/discount/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractSeason', ContractSeason);

    ContractSeason.$inject = ['$resource'];

    function ContractSeason ($resource) {
        var resourceUrl =  'api/contracts/season/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/season/exception'},
            'copy': { method:'POST', url: 'api/contracts/season/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractMinimumStay', ContractMinimumStay);

    ContractMinimumStay.$inject = ['$resource'];

    function ContractMinimumStay ($resource) {
        var resourceUrl =  'api/contracts/min-stay/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/min-stay/exception'},
            'copy': { method:'POST', url: 'api/contracts/min-stay/copy/:id/:index'},
            'move': { method:'PUT', url: 'api/contracts/min-stay/move/:id' }
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractMaximumStay', ContractMaximumStay);

    ContractMaximumStay.$inject = ['$resource'];

    function ContractMaximumStay ($resource) {
        var resourceUrl =  'api/contracts/max-stay/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/max-stay/exception'},
            'copy': { method:'POST', url: 'api/contracts/max-stay/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractEndorsement', ContractEndorsement);

    ContractEndorsement.$inject = ['$resource'];

    function ContractEndorsement ($resource) {
        var resourceUrl =  'api/contracts/endorsement/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/endorsement/exception'},
            'copy': { method:'POST', url: 'api/contracts/endorsement/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractDayTime', ContractDayTime);

    ContractDayTime.$inject = ['$resource'];

    function ContractDayTime ($resource) {
        var resourceUrl =  'api/contracts/day-time/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/day-time/exception'},
            'copy': { method:'POST', url: 'api/contracts/day-time/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractCombination', ContractCombination);

    ContractCombination.$inject = ['$resource'];

    function ContractCombination ($resource) {
        var resourceUrl =  'api/contracts/combination/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/combination/exception'},
            'copy': { method:'POST', url: 'api/contracts/combination/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractEligibility', ContractEligibility);

    ContractEligibility.$inject = ['$resource'];

    function ContractEligibility ($resource) {
        var resourceUrl =  'api/contracts/eligibility/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/eligibility/exception'},
            'copy': { method:'POST', url: 'api/contracts/eligibility/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractTravelRestriction', ContractTravelRestriction);

    ContractTravelRestriction.$inject = ['$resource'];

    function ContractTravelRestriction ($resource) {
        var resourceUrl =  'api/contracts/travel-restriction/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/travel-restriction/exception'},
            'copy': { method:'POST', url: 'api/contracts/travel-restriction/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractGroup', ContractGroup);

    ContractGroup.$inject = ['$resource'];

    function ContractGroup ($resource) {
        var resourceUrl =  'api/contracts/groups/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/groups/exception'},
            'copy': { method:'POST', url: 'api/contracts/groups/copy/:id/:index'}

        });
    }

    angular
        .module('americanoApp')
        .factory('ContractStopover', ContractStopover);

    ContractStopover.$inject = ['$resource'];

    function ContractStopover ($resource) {
        var resourceUrl =  'api/contracts/stopover/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/stopover/exception'},
            'copy': { method:'POST', url: 'api/contracts/stopover/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractSalesRestriction', ContractSalesRestriction);

    ContractSalesRestriction.$inject = ['$resource'];

    function ContractSalesRestriction ($resource) {
        var resourceUrl =  'api/contracts/sales-restriction/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/sales-restriction/exception'},
            'copy': { method:'POST', url: 'api/contracts/sales-restriction/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractPenalties', ContractPenalties);

    ContractPenalties.$inject = ['$resource'];

    function ContractPenalties ($resource) {
        var resourceUrl =  'api/contracts/penalties/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/penalties/exception'},
            'copy': { method:'POST', url: 'api/contracts/penalties/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractAccompaniedTravels', ContractAccompaniedTravels);

    ContractAccompaniedTravels.$inject = ['$resource'];

    function ContractAccompaniedTravels ($resource) {
        var resourceUrl =  'api/contracts/accompanied-travels/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/accompanied-travels/exception'},
            'copy': { method:'POST', url: 'api/contracts/accompanied-travels/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractTransfer', ContractTransfer);

    ContractTransfer.$inject = ['$resource'];

    function ContractTransfer ($resource) {
        var resourceUrl =  'api/contracts/transfer/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/transfer/exception'},
            'copy': { method:'POST', url: 'api/contracts/transfer/copy/:id/:index'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractCustom', ContractCustom);

    ContractCustom.$inject = ['$resource'];

    function ContractCustom($resource) {
        var resourceUrl =  'api/contracts/getAllContracts';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
        .module('americanoApp')
        .factory('RoutingActivated', RoutingActivated);

    RoutingActivated.$inject = ['$resource'];

    function RoutingActivated ($resource) {
        var resourceUrl =  'api/routings-activated';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractFareBasisCode', ContractFareBasisCode);

    ContractFareBasisCode.$inject = ['$resource'];

    function ContractFareBasisCode ($resource) {
        var resourceUrl =  'api/contract-fare-basis-code/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractFareOriginDestination', ContractFareOriginDestination);

    ContractFareOriginDestination.$inject = ['$resource'];

    function ContractFareOriginDestination ($resource) {
        var resourceUrl =  'api/contracts/fare-data/origin-destination/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractFareBookingCode', ContractFareBookingCode);

    ContractFareBookingCode.$inject = ['$resource'];

    function ContractFareBookingCode ($resource) {
        var resourceUrl =  'api/contract-fare-booking-code/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractFarePassengerType', ContractFarePassengerType);

    ContractFarePassengerType.$inject = ['$resource'];

    function ContractFarePassengerType ($resource) {
        var resourceUrl =  'api/contract-fare-passenger-type/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET', isArray: true,
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractGDS', ContractGDS);

    ContractGDS.$inject = ['$resource'];

    function ContractGDS ($resource) {
        var resourceUrl =  'api/contracts/getGds/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: false},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractSearch', ContractSearch);

    ContractSearch.$inject = ['$resource'];

    function ContractSearch($resource) {
        var resourceUrl = 'api/_search/contracts/:query/:type';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }

    angular
        .module('americanoApp')
        .factory('SpecialContractSearch', SpecialContractSearch);

    SpecialContractSearch.$inject = ['$resource'];

    function SpecialContractSearch($resource) {
        var resourceUrl = 'api/_search/scontracts/:type/:searchBy/:query';
        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }



    angular
        .module('americanoApp')
        .factory('ContractValidate', ContractValidate);

    ContractValidate.$inject = ['$resource'];

    function ContractValidate($resource) {
        var resourceUrl = 'api/contracts/validate/:contract_id';
        return $resource(resourceUrl, {}, {
            'save': { method: 'POST'},
            'get': {method: 'GET'},
            'validate': {method:'POST'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractPublish', ContractPublish);

    ContractPublish.$inject = ['$resource'];

    function ContractPublish($resource) {
        var resourceUrl = 'api/contracts/publish/:contract_id';
        return $resource(resourceUrl, {}, {
            'save': { method: 'POST'},
            'get': {method: 'GET'},
            'publish': {method:'POST'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractGDSStatus', ContractGDSStatus);

    ContractGDSStatus.$inject = ['$resource'];

    function ContractGDSStatus($resource) {
        var resourceUrl = 'api/contracts/retrieveStatus';
        return $resource(resourceUrl, {}, {
            'get': {method: 'POST'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractRuleExist', ContractRuleExist);

    ContractRuleExist.$inject = ['$resource'];

    function ContractRuleExist($resource) {
        var resourceUrl = 'api/contracts/ruleExist/:contract_id';
        return $resource(resourceUrl, {}, {
            'get': {method: 'GET'}
        });
    }

    angular
        .module('americanoApp')
        .factory('PassengerTypeIsInFare', PassengerTypeIsInFare);

    PassengerTypeIsInFare.$inject = ['$resource'];

    function PassengerTypeIsInFare($resource) {
        var resourceUrl = 'api/contracts/checkPassengerType/:contract_id/:passenger_type';
        return $resource(resourceUrl, {}, {
            'get': {method: 'GET'}
        });
    }

    angular
        .module('americanoApp')
        .factory('ContractVoluntaryChange', ContractVoluntaryChange);

    ContractVoluntaryChange.$inject = ['$resource'];

    function ContractVoluntaryChange ($resource) {
        var resourceUrl =  'api/contracts/voluntary-change/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/voluntary-change/exception'},
            'copy': { method:'POST', url: 'api/contracts/voluntary-change/copy/:id/:index'}

        });
    }

    angular
        .module('americanoApp')
        .factory('ContractHigherIntermediatePoint', ContractHigherIntermediatePoint);

    ContractHigherIntermediatePoint.$inject = ['$resource'];

    function ContractHigherIntermediatePoint ($resource) {
        var resourceUrl =  'api/contracts/higher-intermediate-point/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/higher-intermediate-point/exception'},
            'copy': { method:'POST', url: 'api/contracts/higher-intermediate-point/copy/:id/:index'}

        });
    }

    angular
    .module('americanoApp')
    .factory('ContractMiscellaneousFareTag', ContractMiscellaneousFareTag);

    ContractMiscellaneousFareTag.$inject = ['$resource'];

	function ContractMiscellaneousFareTag ($resource) {
	    var resourceUrl =  'api/contracts/miscellaneous-fare-tag/:id/:index';

	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	            	if(data)
	            		data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/miscellaneous-fare-tag/exception'},
            'copy': { method:'POST', url: 'api/contracts/miscellaneous-fare-tag/copy/:id/:index'}
	    });
	}

    angular
        .module('americanoApp')
        .factory('ContractVoluntaryRefund', ContractVoluntaryRefund);

    ContractVoluntaryRefund.$inject = ['$resource'];

    function ContractVoluntaryRefund ($resource) {
        var resourceUrl =  'api/contracts/voluntary-refund/:id/:index';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                	if(data)
                		data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'updateException' : { method:'PUT', url: 'api/contracts/voluntary-refund/exception'},
            'copy': { method:'POST', url: 'api/contracts/voluntary-refund/copy/:id/:index'}

        });
    }
    
    
    angular
    .module('americanoApp')
    .factory('ContractTourAgentsOtherDiscount', ContractTourAgentsOtherDiscount);

    ContractTourAgentsOtherDiscount.$inject = ['$resource'];
	
	function ContractTourAgentsOtherDiscount ($resource) {
	    var resourceUrl =  'api/contracts/tour-agents-other-discount/:id/:index';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	            	if(data)
	            		data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' },
	        'updateException' : { method:'PUT', url: 'api/contracts/tour-agents-other-discount/exception'},
	        'copy': { method:'POST', url: 'api/contracts/tour-agents-other-discount/copy/:id/:index'}
	
	    });
	}
    
	
	 angular
	    .module('americanoApp')
	    .factory('ContractTours', ContractTours);

	    ContractTours.$inject = ['$resource'];
		
		function ContractTours ($resource) {
		    var resourceUrl =  'api/contracts/tours/:id/:index';
		
		    return $resource(resourceUrl, {}, {
		        'query': { method: 'GET', isArray: true},
		        'get': {
		            method: 'GET',
		            transformResponse: function (data) {
		            	if(data)
		            		data = angular.fromJson(data);
		                return data;
		            }
		        },
		        'update': { method:'PUT' },
		        'updateException' : { method:'PUT', url: 'api/contracts/tours/exception'},
		        'copy': { method:'POST', url: 'api/contracts/tours/copy/:id/:index'}
		
	    });
	}
    
})();
