(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('RoutingTariffSabreDeleteController',RoutingTariffSabreDeleteController);

    RoutingTariffSabreDeleteController.$inject = ['$uibModalInstance', 'entity', 'RoutingTariffSabre'];

    function RoutingTariffSabreDeleteController($uibModalInstance, entity, RoutingTariffSabre) {
        var vm = this;
        vm.routingTariffSabre = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            RoutingTariffSabre.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
