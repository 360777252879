(function () {
    'use strict';

    angular
        .module('americanoApp')
        .controller('RuleExceptionController', RuleExceptionController);

    RuleExceptionController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$uibModalInstance', 'SeasonTypeCustom', 'ContractFareBasisCode'];

    function RuleExceptionController ($rootScope, $scope, $state, $stateParams, $uibModalInstance, SeasonTypeCustom, ContractFareBasisCode) {
        var vm = this;
        vm.fareGroupingType = "FareBasisCode";
        vm.currentStep = 1;
        vm.seasonTypes = SeasonTypeCustom.get();
        vm.contractId = $stateParams.id_contract;
        vm.selectedFares = [];
        vm.selectedStatus = [];
        vm.selectedRow = [];
        vm.faresAssigned = [];
        vm.loadFareByFareBasisCode = loadFareByFareBasisCode;
        vm.loadFareByFareGroup = loadFareByFareGroup;
        vm.loadFareByFareFamily = loadFareByFareFamily;
        vm.groupFareAvailable = groupFareAvailable;
        vm.checkFares = checkFares;
        vm.originalFares = [];

        vm.parent = $scope.$parent.vm;
        vm.exception = {
            fareBasisCode: [],
            seasonType: null,
            type: null,
            owrt: null,
            location1: null,
            location2: null,
            index:vm.parent.index
        };

        vm.groupFareAvailable();

        vm.save = function() {
        	$uibModalInstance.dismiss();

        	vm.exception.fareBasisCode = vm.faresAssigned;

        	if(vm.exception.seasonType == ""){
        		vm.exception.seasonType = null;
        	}
        	vm.parent.saveException(vm.exception);
        }

        vm.close = function() {
        	$uibModalInstance.dismiss();

        	vm.parent.resetCheckbox();
        }

        vm.registerAllFares = function(isChecked) {
        	if (isChecked) {
        		vm.selectedRow = [];
        		vm.selectedFares = [];
        		for (var i = 0; i < vm.faresAvailable.length; i++) {
            		vm.selectedFares.push(vm.faresAvailable[i]);
            		vm.selectedStatus[i] = true;
            		vm.selectedRow.push(i);
            	}
        	} else {
        		vm.selectedRow = [];
        		vm.selectedFares = [];
        		for (var i = 0; i < vm.selectedStatus.length; i++) {
        			vm.selectedStatus[i] = false;
        		}
        	}

//        	console.log("MASTER");
//        	console.log(vm.selectedFares);
//        	console.log(vm.selectedStatus);
//        	console.log(vm.selectedRow);
        }

		vm.rowHighlighted = function(row, rule, index) {
			var selectedExist = false;

			for (var i = 0; i < vm.selectedRow.length; i++) {
				if (vm.selectedRow[i] == row) {
					vm.selectedRow.splice(i, 1);
					selectedExist = true;
					break;
				}
			}

			if (selectedExist)
				vm.selectedStatus[index] = false;
			else {
				vm.selectedStatus[index] = true;
				vm.selectedRow.push(row);
			}

			vm.registerRule(row, rule, index);
	 	}

		vm.registerRule = function(transactionIndex, fare, index) {
        	if (vm.selectedStatus[index]) {
        		vm.selectedFares.push(fare);
            	vm.selectedStatus[index] = true;

        	} else {
        		vm.selectedFares.splice(vm.selectedFares.indexOf(fare), 1);
        		vm.selectedStatus[index] = false;
        	}

        	if (vm.selectedFares.length == vm.faresAvailable.length)
        		vm.master = true;
        	else
        		vm.master = false;

//        	console.log("EXCEPTION");
//        	console.log(vm.selectedFares);
//        	console.log(vm.selectedStatus);
//        	console.log(vm.selectedRow);
        }

		vm.getSelectedRowStatus = function(row) {
	 		var status = false;

	 		if (vm.master) {
	 			status = true;
	 		} else {
 				for (var i = 0; i < vm.selectedRow.length; i++) {
		 			if (vm.selectedRow[i] == row) {
		 				status = true;
		 				break;
		 			}
		 		}
	 		}

	 		return status;
	 	}

        vm.resetCheckbox = function() {
	       	 vm.selectedFares = [];
	       	 vm.selectedRow = [];
	       	 vm.master = false;
	       	 for (var i = 0; i < vm.selectedStatus.length; i++) {
	       		 vm.selectedStatus[i] = false;
	       	 }
       }

       vm.addSelectedFares = function() {
    	   if (vm.fareGroupingType.toLowerCase() == "farefamily") {
    		   vm.selectedFares.forEach(function(selectedFare) {
    			   for (var i = 0; i < vm.fareBasisCodes.length; i++) {
    				   if (vm.fareBasisCodes[i].charAt(0).toUpperCase() == selectedFare.charAt(0).toUpperCase()) {
    					   if (vm.faresAssigned.indexOf(vm.fareBasisCodes[i]) == -1)
    						   vm.faresAssigned.push(vm.fareBasisCodes[i]);
    				   }
    			   }
    		   });
    	   } else if (vm.fareGroupingType.toLowerCase() == "faregroup") {

    	   } else {
    		   vm.selectedFares.forEach(function(selectedFare) {
        		   vm.faresAssigned.push(selectedFare);
        	   });

        	   vm.temp = [];

        	   for (var i = 0; i < vm.faresAvailable.length; i++) {
        		   if (vm.selectedRow.indexOf(i) == -1) {
        			   vm.temp.push(vm.faresAvailable[i]);
        		   }
        	   }

        	   vm.faresAvailable = vm.temp;
    	   }

    	   vm.resetCheckbox();
       }
       
       vm.addWildcard = function() {
    	   if (vm.wildcard != undefined && vm.wildcard != null && vm.wildcard != "") {
    		   vm.faresAssigned.push(vm.wildcard);
    	   }
       }
       
       vm.removeSelectedFare = function(index, fare) {
    	   vm.faresAssigned.splice(index, 1);

    	   if (vm.fareGroupingType.toLowerCase() == "farebasiscode") {
    		   if (vm.originalFares.indexOf(fare) != -1)
    			   vm.faresAvailable.push(fare);
    	   }
       }

       function groupFareAvailable() {
    	   vm.faresAvailable = [];
    	   vm.faresAssigned = [];

    	   if (vm.fareGroupingType.toLowerCase() == "farefamily")
    		   vm.loadFareByFareFamily();
    	   else if (vm.fareGroupingType.toLowerCase() == "faregroup") {
    		   vm.loadFareByFareGroup();
    	   } else
    		   vm.loadFareByFareBasisCode();
       }

       function loadFareByFareFamily() {
    	   ContractFareBasisCode.get({id: vm.contractId}, function(success) {
    		   vm.fareBasisCodes = success.customStringListVariableType;

    		   if (vm.fareBasisCodes.length > 0) {
    			   vm.fareBasisCodes.forEach(function(fbc) {
    				   if (vm.faresAvailable.indexOf(fbc.charAt(0) + "*") == -1) {
    					   vm.faresAvailable.push(fbc.charAt(0) + "*");
    				   }
        		   });
    		   }

    		   vm.checkFares();
    	   }, function(error) {});
       }

       function loadFareByFareGroup() {

       }

       function loadFareByFareBasisCode() {
	       	ContractFareBasisCode.get({id: vm.contractId}, function(success) {
	           	vm.faresAvailable = success.customStringListVariableType;
	           	vm.originalFares = success.customStringListVariableType;
	           	
	           	vm.checkFares();
	       	}, function(error) {});
       }

       function checkFares() {
    	   vm.selectedRules = vm.parent.selectedRules;

	       	if (vm.selectedRules.length > 0) {
	       		if (vm.selectedRules[0].exception != undefined && vm.selectedRules[0].exception != null) {
	               	vm.exception = vm.selectedRules[0].exception;

	               	vm.exception.fareBasisCode.forEach(function(fbc) {
	                   	vm.selectedFares.push(fbc);

	                   	for (var i = 0; i < vm.faresAvailable.length; i++) {
	                   		if (vm.faresAvailable[i] == fbc) {
	                   			vm.selectedRow.push(i);
	                   			break;
	                   		}
	                   	}
	               	});
	       		}
	       	}

	       	vm.addSelectedFares();
       }
}
})();
