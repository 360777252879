(function() {
	'use strict';

	angular.module('americanoApp').config(stateConfig);

	stateConfig.$inject = [ '$stateProvider'];

	function stateConfig($stateProvider) {
		$stateProvider			
				.state(
						'vendor',
						{
							parent : 'entity',
							url : '/vendor?page&sort&search',
							data : {
								authorities : [ 'ROLE_USER' ],
								pageTitle : 'americanoApp.form.detail.title'
							},
							views : {
								'content@' : {
									templateUrl : 'app/entities/vendor/vendors.html',
									controller : 'VendorsController',
									controllerAs : 'vm'
								}
							},
							params : {
								page : {
									value : '1',
									squash : true
								},
								sort : {
									value : 'id,desc',
									squash : true
								},
								search : null
							},
							resolve : {
								translatePartialLoader : [
										'$translate',
										'$translatePartialLoader',
										function($translate,
												$translatePartialLoader) {
											$translatePartialLoader
													.addPart('vendor');
											return $translate.refresh();
										} ],
								pagingParams : [
										'$stateParams',
										'PaginationUtil',
										function($stateParams, PaginationUtil) {
											return {
												page : PaginationUtil
														.parsePage($stateParams.page),
												sort : $stateParams.sort,
												predicate : PaginationUtil
														.parsePredicate($stateParams.sort),
												ascending : PaginationUtil
														.parseAscending($stateParams.sort),
												search : $stateParams.search
											};
										} ],
							}
						})
				.state(
						'vendor.new',
						{
							parent : 'vendor',
							url : '/new',
							data : {
								authorities : [ 'ROLE_USER' ],
								pageTitle : 'americanoApp.form.detail.title'
							},
							onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				                $uibModal.open({
				                    templateUrl: 'app/entities/vendor/vendor-dialog.html',
				                    controller: 'VendorDialogController',
				                    controllerAs: 'vm',
				                    backdrop: 'static',
				                    size: 'lg',
				                    resolve : {
										translatePartialLoader : [
												'$translate',
												'$translatePartialLoader',
												function($translate,
														$translatePartialLoader) {
													$translatePartialLoader
															.addPart('vendor');
													return $translate.refresh();
												} ],
										form1 : [ '$stateParams', 'FormContract',
												function($stateParams, FormContract) {
													return FormContract.get({
														tag : "vendor"
													});
												} ],
										entity : [ '$stateParams',
												function($stateParams) {
													return {
														pcc : null,
														name : null,
														description : null,
														gds : null,
														group : null
													};
												} ]
									}
				                }).result.then(function() {
				                    $state.go('vendor', null, { reload: true });
				                }, function() {
				                    $state.go('vendor');
				                });
				            }]
						})
				.state(
						'vendor.edit',
						{
							parent : 'vendor',
							url : '/edit/{id}',
							data : {
								authorities : [ 'ROLE_USER' ],
							},
							onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
				                $uibModal.open({
				                    templateUrl: 'app/entities/vendor/vendor-dialog.html',
				                    controller: 'VendorDialogController',
				                    controllerAs: 'vm',
				                    backdrop: 'static',
				                    size: 'lg',
				                    resolve : {
										translatePartialLoader : [
												'$translate',
												'$translatePartialLoader',
												function($translate,
														$translatePartialLoader) {
													$translatePartialLoader
															.addPart('vendor');
													return $translate.refresh();
												} ],
										form1 : [ '$stateParams', 'FormContract',
												function($stateParams, FormContract) {
													return FormContract.get({
														tag : "vendor"
													});
												} ],
										entity : [ 'Vendor',
													function(Vendor) {
														return Vendor.get({
															id : $stateParams.id
														});
													} ]
									}
				                }).result.then(function() {
				                    $state.go('vendor', null, { reload: true });
				                }, function() {
				                    $state.go('vendor');
				                });
				            }]
						})
				.state(
						'vendor.delete',
						{
							parent : 'vendor',
							url : '/{id}/delete',
							data : {
								authorities : [ 'ROLE_USER' ]
							},
							onEnter : [
									'$stateParams',
									'$state',
									'$uibModal',
									function($stateParams, $state, $uibModal) {
										$uibModal
												.open({
													templateUrl : 'app/entities/vendor/vendor-delete-dialog.html',
													controller : 'VendorDeleteController',
													controllerAs : 'vm',
													size : 'md',
													resolve : {
														entity : [
																'Vendor',
																function(Vendor) {
																	return Vendor
																			.get({
																				id : $stateParams.id
																			});
																} ]
													}
												}).result
												.then(
														function() {
															$state
																	.go(
																			'vendor',
																			{
																			},
																			{
																				reload : true
																			});
														}, function() {
															$state.go("^");
														});
									} ]
						})
		
	}
})();