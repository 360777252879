(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('MasterAccountDetailController', MasterAccountDetailController);

    MasterAccountDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'MasterAccount'];

    function MasterAccountDetailController($scope, $rootScope, $stateParams, entity, MasterAccount) {
        var vm = this;
        vm.masterAccount = entity;
        vm.load = function (id) {
            MasterAccount.get({id: id}, function(result) {
                vm.masterAccount = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:masterAccountUpdate', function(event, result) {
            vm.masterAccount = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
