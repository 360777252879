(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('HigherIntermediatePointController', HigherIntermediatePointController);

    HigherIntermediatePointController.$inject = [
        '$scope', '$parse', '$state', '$stateParams', '$uibModal',
        'ContractHigherIntermediatePoint', 'form1', '$uibModalInstance', 'ContractValue'];

    function HigherIntermediatePointController (
        $scope, $parse, $state, $stateParams, $uibModal,
        ContractHigherIntermediatePoint, form1, $uibModalInstance, ContractValue) {

        var vm = this;
        vm.entity = ContractHigherIntermediatePoint.get({ id : $stateParams.id_contract });
        vm.form1 = form1;
        vm.id_contract = $stateParams.id_contract;
        vm.index = $stateParams.index;

        vm.entity.$promise.then(function(contract) {
            vm.hipRules = contract.data[25].pages;

            if (vm.index < vm.hipRules.length)
                vm.editHip(vm.hipRules[vm.index]);
        });

        //HIP
        vm.addHip = function(){
            vm.entity.data[25].pages.push({
                forms:[
                    angular.copy(vm.form1)
                ]
            });
        }

        vm.editHip = function(x){
            vm.form1.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            });
            // ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            // vm.form1 = angular.copy(x.forms[0]);


            vm.editObjectHip= x;
            vm.isHipEdit = true;
        };

        vm.saveHipEdit = function(){
            vm.entity.data[25].pages[vm.entity.data[25].pages.indexOf(vm.editObjectHip)].forms[0] = angular.copy(vm.form1);


            vm.editObjectHip= null;
            vm.isHipEdit = false;
        };
        //END HIP

		vm.save = function(){
		    var forms = [vm.form1];

            if (vm.isHipEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractHigherIntermediatePoint.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractHigherIntermediatePoint.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }


            // if (vm.isHipEdit)
				// vm.saveHipEdit();
            // else
				// vm.addHip();
            //
            // ContractHigherIntermediatePoint.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// }, function(error){
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };
    }
})();
