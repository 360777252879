(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormContractExpiredController', FormContractExpiredController);


    FormContractExpiredController.$inject = ['$scope', '$state', '$interval', '$filter',  '$rootScope', '$stateParams', 'ContractHeader', 'Principal', '$uibModalInstance', 'ContractValue', 'ContractValidate', 'ContractGDSStatus', 'ContractPublish', 'ContractTargetSystem', 'Contract'];

    function FormContractExpiredController ($scope, $state, $interval, $filter,  $rootScope, $stateParams, ContractHeader, Principal, $uibModalInstance, ContractValue, ContractValidate, ContractGDSStatus, ContractPublish, ContractTargetSystem, Contract) {
        var vm = this;
        var validateInterval, publishInterval;

        vm.contractValue = ContractValue;
        vm.contract = [];
        vm.GDSOption = ['Sabre', 'Travelport', 'Amadeus'];
        vm.showValidateTable = false;
        vm.showExpiredTable = false;
        vm.statusOkToPublishNow = false;
        vm.hasBeenValidated = false;
        vm.validateTravelportResult = [];
        vm.validateSabreResult = [];
        vm.validateAmadeusResult = [];
        vm.totalValidateError = 0;
        vm.expiredTravelportResult = "";
        vm.expiredSabreResult = "";
        vm.expiredAmadeusResult = "";

        vm.contractIDList = {
        	customStringVariableType : null,
        	customLongVariableType : null,
        	customIntegerVariableType : null,
        	customStringListVariableType : [$stateParams.id_contract]
        };

        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };
        
        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
        });
        
        ContractHeader
		.get({
			id : $stateParams.id_contract
		}).$promise.then(function(contract) {
        	vm.entity = contract;
        	vm.targetSystem = vm.entity.target_system;
            vm.contract.push(vm.entity);
        });

        vm.expired = function() {
        	vm.entity.$promise.then(function(contract){
        		vm.showExpiredTable = true;

        		var data = {
                   		contract:[contract]
                };
        		
        		for (var j = 0; j < contract.contractGdsStatus.length ; j++) {
        			if (contract.contractGdsStatus[j].gdsname == "APF") 
        				contract.contractGdsStatus[j].currentExpireStatus = "Waiting";
        			if (contract.contractGdsStatus[j].gdsname == "Sabre") 
        				contract.contractGdsStatus[j].currentExpireStatus = "Waiting";
        			if (contract.contractGdsStatus[j].gdsname == "Amadeus") 
        				contract.contractGdsStatus[j].currentExpireStatus = "Waiting";
        		}
        		
        		Contract.updateStatus(contract, function(result){
        			Contract.expire(data, function(resp){
            			var expiredResult = JSON.parse(resp.customStringVariableType);
            			expiredResult.forEach(function(result) {  
            				if (result.Travelport != undefined  && result.Travelport.toLowerCase() != 'success' && result.Travelport.toLowerCase() != 'error'){
            					vm.expiredTravelportResult =  result.Travelport;
            				}
            				if (result.Amadeus != undefined  && result.Amadeus.toLowerCase() != 'success' && result.Amadeus.toLowerCase() != 'error'){
            					vm.expiredAmadeusResult = result.Amadeus;
            				}
            				if (result.Sabre != undefined  && result.Sabre.toLowerCase() != 'Success' && result.Sabre.toLowerCase() != 'error'){
            					vm.expiredSabreResult = result.Sabre;
            				}
            			});
            			
            			ContractGDSStatus.get(vm.contractIDList, function(data){
            				angular.forEach(data, function(value, key){
	        	        		if (vm.contract[0].id == key) {
	        	        			vm.contract[0].contractGdsStatus = value;
	        	        		}
            				});
        	    		});
    		        }, function(error){
    		        	console.log("Error expire contract");
    		        	console.log(error);
    		        });
        		}, function(error){
		        	console.log("Error update status contract");
		        	console.log(error);
        		});
        	});
        }
        
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }

        vm.fixError = function() {
        	$uibModalInstance.dismiss('fix');
        }

        vm.checkGDSExists = function(gdsList, gds, type) {
        	var exist = false;

        	for (var i = 0; i < gdsList.length; i++) {
        		if (type == 'array') {
        			if (gdsList[i] == gds) {
            			exist = true;
            			break;
            		}
        		} else {
        			if (gdsList[i].gdsname == gds) {
            			exist = true;
            			break;
            		}
        		}

        	}
        	return exist;
        }

        $scope.$on('$destroy', function () {
        	if (angular.isDefined(publishInterval))
        		$interval.cancel(publishInterval);

	        if (angular.isDefined(validateInterval))
				$interval.cancel(validateInterval);
        });
    }
})();
