(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FieldPageDeleteController',FieldPageDeleteController);

    FieldPageDeleteController.$inject = ['$uibModalInstance', 'entity', 'FieldPage', '$stateParams'];

    function FieldPageDeleteController($uibModalInstance, entity, FieldPage, $stateParams) {
        var vm = this;
        vm.fieldPage = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.confirmDelete = function () {
        	vm.fieldPage.fields.splice($stateParams.idx, 1);
        	
            FieldPage.update(vm.fieldPage,
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
