(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('ContractController', ContractController);

    ContractController.$inject = ['$scope', '$state', 'Contract', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'ContractValue', 'ContractSearch', '$stateParams', 'SpecialContractSearch', 'Principal'];

    function ContractController ($scope, $state, Contract, ParseLinks, AlertService, pagingParams, paginationConstants, ContractValue, ContractSearch, $stateParams, SpecialContractSearch, Principal) {
        var vm = this;
        vm.size = 0;
        vm.itemsPerPage = 0;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.contractValue = ContractValue;
        vm.currentSearch = "";
        vm.filterType = "none";
        vm.isAdmin = false;
        vm.searchTypeOptionsAdmin = [
                                    {key:'rule_id', value:'Rule ID'},
                                    {key:'carrier_code', value:'Carrier Code'},
                                    {key:'account_code', value:'Account Code'},
                                    {key:'company', value:'Company / Organization'},
                                    {key:'contract_label', value:'Contract Label'},
                                    {key:'user_created', value:'User Created'},
                                    {key:'contract_type', value:'Contract Type'}
                                ];
        vm.searchTypeOptionsUser= [
                                    {key:'rule_id', value:'Rule ID'},
                                    {key:'carrier_code', value:'Carrier Code'},
                                    {key:'account_code', value:'Account Code'},
                                    {key:'company', value:'Company / Organization'},
                                    {key:'contract_label', value:'Contract Label'},
                                    {key:'user_created', value:'User Created'},
                                    {key:'contract_type', value:'Contract Type'}
                                ];

        vm.selectedContract = [];
        vm.selectedStatus = [];
        vm.selectedContractList = [];

        Principal.identity().then(function(account) {
            account.authorities.forEach(function(auth) {
            	if (auth == "ROLE_ADMIN")
            		vm.isAdmin = true;
            });
        });

        vm.emptySearchCheck = function(type) {
        	if (!vm.searchQuery) {
        		if (type == 'all')
        			vm.loadAll();
        		else if (type == 'published')
        			vm.filter();
        	}

        }

        vm.registerAllContracts = function(isChecked) {
        	if (isChecked) {
        		vm.selectedContract = [];

        		for (var i = 0; i < vm.contracts.length; i++) {
//        			if (vm.contracts[i].target_system == null || vm.contracts[i].target_system.length < 1) {
        				vm.selectedContract.push(vm.contracts[i]);
                		vm.selectedStatus[i] = true;
//        			} else
//        				vm.selectedStatus[i] = false;
            	}
        	} else {
        		vm.selectedContract = [];
        		for (var i = 0; i < vm.selectedStatus.length; i++) {
        			vm.selectedStatus[i] = false;
        		}
        	}
        }

        vm.registerContract = function(contract, index) {
        	if (vm.selectedStatus[index]) {
        		vm.selectedContract.push(contract);
        	} else {
        		vm.selectedContract.splice(vm.selectedContract.indexOf(contract), 1);
        	}

        	if (vm.selectedContract.length == vm.contracts.length) {
        		vm.master = true;
        	} else
        		vm.master = false;
        }

        vm.resetCheckbox = function() {
        	 vm.selectedContract = [];
             for (var i = 0; i < vm.selectedStatus.length; i++) {
            	 vm.selectedStatus[i] = false;
             }
             vm.master = false;
        }

        function loadAll () {
            Contract.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contracts = data;
                vm.page = pagingParams.page;
                vm.searchQuery = "";
                vm.currentSearch = "";
                vm.resetCheckbox();
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        vm.filter = function () {
        	ContractSearch.query({
        		page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                query: "filterBy",
                type: vm.filterType
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contracts = data;
                vm.page = pagingParams.page;
                vm.searchQuery = "";
                vm.currentSearch = "";
                vm.resetCheckbox();
            }
            function onError(error) {
            	vm.loadAll();
            }
        };

        vm.setCurrentSearch = function(search) {
        	vm.currentSearch = search;
        }

        vm.runSearch = function() {
        	if (vm.searchQuery != "") {
        		if (vm.filterType != "none")
            		vm.specialSearch();
            	else
            		vm.search();
        	}
        }

        vm.search = function () {
        	ContractSearch.query({
        		page: pagingParams.page - 1,
                size:  vm.itemsPerPage,
                sort: sort(),
                query: encodeURIComponent(vm.searchQuery),
                type: encodeURIComponent(vm.searchType)
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contracts = data;
                vm.page = pagingParams.page;
                vm.searchQuery = vm.currentSearch;
            }
            function onError(error) {
                vm.loadAll();
            }
        };

        vm.specialSearch = function () {
        	SpecialContractSearch.query({
        		page: pagingParams.page - 1,
                size:  vm.itemsPerPage,
                sort: sort(),
                query: encodeURIComponent(vm.searchQuery),
                searchBy: encodeURIComponent(vm.searchType),
                type: encodeURIComponent(vm.filterType)
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.contracts = data;
                vm.page = pagingParams.page;
                vm.searchQuery = vm.currentSearch;
            }
            function onError(error) {
                vm.loadAll();
            }
        };

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
        	pagingParams.page = vm.page;

        	if (vm.currentSearch != "")
        		if (vm.filterType != "none")
            		vm.specialSearch();
            	else
            		vm.search();
        	else {
        		if (vm.filterType != "none")
            		vm.filter();
            	else
            		vm.loadAll();
        	}
        }

        vm.getSelectedContractList = function() {
        	vm.selectedContractList = [];

        	vm.selectedContract.forEach(function(contract) {
        		vm.selectedContractList.push(contract.id);
        	});
        }

        vm.retrieveContracts = function(value) {
        	pagingParams.page = 1;
        	if (value != "none") {
        		if (value == "released")
        			vm.filterType = "released";
        		else if (value == "unreleased")
        			vm.filterType = "unreleased";

        		vm.filter();
        	} else {
        		vm.filterType = "none";
        		vm.loadAll();
        	}
        }

        vm.setItemsPerPage = function(value) {
        	vm.size = value;
        	vm.itemsPerPage = value;

        	if (vm.filterType != "none")
        		vm.filter();
        	else
        		vm.loadAll();
        }

        vm.checkContractStatus = function(contract) {
        	var status = false;

        	if (contract.target_system != null) {
        		if (contract.target_system.length < 1)
            		status = true;
        	} else
        		status = true;

        	return status;
        }

        vm.viewContract = function(id) {
        	var url = $state.href("contract-detail", {id_contract: id});
        	window.open(url, '_blank');
        }
    }
})();
