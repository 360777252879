(function() {
	'use strict';

	angular.module('americanoApp').factory('FormValue', FormValue);

	function FormValue() {

		var service = {
			getFormValue : getFormValue,
			getFormContractValue : getFormContractValue
		};

		return service;

		function getFormContractValue(contract, tag, page) {
			var data = contract.data;
			var result = null;

			if (data != null && data != undefined) {
				loop: for (var i = 0; i < data.length; i++) {

					var pages = data[i].pages;

					if (page != null && page != undefined) {
						var current_page = data[i].pages[page];

						if (current_page != null && current_page != undefined) {
							var current_forms = current_page.forms;

							result = getFormValue(current_forms, tag);

							if (result != null)
								break loop;
						}
					} else {
						if (pages != null && pages != undefined) {
							for (var j = 0; j < pages.length; j++) {
								var forms = pages[j].forms;

								result = getFormValue(forms, tag);

								if (result != null)
									break loop;
							}
						}
					}
				}
			}

			if (result == null || result == "")
				result = "N/A";

			return result;
		}

		function getFormValue(forms, tag) {
			var result = null;
			var found = false;

			if (forms != null && forms != undefined) {
				for (var i = 0; i < forms.length; i++) {
					var form_items = forms[i].form_items;

					for (var j = 0; j < form_items.length; j++) {
						var form_item = form_items[j];

						if (form_item.tag == tag) {
							if (form_item.type == "dropdown_auto") {
								return getDropdownAutoValue(form_item);
							}

							result = form_item.value;
							found = true;
						}

						if (!found && form_item.type == "multivalue") {
							result = getMultivalueValue(form_item.value, tag);

							if (result != null)
								found = true;
						}

						if (found)
							break;
					}

					if (found)
						break;
				}
			}

			return result;
		}

		function getDropdownAutoValue(form_item) {
			var value = form_item.value;
			var result = "";

			if (value != null && value != undefined && value != "") {
				// Add the type according to the object needed
				if (value.airline_code != undefined && value.airline_name != undefined) {
					result = value.airline_code + " - " + value.airline_name;
				} else if (value.code != undefined && value.name != undefined) {
					result = value.code + " - " + value.name;
				} else if (value.currency_code != undefined && value.currency != undefined) {
					result = value.currency_code + " - " + value.currency;
				} else if (value.code != undefined && value.transportingClass != undefined) {
					result = value.code + ' - ' + value.transportingClass;
				} else if (value.tariff_number != undefined && value.geo_definition != undefined) {
					result = value.tariff_number + ' - ' + value.geo_definition;
				} else if (value.tariff_number != undefined && value.description != undefined) {
					result = value.tariff_number + ' - ' + value.description;
				} else if (value.initialCode != undefined && value.fareType != undefined) {
					result = value.initialCode + ' - ' + value.fareType;
				} else if (value.name != undefined) {
					result = value.name;
				} else if (value.surchargeType != undefined) {
					result = value.surchargeType;
				} else if (value.description != undefined && value.travelSegmentIndicatorAPFName != undefined) {
					result = value.description + ' - ' + value.travelSegmentIndicatorAPFName;
				} else if (value.return_from_name != undefined) {
					result = value.return_from_name;
				} else if (value.returnFromName != undefined) {
					result = value.returnFromName;
				} else if (value.travel_segment_indicator != undefined) {
					result = value.travel_segment_indicator;
				}else if (value.travelSegmentIndicatorSabreID != undefined) {
					result = value.travelSegmentIndicatorSabreName;
				}else if (value.travelSegmentIndicatorAPFName != undefined) {
					result = value.travelSegmentIndicatorAPFName;
				}else if (value.passengerType != undefined) {
				    if(value.passengerType.code != undefined){
                        result = value.passengerType.code+ ' - ' + value.passengerType.name;
                    }

				}

				if (result == "")
					result = value;
			}

			return result;
		}

		function getMultivalueValue(multivalue, tag) {
			var result = null;
			var temp = "";

			if (multivalue != null) {
				for (var l = 0; l < multivalue.length; l++) {
					var forms = multivalue[l];

					for (var m = 0; m < forms.length; m++) {
						if (forms[m].tag == tag) {
							var value = forms[m].value;

							if (value != null && value != undefined
									&& value != "") {
								if (forms[m].type == "dropdown_auto") {
									temp = getDropdownAutoValue(forms[m]);
								} else
									temp = value;
							}
						}
					}

					if (temp != null && temp != undefined && temp != "") {
						if (result == null)
							result = "";

						if (l < multivalue.length - 1)
							result += temp + ", ";
						else
							result += temp;
					}
				}
			}

			return result;
		}
	}
})();
