(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormFareExceptionDialogController', FormFareExceptionDialogController);

    FormFareExceptionDialogController.$inject = [
        '$scope', '$parse', '$state', '$stateParams', '$uibModal', '$uibModalInstance',
        'ContractFareBasisCode', 'form1', 'form2', 'airlineMaster', 'passengerMaster', 'currencies', 'transporting_class', 'ContractFareException', 'ContractValue'];

    function FormFareExceptionDialogController (
    		$scope, $parse, $state, $stateParams, $uibModal, $uibModalInstance,
    		ContractFareBasisCode, form1, form2, airlineMaster, passengerMaster, currencies, transporting_class, ContractFareException, ContractValue) {
        var vm = this;
        vm.form1 = form1;
        vm.form2 = form2;
        vm.airlineMaster = airlineMaster;
        vm.passengerMaster = passengerMaster;
        vm.operation = $stateParams.operation;
        vm.index = $stateParams.index;
        vm.currencies = currencies;
        vm.transporting_class = transporting_class;

        if (vm.operation == 'EDIT' && vm.index != undefined) {
            vm.bookingCodeException = ContractFareException.getBookingCode({id: $stateParams.id_contract, index: vm.index, group:$stateParams.group});

            vm.bookingCodeException.$promise.then(function(){
                vm.form1.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.bookingCodeException.data[1].bookingCodeException[0].forms[0], vm.form1);
                });
                vm.form2.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.bookingCodeException.data[1].bookingCodeException[0].forms[1], vm.form2);
                });
            });
        }

        vm.copyBookingCodePrimary = function(x){
            var z = angular.copy(x);
            vm.form1.form_items[0].value.push(z);
        };
        //Booking Code Multivalue
        vm.pushBookingCode = function(x, y){

            y[0].value = vm.bookingCode.fare_booking_code_exception_primary_type_fare;
            y[1].value = vm.bookingCode.fare_booking_code_exception_primary_fare;
            y[2].value = vm.bookingCode.fare_booking_code_exception_primary_ticket_designator;
            y[3].value = vm.bookingCode.fare_booking_code_exception_primary_passenger_type;
            y[4].value = vm.bookingCode.fare_booking_code_exception_primary_booking_code;
            y[5].value = vm.bookingCode.fare_booking_code_exception_primary_transporting_class;
            y[6].value = vm.bookingCode.fare_booking_code_exception_primary_direction;
            y[7].value = vm.bookingCode.fare_booking_code_exception_primary_type_1;
            y[8].value = vm.bookingCode.fare_booking_code_exception_primary_location_1;
            y[9].value = vm.bookingCode.fare_booking_code_exception_primary_type_2;
            y[10].value = vm.bookingCode.fare_booking_code_exception_primary_location_2;
            y[11].value = vm.bookingCode.fare_booking_code_exception_primary_application;
            y[12].value = vm.bookingCode.fare_booking_code_exception_primary_flight_number;
            y[13].value = vm.bookingCode.fare_booking_code_exception_primary_through;
            y[14].value = vm.bookingCode.fare_booking_code_exception_primary_surcharge_currency;
            y[15].value = vm.bookingCode.fare_booking_code_exception_primary_surcharge_amount;

        	if(vm.form1.form_items[x].value == null){
        		vm.form1.form_items[x].value = [];
        	}
        	vm.form1.form_items[x].value.push(y);
        	var z = angular.copy(vm.form1.form_items[x].value);
        	vm.form1.form_items[x].value = z;
            vm.bookingCode = [];
        };

        vm.removeBookingCode = function(y){
        	vm.form1.form_items[0].value.splice(y, 1);
        	var z = angular.copy(vm.form1.form_items[0].value);
        	vm.form1.form_items[0].value = z;
        };

        vm.copyBookingCodeSecondary = function(x){
            var z = angular.copy(x);
            vm.form2.form_items[0].value.push(z);
            // var z = angular.copy(vm.form2.form_items[0].value);
            // vm.form2.form_items[0].value = z;
        };
        //Secondary Airline Booking Code
        vm.pushBookingCodeSecondary = function(x, y){

            y[0].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_type_fare;
            y[1].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_fare;
            y[2].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_ticket_designator;
            y[3].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_passenger_type;
            y[4].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_booking_code;
            y[5].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_transporting_class;
            y[6].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_direction;
            y[7].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_type_1;
            y[8].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_location_1;
            y[9].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_type_2;
            y[10].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_location_2;
            y[11].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_application;
            y[12].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_carrier_flight;
            y[13].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_flight_number;
            y[14].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_through;
            y[15].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_surcharge_currency;
            y[16].value = vm.bookingCodeSecondary.fare_booking_code_exception_secondary_surcharge_amount;


            if(vm.form2.form_items[x].value == null){
                vm.form2.form_items[x].value = [];
            }
            vm.form2.form_items[x].value.push(y);
            var z = angular.copy(vm.form2.form_items[x].value);
            vm.form2.form_items[x].value = z;
            vm.bookingCodeSecondary = [];
        };

        vm.removeBookingCodeSecondary = function(y){
            vm.form2.form_items[0].value.splice(y, 1);
            var z = angular.copy(vm.form2.form_items[0].value);
            vm.form2.form_items[0].value = z;
        };

        ContractFareBasisCode.get({id: $stateParams.id_contract}, function(success) {
 		   vm.fareBasisCodes = success.customStringListVariableType;
        });

        vm.save = function() {
        	var object = {
        		forms:[vm.form1, vm.form2],
        		contractId: $stateParams.id_contract,
        		index: vm.index
        	}

        	ContractFareException.createUpdateBookingCode(object, function(success) {
        		$uibModalInstance.close(success);
        	}, function(error) {});
        }

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.checkValue = function(value, options){
            if(value == null || value == "" || value == undefined){
                return options;
            } else {
                return value;
            }
        };

        vm.primaryBookingCodeExceptionTag = ["fare_booking_code_exception_primary_surcharge_currency", "fare_booking_code_exception_primary_surcharge_amount", "fare_booking_code_exception_primary_flight_number", "fare_booking_code_exception_primary_through"];
        vm.secondaryBookingCodeExceptionTag = ["fare_booking_code_exception_secondary_surcharge_amount", "fare_booking_code_exception_secondary_surcharge_currency", "fare_booking_code_exception_secondary_through", "fare_booking_code_exception_secondary_flight_number", "fare_booking_code_exception_secondary_carrier_flight"];
    }
})();
