(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('Notification', Notification);

    Notification.$inject = ['$resource'];

    function Notification ($resource) {
        var resourceUrl =  'api/notifications/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
    .module('americanoApp')
    .factory('NotificationCustom', NotificationCustom);

    NotificationCustom.$inject = ['$resource'];

	function NotificationCustom($resource) {
		var resourceUrl =  'api/notifications/getUnreadNotificationsCount';
	
	    return $resource(resourceUrl, {}, {
	    	'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        }
	    });
	} 
	
	angular
	.module('americanoApp')
    .factory('NotificationSearch', NotificationSearch);

	NotificationSearch.$inject = ['$resource'];
	
	function NotificationSearch($resource) {
		var resourceUrl = 'api/_search/notification/:query'; 
		return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true}
	    });
	}

    angular
    .module('americanoApp')
    .factory('NotificationUser', NotificationUser);

    NotificationUser.$inject = ['$resource'];
	
	function NotificationUser ($resource) {
	    var resourceUrl =  'api/notifications/user/:username/:filterBy/:statusBy';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
})();
