(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('VoluntaryChangeController', VoluntaryChangeController);

    VoluntaryChangeController.$inject = ['$scope', '$parse', '$state', '$stateParams', '$uibModal', '$uibModalInstance',
        'form1', 'form2', 'form3', 'form4', 'form5',
        'ContractValue', 'ContractVoluntaryChange', 'airlines', 'currencies', 'passenger_type', 'routing_tariff_sabre', 'fare_type'];

    function VoluntaryChangeController ($scope, $parse, $state, $stateParams, $uibModal, $uibModalInstance,
                                        form1, form2, form3, form4, form5,
                                        ContractValue, ContractVoluntaryChange, airlines, currencies, passenger_type, routing_tariff_sabre, fare_type) {
    	var vm = this;
        vm.airlines = airlines;
        vm.currencies = currencies;
        vm.passengers = passenger_type;
    	vm.contractValue = ContractValue;
        vm.routing_tariff_sabre = routing_tariff_sabre;
        vm.fare_type = fare_type;
    	vm.entity = ContractVoluntaryChange.get({ id : $stateParams.id_contract });
		vm.form1 = form1;
		vm.form2 = form2;
		vm.form3 = form3;
		vm.form4 = form4;
		vm.form5 = form5;

		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;

		vm.entity.$promise.then(function(contract) {
    		vm.voluntaryRules = contract.data[23].pages;
            vm.resetForm5 = angular.copy(vm.form5);
    		if (vm.index < vm.voluntaryRules.length)
    			vm.editVoluntary(vm.voluntaryRules[vm.index]);
    	});

		vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
	    	};

     	//Voluntary Change
		vm.addVoluntary = function(){
			vm.entity.data[23].pages.push({
				forms:[
					angular.copy(vm.form1),
					angular.copy(vm.form2),
					angular.copy(vm.form3),
                    angular.copy(vm.form4),
                    angular.copy(vm.form5)
				]
			});
		};

		vm.editVoluntary = function(x){
		    vm.form1.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            });
		    vm.form2.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[1], vm.form2);
            });
		    vm.form3.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[2], vm.form3);
            });
		    vm.form4.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[3], vm.form4);
            });
		    vm.form5.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[4], vm.form5);
            });

            // vm.form1 = angular.copy(x.forms[0]);
			// vm.form2 = angular.copy(x.forms[1]);
			// vm.form3 = angular.copy(x.forms[2]);
			// vm.form4 = angular.copy(x.forms[3]);
			// vm.form5 = angular.copy(x.forms[4]);

			vm.editObjectVoluntary = x;
			vm.isVoluntaryEdit = true;
		};

		vm.saveVoluntaryEdit = function(){
			vm.entity.data[23].pages[vm.entity.data[23].pages.indexOf(vm.editObjectVoluntary)].forms[0] = angular.copy(vm.form1);
			vm.entity.data[23].pages[vm.entity.data[23].pages.indexOf(vm.editObjectVoluntary)].forms[1] = angular.copy(vm.form2);
			vm.entity.data[23].pages[vm.entity.data[23].pages.indexOf(vm.editObjectVoluntary)].forms[2] = angular.copy(vm.form3);
			vm.entity.data[23].pages[vm.entity.data[23].pages.indexOf(vm.editObjectVoluntary)].forms[3] = angular.copy(vm.form4);
			vm.entity.data[23].pages[vm.entity.data[23].pages.indexOf(vm.editObjectVoluntary)].forms[4] = angular.copy(vm.form5);

			vm.editObjectVoluntary = null;
			vm.isVoluntaryEdit = false;

		};

		//END Voluntary Change

		vm.save = function(){
            var forms = [vm.form1, vm.form2, vm.form3, vm.form4, vm.form5];

            if (vm.isVoluntaryEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractVoluntaryChange.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractVoluntaryChange.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isVoluntaryEdit)
				// vm.saveVoluntaryEdit();
            // else
				// vm.addVoluntary();
            //
            // ContractVoluntaryChange.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        // Multivalue Voluntary Changed
        vm.pushDataVoluntaryChange = function(x, y){
            y[0].value = vm.dataChangeCondition.rules_voluntary_changes_not_permitted_for;
            y[1].value = vm.dataChangeCondition.rules_voluntary_changes_are_permitted_journey;
            y[2].value = vm.dataChangeCondition.rules_voluntary_changes_are_permitted_pricing_unit;
            y[3].value = vm.dataChangeCondition.rules_voluntary_changes_are_permitted_fares_component;
            y[4].value = vm.dataChangeCondition.rules_voluntary_changes_period;
            y[5].value = vm.dataChangeCondition.rules_voluntary_changes_unit;
            y[6].value = vm.dataChangeCondition.rules_voluntary_changes_sequence;
            y[7].value = vm.dataChangeCondition.rules_voluntary_changes_day_of_week;
            y[8].value = vm.dataChangeCondition.rules_voluntary_changes_measured_from;
            y[9].value = vm.dataChangeCondition.rules_voluntary_changes_prior_to;
            y[10].value = vm.dataChangeCondition.rules_voluntary_changes_changes_must_with_ticket_validity;
            y[11].value = vm.dataChangeCondition.rules_voluntary_changes_domestic_can_replaced_by_international;
            y[12].value = vm.dataChangeCondition.rules_voluntary_changes_carrier;
            y[13].value = vm.dataChangeCondition.rules_voluntary_changes_not_permitted;

            if(vm.form3.form_items[x].value == null){
                vm.form3.form_items[x].value = [];
            }
            vm.form3.form_items[x].value.push(y);
            var z = angular.copy(vm.form3.form_items[x].value);
            vm.form3.form_items[x].value = z;

            vm.dataChangeCondition = [];
        };

        vm.removeDataVoluntaryChange = function(x, y){
            vm.form3.form_items[x].value.splice(y, 1);
            var z = angular.copy(vm.form3.form_items[x].value);
            vm.form3.form_items[x].value = z;
        };

        vm.copyDataVoluntaryChange = function(x, y){
        	vm.data = angular.copy(vm.form3.form_items[x].value[y]);
        	vm.form3.form_items[x].value.push(vm.data);

        	var newItemIndex = vm.form3.form_items[x].value.length - 1;

        	if (vm.isEditEmbeddedSurfaceSectors == undefined || vm.isEditEmbeddedSurfaceSectors == null) {
        		vm.isEditEmbeddedSurfaceSectors = {}
        	}

        	vm.isEditEmbeddedSurfaceSectors[newItemIndex] = true;
        };
        // End Multivalue Voluntary Change

        // Multivalue Travel Limitation
        vm.pushDataVoluntaryTravelLimit = function(x, x1, y){
            y[0].value = vm.dataTravelLimitation.rules_voluntary_changes_reissue_list_cities_considered_same_points_location_1;
            y[1].value = vm.dataTravelLimitation.rules_voluntary_changes_reissue_list_cities_considered_same_points_location_2;

            if(vm.form5.form_items[x].form_items_child[x1].value == null){
                vm.form5.form_items[x].form_items_child[x1].value = [];
            }
            vm.form5.form_items[x].form_items_child[x1].value.push(y);
            var z = angular.copy(vm.form5.form_items[x].form_items_child[x1].value);
            vm.form5.form_items[x].form_items_child[x1].value = z;

            vm.dataTravelLimitation = [];
        };

        vm.removeDataVoluntaryTravelLimit = function(x, x1, y){
            vm.form5.form_items[x].form_items_child[x1].value.splice(y, 1);
            var z = angular.copy(vm.form5.form_items[x].form_items_child[x1].value);
            vm.form5.form_items[x].form_items_child[x1].value = z;
        };

        vm.copyDataVoluntaryTravelLimit = function(x, x1, y){
        	vm.data = angular.copy(vm.form5.form_items[x].form_items_child[x1].value[y]);
        	vm.form5.form_items[x].form_items_child[x1].value.push(vm.data);

        	var newItemIndex = vm.form5.form_items[x].form_items_child[x1].value.length - 1;

        	if (vm.isEditTravelLimitationMultivalue == undefined || vm.isEditTravelLimitationMultivalue == null) {
        		vm.isEditTravelLimitationMultivalue = {}
        	}

        	vm.isEditTravelLimitationMultivalue[newItemIndex] = true;
        };
        // End Multivalue Travel Limitation

        // Multivalue Condition Change Repricing
        vm.pushDataChangeFareRepricing = function(x, x1, y){
            y[0].value = vm.dataConditionFareRepricing.rules_voluntary_changes_reissue_list_tariff_number_sabre;
            y[1].value = vm.dataConditionFareRepricing.rules_voluntary_changes_reissue_list_specify_carrier;

            if(vm.form5.form_items[x].form_items_child[x1].value == null){
                vm.form5.form_items[x].form_items_child[x1].value = [];
            }
            vm.form5.form_items[x].form_items_child[x1].value.push(y);
            var z = angular.copy(vm.form5.form_items[x].form_items_child[x1].value);
            vm.form5.form_items[x].form_items_child[x1].value = z;

            vm.dataConditionFareRepricing = [];
        };

        vm.removeDataConditionFareRepricing = function(x, x1, y){
            vm.form5.form_items[x].form_items_child[x1].value.splice(y, 1);
            var z = angular.copy(vm.form5.form_items[x].form_items_child[x1].value);
            vm.form5.form_items[x].form_items_child[x1].value = z;
        };

        vm.copyDataConditionFareRepricing = function(x, x1, y){
        	vm.data = angular.copy(vm.form5.form_items[x].form_items_child[x1].value[y]);
        	vm.form5.form_items[x].form_items_child[x1].value.push(vm.data);

        	var newItemIndex = vm.form5.form_items[x].form_items_child[x1].value.length - 1;

        	if (vm.isEditChangeFareRepricing == undefined || vm.isEditChangeFareRepricing == null) {
        		vm.isEditChangeFareRepricing = {}
        	}

        	vm.isEditChangeFareRepricing[newItemIndex] = true;
        };
        // End Multivalue Condition Change Repricing

        // Multivalue Change Fare Type
        vm.pushDataChangeFareType = function(x, x1, y){
            y[0].value = vm.dataConditionFareType.rules_voluntary_changes_reissue_list_fare_type;
            y[1].value = vm.dataConditionFareType.rules_voluntary_changes_reissue_list_fare_type_note_permitted;

            if(vm.form5.form_items[x].form_items_child[x1].value == null){
                vm.form5.form_items[x].form_items_child[x1].value = [];
            }
            vm.form5.form_items[x].form_items_child[x1].value.push(y);
            var z = angular.copy(vm.form5.form_items[x].form_items_child[x1].value);
            vm.form5.form_items[x].form_items_child[x1].value = z;

            vm.dataConditionFareType = [];
        };

        vm.removeDataConditionFareType = function(x, x1, y){
            vm.form5.form_items[x].form_items_child[x1].value.splice(y, 1);
            var z = angular.copy(vm.form5.form_items[x].form_items_child[x1].value);
            vm.form5.form_items[x].form_items_child[x1].value = z;
        };

        vm.copyDataConditionFareType = function(x, x1, y){
        	vm.data = angular.copy(vm.form5.form_items[x].form_items_child[x1].value[y]);
        	vm.form5.form_items[x].form_items_child[x1].value.push(vm.data);

        	var newItemIndex = vm.form5.form_items[x].form_items_child[x1].value.length - 1;

        	if (vm.isEditChangeFareType == undefined || vm.isEditChangeFareType == null) {
        		vm.isEditChangeFareType = {}
        	}

        	vm.isEditChangeFareType[newItemIndex] = true;
        };
        // End Multivalue Change Fare Type

        // Multivalue Condition Season
        vm.pushDataConditionSeason = function(x, x1,  y){
            y[0].value = vm.dataConditionSeason.rules_voluntary_changes_condition_fares_repricing_seasonality_indicator;
            y[1].value = vm.dataConditionSeason.rules_voluntary_changes_condition_fares_repricing_specification;

            if(vm.form5.form_items[x].form_items_child[x1].value == null){
                vm.form5.form_items[x].form_items_child[x1].value = [];
            }
            vm.form5.form_items[x].form_items_child[x1].value.push(y);
            var z = angular.copy(vm.form5.form_items[x].form_items_child[x1].value);
            vm.form5.form_items[x].form_items_child[x1].value = z;

            vm.dataConditionSeason = [];
        };

        vm.removeDataConditionSeason = function(x, x1, y){
            vm.form5.form_items[x].form_items_child[x1].value.splice(y, 1);
            var z = angular.copy(vm.form5.form_items[x].form_items_child[x1].value);
            vm.form5.form_items[x].form_items_child[x1].value = z;
        };

        vm.copyDataConditionSeason = function(x, x1, y){
        	vm.data = angular.copy(vm.form5.form_items[x].form_items_child[x1].value[y]);
        	vm.form5.form_items[x].form_items_child[x1].value.push(vm.data);

        	var newItemIndex = vm.form5.form_items[x].form_items_child[x1].value.length - 1;

        	if (vm.isEditConditionSeason == undefined || vm.isEditConditionSeason == null) {
        		vm.isEditConditionSeason = {}
        	}

        	vm.isEditConditionSeason[newItemIndex] = true;
        };
        // End Multivalue Condition Season

        // Multivalue Ticketing Instruction
        vm.pushDataTicketInstruction = function(x, x1, y){
            y[0].value = vm.dataTicketingInstruction.rules_voluntary_changes_ticketing_instruction_carrier;
            y[1].value = vm.dataTicketingInstruction.rules_voluntary_changes_ticketing_instruction_not_permitted;

            if(vm.form5.form_items[x].form_items_child[x1].value == null){
                vm.form5.form_items[x].form_items_child[x1].value = [];
            }
            vm.form5.form_items[x].form_items_child[x1].value.push(y);
            var z = angular.copy(vm.form5.form_items[x].form_items_child[x1].value);
            vm.form5.form_items[x].form_items_child[x1].value = z;

            vm.dataTicketingInstruction = [];
        };

        vm.removeDataTicketingInstruction = function(x, x1, y){
            vm.form5.form_items[x].form_items_child[x1].value.splice(y, 1);
            var z = angular.copy(vm.form5.form_items[x].form_items_child[x1].value);
            vm.form5.form_items[x].form_items_child[x1].value = z;
        };

        vm.copyDataTicketingInstruction = function(x, x1, y){
        	vm.data = angular.copy(vm.form5.form_items[x].form_items_child[x1].value[y]);
        	vm.form5.form_items[x].form_items_child[x1].value.push(vm.data);

        	var newItemIndex = vm.form5.form_items[x].form_items_child[x1].value.length - 1;

        	if (vm.isEditTicketingInstruction == undefined || vm.isEditTicketingInstruction == null) {
        		vm.isEditTicketingInstruction = {}
        	}

        	vm.isEditTicketingInstruction[newItemIndex] = true;
        };
        // End Multivalue Ticketing Instruction

        /* Function save reissue list tab  2 */
        vm.saveReissueListTab2 = function (y) {
            if(vm.reissueListCurrentEdit != null){
                vm.form5.form_items[0].value[vm.reissueListCurrentEdit] =  y;
            }else {
                if (vm.form5.form_items[0].value == null) {
                    vm.form5.form_items[0].value = [];
                }

                vm.form5.form_items[0].value.push(y);
                var z = angular.copy(vm.form5.form_items[0].value);
                vm.form5.form_items[0].value = z;
            }
            vm.reissueListCurrentEdit = null;
        };

        vm.editReissueListTab2 = function(i){
            vm.reissueListCurrentEdit = i;
            vm.form5.form_items[0].form_items_child = vm.form5.form_items[0].value[i];
        }

        vm.createReissueListTab2 = function(){
            vm.reissueListCurrentEdit = null;
            vm.form5.form_items[0].form_items_child = angular.copy(vm.resetForm5.form_items[0].form_items_child);
        };

        vm.removeReissueListTab2 = function(index){
            vm.form5.form_items[0].value.splice(index, 1);
        }
        /* End Function save reissue list tab  2 */

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.checkValueApplyTo = function(value) {
        	if (!vm.isVoluntaryEdit) {
        		if (value == null || value == "" || value == undefined)
                    return "Outbound and Inbound";
        	}

            return value;
        }

        vm.tagTab1 = ["rules_voluntary_changes_apply_to"];
        vm.tagTabTextOnly = ["rules_voluntary_changes_text_information_only", "rules_voluntary_changes_note_apf", "rules_voluntary_changes_note_amadeus", "rules_voluntary_changes_note_sabre", "rules_voluntary_changes_note", "rules_voluntary_changes_override"];
    }
})();
