(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('VendorDialogController', VendorDialogController);

    VendorDialogController.$inject = [
    '$scope', '$parse', '$state', '$stateParams',
    'entity', 'form1', '$filter',
    'Vendor', '$uibModalInstance'];

    function VendorDialogController (
    		$scope, $parse, $state, $stateParams,
    		entity, form1, 
    		$filter,
    		Vendor, $uibModalInstance) {
    	
    	var vm = this;
    	vm.vendor = entity;
		vm.form1 = form1;
		vm.currentStep = 1;
		
		if(vm.vendor.$promise!=null){
	        vm.vendor.$promise.then(function(result){
	        	vm.bindForm();
			});
		}
		
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.bindForm = function(){
        	vm.form1.$promise.then(function(form){
        		form1.form_items[0].value = vm.vendor.option;
        			
        		if(vm.vendor.option == "Group PCC") {
        			var group = [];
        			vm.vendor.pcc.forEach(function(member) {
        				group.push({"group_pcc":member});
        			});
        			vm.vendor.pcc = group;
        		} 
        			
        		form1.form_items[1].value = vm.vendor.pcc;
        		form1.form_items[2].value = vm.vendor.name;
        		form1.form_items[3].value = vm.vendor.description;
        		form1.form_items[4].value = vm.vendor.gds;
        	});
        };
        
        vm.bindValue = function(){
        	vm.vendor.pcc = [];
        	vm.vendor.option = vm.form1.form_items[0].value;
        	
        	if (vm.form1.form_items[0].value == "PCC"){
        		if (typeof vm.form1.form_items[1].value === 'string' ) {
        			var temp = [];
        			temp.push(vm.form1.form_items[1].value);
        			vm.form1.form_items[1].value = temp;
        		}
	        	
        		vm.vendor.pcc = vm.form1.form_items[1].value;
        	}else {
        		vm.form1.form_items[1].value.forEach(function(pcc) {
            		vm.vendor.pcc.push(pcc.group_pcc);
            	});
        	}
        	
        	vm.vendor.name = vm.form1.form_items[2].value;
        	vm.vendor.description = vm.form1.form_items[3].value;
        	vm.vendor.gds = vm.form1.form_items[4].value;
        };
        
        vm.save = function () {
            vm.isSaving = true;
            vm.bindValue();
            
            if (vm.vendor.id != null) {
            	Vendor.update(vm.vendor, function(result){
    				$uibModalInstance.close(result);
    	    		console.log("Save sukses");
    	    	}, function(error){
    	    		console.log("Save error ");
    	    		console.log(error);
    	    	});
            } else {
            	Vendor.save(vm.vendor, function(result){
    				$uibModalInstance.close(result);
    	    		console.log("Save sukses");
    	    	}, function(error){
    	    		console.log("Save error ");
    	    		console.log(error);
    	    	});           	
            }
        };
        
        vm.pushGroupPCC = function(x, y){
        	if(vm.form1.form_items[x].value == null){
        		vm.form1.form_items[x].value = [];
        	}
        	vm.form1.form_items[x].value.push(y);
        	var z = angular.copy(vm.form1.form_items[x].value);
        	vm.form1.form_items[x].value = z;        	
//        	console.log(x);
//        	console.log(vm.form1.form_items[x].value);
        };
        
        vm.removeGroupPCC = function(x, y){
        	vm.form1.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form1.form_items[x].value);
        	vm.form1.form_items[x].value = z;
        }
        
        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };
    }
})();

//
//vm.filter = function(input, tag) {
//    var k=0, form=input.length;
//    for(;k<form;k++){
//	    var i = 0, len = input[k].form_items.length;
//    	for (; i<len; i++) {
//    		if(input[k].form_items[i].tag == tag){
//	    		return input[k].form_items[i].value;
//	    	}
//	    }
//    }
//    return null;
// };
//  			  
//vm.bindForm = function(forms, object){
//	  var form = forms.length;
//	  for(var k=0;k<form;k++){
//		  for(var l=0;l<forms[k].form_items.length;l++){
//			  var tag = forms[k].form_items[l].tag;
//			  forms[k].form_items[l].value = $scope.$eval(object+"."+tag);
//		  }
//	  }
//  };
//  
//  if(vm.entity.$promise!=null){
//        vm.entity.$promise.then(function(result){
//        	vm.form1.$promise.then(function(result){
//	        	var forms = [vm.form1];
//	        	vm.bindForm(forms, 'vm.entity');
//        	});
//		});
//	}
//  
//var onSaveSuccess = function (result) {
//    $scope.$emit('americanoApp:vendorUpdate', result);
//    vm.isSaving = false;
//    $state.go('contract.vendor', {id_contract:$stateParams.id_contract}, { reload: true });
//};
//
//var onSaveError = function () {
//    vm.isSaving = false;
//    
//};
//
//vm.save = function(){
//	for(var key in vm.entity){
//		var exclude = [
//		  "id",
//		  "contract"
//		];
//
//		if(exclude.indexOf(key) == -1){
//			var forms = [vm.form1];
//			var found = vm.filter(forms, key);
//			var ref = 'entity.'+key;
//		    var getter = $parse(ref);
//			getter.assign(vm, found);
//		}
//	}
//	
//	if(vm.entity.id == null){
////		console.log(vm.entity);
//		Vendor.save(vm.entity, onSaveSuccess, onSaveError);
////		Fare.save(vm.entity, function(result){
////			$state.go('contract.distribution', {id_contract:$stateParams.id_contract}, { reload: true });
////    		console.log("Save sukses");
////    	}, function(error){
////    		console.log(error);
////    	});
//	}else{
//		Vendor.update(vm.entity, onSaveSuccess, onSaveError);
////		Fare.update(vm.entity, function(result){
////			$state.go('contract.distribution', {id_contract:$stateParams.id_contract}, { reload: true });
////    	}, function(error){
////    		console.log(error);
////    	});
//	}
//};