(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('GdsTemplate', GdsTemplate);

    GdsTemplate.$inject = ['$resource', 'DateUtils'];

    function GdsTemplate ($resource, DateUtils) {
        var resourceUrl =  'api/gds-templates/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    data.templateCreatedDate = DateUtils.convertLocalDateFromServer(data.templateCreatedDate);
                    data.templateModifiedDate = DateUtils.convertLocalDateFromServer(data.templateModifiedDate);
                    return data;
                }
            },
            'update': {
                method: 'PUT',
                transformRequest: function (data) {
                    data.templateCreatedDate = DateUtils.convertLocalDateToServer(data.templateCreatedDate);
                    data.templateModifiedDate = DateUtils.convertLocalDateToServer(data.templateModifiedDate);
                    return angular.toJson(data);
                }
            },
            'save': {
                method: 'POST',
                transformRequest: function (data) {
                    data.templateCreatedDate = DateUtils.convertLocalDateToServer(data.templateCreatedDate);
                    data.templateModifiedDate = DateUtils.convertLocalDateToServer(data.templateModifiedDate);
                    return angular.toJson(data);
                }
            }
        });
    }
    
    angular
	    .module('americanoApp')
	    .factory('GdsTemplateCustom', GdsTemplateCustom);
	
    GdsTemplateCustom.$inject = ['$resource'];
	
	function GdsTemplateCustom($resource) {
		var resourceUrl =  'api/gds-templates/getGdsTemplates';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
})();
