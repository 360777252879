(function() {
    'use strict';

    angular
    .module('americanoApp')
    .factory('ContractDistribution', ContractDistribution);

	ContractDistribution.$inject = ['$resource'];

	function ContractDistribution ($resource) {
	    var resourceUrl =  'api/contracts/distribution/:id/:index';

	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' },
            'copy': { method:'POST', url:"api/contracts/distribution/copy/:id/:index"},
	    });
	}
})();

