(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormDistributionController', FormDistributionController);

    FormDistributionController.$inject = ['$scope', '$state', '$filter', '$rootScope', '$stateParams', 'ParseLinks', 'pagingParams',
                                          'paginationConstants', 'AlertService', 'ContractDistributionException', 'ContractValue', '$uibModal', 'ContractDistribution'];

    function FormDistributionController ($scope, $state, $filter, $rootScope, $stateParams, ParseLinks, pagingParams,
    		paginationConstants, AlertService, ContractDistributionException, ContractValue, $uibModal, ContractDistribution) {
    	var vm = this;
        vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;
        vm.contractValue = ContractValue;
        vm.id_contract = $stateParams.id_contract;
        vm.loadAll = loadAll;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.lastIndex = 0;
        vm.loadAll();

        // Initialize Rule Exception Variables
        var exceptionModalInstance = null, errorModalInstance = null;
        vm.selectedDistributions = [];
        vm.selectedStatus = [];
        vm.selectedRow = [];

        function loadAll () {
            ContractDistribution.query({
            	id:$stateParams.id_contract,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.total = vm.queryCount;

                console.log(data);
                vm.distributions = data;
                vm.page = pagingParams.page;
                vm.lastIndex = vm.total - 1;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition () {
        	pagingParams.page = vm.page;

            $state.transitionTo($state.$current, {
         	   	id_contract: $stateParams.id_contract,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

       vm.back = function(){
    	   if($rootScope.isCalculated) {
               $("#tab3calculated").trigger("click");
           }
           else {
        	   $( "#tab4" ).trigger( "click" );
           }
    	   
       };

       // Rule Exception
       vm.registerAllRules = function(isChecked) {
	       	if (isChecked) {
	       		vm.selectedRow = [];
	       		vm.selectedDistributions = [];
	       		for (var i = 0; i < vm.distributions.length; i++) {
	           		vm.selectedDistributions.push(vm.distributions[i]);
	           		vm.selectedStatus[vm.distributions[i].id] = true;
	           		vm.selectedRow.push(vm.lastIndex - i);
	           	}

	       	} else {
	       		vm.selectedRow = [];
	       		vm.selectedDistributions = [];
	       		for (var i = 0; i < vm.selectedStatus.length; i++) {
	       			vm.selectedStatus[i] = false;
	       		}
	       	}
       }

        vm.copyDistribution = function(originalIndex) {
            $state.go('contract.distribution.copy', {
                index: originalIndex,
                id_contract : $stateParams.id_contract
            }, { reload : true });
        }

		vm.rowHighlighted = function(row, distribution) {
			var selectedExist = false;
			var index = distribution.id;

			for (var i = 0; i < vm.selectedRow.length; i++) {
				if (vm.selectedRow[i] == row) {
					vm.selectedRow.splice(i, 1);
					selectedExist = true;
					break;
				}
			}

			if (selectedExist) {
				vm.selectedStatus[index] = false;
			} else {
				vm.selectedStatus[index] = true;
				vm.selectedRow.push(row);
			}

			vm.registerDistribution(distribution, index);
	 	}

		vm.registerDistribution = function(distribution, index) {
	       	if (vm.selectedStatus[index]) {
	       		vm.selectedDistributions.push(distribution);
	           	vm.selectedStatus[index] = true;
	       	} else {
	       		vm.selectedDistributions.splice(vm.selectedDistributions.indexOf(distribution), 1);
	       		vm.selectedStatus[index] = false;
	       	}

	       	if (vm.selectedDistributions.length == vm.distributions.length)
	       		vm.master = true;
	       	else
	       		vm.master = false;
       }

	 	vm.getSelectedRowStatus = function(row) {
	 		var status = false;

	 		if (vm.master) {
	 			status = true;
	 		} else {
				for (var i = 0; i < vm.selectedRow.length; i++) {
		 			if (vm.selectedRow[i] == row) {
		 				status = true;
		 				break;
		 			}
		 		}
	 		}

	 		return status;
	 	}

       vm.resetCheckbox = function() {
	       	vm.selectedDistributions = [];
	       	vm.selectedRow = [];
	    	for (var i = 0; i < vm.selectedStatus.length; i++) {
	    		vm.selectedStatus[i] = false;
	    	}
	    	vm.master = false;
       }

       vm.viewException = function(event, distribution, index) {
    	   event.stopPropagation();
	       vm.resetCheckbox();
    	   vm.selectedDistributions.push(distribution);
       	   vm.runException();
       }

       vm.runException = function() {
       	if (!vm.checkExceptionSelection())
       		vm.openExceptionModal();
       	else
       		vm.openErrorModal();
       }

       vm.checkExceptionSelection = function() {
	       	var currentException = null;
	       	var isDifferentException = false;

	       	for (var x = 0; x < vm.selectedDistributions.length; x++) {
	       		if (vm.selectedDistributions[x].exception != undefined && vm.selectedDistributions[x].exception != null) {
	       			currentException = vm.selectedDistributions[x].exception;
	       			break;
	       		}
	       	}

	       	for (var i = 0; i < vm.selectedDistributions.length; i++) {
	       		var exception = vm.selectedDistributions[i].exception;

	       		if (exception != null && exception != undefined) {
	           		if (JSON.stringify(currentException) != JSON.stringify(exception)){
	           			isDifferentException = true;
	           			break;
	           		}
	       		}
	       	}

	       	return isDifferentException;
       }

       vm.resetExceptionModal = function () {
       	exceptionModalInstance = null;
       };

       vm.openExceptionModal = function() {
       	if (exceptionModalInstance !== null) return;

       	exceptionModalInstance = $uibModal.open({
       		data: {
       			authorities: ["ROLE_USER"]
       		},
       		animation: true,
       		templateUrl: 'app/entities/contract/distribution/exception/distribution-exception.html',
       		controller: 'DistributionExceptionController',
       		controllerAs: 'vm',
       		scope: $scope.$new(),
       		backdrop: 'static',
       		size: 'lg',
       		resolve: {
       			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
       				$translatePartialLoader.addPart('global');
       				return $translate.refresh();
       			}]
       		}
       	});

       	exceptionModalInstance.result.then(vm.resetExceptionModal, vm.resetExceptionModal);
       }

       vm.resetErrorModal = function () {
       	errorModalInstance = null;
       };

       vm.openErrorModal = function() {
       	if (errorModalInstance !== null) return;

       	errorModalInstance = $uibModal.open({
       		data: {
       			authorities: ["ROLE_USER"]
       		},
       		animation: true,
       		templateUrl: 'app/entities/contract/distribution/exception/distribution-exception-error.html',
       		controller: 'DistributionExceptionErrorController',
       		controllerAs: 'vm',
       		scope: $scope.$new(),
       		backdrop: 'static',
       		size: 'lg',
       		resolve: {
       			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
       				$translatePartialLoader.addPart('global');
       				return $translate.refresh();
       			}]
       		}
       	});

       	errorModalInstance.result.then(vm.resetErrorModal, vm.resetErrorModal);
       }

       vm.saveException = function(exception) {
	       	var object = {
	       			exception: exception,
	       			contractId: vm.id_contract,
	       			index: []
	        }

	       	for (var i = 0; i < vm.selectedDistributions.length; i++) {
	       		object.index.push(vm.selectedDistributions[i].id);
	       	}

	       	console.log(object);

	       	ContractDistributionException.updateException(object, function(result){
	       		$state.go($state.current, {id_contract:vm.id_contract}, { reload:true });
           }, function(error){});
       }
    }
})();
