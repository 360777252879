(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('Team', Team);

    Team.$inject = ['$resource'];

    function Team ($resource) {
        var resourceUrl =  'api/teams/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
		.module('americanoApp')
	    .factory('TeamSearch', TeamSearch);
	
    TeamSearch.$inject = ['$resource'];
	
	function TeamSearch($resource) {
		var resourceUrl = 'api/_search/teams/:query'; 
		return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true}
	    });
	}
})();
