(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('RuleApplicationController', RuleApplicationController);

    RuleApplicationController.$inject = [
        '$scope', '$parse', '$state', '$stateParams', '$uibModal',
        'ContractRuleApplicationOnly', 'form1', '$uibModalInstance', 'ContractValue'];

    function RuleApplicationController (
        $scope, $parse, $state, $stateParams, $uibModal,
        ContractRuleApplicationOnly, form1, $uibModalInstance, ContractValue) {

        var vm = this;
        vm.entity = ContractRuleApplicationOnly.get({ id : $stateParams.id_contract });
        vm.form1 = form1;
        vm.id_contract = $stateParams.id_contract;
        vm.index = $stateParams.index;

        vm.entity.$promise.then(function(contract) {
            vm.ruleAppRules = contract.data[4].pages;

            if (vm.index < vm.ruleAppRules.length)
                vm.editRuleApplication(vm.ruleAppRules[vm.index]);
        });

        //RULES APPLICATION
        vm.addRuleApplication = function(){
            vm.entity.data[4].pages.push({
                forms:[
                    angular.copy(vm.form1)
                ]
            });
        }

        vm.editRuleApplication = function(x){
            vm.form1.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            });

            vm.editObjectRuleApplication= x;
            vm.isRuleApplicationEdit = true;
        };

        vm.saveFlightApplicationEdit = function(){
            vm.entity.data[4].pages[vm.entity.data[4].pages.indexOf(vm.editObjectRuleApplication)].forms[0] = angular.copy(vm.form1);

            vm.editObjectRuleApplication= null;
            vm.isRuleApplicationEdit = false;
        };
        //END RULES APPLICATION

        vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
    	};

		vm.save = function(){
            var forms = [vm.form1];

            if (vm.isRuleApplicationEdit){
//                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractRuleApplicationOnly.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
//                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractRuleApplicationOnly.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };
    }
})();
