(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TourAgentsOtherDiscountCopyDialogController', TourAgentsOtherDiscountCopyDialogController);

    TourAgentsOtherDiscountCopyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'ContractTourAgentsOtherDiscount'];
    
    function TourAgentsOtherDiscountCopyDialogController ($scope, $stateParams, $uibModalInstance, ContractTourAgentsOtherDiscount) {
    	var vm = this;
    	vm.id = $stateParams.id;
    	vm.index = $stateParams.index;

    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };

        vm.confirmCopy = function (id, index, lastIndex) {
        	ContractTourAgentsOtherDiscount.copy({id:$stateParams.id_contract, index:index}, function(data){
                $uibModalInstance.close(data.id);
            });
	    };
    }
})();
