angular.module('americanoApp')
	.directive('checkboxGroup', function() {
  return {
    restrict: 'E',
    controller: function($scope, $attrs, $element) {
      var self = this;
      var ngModels = [];
      var minRequired;
      
      Object.byString = function(o, s) {
    	    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    	    s = s.replace(/^\./, '');           // strip a leading dot
    	    var a = s.split('.');
    	    for (var i = 0, n = a.length; i < n; ++i) {
    	        var k = a[i];
    	        
    	        if(o != undefined){
	    	        if (k in o) {
	    	            o = o[k];
	    	        } else {
	    	            return;
	    	        }
    	        }
    	    }
    	    return o;
    	}
      
      self.validate = function() {
        var checkedCount = 0;
        angular.forEach(ngModels, function(ngModel) {
          if ( ngModel.$modelValue ) {
            checkedCount++;
          }
        });
//        console.log('minRequired', minRequired);
//        console.log('checkedCount', checkedCount);
        var minRequiredValidity = checkedCount >= minRequired;
        
        var isSet = false;
        angular.forEach(ngModels, function(ngModel) {
        	if(!isSet){
        		var form = $element.parent().controller('form');
        		var d = Object.byString(form, $attrs.name);
        		
        		if(d!=null){
        			d.$setValidity('checkboxGroupMinRequired', minRequiredValidity, self); 
        		}
//        		ngModel.$setValidity('checkboxGroupMinRequired', minRequiredValidity, self);  
        		isSet = true;
        	}
        });
      };
      
      self.register = function(ngModel) {
        ngModels.push(ngModel);
      };
      
      self.deregister = function(ngModel) {
        var index = this.ngModels.indexOf(ngModel);
        if ( index != -1 ) {
          this.ngModels.splice(index, 1);
        }
      };
        
      $scope.$watch($attrs.minRequired, function(value) {
        minRequired = parseInt(value, 10);
        self.validate();
      });
    }
  };
});

angular.module('americanoApp').directive('input', function() {
  return {
    restrict: 'E',
    require: ['?^checkboxGroup','?ngModel'],
    link: function(scope, element, attrs, controllers) {
      var checkboxGroup = controllers[0];
      var ngModel = controllers[1];
      if ( attrs.type=='checkbox' && checkboxGroup && ngModel ) {
//    	  console.log("register checkbox");
        checkboxGroup.register(ngModel);
        scope.$watch(function() { return ngModel.$modelValue; }, checkboxGroup.validate );
        // In case we are adding and removing checkboxes dynamically we need to tidy up after outselves.
//        scope.$on('$destroy', function() { checkboxGroup.deregister(ngModel); });
      }
    }
  };
});