(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('MinimumStayController', MinimumStayController);

    MinimumStayController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', '$uibModal',
    'ContractMinimumStay', 'stayMeasuredFromApfMinimumStay','stayMeasuredFromAmadeus','travelSegmentIndicatorSabre', 'returnTravelFromAmadeus', 'returnTravelFromApfMinimumStay',
	'currencies', '$uibModalInstance', 'form1', 'form2', 'form3', 'form4', 'form5', 'ContractValue'];

    function MinimumStayController (
    		$scope, $parse, $state, $stateParams, $uibModal,
    		ContractMinimumStay, stayMeasuredFromApfMinimumStay,stayMeasuredFromAmadeus, travelSegmentIndicatorSabre, returnTravelFromAmadeus, returnTravelFromApfMinimumStay,
    		currencies, $uibModalInstance, form1, form2, form3, form4, form5, ContractValue) {

    	var vm = this;
    	vm.contractValue = ContractValue;
    	vm.entity = ContractMinimumStay.get({ id : $stateParams.id_contract });
		vm.form1 = form1;
		vm.form2 = form2;
		vm.form3 = form3;
		vm.form4 = form4;
		vm.form5 = form5;

		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;

		vm.stayMeasuredFromApfMinimumStay = stayMeasuredFromApfMinimumStay;
		vm.stayMeasuredFromAmadeus = stayMeasuredFromAmadeus;

		// vm.stayMeasuredFromSabre = stayMeasuredFromSabre;
		// vm.returnTravelFromSabre = returnTravelFromSabre;

		vm.travelSegmentIndicatorSabre = travelSegmentIndicatorSabre;

		vm.returnTravelFromAmadeus = returnTravelFromAmadeus;
		vm.returnTravelFromApfMinimumStay = returnTravelFromApfMinimumStay;
		vm.currencies = currencies;

		vm.entity.$promise.then(function(contract) {
    		vm.minimumStayRules = contract.data[11].pages;

    		if (vm.index < vm.minimumStayRules.length)
    			vm.editMinimumStay(vm.minimumStayRules[vm.index]);
    	});

		vm.stayMeasuredFromAmadeus.$promise.then(function(smfa) {
        	smfa.forEach(function(smf) {
        		if (smf.index == "48"  && vm.form3 != undefined) {
        			vm.form3.$promise.then(function(form) {
        				for (var i = 0; i < form.form_items.length; i++) {
        					if (form.form_items[i].tag == "rules_minimum_stay_measured_from_amadeus") {
            					form.form_items[i].value = smf;
            					break;
            				}
        				}
        			});
        		}
        	});
        });

		vm.returnTravelFromAmadeus.$promise.then(function(rtfa) {
			rtfa.forEach(function(rtf) {
        		if (rtf.returnTravelId == "36" && vm.form3 != undefined) {
        			vm.form3.$promise.then(function(form) {
        				for (var i = 0; i < form.form_items.length; i++) {
        					if (form.form_items[i].tag == "rules_minimum_stay_return_travel_from_amadeus") {
            					form.form_items[i].value = rtf;
            					break;
            				}
        				}
        			});
        		}
        	});
        });

        // vm.stayMeasuredFromSabre.$promise.then(function(smfs) {
			// smfs.forEach(function(smf) {
        // 		if (smf.index == "1"  && vm.form3 != undefined) {
        // 			vm.form3.$promise.then(function(form) {
        // 				for (var i = 0; i < form.form_items.length; i++) {
        // 					if (form.form_items[i].tag == "rules_minimum_stay_measured_from_sabre") {
        //     					form.form_items[i].value = smf;
        //     					break;
        //     				}
        // 				}
        // 			});
        // 		}
        // 	});
        // });
        //
        // vm.returnTravelFromSabre.$promise.then(function(rtfs) {
			// rtfs.forEach(function(rtf) {
        // 		if (rtf.returnTravelId == "5"  && vm.form3 != undefined) {
        // 			vm.form3.$promise.then(function(form) {
        // 				for (var i = 0; i < form.form_items.length; i++) {
        // 					if (form.form_items[i].tag == "rules_minimum_stay_return_travel_from_sabre") {
        //     					form.form_items[i].value = rtf;
        //     					break;
        //     				}
        // 				}
        // 			});
        // 		}
        // 	});
        // });

        vm.travelSegmentIndicatorSabre.$promise.then(function(rtfs) {
            rtfs.forEach(function(rtf) {
//                console.log("apa", rtf)
//                console.log(form3)
                if (rtf.travelSegmentIndicatorSabreID == "5"  && vm.form3 != undefined) {
                    vm.form3.$promise.then(function(form) {
                        for (var i = 0; i < form.form_items.length; i++) {
                            if (form.form_items[i].tag == "rules_minimum_stay_return_travel_from_sabre") {
                                form.form_items[i].value = rtf;
//                            	console.log("form3", vm.form3.label)
//                            	console.log("lalala", form3.form_items[i]);
                            }
                        }
                    });
                }
                if (rtf.travelSegmentIndicatorSabreID == "1"  && vm.form3 != undefined) {
                    vm.form3.$promise.then(function(form) {
                        for (var i = 0; i < form.form_items.length; i++) {
                            if (form.form_items[i].tag == "rules_minimum_stay_measured_from_sabre") {
                                form.form_items[i].value = rtf;
//                            	console.log("form3", vm.form3.label)
//                            	console.log("lalala", form3.form_items[i]);
                            }
                        }
                    });
                }
            });
        });

		vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
	    	};

     	//MINIMUM STAY
		vm.addMinimumStay = function(){
			vm.entity.data[11].pages.push({
				forms:[
					angular.copy(vm.form1),
					angular.copy(vm.form2),
					angular.copy(vm.form3),
					angular.copy(vm.form4),
					angular.copy(vm.form5)
				]
			});
		};

		vm.editMinimumStay = function(x){
            vm.form1.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            });
            vm.form2.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[1], vm.form2);
            });
            vm.form3.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[2], vm.form3);
            });
            vm.form4.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[3], vm.form4);
            });
            vm.form5.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[4], vm.form5);
            });
            // ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            // ContractValue.setValueFromDatabase(x.forms[1], vm.form2);
            // ContractValue.setValueFromDatabase(x.forms[2], vm.form3);
            // ContractValue.setValueFromDatabase(x.forms[3], vm.form4);
            // ContractValue.setValueFromDatabase(x.forms[4], vm.form5);

			// vm.form1 = angular.copy(x.forms[0]);
			// vm.form2 = angular.copy(x.forms[1]);
			// vm.form3 = angular.copy(x.forms[2]);
			// vm.form4 = angular.copy(x.forms[3]);
			// vm.form5 = angular.copy(x.forms[4]);

			vm.editObjectMinimumStay = x;
			vm.isMinimumStayEdit = true;
		};

		vm.saveMinimumStayEdit = function(){
			vm.entity.data[11].pages[vm.entity.data[11].pages.indexOf(vm.editObjectMinimumStay)].forms[0] = angular.copy(vm.form1);
			vm.entity.data[11].pages[vm.entity.data[11].pages.indexOf(vm.editObjectMinimumStay)].forms[1] = angular.copy(vm.form2);
			vm.entity.data[11].pages[vm.entity.data[11].pages.indexOf(vm.editObjectMinimumStay)].forms[2] = angular.copy(vm.form3);
			vm.entity.data[11].pages[vm.entity.data[11].pages.indexOf(vm.editObjectMinimumStay)].forms[3] = angular.copy(vm.form4);
			vm.entity.data[11].pages[vm.entity.data[11].pages.indexOf(vm.editObjectMinimumStay)].forms[4] = angular.copy(vm.form5);

			vm.editObjectMinimumStay = null;
			vm.isMinimumStayEdit = false;
		};
		//END MINIMUM STAY

		vm.save = function(){
            var forms = [vm.form1, vm.form2, vm.form3, vm.form4, vm.form5];

            if (vm.isMinimumStayEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractMinimumStay.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractMinimumStay.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            // if (vm.isMinimumStayEdit)
				// vm.saveMinimumStayEdit();
            // else
				// vm.addMinimumStay();
            //
            // ContractMinimumStay.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.resetMustCommenceChange = function(form) {
        	if (form != null && form != undefined) {
        		form.form_items[1].value = "";
        		form.form_items[2].value = "";
        		form.form_items[3].value = "";
        		form.form_items[4].value = "";
        	}
        }

        vm.resetMinimumStayCondition = function() {
            vm.form2.form_items[0].value = null;
        }

        vm.resetMinimumStayRelationship = function() {
            vm.form5.form_items[4].value = null;
        }
    }
})();
