(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FieldValidationDialogController', FieldValidationDialogController);

    FieldValidationDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'FieldValidation', 'forms'];

    function FieldValidationDialogController ($scope, $stateParams, $uibModalInstance, entity, FieldValidation, forms) {
        var vm = this;
        vm.fieldValidation = entity;
        vm.forms = forms;
        vm.rules = [];
        vm.dataTypes = ["String", "Integer", "Double", "Date", "Object", "Array"];
        vm.ruleTypes = ["Value", "Date", "Dependency", "Mandatory", "Regex", "Length"];
        vm.gdsList = ["All", "Travelport", "Sabre", "Amadeus"];
        
        if (vm.fieldValidation.minLength != undefined && vm.fieldValidation.minLength != null)
        	vm.fieldValidation.minLength = parseInt(vm.fieldValidation.minLength);
        
        if (vm.fieldValidation.maxLength != undefined && vm.fieldValidation.maxLength != null)
        	vm.fieldValidation.maxLength = parseInt(vm.fieldValidation.maxLength);
        
        //Set data to model
    	if (vm.fieldValidation.formTag != undefined && vm.fieldValidation.formTag != null) {
    		for (var i = 0; i < vm.forms.length; i++) {
        		if (vm.forms[i].tag == vm.fieldValidation.formTag) {
        			vm.form = vm.forms[i];
        			break;
        		}
        	}
    	}
    	
    	if (vm.fieldValidation.fieldTag != undefined && vm.fieldValidation.fieldTag != null)
    		vm.tag = vm.fieldValidation.fieldTag;
        	
    	if (vm.fieldValidation.dataType != undefined && vm.fieldValidation.dataType != null)
    		vm.datatype = vm.fieldValidation.dataType;
    	
    	if (vm.fieldValidation.rules != undefined && vm.fieldValidation.rules != null)
    		vm.rules = vm.fieldValidation.rules;
    	
        vm.load = function(id) {
            FieldValidation.get({id : id}, function(result) {
                vm.fieldValidation = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:fieldValidationUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            vm.fieldValidation.formTag = vm.form.tag;
            vm.fieldValidation.formDetails = vm.form.header_name + " ( " + vm.form.tag + " )";
            vm.fieldValidation.fieldTag = vm.tag;
            vm.fieldValidation.dataType = vm.datatype;
            vm.fieldValidation.rules = vm.rules;
            
            if (vm.datatype == "Select Data Type")
            	vm.fieldValidation.dataType = null;
            else if (vm.datatype != "Date")
            	vm.fieldValidation.allowBackdate = null;
            
            if (vm.fieldValidation.id !== null) {
                FieldValidation.update(vm.fieldValidation, onSaveSuccess, onSaveError);
            } else {
                FieldValidation.save(vm.fieldValidation, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.updateFieldTag = function(type) {
        	var form = null;
        	
        	vm.ruleTags = [];
        	
        	if (type == "Main") {
        		vm.tags = [];
        		form = vm.form;
        	} else
        		form = vm.ruleform;
        	
        	if (form != null && form != undefined && form.tag != undefined && form.tag != null) {
        		form.form_items.forEach(function(item) {
        			if (item.tag != undefined && item.tag != null && item.tag != '') {
        				if (type == "Main") {
        					vm.tags.push(item.tag);
        					vm.tag = null;
        					vm.ruleform = form;
        				}
        				
    					vm.ruleTags.push(item.tag);
    					vm.ruletag = null;
        			}
        		});
        	}
        }
        
        vm.applySameFormFieldTag = function() {
        	vm.ruleform = null;
        	vm.ruletag = null;
        	
        	if (vm.sameformandfieldtag) {
        		vm.ruleform = vm.form;
        		vm.ruletag = vm.tag;
        	}
        }
        
        vm.updateTypeChange = function() {
        	vm.ruleRelationships = [];
        	
        	if (vm.ruletype == "Date") {
        		vm.ruleRelationships = ["Must be earlier than target date field", "Must be later than target date field"];
        	} else if (vm.ruletype == "Dependency") {
        		vm.ruleRelationships = ["Must fill the target field", "Must not fill the target field"];
        	} else if (vm.ruletype == "Mandatory") {
        		vm.ruleRelationships = ["Is mandatory"];
        	} else if (vm.ruletype == "Regex" || vm.ruletype == "Value") {
        		vm.ruleRelationships = ["Must match the value", "Must not match the value"];
        	} else if (vm.ruletype == "Length") {
        		vm.ruleRelationships = ["Fixed length", "Range length"];
        	}
        	
        	vm.rulerelationship = vm.ruleRelationships[0];
        }
        
        vm.addRule = function() {
        	var label = null;
        	
        	if (vm.ruleform != undefined && vm.ruleform != null) {
        		if (vm.ruleform.form_items != undefined) {
        			for (var idx = 0; idx < vm.ruleform.form_items.length; idx++) {
            			var fi = vm.ruleform.form_items[idx];
            			
            			if (fi.tag == vm.ruletag) {
            				label = fi.label;
            				break;
            			}
            		}
        		}
        	}
        	
        	var rule = {
        			type: vm.ruletype,
        			gds: vm.rulegds,
        			relationship: vm.rulerelationship,
        			value: vm.rulevalue,
        			targetTag: vm.ruletag,
        			targetFormTag: vm.ruleform.tag,
        			targetFormDetails: vm.ruleform.header_name + " ( " + vm.ruleform.tag + " )",
        			targetFieldName: label
        	}
        	
        	var exist = false;
        	
        	for (var i = 0; i < vm.rules.length; i++) {
        		if (vm.rules[i].targetTag.trim().toLowerCase() == rule.targetTag.trim().toLowerCase()) {
        			vm.rules[i] = rule;
        			exist = true;
        			break;
        		}
        	}
        	
        	if (!exist)
        		vm.rules.push(rule);
        	
        	vm.ruletag = null;
        	vm.rulerelationship = null;
        	vm.ruletype = null;
        	vm.rulegds = vm.gdsList[0];
        	vm.rulevalue = null;
        }
        
        vm.deleteRule = function(index) {
        	vm.rules.splice(index, 1);
        }
    }
})();
