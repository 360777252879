(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('GdsTypeDialogController', GdsTypeDialogController);

    GdsTypeDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'GdsType'];

    function GdsTypeDialogController ($scope, $stateParams, $uibModalInstance, entity, GdsType) {
        var vm = this;
        vm.gdsType = entity;
        vm.load = function(id) {
            GdsType.get({id : id}, function(result) {
                vm.gdsType = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:gdsTypeUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.gdsType.id !== null) {
            	vm.gdsType.gdsModifiedDate = "0000-00-00";
            	vm.gdsType.gdsModifiedBy = "-";
                GdsType.update(vm.gdsType, onSaveSuccess, onSaveError);
            } else {
            	vm.gdsType.gdsCreatedDate = "0000-00-00";
            	vm.gdsType.gdsCreatedBy = "-";
                GdsType.save(vm.gdsType, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.gdsCreatedDate = false;
        vm.datePickerOpenStatus.gdsModifiedDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
