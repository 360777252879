(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormFareExceptionController', FormFareExceptionController);

    FormFareExceptionController.$inject = [
        '$scope', '$parse', '$state', '$stateParams', 'ContractFareException', 'FormValue'];

    function FormFareExceptionController (
        $scope, $parse, $state, $stateParams, ContractFareException, FormValue) {

        var vm = this;
        vm.FormValue = FormValue;
        vm.bookingCodeExceptions = [];

        ContractFareException.getBookingCode({id: $stateParams.id_contract, index: "ALL", group: $stateParams.group}, function(success) {
        	vm.bookingCodeExceptions = success.data[1].bookingCodeException;

        	if (vm.bookingCodeExceptions != null || vm.bookingCodeExceptions != undefined) {
                for(var z=0; z<vm.bookingCodeExceptions.length; z++){
                    all : for(var i = 0; i < vm.bookingCodeExceptions[z].forms.length; i++){
                        hasError = false;
	                        for(var j = 0; j < vm.bookingCodeExceptions[z].forms[i].form_items.length; j++){
	                            if(vm.bookingCodeExceptions[z].forms[i].form_items[j].type.toLowerCase() == "multivalue"){
	                                if(vm.bookingCodeExceptions[z].forms[i].form_items[j].value != null){
	                                    for(var k = 0; k < vm.bookingCodeExceptions[z].forms[i].form_items[j].value.length; k++){
	                                        for(var l = 0; l < vm.bookingCodeExceptions[z].forms[i].form_items[j].value[k].length; l++){
	                                            if(vm.bookingCodeExceptions[z].forms[i].form_items[j].value[k][l].errorAPF.length > 0){
	                                                hasError = true;
	                                                break all;
	                                            }
	
	                                        if(vm.bookingCodeExceptions[z].forms[i].form_items[j].value[k][l].errorAmadeus.length > 0){
	                                            hasError = true;
	                                            break all;
	                                        }
	                                            if(vm.bookingCodeExceptions[z].forms[i].form_items[j].value[k][l].errorAmadeus.length > 0){
	                                                hasError = true;
	                                                break all;
	                                            }
	
	                                        if(vm.bookingCodeExceptions[z].forms[i].form_items[j].value[k][l].errorSabre.length > 0){
	                                            hasError = true;
	                                            break all;
	                                            if(vm.bookingCodeExceptions[z].forms[i].form_items[j].value[k][l].errorSabre.length > 0){
	                                                hasError = true;
	                                                break all;
	                                            }
	                                        }
	                                    }
	                                }
	                            }
	                        }
	                    }
                    }
                    vm.bookingCodeExceptions[z].hasError = hasError;                    
                }
        	} else {
        		vm.bookingCodeExceptions = [];
        	}
        }, function(error) {});

        vm.deleteBookingCodeException = function(index) {
        	ContractFareException.deleteBookingCode({id: $stateParams.id_contract, index: index}, function(success) {
        		vm.bookingCodeExceptions = success.data[1].bookingCodeException;
        	}, function(error) {});
        }

        vm.back = function(){
            $( "#tab2" ).trigger( "click" );
        };

        vm.next = function(){
            $( "#tab4" ).trigger( "click" );
        };

        vm.DirectToFare = function() {
        	if($rootScope.isCalculated) {
        		$state.go("contract.fare-calculated", {id_contract: $stateParams.id_contract});
        	}else{
        		$state.go("contract.fare", {id_contract: $stateParams.id_contract});
        	}
	 	}
    }
})();
