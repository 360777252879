(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormCreateRoutingController', FormCreateRoutingController);

    FormCreateRoutingController.$inject = [
    '$scope', '$stateParams',
    'entity', 'airlines', 'routing_tariff_amadeus', 'routing_tariff_sabre', 'Routing',
        'form_routing',
        'form_routing_tariff',
        'form_routing_number',
        'form_routing_path',
        'form_routing_bottom',
        'form_routing_restriction',
        'ContractFare',
        '$uibModalInstance'];

    function FormCreateRoutingController (
    		$scope, $stateParams, entity, airlines, routing_tariff_amadeus, routing_tariff_sabre, Routing,
            form_routing,
            form_routing_tariff,
            form_routing_number,
            form_routing_path,
            form_routing_bottom,
            form_routing_restriction,
            ContractFare,
            $uibModalInstance) {
    	var vm = this;

    	vm.form_routing = form_routing;
    	vm.form_routing_tariff = form_routing_tariff;
    	vm.form_routing_number = form_routing_number;
    	vm.form_routing_path = form_routing_path;
    	vm.form_routing_bottom = form_routing_bottom;
    	vm.form_routing_restriction = form_routing_restriction;

        vm.routing = entity;
		vm.airlines = airlines;
		vm.routing_tariff_sabre = routing_tariff_sabre;
		vm.routing_tariff_amadeus = routing_tariff_amadeus;

		if(vm.routing.$promise!=null){
	        vm.routing.$promise.then(function(result){
	        	vm.bindFormRouting();
	        	vm.bindFormRoutingTariff();
	        	vm.bindFormRoutingNumber();
	        	vm.bindFormRoutingPath();
	        	vm.bindFormRoutingBottom();
	        	vm.bindFormRoutingRestriction();
			});
		}

        var onSaveSuccess = function (result) {
        	$uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function (result) {
            vm.isSaving = false;
        };

        vm.bindFormRouting = function () {
            vm.form_routing.$promise.then(function (form_routing) {
                form_routing.form_items[0].value = vm.routing.airline;
                form_routing.form_items[1].value = vm.routing.routing_description;
            });
        };

        vm.bindFormRoutingTariff = function () {
            vm.form_routing_tariff.$promise.then(function (form_routing_tariff) {
                form_routing_tariff.form_items[0].value = vm.routing.routing_tariff_amadeus;
                form_routing_tariff.form_items[1].value = vm.routing.routing_tariff_sabre;
                form_routing_tariff.form_items[2].value = vm.routing.routing_tariff;
            });
        };

        vm.bindFormRoutingNumber = function () {
            vm.form_routing_number.$promise.then(function (forrm_routing_number) {
                form_routing_number.form_items[0].value = vm.routing.routing_number;
                form_routing_number.form_items[1].value = vm.routing.route_number;
            });
        };

        vm.bindFormRoutingPath = function () {
            vm.form_routing_path.$promise.then(function (form_routing_path) {
            	angular.forEach(vm.routing.map_details, function(data){
            		var formItemRoutePath = form_routing_path.form_items[1].form_items_child;
            		angular.forEach(formItemRoutePath, function(dataForm){
            			if(dataForm.tag == "routing_new_route_path_boarding_point"){
            				dataForm.value = data["boardingPoint"];
            			}
            			else if(dataForm.tag == "routing_new_route_path_map_via"){
            				dataForm.value = data["via"];
            			}
            			else if(dataForm.tag == "routing_new_route_path_off_point"){
            				dataForm.value = data["offPoint"];
            			}
            			else if(dataForm.tag == "routing_new_route_path_multivalue_data"){
            				dataForm.value = data["routePath"];
            			}
            		});
            		
            		if(form_routing_path.form_items[1].value == null){
                		form_routing_path.form_items[1].value = [];
                	}
                	form_routing_path.form_items[1].value.push(formItemRoutePath);
                	 var z = angular.copy(form_routing_path.form_items[1].value);
                     vm.form_routing_path.form_items[1].value = z;

            	});
            	
            	form_routing_path.form_items[2].value = vm.routing.map_detail;
             	
            	
            });
        };

        vm.bindFormRoutingBottom = function () {
            vm.form_routing_bottom.$promise.then(function (form_routing_bottom) {
                form_routing_bottom.form_items[0].value = vm.routing.domestic_route_validation;
                form_routing_bottom.form_items[1].value = vm.routing.entry_exit_point;
                form_routing_bottom.form_items[2].value = vm.routing.ticketed_unticketed_point;
                //form_routing_bottom.form_items[3].value = vm.routing.map_detail;
            });
        };

        vm.bindFormRoutingRestriction = function () {
            vm.form_routing_restriction.$promise.then(function (form_routing_restriction) {
                angular.forEach(vm.routing.routing_restriction, function(value, key) {

                    var d = angular.copy(form_routing_restriction.form_items[0].form_items_child);

                    if(form_routing_restriction.form_items[0].value == null){
                        form_routing_restriction.form_items[0].value = [];
                    }

                    angular.forEach(d, function(key, v2){
                        if(key.tag == "routing_new_routing_restriction_value")
                            key.value = value.routingRestriction;
                        else if(key.tag == "routing_new_routing_restriction_travel_between_a")
                            key.value = value.travelBetweenA;
                        else if(key.tag == "routing_new_routing_restriction_travel_between_b")
                            key.value = value.travelBetweenB;
                        else if(key.tag == "routing_new_routing_restriction_to_from_c")
                            key.value = value.toFrom;
                        else if(key.tag == "routing_new_routing_restriction_to_from_d")
                            key.value = value.mustBeCarrierAlliance;
                        else if(key.tag == "routing_new_routing_restriction_must_be_must_not_be_e")
                            key.value = value.mustBeMustNotBe;
                        else if(key.tag == "routing_new_routing_restriction_must_be_must_not_be_f")
                            key.value = value.via;
                        else if(key.tag == "routing_new_routing_restriction_nonstop_direct_g")
                            key.value = value.nonStopDirect;
                        else if(key.tag == "routing_new_routing_restriction_nonstop_direct_h")
                            key.value = value.stopoverIn;
                        else if(key.tag == "routing_new_routing_restriction_nonstop_direct_i")
                            key.value = value.requiredNotRequired;
                        else if(key.tag == "routing_new_routing_restriction_surface_sector")
                            key.value = value.surfaceSector;

                    });

                    form_routing_restriction.form_items[0].value.push(d);
                });
            });
        };


        vm.generateMapDetail = function(){
            if (document.getElementById("generated_route_textarea").className != null) {
                document.getElementById("generated_route_textarea").className = document.getElementById("generated_route_textarea").className.replace(/(?:^|\s)hasData(?!\S)/g , '');
                document.getElementById("generated_route_textarea").className += " hasData";
            }

            var leftToRight = false;
            for(var x=0;x<vm.form_routing_path.form_items.length;x++){
                var tag = vm.form_routing_path.form_items[x].tag;
                if(tag == "routing_new_routing_path_left_to_right"){
                    var value = vm.form_routing_path.form_items[x].value;
                    if(value == 'Map Applies Left to Right Only'){
                        leftToRight = true;
                    }
                }
            }

            for(var x=0;x<vm.form_routing_path.form_items.length;x++){
                var tag = vm.form_routing_path.form_items[x].tag;
                if(tag == "routing_new_route_path_multivalue"){
                    var multiValue = vm.form_routing_path.form_items[x].value;
                    if(multiValue != null) {
                        var origin = [];
                        var via = [];
                        var destination = [];
                        for(var y=0;y<multiValue.length;y++){
                            angular.forEach(multiValue[y], function(value, key) {
                                if(value.tag == "routing_new_route_path_boarding_point"){
                                    if(value.value != null  || value.value != undefined) {
                                        origin.push(value.value);
                                    }
                                }
                                else if(value.tag == "routing_new_route_path_map_via") {
                                    via.push(value.value);
                                }
                                else if(value.tag == "routing_new_route_path_off_point"){
                                    if(value.value != null  || value.value != undefined)
                                        destination.push(value.value);
                                }
                            });
                        }
                        if(!leftToRight){
                            //get from fare
                            ContractFare.queryOriginDestination({ id : $stateParams.id_contract },
                                function(data){
                                    for(var x=0;x<data.length;x++){
                                        origin.push(data[x].origin);
                                        destination.push(data[x].destination);
                                    }

                                    var size = origin.length > destination.length ? origin.length :   destination.length;
                                    var stringLine = [];
                                    for(var i=0;i<origin.length;i++){
                                        stringLine.push("*"+origin[i]+"-|");
                                    }
                                    for(var i=0;i<size;i++){
                                        if(via[i] != null && via[i] != "'") {
                                            stringLine[i] = stringLine[i] + "-" + via[i]+"-|";
                                        }
                                        else{
                                            stringLine[i] = stringLine[i] + "     |";
                                        }
                                    }
                                    for(var i=0;i<destination.length;i++){
                                        if(destination[i] != null) {
                                            stringLine[i] = stringLine[i] + "-" + destination[i]+"*";
                                        }
                                    }

                                    vm.mapDetail = "";
                                    for(var i=0;i<stringLine.length;i++){
                                        if (vm.mapDetail.length == 0)
                                            vm.mapDetail = vm.mapDetail+stringLine[i];
                                        else
                                            vm.mapDetail = vm.mapDetail+"\n"+stringLine[i];
                                    }
                                }, function(err){
                                }
                            );
                        }
                        else{
                            var stringLine = [];
                            for(var i=0;i<origin.length;i++){
                                stringLine.push("*"+origin[i]+"-|");
                            }
                            for(var i=0;i<via.length;i++){
                                if(via[i] != null && via[i] != "'") {
                                    stringLine[i] = stringLine[i] + "-" + via[i]+"-|";
                                }
                                else{
                                    stringLine[i] = stringLine[i] + "     |";
                                }
                            }
                            for(var i=0;i<destination.length;i++){
                                if(destination[i] != null) {
                                    stringLine[i] = stringLine[i] + "-" + destination[i]+"*";
                                }
                            }

                            vm.mapDetail = "";
                            for(var i=0;i<stringLine.length;i++){
                                if (vm.mapDetail.length == 0)
                                    vm.mapDetail = vm.mapDetail+stringLine[i];
                                else
                                    vm.mapDetail = vm.mapDetail+"\n"+stringLine[i];
                            }
                        }
                    }
                }
            }


        };

        vm.bindValue = function(){
            vm.routing.airline = vm.form_routing.form_items[0].value
            vm.routing.routing_description = vm.form_routing.form_items[1].value
            vm.routing.routing_tariff_amadeus = vm.form_routing_tariff.form_items[0].value;
            vm.routing.routing_tariff_sabre = vm.form_routing_tariff.form_items[1].value;
            vm.routing.routing_tariff = vm.form_routing_tariff.form_items[2].value;
            vm.routing.routing_number = vm.form_routing_number.form_items[0].value;
            vm.routing.route_number   = vm.form_routing_number.form_items[1].value;

            vm.routing.domestic_route_validation = vm.form_routing_bottom.form_items[0].value;
            vm.routing.entry_exit_point = vm.form_routing_bottom.form_items[1].value;
            vm.routing.ticketed_unticketed_point = vm.form_routing_bottom.form_items[2].value;

            vm.routing.left_to_right = vm.form_routing_path.form_items[0].value;
            vm.routing.map_detail = vm.form_routing_path.form_items[2].value;

            //multivalue
            vm.routing.routing_restriction = [];
            angular.forEach(vm.form_routing_restriction.form_items[0].value, function(value, key) {
                var routingValue = {};

                angular.forEach(value, function(v, key) {

                    if(v != null){
                        if (v.tag == "routing_new_routing_restriction_value") {
                            routingValue.routingRestriction = v.value;
                        }
                        else if (v.tag == "routing_new_routing_restriction_travel_between_a") {
                            routingValue.travelBetweenA = v.value;
                        }
                        else if (v.tag == "routing_new_routing_restriction_travel_between_b") {
                            routingValue.travelBetweenB = v.value;
                        } else if (v.tag == "routing_new_routing_restriction_to_from_c") {
                            routingValue.toFrom = v.value;
                        } else if (v.tag == "routing_new_routing_restriction_to_from_d") {
                            routingValue.mustBeCarrierAlliance = v.value;
                        } else if (v.tag == "routing_new_routing_restriction_must_be_must_not_be_e") {
                            routingValue.mustBeMustNotBe = v.value;
                        } else if (v.tag == "routing_new_routing_restriction_must_be_must_not_be_f") {
                            routingValue.via = v.value;
                        } else if (v.tag == "routing_new_routing_restriction_nonstop_direct_g") {
                            routingValue.nonStopDirect = v.value;
                        } else if (v.tag == "routing_new_routing_restriction_nonstop_direct_h") {
                            routingValue.stopoverIn = v.value;
                        } else if (v.tag == "routing_new_routing_restriction_nonstop_direct_i") {
                            routingValue.requiredNotRequired = v.value;
                        } else if (v.tag == "routing_new_routing_restriction_surface_sector") {
                            routingValue.surfaceSector = v.value;
                        }
                    }
                });
                vm.routing.routing_restriction.push(routingValue);
            });

            vm.routing.map_details = [];
            angular.forEach(vm.form_routing_path.form_items[1].value, function(value, key) {
                var routingValue = {};
                angular.forEach(value, function(v, key) {
                    if(v != null){
                        if (v.tag == "routing_new_route_path_boarding_point") {
                            routingValue.boardingPoint = v.value;
                        }
                        else if (v.tag == "routing_new_route_path_map_via") {
                            routingValue.via = v.value;
                        }
                        else if (v.tag == "routing_new_route_path_off_point") {
                            routingValue.offPoint = v.value;
                        } else if (v.tag == "routing_new_route_path_multivalue_data") {
                            routingValue.routePath = v.value;
                        }
                    }
                });
                vm.routing.map_details.push(routingValue);
            });

//            console.log(vm.routing);
        };

        vm.save = function () {
            vm.isSaving = true;
            vm.bindValue();


            if (vm.routing.id != null) {
                Routing.update(vm.routing, onSaveSuccess, onSaveError);
            } else {
                Routing.save(vm.routing, onSaveSuccess, onSaveError);
            }

        };

        vm.check = function(field){
            if(vm.dataRoutingRestriction != null) {
                if (vm.dataRoutingRestriction.restriction == "1. Travel Between Via City") {
                    if (field == "a" || field == "b" || field == "e" || field == "f") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "2. Travel Via City") {
                    if (field == "e" || field == "f") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "3. Travel Nonstop/Direct") {
                    if (field == "e" || field == "g") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "4. Travel Between Nonstop/Direct") {
                    if (field == "a" || field == "b" || field == "e" || field == "g") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "5. Travel To/From Via City") {
                    if (field == "c" || field == "e" || field == "f") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "6. Travel To/From Nonstop/Direct") {
                    if (field == "c" || field == "e" || field == "g") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "7. Stopover") {
                    if (field == "a" || field == "b" || field == "h" || field == "i") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "8. Surface/Air") {
                    if (field == "a" || field == "b" || field == "j" || field == "i") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "9. Carrier/Alliance") {
                    if (field == "d") {
                        return true;
                    } else {
                        return false;
                    }
                } else if (vm.dataRoutingRestriction.restriction == "10. Stopover (In)") {
                    if (field == "h" || field == "i") {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        };

        /* Routing Restriction */
        vm.pushDataRoutingRestriction = function(x, y){
            y[0].value = vm.dataRoutingRestriction.restriction;
            y[1].value = vm.dataRoutingRestriction.a;
            y[2].value = vm.dataRoutingRestriction.b;
            y[3].value = vm.dataRoutingRestriction.c;
            y[4].value = vm.dataRoutingRestriction.d;
            y[5].value = vm.dataRoutingRestriction.e;
            y[6].value = vm.dataRoutingRestriction.f;
            y[7].value = vm.dataRoutingRestriction.g;
            y[8].value = vm.dataRoutingRestriction.h;
            y[9].value = vm.dataRoutingRestriction.i;
            y[10].value = vm.dataRoutingRestriction.j;

            if(vm.form_routing_restriction.form_items[0].value == null) {
                vm.form_routing_restriction.form_items[0].value = [];
            }
            vm.form_routing_restriction.form_items[0].value.push(y);
            var z = angular.copy(vm.form_routing_restriction.form_items[0].value);
            vm.form_routing_restriction.form_items[0].value = z;

            vm.dataRoutingRestriction = [];
        };

        vm.removeDataRoutingRestriction = function(x, y){
            vm.form_routing_restriction.form_items[0].value.splice(y, 1);
            var z = angular.copy(vm.form_routing_restriction.form_items[0].value);
            vm.form_routing_restriction.form_items[0].value = z;
        };
        /* End Routing Restriction */

        /* Route Path */
        vm.pushRoutePath = function(x, y){

            y[0].value = vm.routePath.origin;
            y[1].value = vm.routePath.via;
            y[2].value = vm.routePath.destination;

            if(vm.routePath.via != undefined)
                y[3].value = vm.routePath.origin + '-' + vm.routePath.via + '-' + vm.routePath.destination;
            else
                y[3].value = vm.routePath.origin + '-' + vm.routePath.destination;

            if(vm.form_routing_path.form_items[1].value == null) {
                vm.form_routing_path.form_items[1].value = [];
            }
            vm.form_routing_path.form_items[1].value.push(y);
            var z = angular.copy(vm.form_routing_path.form_items[1].value);
            vm.form_routing_path.form_items[1].value = z;

            vm.routePath = [];
        };

        vm.removeRoutePath = function(x, y){
            vm.form_routing_path.form_items[1].value.splice(y, 1);
            var z = angular.copy(vm.form_routing_path.form_items[1].value);
            vm.form_routing_path.form_items[1].value = z;
        };
        
        
        vm.copyRoutePath = function(x, y){
        	vm.data = angular.copy(vm.form_routing_path.form_items[1].value[y]);        	
        	vm.form_routing_path.form_items[1].value.push(vm.data);
        	
        	var newItemIndex = vm.form_routing_path.form_items[1].value.length - 1;        	
        	
        	if (vm.isEditRoutePath == undefined || vm.isEditRoutePath == null) {
        		vm.isEditRoutePath = {}
        	}        	
        	
        	vm.isEditRoutePath[newItemIndex] = true;
        };
        
        vm.checkMapDetail = function(data) {
        	var ori = data[0].value;
        	var via = data[1].value;
        	var dest = data[2].value;

        	console.log(via);
        	
            if(via != undefined && via != null && via != "")
            	data[3].value = ori + '-' + via + '-' + dest;
            else
            	data[3].value = ori + '-' + dest;
        }

        
        /* End Route Path */


        vm.clear = function() {
        	$uibModalInstance.dismiss('cancel');
        };

        $scope.$watch('vm.form.form_items[6].value', function() {
        	try {
    		  if(typeof vm.form.form_items[6] == 'undefined') {
    			  // does not exist
    		  }
    		  else {
    			  // does exist
    			  resultMapDetail();
    		  }
    		}
    		catch (error){ /* ignore */ }
        });

        $scope.$watch('vm.form.form_items[7].value', function() {
        	try {
      		  if(typeof vm.form.form_items[7] == 'undefined') {
      		    // does not exist
      		  }
      		  else {
      		  // does exist
      	        	resultMapDetail();
      		  }
      		}
      		catch (error){ /* ignore */ }
        });

        $scope.$watch('vm.form.form_items[8].value', function() {
        	try {
      		  if(typeof vm.form.form_items[8] == 'undefined') {
      		    // does not exist
      		  }
      		  else {
      		  // does exist
      	        	resultMapDetail();
      		  }
      		}
      		catch (error){ /* ignore */ }
        });

        function resultMapDetail (){
        	var rslt ="";

//        	if(vm.form.form_items[5].value != null || vm.form.form_items[6].value != null || vm.form.form_items[7].value != null){
//        		rslt = rslt + "*";
//        	}

        	if(vm.form.form_items[6].value != null || vm.form.form_items[8].value != null){
        		rslt = rslt + "*";
        	}

        	if(vm.form.form_items[6].value != null){
        		rslt = rslt + vm.form.form_items[6].value;

        		if(vm.form.form_items[7].value != null && vm.form.form_items[7].value != ""){
        			rslt = rslt + "-";
        		}
        	}

//        	if(vm.form.form_items[6].value != null){
//        		if(rslt.length > 0 && vm.form.form_items[5].value != null){
//        			rslt = rslt + "-";
//        		}
//        		rslt = rslt + vm.form.form_items[6].value;
//        	}

//        	if(vm.form.form_items[7].value != null){
//        		if(rslt.length > 0 && (vm.form.form_items[5].value != null || vm.form.form_items[6].value != null)){
//        			rslt = rslt + "-";
//        		}
//        		rslt = rslt + vm.form.form_items[7].value;
//        	}

        	if (vm.form.form_items[7].value != null) {
        		rslt = rslt + vm.form.form_items[7].value;
        	}


        	if(vm.form.form_items[8].value != null){
        		if(rslt.length > 0 && vm.form.form_items[6].value != null){
        			rslt = rslt + "-";
        		}
        		rslt = rslt + vm.form.form_items[8].value;
        	}

//        	if(vm.form.form_items[5].value != null || vm.form.form_items[6].value != null || vm.form.form_items[7].value != null){
//        		rslt = rslt + "*";
//        	}

        	if(vm.form.form_items[6].value != null || vm.form.form_items[8].value != null){
        		rslt = rslt + "*";
        	}

        	vm.form.form_items[9].value = rslt;
        }

        vm.checkHasMultivalueRoute = function() {
        	if (vm.form_routing_path.form_items[1].value != null && vm.form_routing_path.form_items[1].value.length > 0) {
        		return false;
        	} else {
        		vm.mapDetail = "";
        		if (document.getElementById("generated_route_textarea").className != null)
        			document.getElementById("generated_route_textarea").className = document.getElementById("generated_route_textarea").className.replace(/(?:^|\s)hasData(?!\S)/g , '');
        		return true;
        	}
        }

     // Any function returning a promise object can be used to load values asynchronously
//        vm.getAirlines = function(val) {
//          return $http.get('//maps.googleapis.com/maps/api/geocode/json', {
//            params: {
//              address: val,
//              sensor: false
//            }
//          }).then(function(response){
//            return response.data.results.map(function(item){
//              return item.formatted_address;
//            });
//          });
//        };
        
        vm.copyDataRoutingRestriction = function(x, y){
        	vm.data = angular.copy(vm.form_routing_restriction.form_items[0].value[y]);        	
        	vm.form_routing_restriction.form_items[0].value.push(vm.data);
        	
        	var newItemIndex = vm.form_routing_restriction.form_items[0].value.length - 1;        	
        	
        	if (vm.isEditDataRoutingRestriction == undefined || vm.isDataRoutingRestriction == null) {
        		vm.isEditDataRoutingRestriction = {}
        	}        	
        	
        	vm.isEditDataRoutingRestriction[newItemIndex] = true;
        };

    }
})();
