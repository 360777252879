(function() {
	'use strict';

	angular.module('americanoApp').controller('ManageContractTabController',
			ManageContractTabController);

	ManageContractTabController.$inject = ['$scope', '$location', '$state', 'Auth', 'Principal', 'ENV'];

	function ManageContractTabController($scope, $location, $state, Auth, Principal, ENV) {
		var vm = this;

		vm.navCollapsed = true;
		vm.isAuthenticated = Principal.isAuthenticated;
		vm.inProduction = ENV === 'prod';
		vm.login = login;
		vm.logout = logout;
		vm.$state = $state;

		function login() {
			LoginService.open();
		}

		function logout() {
			Auth.logout();
			$state.go('login');
		}
	}
})();
