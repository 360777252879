(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['Principal', 'User', 'ParseLinks', 'paginationConstants', 'JhiLanguageService', 'pagingParams', '$state'];

    function UserManagementController(Principal, User, ParseLinks, paginationConstants, JhiLanguageService, pagingParams, $state) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];


        /*vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;

        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;

        vm.clear = clear;
        vm.currentAccount = null;
        vm.languages = null;
        vm.links = null;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.setActive = setActive;
        vm.totalItems = null;
        vm.users = [];
        vm.loadAll();
        */


        vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll();


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        Principal.identity().then(function(account) {
            vm.currentAccount = account;
        });


        function loadAll () {
//            User.query({page: vm.page - 1, size: paginationConstants.itemsPerPage}, function (result, headers) {
//                vm.links = ParseLinks.parse(headers('link'));
//                vm.totalItems = headers('X-Total-Count');
//                vm.users = result;
//            });
        	console.log(pagingParams.page);
        	console.log(vm.itemsPerPage);
        	User.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.users = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function setActive (user, isActivated) {
            user.activated = isActivated;
            User.update(user, function () {
                vm.loadAll();
                vm.clear();
            });
        }

        function clear () {
            vm.user = {
                id: null, login: null, firstName: null, lastName: null, email: null,
                activated: null, langKey: null, createdBy: null, createdDate: null,
                lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                resetKey: null, authorities: null
            };
            vm.editForm.$setPristine();
            vm.editForm.$setUntouched();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + 'desc',
                search: vm.currentSearch
            });
        }


        vm.checkSearchQuery = function() {
            if (vm.searchQuery == "")
                vm.loadAll();
        }


        vm.search = function () {
            User.search({
                page: pagingParams.page - 1,
                size: vm.searchResultItemsPerPage,
                sort: sort(),
                query: vm.searchQuery
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.users = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                vm.loadAll();
            }
        };
    }
})();
