(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('ReturnTravelSabreDetailController', ReturnTravelSabreDetailController);

    ReturnTravelSabreDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'ReturnTravelSabre'];

    function ReturnTravelSabreDetailController($scope, $rootScope, $stateParams, entity, ReturnTravelSabre) {
        var vm = this;
        vm.returnTravelSabre = entity;
        vm.load = function (id) {
            ReturnTravelSabre.get({id: id}, function(result) {
                vm.returnTravelSabre = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:returnTravelSabreUpdate', function(event, result) {
            vm.returnTravelSabre = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
