(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('AirlineTemplateRuleDialogController', AirlineTemplateRuleDialogController);

    AirlineTemplateRuleDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'airlines', 'AirlineTemplateRule'];

    function AirlineTemplateRuleDialogController ($scope, $stateParams, $uibModalInstance, entity, airlines, AirlineTemplateRule) {
        var vm = this;
        vm.atr = entity;
        vm.airlines = airlines;
        vm.readingOrientations = ["LEFT_TO_RIGHT", "TOP_TO_BOTTOM"];
        vm.dataTypes = ["STRING", "INTEGER", "DOUBLE", "DATE"];
        vm.valuePositions = ["UP", "DOWN", "LEFT", "RIGHT", "SELF"];
        vm.multipleValues = ["YES", "NO"];
        vm.masters = ["", "CITY", "PASSENGER TYPE", "CURRENCY", "SEASON", "FARE TYPE"];
        vm.lengthTypes = ["Fix", "Range", "Up To", "Unlimited"];
        vm.associatedTags = ["Boarding Point", "Off Point"];
        vm.labels = [];
        vm.rules = [];
        vm.dateformats = [];
        
        if (vm.atr.$promise != undefined) {
        	vm.atr.$promise.then(function(atr) {
            	if (atr.rules != null && atr.rules != undefined) {
                	vm.rules = atr.rules;
                }
            	
            	if (atr.dateFormat != null && atr.dateFormat != undefined) {
                	vm.dateformats = atr.dateFormat;
                }
            	
            	if (atr.airlineCode != null && atr.airlineCode != undefined) {
                	vm.airlines.$promise.then(function(airlines) {
                		airlines.forEach(function(airline) {
                    		if (airline.airline_code == atr.airlineCode) {
                    			vm.airlineInput = airline;
                    		}
                    	});
                	});
                }
            });
        }
        
        vm.ruleObject = {
        	americanoTag: null,
        	labels: [],
        	expectedDataType:null,
        	multipleValue: null,
        	valuePosition: null,
        	valueRegex: null,
        	valueLength: null,
        	valueDelimiter: null,
        	master:null
        };
        
        vm.addLabels = function() {
        	if (vm.label != null && vm.label != undefined && vm.label.length > 0) {
        		vm.labels.push(vm.label);
        		vm.label = "";
        	}
        };
        
        vm.addFormat = function() {
        	if (vm.format != null && vm.format != undefined && vm.format.length > 0) {
        		vm.dateformats.push(vm.format);
        		vm.format = "";
        	}
        };
        
        vm.addRule = function() {
        	vm.rule = angular.copy(vm.ruleObject);
        	
        	vm.rule.americanoTag = vm.tag;
        	vm.rule.strictMatch = vm.strictmatch;
        	vm.rule.caseSensitive = vm.casesensitive;
        	vm.rule.includeDelimiter = vm.includedelimiter;
        	vm.rule.labels = vm.labels;
        	vm.rule.expectedDataType = vm.datatype;
        	
        	if (vm.multiplevalue == "YES")
        		vm.rule.multipleValue = true;
        	else
        		vm.rule.multipleValue = false;
        	
        	vm.rule.valuePosition = vm.valueposition;
        	vm.rule.valueRegex = vm.valueregex;
        	vm.rule.valueDelimiter = vm.valuedelimiter;
        	
        	var length = "";
        	
        	if (vm.lengthtype == 'Fix') {
        		length = vm.lengthfix;
        	} else if (vm.lengthtype == 'Range') {
        		length = vm.lengthstart + "-" + vm.lengthend;
        	} else if (vm.lengthtype == 'Up To') {
        		length = "~" + vm.length;
        	} else if (vm.lengthtype == 'Unlimited') {
        		length = "UNLIMITED";
        	}
        	
        	vm.rule.valueLength = length;
        	vm.rule.master = vm.frommaster;
        	vm.rule.strictMatch = vm.strictmatch;
        	vm.rule.combined = vm.combined;
        	vm.rule.associatedTag = vm.associatedtag;
        	
        	vm.rules.push(vm.rule);
        	vm.resetRuleValues();
        };
        
        vm.deleteRule = function(index) {
        	if (vm.rules[index])
        		vm.rules.splice(index, 1);
        }
        
        vm.deleteLabel = function(index) {
        	if (vm.labels[index])
        		vm.labels.splice(index, 1);
        }
        
        vm.deleteFormat = function(index) {
        	if (vm.dateformats[index])
        		vm.dateformats.splice(index, 1);
        }
        
        vm.resetRuleValues = function() {
        	vm.tag = null;
        	vm.strictMatch = null;
        	vm.caseSensitive = null;
        	vm.includeDelimiter = null;
        	vm.labels = [];
        	vm.datatype = null;
        	vm.isupto = null;
        	vm.length = null;
        	vm.valueposition = null;
        	vm.multiplevalue = null;
        	vm.valueDelimiter = null;
        	vm.combined = null;
        	vm.associatedtag = null;
        	vm.lengthtype = null;
        }
        
        vm.initRegex = function() {
        	if (vm.valueregex == undefined || vm.valueregex == null) {
        		vm.valueregex = "^[A-Z]+$";
        	}
        }
        
        vm.processLength = function(isEdit, index) {
        	if (isEdit) {
        		var value = vm.rules[index].valueLength;
            	
        		if (value == "UNLIMITED") {
            		vm.lengthtypeedit = "Unlimited";
            	} else {
            		if (value != null && value.startsWith("~")) {
            			vm.lengthtypeedit = "Up To";
                		value = value.substring(1);
                		vm.lengthedit = parseInt(value);
                	} else if (value != null && value.includes("-")){
                		vm.lengthtypeedit = "Range";
                		
                		if (value != null) {
                			var lengths = value.split("-");
                    		
                    		if (lengths.length == 2) {
                    			vm.lengthstartedit = parseInt(lengths[0]);
                    			vm.lengthendedit = parseInt(lengths[1]);
                    		}
                		}
                	} else {
                		vm.lengthtypeedit = "Fix";
                		
                		if (value != null)
                			vm.lengthfixedit = parseInt(value);
                	}
            	}
        	} else {
        		var length = "";
            	
        		if (vm.lengthtypeedit == 'Fix') {
        			length = vm.lengthfixedit;
        		} else if (vm.lengthtypeedit == 'Range') {
	        		length = vm.lengthstartedit + "-" + vm.lengthendedit;
	        	} else if (vm.lengthtypeedit == 'Up To') {
	        		length = "~" + vm.lengthedit;
	        	} else if (vm.lengthtypeedit == 'Unlimited') {
	        		length = "UNLIMITED";
	        	}
	        	
        		vm.rules[index].valueLength = length;
        	}
        }
        
        vm.processMultivalue = function(isEdit, index) {
        	if (isEdit) {
        		if (vm.rules[index].multipleValue)
        			vm.multivalueedit = "YES";
        		else
        			vm.multivalueedit = "NO";
        	} else {
        		if (vm.multivalueedit == "YES")
        			vm.rules[index].multipleValue = true;
            	else
            		vm.rules[index].multipleValue = false;
        	}
        }
        
        vm.processEditLabels = function(isEdit, index) {
        	if (isEdit) {
        		vm.labeledits = vm.rules[index].labels;
        	} else {
        		vm.rules[index].labels = vm.labeledits;
        	}
        }
        
        vm.addEditLabels = function() {
        	if (vm.labeledit != null && vm.labeledit != undefined && vm.labeledit.length > 0) {
        		vm.labeledits.push(vm.labeledit);
        		vm.labeledit = "";
        	}
        };
        
        vm.deleteEditLabel = function(index) {
        	if (vm.labeledits[index])
        		vm.labeledits.splice(index, 1);
        }
        
        vm.load = function(id) {
        	AirlineTemplateRule.get({id : id}, function(result) {
                vm.atr = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:atrUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            vm.atr.airline = vm.airlineInput.airline_name;
            vm.atr.airlineCode = vm.airlineInput.airline_code;
            vm.atr.dateFormat = vm.dateformats;
            
            vm.atr.rules = vm.rules;
            
            if (vm.atr.id !== null) {
            	AirlineTemplateRule.update(vm.atr, onSaveSuccess, onSaveError);
            } else {
            	AirlineTemplateRule.save(vm.atr, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        vm.resetRegexForDateType = function(isEdit, rule) {
        	if (!isEdit)
    			vm.valueregex = '';
        	else {
        		if (rule.expectedDataType == 'DATE')
        			rule.valueRegex = '';
        	}
        }
        
        vm.resetTag = function(rule) {
        	if (!rule.combined)
        		rule.associatedTag = null;
        }
    }
})();
