(function() {
	'use strict';

	angular.module('americanoApp').controller('AddOnDialogController',
			AddOnDialogController);

	AddOnDialogController.$inject = [ '$scope', '$stateParams',
			'$uibModalInstance', 'entity', 'form1','form1_amadeus', 'form2', 'form3','form3_amadeus', 'form4',
			'form5', 'form6','form6_amadeus', 'form7', 'AddOn', 'airlines', 'currencies',
			'passenger_type' ];

	function AddOnDialogController($scope, $stateParams, $uibModalInstance,
			entity, form1, form1_amadeus, form2, form3,form3_amadeus, form4, form5, form6,form6_amadeus, form7, AddOn,
			airlines, currencies, passenger_type) {
		var vm = this;
		vm.airlines = airlines;
		vm.addOn = entity;
		vm.form1 = form1;
		vm.form1_amadeus = form1_amadeus;
		vm.form2 = form2;
		vm.form3 = form3;
		vm.form3_amadeus = form3_amadeus;
		vm.form4 = form4;
		vm.form5 = form5;
		vm.form6 = form6;
		vm.form6_amadeus = form6_amadeus;
		vm.form7 = form7;
		vm.currencies = currencies;
		vm.passenger_type = passenger_type;
		vm.gds = null;
		vm.GDSOptions = ["APF", "Amadeus"];
		vm.progress = "Initial";

		vm.next = function() {
			vm.progress = "Next";
		}

		if (vm.addOn.addOnGdsName != undefined && vm.addOn.addOnGdsName != null) {
			vm.gds = vm.addOn.addOnGdsName;
			vm.next();
		}

		vm.save = function() {
			vm.addOn.addOnGdsName = vm.gds;
			if (vm.addOn.id != null) {
				AddOn.update(vm.addOn, function(result) {
//					$uibModalInstance.close(result);
					AddOn.publish(result, function(resp){  
	                    
	        			console.log("Publish Message: " + resp.customStringVariableType);

	        			var publishResult = JSON.parse(resp.customStringVariableType);

	        			var res = true;
	        			for(var i=0;i<publishResult.length;i++){
	        				var result = publishResult[i];
	        				if (result.Travelport != undefined  && result.Travelport.toLowerCase() != 'success' && result.Travelport.toLowerCase() != 'error'){
	        					vm.publishTravelportResult =  result.Travelport;
	        					res = false;
	        				}
	        				if (result.Amadeus != undefined  && result.Amadeus.toLowerCase() != 'success' && result.Amadeus.toLowerCase() != 'error'){
	        					vm.publishAmadeusResult = result.Amadeus;
	        					res = false;
	        				}
	        				if (result.Sabre != undefined  && result.Sabre.toLowerCase() != 'success' && result.Sabre.toLowerCase() != 'error'){
	        					vm.publishSabreResult = result.Sabre;
	        					res = false;
	        				}            					
	        			}
	        			
	        			if(res){
	                        $uibModalInstance.close(result);
	        			}
	        			else{
	        				
	        			}
			        }, function(error){
			        	vm.publishTravelportResult = "Internal Server Error";
		        		vm.publishAmadeusResult = "Internal Server Error";
	        			vm.publishSabreResult = "Internal Server Error";
			        	alert(error.data.headers['x-americanoapp-alert']);
			        });
					
				}, function(error) {
//					console.log(error);
				});
			} else {
				
				AddOn.save(vm.addOn, function(result) {
					
					AddOn.publish(result, function(resp){  
	                    
	        			console.log("Publish Message: " + resp.customStringVariableType);

	        			var publishResult = JSON.parse(resp.customStringVariableType);

	        			var res = true;
	        			for(var i=0;i<publishResult.length;i++){
	        				var result = publishResult[i];
	        				if (result.Travelport != undefined  && result.Travelport.toLowerCase() != 'success' && result.Travelport.toLowerCase() != 'error'){
	        					vm.publishTravelportResult =  result.Travelport;
	        					res = false;
	        				}
	        				if (result.Amadeus != undefined  && result.Amadeus.toLowerCase() != 'success' && result.Amadeus.toLowerCase() != 'error'){
	        					vm.publishAmadeusResult = result.Amadeus;
	        					res = false;
	        				}
	        				if (result.Sabre != undefined  && result.Sabre.toLowerCase() != 'success' && result.Sabre.toLowerCase() != 'error'){
	        					vm.publishSabreResult = result.Sabre;
	        					res = false;
	        				}            					
	        			}
	        			
	        			if(res){
	                        $uibModalInstance.close(result);
	        			}
	        			else{
	        				
	        			}
			        }, function(error){
			        	vm.publishTravelportResult = "Internal Server Error";
		        		vm.publishAmadeusResult = "Internal Server Error";
	        			vm.publishSabreResult = "Internal Server Error";
			        	alert(error.data.headers['x-americanoapp-alert']);
			        });
					
//					$uibModalInstance.close(result);
				}, function(error) {
//					console.log(error);
				});
				
				
				
			}
		};

		vm.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};

		// permitted carrier
		vm.pushdataPermittedCarrier = function(x, y) {

		    if(vm.addOn.permittedCarrier.length < 10){

                var carrier = vm.dataPermittedCarrier.add_on_permitted_carrier_carrier;
                var bookingCode = vm.dataPermittedCarrier.add_on_permitted_carrier_booking_code;

                var permittedcarrier = {
                    carrier : null,
                    bookingCode : null
                }

                permittedcarrier.carrier = carrier;
                permittedcarrier.bookingCode = bookingCode;

                if (permittedcarrier.carrier != null
                    && permittedcarrier.bookingCode != null) {
                    vm.addOn.permittedCarrier.push(permittedcarrier);
                }

                vm.dataPermittedCarrier = [];
            }

		};

		vm.removedataPermittedCarrier = function(x, y) {
			vm.addOn.permittedCarrier.splice(y, 1);
		};

		// secondary passenger type
		vm.pushdataSecondaryPassengerType = function(x, y) {

			var passengerType = vm.dataSecondaryPassengerType.add_on_secondary_passenger_type_passenger_type;
			var addOnCurrencyAmount_currency = vm.dataSecondaryPassengerType.add_on_secondary_passenger_add_on_amount_currency;
			var addOnCurrencyAmount_amount = vm.dataSecondaryPassengerType.add_on_secondary_passenger_amount;
			var applyFirst1 = vm.dataSecondaryPassengerType.add_on_secondary_passenger_apply_first_percent;
			var applyFirst2 = vm.dataSecondaryPassengerType.add_on_secondary_passenger_apply_first_amount;
			var increaseDecrease1 = vm.dataSecondaryPassengerType.add_on_secondary_passenger_increase_decrease_percent;
			var increaseDecrease2 = vm.dataSecondaryPassengerType.add_on_secondary_passenger_increase_decrease_amount;
			var percent = vm.dataSecondaryPassengerType.add_on_secondary_passenger_percent;
			var currencyAmount1_amount = vm.dataSecondaryPassengerType.add_on_secondary_passenger_amount1;
			var currencyAmount2_amount = vm.dataSecondaryPassengerType.add_on_secondary_passenger_amount2;
			var currencyAmount1_currency = vm.dataSecondaryPassengerType.add_on_secondary_passenger_currency1;
			var currencyAmount2_currency = vm.dataSecondaryPassengerType.add_on_secondary_passenger_currency2;

			var specifiedcurrencyamount = {
				currency : null,
				amount : null
			}
			specifiedcurrencyamount.currency = addOnCurrencyAmount_currency;
			specifiedcurrencyamount.amount = addOnCurrencyAmount_amount;

			var currencyamount1 = {
				currency : null,
				amount : null
			}
			currencyamount1.currency = currencyAmount1_currency;
			currencyamount1.amount = currencyAmount1_amount;

			var currencyamount2 = {
				currency : null,
				amount : null
			}
			currencyamount2.currency = currencyAmount2_currency;
			currencyamount2.amount = currencyAmount2_amount;

			var secondarypassengertype = {
				passengerType : null,
				addOnCurrencyAmount : null,
				type : null,
				applyFirst : null,
				increaseDecrease : null,
				increaseDecrease2 : null,
				percent : null,
				currencyAmount1 : null,
				currencyAmount2 : null
			}

			secondarypassengertype.passengerType = passengerType;
			secondarypassengertype.addOnCurrencyAmount = specifiedcurrencyamount;
			secondarypassengertype.increaseDecrease = increaseDecrease1;
			secondarypassengertype.increaseDecrease2 = increaseDecrease2;
			secondarypassengertype.percent = percent;
			secondarypassengertype.currencyAmount1 = currencyamount1;
			secondarypassengertype.currencyAmount2 = currencyamount2;
			secondarypassengertype.type = vm.type;

			if (secondarypassengertype.passengerType != null && secondarypassengertype.addOnCurrencyAmount != null) {
				vm.addOn.secondaryPassengerType.push(secondarypassengertype);
			}

			vm.dataSecondaryPassengerType = [];
		};

		vm.changeType = function(tag) {
			if (tag == "add_on_secondary_passenger_apply_first_percent") {
				vm.type = "Percent";
			} else if (tag == "add_on_secondary_passenger_apply_first_amount") {
				vm.type = "Amount";
			}
		}

		vm.changeFare = function(tag) {
			if (tag == "add_on_fare_type_option") {
				vm.fare = "Fare Type";
			} else if (tag == "add_on_fare_basis_code_option") {
				vm.fare = "Fare Basis Code";
			}
		}


		vm.removedataSecondaryPassengerType = function(x, y) {
			vm.addOn.secondaryPassengerType.splice(y, 1);
		};

		// date picker
		vm.Date = function(arg) {
			if (arg == null) {
				return new Date();
			} else {
				if (arg != "indef") {
					return new Date(arg);
				} else {
					return "indef";
				}
			}
		}

		vm.initDate = function(tag) {
			if (tag == "add_on_effective_date") {
				if (vm.addOn.effectiveDate != null) {
					vm.addOn.effectiveDate = vm.Date(vm.addOn.effectiveDate);
				}
			} else if (tag == "add_on_discontinue_date") {
				if (vm.addOn.discontinueDate != null) {
					vm.addOn.discontinueDate = vm.Date(vm.addOn.discontinueDate);
				}
			}
		}

        vm.setDiscontinueDate = function(arg){
		    if(arg != null){
		        var today = arg;
		        vm.addOn.discontinueDate = today.setYear(today.getFullYear() + 1);
                $scope.maxDate = today.setYear(today.getFullYear()+2);
            }
        }

		$scope.opened = [];
		$scope.openDatePicker = function($event, index) {
			$event.preventDefault();
			$event.stopPropagation();
			$scope.opened = [];
			$scope.opened[index] = true;
		};

        vm.resetGDSSelection = function() {
            vm.addOn.addOnGDS = null;
        };

        vm.uppercase = function(item){
            if(item.tag == "add_on_permitted_carrier_booking_code"){
                if(vm.dataPermittedCarrier[item.tag] != null) {
                    vm.dataPermittedCarrier[item.tag] = vm.dataPermittedCarrier[item.tag].toUpperCase();
                }
            }

            vm.isInvalidBookingCode = false;
            var panjang = vm.dataPermittedCarrier[item.tag].split(" ").length;
            if(panjang > 8){
                vm.isInvalidBookingCode = true;
            }
        }

        vm.checkLength = function(arg, tag){
            vm.isInvalidInterior = false;
            vm.isInvalidInteriorCities = false;
            vm.isInvalidInteriorCitiesRegex = false;
            vm.isInvalidInteriorZone = false;
            vm.isInvalidInteriorZoneRegex = false;

            if(arg != null && tag == "add_on_location_interior_cities_zone"){

                vm.addOn.locationInteriorCityZone = vm.addOn.locationInteriorCityZone.toUpperCase();

                var panjang = arg.split(" ").length;
                if(panjang > 8){
                    vm.isInvalidInterior = true;
                }

                for(i=1; i <= panjang; i++){
                    var arr = arg.split(" ");
                    for(var j=0; j < arr.length; j++){
                        if(vm.addOn.interiorCityZone == 'Cities/Airport'){

                            var regexPattern = new RegExp("^[a-zA-Z]*$");
                            if(!regexPattern.test(arr[j])){
                                vm.isInvalidInteriorCitiesRegex = true;
                            }

                            if(arr[j].length > 3){
                                vm.isInvalidInteriorCities = true;
                                break;
                            }
                        } else if(vm.addOn.interiorCityZone == 'Zone'){

                            var regexPattern = new RegExp("^[a-zA-Z0-9]*$");
                            if(!regexPattern.test(arr[j])){
                                vm.isInvalidInteriorZoneRegex = true;
                            }
                            if(arr[j].length > 8){
                                vm.isInvalidInteriorZone = true;
                                break;
                            }
                        }
                    }
                }

            }

            vm.isInvalidGateway = false;
            vm.isInvalidGatewayCities = false;
            vm.isInvalidGatewayCitiesRegex = false;
            vm.isInvalidGatewayZone = false;
            vm.isInvalidGatewayZoneRegex = false;

            if(arg != null && tag == "add_on_location_gateway_cities_zone"){

                vm.addOn.locationGatewayCityZone = vm.addOn.locationGatewayCityZone.toUpperCase()

                var panjang = arg.split(" ").length;
                if(panjang > 3){
                    vm.isInvalidGateway = true;
                }

                for(i=1; i <= panjang; i++){
                    var arr = arg.split(" ");
                    for(var j=0; j < arr.length; j++){
                        if(vm.addOn.gatewayCityZone == 'Cities/Airport'){

                            var regexPattern = new RegExp("^[a-zA-Z]*$");
                            if(!regexPattern.test(arr[j])){
                                vm.isInvalidGatewayCitiesRegex = true;
                            }

                            if(arr[j].length > 3){
                                vm.isInvalidGatewayCities = true;
                                break;
                            }
                        } else if(vm.addOn.gatewayCityZone == 'Zone'){

                            var regexPattern = new RegExp("^[a-zA-Z0-9]*$");
                            if(!regexPattern.test(arr[j])){
                                vm.isInvalidGatewayZoneRegex = true;
                            }
                            if(arr[j].length > 8){
                                vm.isInvalidGatewayZone = true;
                                break;
                            }
                        }
                    }
                }

            }
        }
	}
})();
