(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('InteriorDialogController', InteriorDialogController);

    InteriorDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Interior'];

    function InteriorDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Interior) {
        var vm = this;

        vm.interior = entity;
        vm.clear = clear;
        vm.save = save;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            if (vm.interior.id != null) {
            	Interior.update(vm.interior, function (result) {
                	
            		Interior.publish(result, function(resp){  
                        
            			console.log("Publish Message: " + resp.customStringVariableType);

            			var publishResult = JSON.parse(resp.customStringVariableType);

            			var res = true;
            			for(var i=0;i<publishResult.length;i++){
            				var result = publishResult[i];
            				if (result.Travelport != undefined  && result.Travelport.toLowerCase() != 'success' && result.Travelport.toLowerCase() != 'error'){
            					vm.publishTravelportResult =  result.Travelport;
            					res = false;
            				}
            				if (result.Amadeus != undefined  && result.Amadeus.toLowerCase() != 'success' && result.Amadeus.toLowerCase() != 'error'){
            					vm.publishAmadeusResult = result.Amadeus;
            					res = false;
            				}
            				if (result.Sabre != undefined  && result.Sabre.toLowerCase() != 'success' && result.Sabre.toLowerCase() != 'error'){
            					vm.publishSabreResult = result.Sabre;
            					res = false;
            				}            					
            			}
            			
            			if(res){
                            $uibModalInstance.close(result);
            			}
            			else{
            				
            			}
    		        }, function(error){
    		        	vm.publishTravelportResult = "Internal Server Error";
		        		vm.publishAmadeusResult = "Internal Server Error";
	        			vm.publishSabreResult = "Internal Server Error";
    		        	alert(error.data.headers['x-americanoapp-alert']);
    		        });
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
            	Interior.save(vm.interior, function (result) {
                	
            		Interior.publish(result, function(resp){  
                        
            			console.log("Publish Message: " + resp.customStringVariableType);

            			var publishResult = JSON.parse(resp.customStringVariableType);

            			var res = true;
            			for(var i=0;i<publishResult.length;i++){
            				var result = publishResult[i];
            				if (result.Travelport != undefined  && result.Travelport.toLowerCase() != 'success' && result.Travelport.toLowerCase() != 'error'){
            					vm.publishTravelportResult =  result.Travelport;
            					res = false;
            				}
            				if (result.Amadeus != undefined  && result.Amadeus.toLowerCase() != 'success' && result.Amadeus.toLowerCase() != 'error'){
            					vm.publishAmadeusResult = result.Amadeus;
            					res = false;
            				}
            				if (result.Sabre != undefined  && result.Sabre.toLowerCase() != 'success' && result.Sabre.toLowerCase() != 'error'){
            					vm.publishSabreResult = result.Sabre;
            					res = false;
            				}            					
            			}
            			
            			if(res){
                            $uibModalInstance.close(result);
            			}
            			else{
            				
            			}
    		        }, function(error){
    		        	vm.publishTravelportResult = "Internal Server Error";
		        		vm.publishAmadeusResult = "Internal Server Error";
	        			vm.publishSabreResult = "Internal Server Error";
    		        	alert(error.data.headers['x-americanoapp-alert']);
    		        });
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            
            };
    		
//            vm.isSaving = true;
//            if (vm.interior.id !== null) {
//                Interior.update(vm.interior, onSaveSuccess, onSaveError);
//            } else {
//                Interior.save(vm.interior, onSaveSuccess, onSaveError);
//            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('americanoApp:interiorUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
