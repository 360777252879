(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('ReturnTravelAmadeusDeleteController',ReturnTravelAmadeusDeleteController);

    ReturnTravelAmadeusDeleteController.$inject = ['$uibModalInstance', 'entity', 'ReturnTravelAmadeus'];

    function ReturnTravelAmadeusDeleteController($uibModalInstance, entity, ReturnTravelAmadeus) {
        var vm = this;
        vm.returnTravelAmadeus = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            ReturnTravelAmadeus.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
