(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TravelSegIndicatorAPFDetailController', TravelSegIndicatorAPFDetailController);

    TravelSegIndicatorAPFDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'TravelSegIndicatorAPF'];

    function TravelSegIndicatorAPFDetailController($scope, $rootScope, $stateParams, entity, TravelSegIndicatorAPF) {
        var vm = this;
        vm.travelSegIndicatorAPF = entity;
        
        var unsubscribe = $rootScope.$on('americanoApp:travelSegIndicatorAPFUpdate', function(event, result) {
            vm.travelSegIndicatorAPF = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
