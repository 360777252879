(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('RuleDialogController', RuleDialogController);

    RuleDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Rule'];

    function RuleDialogController ($scope, $stateParams, $uibModalInstance, entity, Rule) {
        var vm = this;
        vm.rule = entity;
        vm.load = function(id) {
            Rule.get({id : id}, function(result) {
                vm.rule = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:ruleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.rule.id !== null) {
                Rule.update(vm.rule, onSaveSuccess, onSaveError);
            } else {
                Rule.save(vm.rule, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
