(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('StayMeasurementFromSabreDetailController', StayMeasurementFromSabreDetailController);

    StayMeasurementFromSabreDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'StayMeasurementFromSabre'];

    function StayMeasurementFromSabreDetailController($scope, $rootScope, $stateParams, entity, StayMeasurementFromSabre) {
        var vm = this;
        vm.stay_measurement_from_sabre = entity;
        vm.load = function (id) {
        	StayMeasurementFromSabre.get({id: id}, function(result) {
                vm.stay_measurement_from_sabre = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:stay_measurement_from_sabreUpdate', function(event, result) {
            vm.stay_measurement_from_sabre = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
