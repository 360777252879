angular.module('americanoApp')
    .directive('multivalue', ["$q", "$filter", "$timeout", "$compile", function($q, $filter, timer, $compile) {
        return {
        	require:"ngModel",
        	restrict:"E",
        	scope: {
        		ngModel:"=ngModel",
            	name:"@name"
        	},
        	link:function(scope, element, attrs, ngModel){
        	},
            template: 
	            '<input id="new-todo" placeholder="" ng-model="name" autofocus>'+
				'<button ng-click="ngModel.push(name)">Add</button>'+
				'<section id="main" ng-show="ngModel.length" ng-cloak>'+
				'	<ul id="todo-list">'+
				'		<li ng-repeat="todo in ngModel track by $index">'+
				'			<div class="view">'+
				'				<label>{{todo}}</label>'+
				'				<button ng-click="ngModel.splice(ngModel.indexOf(todo), 1);">X</button>'+
				'			</div>'+
				'		</li>'+
				'	</ul>'+
				'</section>'
        }
    }]);