(function() {
    'use strict';

    angular
    .module('americanoApp')
    .factory('ContractFareCalculated', ContractFareCalculated);

	ContractFareCalculated.$inject = ['$resource'];

	function ContractFareCalculated ($resource) {
	    var resourceUrl =  'api/contracts/fare-calculated/:id/:index';

		return $resource(resourceUrl, {}, {
		    'query': { method: 'GET', url: 'api/contracts/fare-calculated/get/:id/:group/:type/:search'},
		    // 'queryOriginDestination': { method: 'GET', url: 'api/contracts/fare/get/origin-destination'},
		    'get': { method: 'GET', transformResponse: function (data) { data = angular.fromJson(data); return data; } },
		    'update': { method:'PUT' },
		    'copy': { method : 'POST', url:'api/contracts/fare-calculated/copy'},
		    // 'delete': { method:'DELETE'},
		    'move': { method:'PUT', url: 'api/contracts/fare-calculated/move'},
		    // 'calculate': { method:'PUT', url: 'api/contracts/fare/calculate'},
		     'deleteMultiple': { method:'POST', url: 'api/contracts/fare-calculated/delete-multiple'}
	    });
	}

})();

