(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('BlackoutCopyDialogController', BlackoutCopyDialogController);

    BlackoutCopyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'Contract'];

    function BlackoutCopyDialogController ($scope, $stateParams, $uibModalInstance, Contract) {
    	var vm = this; 
    	vm.id = $stateParams.id;
    	vm.index = $stateParams.index;
    	
    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };
        
        vm.confirmCopy = function (id, index, lastIndex) {
        	Contract.get({id:$stateParams.id_contract}, function(data){
        		
        		data.data[7].pages.push(data.data[7].pages[index]);

        		Contract.update(data, function(result){
    	    		console.log("Update sukses");
    	    		$uibModalInstance.close(true);
    	    	}, function(error){
    	    		console.log("Update error ");
    	    		console.log(error);
    	    	});
        	});
	    };
    }
})();