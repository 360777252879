(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('DistributionGroupMasterDeleteController',DistributionGroupMasterDeleteController);

    DistributionGroupMasterDeleteController.$inject = ['$uibModalInstance', 'entity', 'DistributionGroupMaster'];

    function DistributionGroupMasterDeleteController($uibModalInstance, entity, DistributionGroupMaster) {
        var vm = this;

        vm.distributionGroupMaster = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            DistributionGroupMaster.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
