(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('FieldPage', FieldPage);

    FieldPage.$inject = ['$resource'];

    function FieldPage ($resource) {
        var resourceUrl =  'api/field-pages/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
    	.module('americanoApp')
    	.factory('FieldPageTag', FieldPageTag);
    
    FieldPageTag.$inject = ['$resource'];
    
    function FieldPageTag ($resource) {
    	var resourceUrl = 'api/field-pages/tag/:tag';
    	
    	return $resource(resourceUrl, {}, {
    		'query': { method: 'GET', isArray: true},
    		'get': {
    			method: 'GET',
    			transformResponse: function (data) {
    				try {
    					data = angular.fromJson(data);
    				} catch(e) {
    					data = null;
    				}
    				
    				return data;
    			}
    		},
    		'update': { method:'PUT'}
    	});
    }
    
    angular
    .module('americanoApp')
    .factory('Fields', Fields);

    Fields.$inject = ['$resource'];
	
	function Fields ($resource) {
	    var resourceUrl =  'api/fields/:tag';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	        },
	        'update': { method:'PUT' }
	    });
	}
    
})();
