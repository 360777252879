(function() {
    'use strict';

    angular
        .module('americanoApp')
        .constant('paginationConstants', {
//            'itemsPerPage': 5
        });

})();
