(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FieldPageController', FieldPageController);

    FieldPageController.$inject = ['$scope', '$state', 'FieldPage', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants', 'FormPage', 'fieldPages'];

    function FieldPageController ($scope, $state, FieldPage, ParseLinks, AlertService, pagingParams, paginationConstants, FormPage, fieldPages) {
    	var vm = this;
        vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.fieldPages = fieldPages;
        vm.loadAll();
        
        vm.disableButton = function(index) {
        	var disable = true;
        	var BreakException= {};

    		try {
    			vm.fieldPages.forEach(function(fieldPage) {
    				if (vm.formPages[index].id == fieldPage.formPage.id) {
            			disable = false;
            			throw BreakException;
    				}
    			});
        	} catch(e) {
        		if (e!==BreakException) throw e;
        	}
        	
        	return disable;
        }
        
        function loadAll () {
        	FormPage.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.formPages = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        } 

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
    }
})();
