(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('TransportingClass', TransportingClass);

    TransportingClass.$inject = ['$resource'];

    function TransportingClass ($resource) {
        var resourceUrl =  'api/transporting-classes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
    .module('americanoApp')
    .factory('TransportingClassCustom', TransportingClassCustom);

    TransportingClassCustom.$inject = ['$resource'];

function TransportingClassCustom ($resource) {
    var resourceUrl =  'api/transporting-classes/getTransportingClass';

    return $resource(resourceUrl, {}, {
        'query': { method: 'GET', isArray: true},
        'get': {
            method: 'GET', isArray: true,
            transformResponse: function (data) {
                if (data) {
                    data = angular.fromJson(data);
                }
                return data;
            }
        },
        'update': { method:'PUT' }
    });
}

	angular
	.module('americanoApp')
	.factory('TransportinClassSearch', TransportinClassSearch);

	TransportinClassSearch.$inject = ['$resource'];

	function TransportinClassSearch($resource) {
		var resourceUrl = 'api/_search/transporting-class/:query'; 
		return $resource(resourceUrl, {}, {
			'query': { method: 'GET', isArray: true}
		});
	}
})();
