(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('NotificationController', NotificationController);

    NotificationController.$inject = ['$scope', '$rootScope', '$state', 'Notification', 'ParseLinks', 'AlertService', 'NotificationUser', 'Principal', 'pagingParams', 'NotificationSearch'];

    function NotificationController ($scope, $rootScope, $state, Notification, ParseLinks, AlertService, NotificationUser, Principal, pagingParams, NotificationSearch) {
        var vm = this;
        
        vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll();
        vm.filterOptions = ["ALL", "UNREAD", "READ"];
        vm.statusOptions = ["NONE", "IMPORTANT", "ERROR", "SUCCESS"];
        
        vm.filterOption = vm.filterOptions[0];
        
        vm.selectedStatus = [];
        vm.selectedStatusOption = [];
        
        for (var i = 0; i < vm.statusOptions.length; i++) {
        	vm.selectedStatus[i] = false;
        }
        
        vm.selectedStatus[0] = true;
        
        var isNoneTriggered = false;
        
        vm.registerStatus = function(status, index) {
        	if (status == "NONE") {
        		vm.selectedStatusOption = [];
        		vm.selectedStatus[index] = true;
        		isNoneTriggered = true;
        		
        		for (var i = 1; i < vm.statusOptions.length; i++) {
        			vm.selectedStatus[i] = false;
        			vm.selectedStatusOption.push(vm.statusOptions[i]);
        		}
        	} else {
        		vm.selectedStatus[0] = false;
        		
        		if (isNoneTriggered)
        			vm.selectedStatusOption = [];
        		
    			if (vm.selectedStatus[index])
            		vm.selectedStatusOption.push(status);
            	else 
            		vm.selectedStatusOption.splice(vm.selectedStatusOption.indexOf(status), 1);
            	
    			isNoneTriggered = false;
        	}
        	
        	vm.loadAll();
        }
        
        vm.checkNoStatusSelected = function(status, index) {
        	var noStatusSelected = true;
    		
    		vm.selectedStatus.forEach(function(status) {
    			if (status)
    				noStatusSelected = false;
    		})
    		
    		if (noStatusSelected) 
    			vm.registerStatus(vm.statusOptions[0], 0);
    		else
    			vm.registerStatus(status, index);
        }
        
        function loadAll() {
        	Principal.identity().then(function(account) {
        		vm.selectedFilterOptionInString = "";
        		vm.selectedStatusOptionInString = "";
        		
        		if (vm.filterOption == "UNREAD")
        			vm.selectedFilterOptionInString = "UNREAD"
        		else if (vm.filterOption == "READ")
        			vm.selectedFilterOptionInString = "READ"
        		else {
        			vm.selectedFilterOptionInString = "READ|UNREAD"
        		}
        		
        		for (var i = 0; i < vm.selectedStatusOption.length; i++) {
        			if (i == vm.selectedStatusOption.length-1)
        				vm.selectedStatusOptionInString = vm.selectedStatusOptionInString + vm.selectedStatusOption[i];
        			else
        				vm.selectedStatusOptionInString = vm.selectedStatusOptionInString + vm.selectedStatusOption[i] + "|" ;
        		}
        		
        		NotificationUser.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort(),
                    username: account.login,
                    filterBy: vm.selectedFilterOptionInString,
                    statusBy: vm.selectedStatusOptionInString
                }, onSuccess, onError);
                function sort() {
                	var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                    if (vm.predicate !== 'id') {
                        result.push('id');
                    }
                    return result;
                }
                function onSuccess(data, headers) {
                	 vm.links = ParseLinks.parse(headers('link'));
                     vm.totalItems = headers('X-Total-Count');
                     vm.queryCount = vm.totalItems;
                     vm.notifications = data;
                     vm.page = pagingParams.page;
                     vm.searchQuery = "";
                     
                     categorizeNotificationByDate();
                }
                function onError(error) {
                    AlertService.error(error.data.message);
                }
            	});
        };
        
        vm.emptySearchCheck = function() {
        	if (!vm.searchQuery)
        		vm.loadAll();
        }
        
        vm.search = function () {
        	NotificationSearch.query({
        		page: pagingParams.page - 1,
                size: vm.searchResultItemsPerPage,
                sort: sort(),
                query: vm.searchQuery
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.notifications = data;
                vm.page = pagingParams.page;
                
               	categorizeNotificationByDate();
            }
            function onError(error) {
                vm.loadAll();
            }
        };
        
        function categorizeNotificationByDate() {
        	var groupObject = {
        		date:"",
        		notif: []
        	};
        	
        	vm.notificationsGroups = [];
        	var dateGroup = [];
        	var notifGroup = [];
        	
        	var currentDateInString = "";
        	var dateInString = "";
        	
        	vm.notifications.forEach(function(notification) {
        		if (convertDateString(notification.createdDate) != currentDateInString) {
        			dateGroup.push(notification.createdDate);
        			currentDateInString = convertDateString(notification.createdDate);
        		}
        	});
        	
        	var i = 0;
        	var current = 0;
        	
        	dateGroup.forEach(function(group) {
        		dateInString = convertDateString(group);
        		notifGroup = [];
        		
        		for (i = current; i < vm.notifications.length; i++) {
        			if (convertDateString(vm.notifications[i].createdDate) == dateInString) {
        				notifGroup.push(vm.notifications[i]);
        			} else {
        				groupObject = {date: group, notif: notifGroup};
        				vm.notificationsGroups.push(groupObject);
        				current = i;
        				break;
        			}
        			
        			if (i == vm.notifications.length-1) {
        				groupObject = {date: group, notif: notifGroup};
        				vm.notificationsGroups.push(groupObject);
        			}
        		}
        	});
        }
        
        vm.setNotifReadStatusToRead = function(notif) {
        	if (notif.readStatus != "READ") {
        		notif.readStatus = "READ";
            	
            	Notification.update(notif, function(data) {
            		$scope.$emit('americanoApp:updateNotification', "");
            		vm.loadAll();
            	}, function(error) {
            		vm.loadAll();
            	});
        	}
        }
        
        vm.checkErrorType = function(notif) {
        	switch(notif.type) {
        		case "Fare Upload" : 
        			if (notif.status == "ERROR") 
						$state.go('contract.fare', {id_contract:notif.contract[0].id}, { reload: true });
        			break;
        		case "Contract Validate":
        		case "Contract Publish":
        			if (notif.status == "ERROR")
        				$state.go('notification-detail', {id:notif.id}, { reload: true });
        			break;
        	}
        };
        
        function convertDateString(date) {
        	var date = new Date(date);
        	
        	return date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();
        }
        
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        };

        function transition () {
        	pagingParams.page = vm.page;
        	
        	$state.transitionTo($state.$current, {
                page: vm.page
        	});
        }
        
        // Update notifications
		var unsubscribeRN = $rootScope.$on('americanoApp:refreshNotification', function(event, result) { 
			vm.loadAll();
		});
		
		$scope.$on('$destroy', unsubscribeRN);
    }
})();
