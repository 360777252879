(function() {
    'use strict';

    angular
        .module('americanoApp')
        .directive('limitTextarea', ["$timeout", limitTextarea]);

    function limitTextarea ($timeout) {
        var directive = {
            restrict: 'A',
            require: '?ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc (scope, element, attrs, ngModel) {
        	var limit = 50;
            if(attrs["limitTextarea"] != "") {
                limit = parseInt(attrs["limitTextarea"]);
            }

            //onload
            $timeout(function(){
            	expand(element, limit);
	     	});
	
            scope.$watch(attrs.ngModel, function(){
            	expand(element, limit);
	    	});
        }

        function expand(element, limit){
        	var maxLength = limit;
        	var text = element.val();
        	
        	var perline = text.split("\n");
        	
        	if (perline.length > 1) {
        		for (var z = 0; z < perline.length; z++) {
        			if (z < perline.length - 1) {
        				while(perline[z].length < maxLength)
                			perline[z] = perline[z] + " ";
        			}
            	}
        	}
        	
        	text = "";
        	
        	perline.forEach(function(line) {
        		text = text + line;
        	});
        	
        	text = text.replace(/\n/g, '');
        	text = text.replace(/\t/g, '');
        	
        	var temp = text.length / maxLength;
            var tempInt = parseInt(temp);
            var row = tempInt;
            var result = "";
            
            if (temp > tempInt) {
            	row = tempInt + 1;
            }
            
            for (var i = 0; i < row; i++) {
            	var currentIndex = i * limit;
            	var nextIndex = (i + 1) * limit;
            	
//            	console.log(currentIndex);
//            	console.log(nextIndex);
            	
            	if (i < row - 1)
            		result = result + text.substring(currentIndex, nextIndex) + "\n";
            	else
            		result = result + text.substring(currentIndex, nextIndex);
            		
//            	console.log(result);
            }
            
//        	console.log(temp + " " + tempInt + " " + row);
        	
            element.val(result);
        }
    }

})();
