(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('DistributionGroupMasterController', DistributionGroupMasterController);

    DistributionGroupMasterController.$inject = ['$scope', '$state', 'DistributionGroupMaster', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function DistributionGroupMasterController ($scope, $state, DistributionGroupMaster, ParseLinks, AlertService, paginationConstants, pagingParams) {
        var vm = this;

        vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll = loadAll;
        
        loadAll();

        function loadAll () {
            DistributionGroupMaster.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.distributionGroupMasters = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        vm.clearField = function (condition) {
            console.log(condition);
            if(condition == 'sabre' || condition == 'amadeus'){
                console.log(condition);
                vm.distributionGroupMaster.galileo = '';
                vm.distributionGroupMaster.worldspan = '';
                vm.distributionGroupMaster.apollo = '';
            }
        }
        
        vm.checkSearchQuery = function() {
        	if (vm.searchQuery == "")
        		vm.loadAll();
        }
        
        vm.search = function () {
        	DistributionGroupMaster.search({
        		page: pagingParams.page - 1,
                size: vm.searchResultItemsPerPage,
                sort: sort(),
                query: vm.searchQuery
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.distributionGroupMasters = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                vm.loadAll();
            }
        };
    }
})();
