(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('VendorsController', VendorsController);

    VendorsController.$inject = ['$scope', '$state', '$stateParams', 'Vendor', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants'];

    function VendorsController ($scope, $state, $stateParams, Vendor, ParseLinks, AlertService, pagingParams, paginationConstants) {
    	var vm = this;
    	vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll();

        function loadAll () {
            Vendor.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.vendors = data;
                vm.page = pagingParams.page;
                
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
            	id_contract: $stateParams.id_contract,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        vm.id_contract = $stateParams.id_contract;
        
        vm.next = function(){
      	   //$state.go('contract.routing', {id_contract:vm.id_contract}, { reload: true });
         	console.log("NEXT");
      	   $( "#tab5" ).trigger( "click" );
         };
         
         vm.back = function(){
      	   $( "#tab4" ).trigger( "click" );
         };
         
    }
})();