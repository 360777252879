(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('ReturnTravelMinStayAPFDeleteController',ReturnTravelMinStayAPFDeleteController);

    ReturnTravelMinStayAPFDeleteController.$inject = ['$uibModalInstance', 'entity', 'ReturnTravelMinStayAPF'];

    function ReturnTravelMinStayAPFDeleteController($uibModalInstance, entity, ReturnTravelMinStayAPF) {
        var vm = this;
        vm.returnTravelMinStayAPF = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            ReturnTravelMinStayAPF.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
