(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormViewFareController', FormViewFareController);

    FormViewFareController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', 'form1', 'form2', 'form3', 'ContractFare', 'ContractValue'];

    function FormViewFareController (
    		$scope, $parse, $state, $stateParams, form1, form2, form3, ContractFare, ContractValue) {
    	var vm = this;

		vm.form1 = form1;
		vm.form2 = form2;
		vm.form3 = form3;
		vm.index = $stateParams.index;
		
    	vm.forms = ContractFare.get({ id : $stateParams.id_contract, index: vm.index });

    	vm.forms.$promise.then(function() {
			if(vm.index != null){
                vm.form1.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[0], vm.form1);
                });
                vm.form2.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[1], vm.form2);
                });
                vm.form3.$promise.then(function() {
                    ContractValue.setValueFromDatabase(vm.forms.forms[2], vm.form3);
                });
			}
		});
    	
    }
})();
