(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormFareExceptionCopyController', FormFareExceptionCopyController);

    FormFareExceptionCopyController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'ContractFareException', '$state'];

    function FormFareExceptionCopyController ($scope, $stateParams, $uibModalInstance, ContractFareException, $state) {
    	var vm = this; 
    	vm.id = $stateParams.id_contract;
    	vm.index = $stateParams.index;
    	
    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };
        
        vm.confirmCopy = function () {
        	var operationObject = {
        			contractId: vm.id,
        			itemIndex: vm.index,
        			operation: "COPY"
        	}
        	
        	ContractFareException.copyBookingCode(operationObject, function(success) {
        		$uibModalInstance.close('close');
            	$state.go('contract.exception-fare-dialog', {operation: 'EDIT', index:success.afterOperationItemIndex}, { reload: true });
            }, function(error) {});
	    };
    }
})();