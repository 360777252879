(function() {
    'use strict';

    angular
        .module('americanoApp')
        .factory('ProfileService', ProfileService);

    ProfileService.$inject = ['$q', '$http'];

    function ProfileService($q, $http) {

        var dataPromise;

        var service = {
            getProfileInfo : getProfileInfo
        };

        return service;

        function getProfileInfo() {
        	var response = {};
            response.activeProfiles = "prod";
            response.ribbonEnv = "prod";
            response.inProduction = false;
            return response;
        }
    }
})();
