(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('CombinationController', CombinationController);

    CombinationController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', '$uibModal',
    'ContractCombination', 'airlines', 'fare_type', 'fare_type_apf',

    'routing_tariff_amadeus', 'routing_tariff_sabre',
    //tab 1
    'formCombination', 'formOpenJaw', 'formGeographyRestriction', 'formCombinationWith',

    //tab 2
    'formCombination2', 'formCircleTrip', 'formCombinationWith2',

    //tab 3
    'formCombination3', 'formRoundTrip', 'formCombinationWith3',

    //tab 4
    'formCombination4', 'formEndOnEnd', 'formCombinationWith4', 'formCombinationTextInformation',

    '$uibModalInstance', 'ContractValue'];

    function CombinationController (
    		$scope, $parse, $state, $stateParams, $uibModal,
    		ContractCombination, airlines, fare_type, fare_type_apf,
            routing_tariff_amadeus, routing_tariff_sabre,

    		//tab 1
    		formCombination, formOpenJaw, formGeographyRestriction, formCombinationWith,

    		//tab 2
    		formCombination2, formCircleTrip, formCombinationWith2,

    		//tab 3
    		formCombination3, formRoundTrip, formCombinationWith3,

    		//tab 4
    		formCombination4, formEndOnEnd, formCombinationWith4, formCombinationTextInformation,

    		$uibModalInstance, ContractValue) {

    	var vm = this;
    	vm.entity = ContractCombination.get({ id : $stateParams.id_contract });

    	//tab 1
    	vm.formCombination = formCombination;
		vm.formOpenJaw = formOpenJaw;
		vm.formGeographyRestriction = formGeographyRestriction;
		vm.formCombinationWith = formCombinationWith;

		vm.formCombination2 = formCombination2;
		vm.formCircleTrip = formCircleTrip;
		vm.formCombinationWith2 = formCombinationWith2;

		vm.formCombination3 = formCombination3;
		vm.formRoundTrip = formRoundTrip;
		vm.formCombinationWith3 = formCombinationWith3;

		vm.formCombination4 = formCombination4;
		vm.formEndOnEnd = formEndOnEnd;
		vm.formCombinationWith4 = formCombinationWith4;
		vm.formCombinationTextInformation = formCombinationTextInformation;

		vm.airlines = airlines;
		vm.fare_type = fare_type;
		vm.fare_type_apf = fare_type_apf;
        vm.routing_tariff_sabre = routing_tariff_sabre;
        vm.routing_tariff_amadeus = routing_tariff_amadeus;

		vm.id_contract = $stateParams.id_contract;

		vm.index = $stateParams.index;
        vm.operation = $stateParams.operation;
        vm.isSaving = false;

        if(vm.operation == 'edit') {
            vm.entity.$promise.then(function (contract) {
                vm.combinationRules = contract.data[15].pages;
                vm.editCombination(vm.combinationRules[vm.index]);
            });
        }
		vm.Date = function(arg){
        	if(arg == null){
          	   return new Date();
        	}else{
          	   return new Date(arg);
        	}
     	};

     	//COMBINATION
		vm.addCombination = function(){
			vm.formCombination = formCombination;
			vm.formOpenJaw = formOpenJaw;
			vm.formGeographyRestriction = formGeographyRestriction;
			vm.formCombinationWith = formCombinationWith;

			vm.formCombination2 = formCombination2;
			vm.formCircleTrip = formCircleTrip;
			vm.formCombinationWith2 = formCombinationWith2;

			vm.formCombination3 = formCombination3;
			vm.formRoundTrip = formRoundTrip;
			vm.formCombinationWith3 = formCombinationWith3;

			vm.formCombination4 = formCombination4;
			vm.formEndOnEnd = formEndOnEnd;
			vm.formCombinationWith4 = formCombinationWith4;
			vm.formCombinationTextInformation = formCombinationTextInformation;

			vm.entity.data[15].pages.push({
				forms:[
					angular.copy(vm.formCombination),
					angular.copy(vm.formCombinationWith),
					angular.copy(vm.formOpenJaw),
					angular.copy(vm.formGeographyRestriction),

					angular.copy(vm.formCombination2),
					angular.copy(vm.formCombinationWith2),
					angular.copy(vm.formCircleTrip),

					angular.copy(vm.formCombination3),
					angular.copy(vm.formCombinationWith3),
					angular.copy(vm.formRoundTrip),

					angular.copy(vm.formCombination4),
					angular.copy(vm.formCombinationWith4),
					angular.copy(vm.formEndOnEnd),
					angular.copy(vm.formCombinationTextInformation),
				]
			});
		};

		vm.editCombination= function(x){
		    vm.formCombination.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.formCombination);
            });
		    vm.formCombinationWith.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[1], vm.formCombinationWith);
            });
		    vm.formOpenJaw.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[2], vm.formOpenJaw);
            });
		    vm.formGeographyRestriction.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[3], vm.formGeographyRestriction);
            });
		    vm.formCombination2.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[4], vm.formCombination2);
            });
		    vm.formCombinationWith2.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[5], vm.formCombinationWith2);
            });
		    vm.formCircleTrip.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[6], vm.formCircleTrip);
            });
		    vm.formCombination3.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[7], vm.formCombination3);
            });
		    vm.formCombinationWith3.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[8], vm.formCombinationWith3);
            });
		    vm.formRoundTrip.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[9], vm.formRoundTrip);
            });
		    vm.formCombination4.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[10], vm.formCombination4);
            });
		    vm.formCombinationWith4.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[11], vm.formCombinationWith4);
            });
		    vm.formEndOnEnd.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[12], vm.formEndOnEnd);
            });
		    vm.formCombinationTextInformation.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[13], vm.formCombinationTextInformation);
            });

            // ContractValue.setValueFromDatabase(x.forms[0], vm.formCombination);
            // ContractValue.setValueFromDatabase(x.forms[1], vm.formCombinationWith);
            // ContractValue.setValueFromDatabase(x.forms[2], vm.formOpenJaw);
            // ContractValue.setValueFromDatabase(x.forms[3], vm.formGeographyRestriction);
            // ContractValue.setValueFromDatabase(x.forms[4], vm.formCombination2);
            // ContractValue.setValueFromDatabase(x.forms[5], vm.formCombinationWith2);
            // ContractValue.setValueFromDatabase(x.forms[6], vm.formCircleTrip);
            // ContractValue.setValueFromDatabase(x.forms[7], vm.formCombination3);
            // ContractValue.setValueFromDatabase(x.forms[8], vm.formCombinationWith3);
            // ContractValue.setValueFromDatabase(x.forms[9], vm.formRoundTrip);
            // ContractValue.setValueFromDatabase(x.forms[10], vm.formCombination4);
            // ContractValue.setValueFromDatabase(x.forms[11], vm.formCombinationWith4);
            // ContractValue.setValueFromDatabase(x.forms[12], vm.formEndOnEnd);
            // ContractValue.setValueFromDatabase(x.forms[13], vm.formCombinationTextInformation);

			vm.isCombinationEdit = true;
		};

		//END COMBINATION

		vm.pushCombined = function(x, y){
        	if(x.value == null){
        		x.value = [];
        	}
        	x.value.push(y);
        	var z = angular.copy(x.value);
        	x.value = z;
        };

        vm.removeCombined = function(item, y){
        	item.value.splice(y, 1);
        	var z = angular.copy(item.value);
        	item.value = z;
        }

        vm.pushGeography = function(x, y){
        	if(x.value == null){
        		x.value = [];
        	}
        	x.value.push(y);
        	var z = angular.copy(x.value);
        	x.value = z;
        };

        vm.removeGeography = function(item, y){
        	item.value.splice(y, 1);
        	var z = angular.copy(item.value);
        	item.value = z;
        }

        vm.checkGeographyShow = function(){

        	var val = vm.formGeographyRestriction.form_items[0].value;
        	if(val == "Between"){
        		return true;
        	}

        	return false;
        }

		vm.save = function(){
            vm.isSaving = true;

            var forms = [vm.formCombination, vm.formCombinationWith, vm.formOpenJaw, vm.formGeographyRestriction,
                vm.formCombination2, vm.formCombinationWith2, vm.formCircleTrip,
                vm.formCombination3, vm.formCombinationWith3, vm.formRoundTrip,
                vm.formCombination4, vm.formCombinationWith4, vm.formEndOnEnd, vm.formCombinationTextInformation
            ];

            if (vm.operation == 'edit' && vm.isCombinationEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractCombination.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractCombination.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.checkValueDropdown = function(value, options){
            console.log("Check value: "+value);
            console.log("Check options: "+options);
            if(value == null || value == "" || value == undefined){
                return options;
            } else {
                return value;
            }
        };

        vm.copyCombined = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditOpenJawCombinedWith == undefined || vm.isEditOpenJawCombinedWith == null) {
        		vm.isEditOpenJawCombinedWith = {}
        	}

        	vm.isEditOpenJawCombinedWith[newItemIndex] = true;
        };

        vm.copyCombinedCircle = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditCircleTripCombinedWith == undefined || vm.isEditCircleTripCombinedWith == null) {
        		vm.isEditCircleTripCombinedWith = {}
        	}

        	vm.isEditCircleTripCombinedWith[newItemIndex] = true;
        };

        vm.copyCombinedRound = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditCombinedWith == undefined || vm.isEditCombinedWith == null) {
        		vm.isEditCombinedWith = {}
        	}

        	vm.isEditCombinedWith[newItemIndex] = true;
        };

        vm.copyCombinedEnd = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditEndOnEndWith == undefined || vm.isEditEndOnEndWith == null) {
        		vm.isEditEndOnEndWith = {}
        	}

        	vm.isEditEndOnEndWith[newItemIndex] = true;
        };

        vm.copyGeography = function(item, y){
        	vm.data = angular.copy(item.value[y]);
        	item.value.push(vm.data);

        	var newItemIndex = item.value.length - 1;

        	if (vm.isEditGeography == undefined || vm.isEditGeography == null) {
        		vm.isEditGeography = {}
        	}

        	vm.isEditGeography[newItemIndex] = true;
        };


    }
})();
