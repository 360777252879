(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FieldPageManageController', FieldPageManageController);

    FieldPageManageController.$inject = ['$scope', '$state', '$stateParams', 'pagingParams', 'AlertService', 'formPage', 'Fields', 'ParseLinks', 'FormPage'];

    function FieldPageManageController($scope, $state, $stateParams, pagingParams, AlertService, formPage, Fields, ParseLinks, FormPage) {
        var vm = this;
        vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;
        vm.formPage = formPage;
        
        vm.loadAll = loadAll;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.index=0;
        vm.loadAll();
        
        function loadAll () {
            Fields.query({
            	tag:$stateParams.tag,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.fields = data;
                vm.page = pagingParams.page;
                
                vm.lastIndex = vm.queryCount - 1;
                vm.index = vm.page - 1;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function transition () {
            $state.transitionTo($state.$current, { 
            	id:$stateParams.id,
            	tag:$stateParams.tag,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }
        
        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:fieldPageManage', result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };
    }
})();
