(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('ActingAsDetailController', ActingAsDetailController);

    ActingAsDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'ActingAs'];

    function ActingAsDetailController($scope, $rootScope, $stateParams, previousState, entity, ActingAs) {
        var vm = this;

        vm.actingAs = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('americanoApp:actingAsUpdate', function(event, result) {
            vm.actingAs = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
