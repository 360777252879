(function() {
	'use strict';
	angular.module('americanoApp').factory('AddOn', AddOn);

	AddOn.$inject = [ '$resource' ];

	function AddOn($resource) {
		var resourceUrl = 'api/addOns/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					data = angular.fromJson(data);
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			},
			'getAPF' : {
				method : 'GET',
				isArray:true,
				url:"api/addOns/APF"
			},
			'getAmadeus' : {
				method : 'GET',
				isArray:true,
				url:"api/addOns/Amadeus"
			},
			'publish': {method:'POST', url : 'api/addOns/publish/:id'}
		});
	}
	
	
	angular.module('americanoApp').factory('AddOnSearch', AddOnSearch);

	AddOnSearch.$inject = ['$resource'];
		
		function AddOnSearch($resource) {
		    var resourceUrl = 'api/addOns/search/:query/:type';
		    return $resource(resourceUrl, {}, {
		        'query': { method: 'GET', isArray: true}
		    });
		}

	angular.module('americanoApp').factory('AddOnSearch2', AddOnSearch2);

	AddOnSearch2.$inject = ['$resource'];
		
		function AddOnSearch2($resource) {
		    var resourceUrl = 'api/addOns/search2/:query/:type';
		    return $resource(resourceUrl, {}, {
		        'query': { method: 'GET', isArray: true}
		    });
		}


})();
