(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormDeleteController',FormDeleteController);

    FormDeleteController.$inject = ['$uibModalInstance', 'entity', 'Form'];

    function FormDeleteController($uibModalInstance, entity, Form) {
        var vm = this;
        vm.form = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            Form.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
