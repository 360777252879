(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FlightApplicationController', FlightApplicationController);

    FlightApplicationController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', '$uibModal',
    'ContractFlightApplication', 'airlines',
    'travelSegmentIndicatorSabre',

    'formFlightApps',
    'formFlightAppsGeographic',

    'formTextInformation',
    'form13', 'form14', '$uibModalInstance', 'ContractValue'];

    function FlightApplicationController (
    		$scope, $parse, $state, $stateParams, $uibModal,
    		ContractFlightApplication, airlines,
    		travelSegmentIndicatorSabre,

    		formFlightApps,
    		formFlightAppsGeographic,
    		formTextInformation,
    		form13, form14, $uibModalInstance, ContractValue) {

    	var vm = this;
    	vm.airlines = airlines;
    	vm.entity = ContractFlightApplication.get({ id : $stateParams.id_contract });
    	vm.form13 = form13;
		vm.form14 = form14;
		vm.formFlightApps = formFlightApps;
		vm.formFlightAppsGeographic = formFlightAppsGeographic;
		vm.formTextInformation = formTextInformation;

		vm.travelSegmentIndicatorSabre = travelSegmentIndicatorSabre;
		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;

		vm.entity.$promise.then(function(contract) {
    		vm.flightAppRules = contract.data[5].pages;

    		if (vm.index < vm.flightAppRules.length)
    			vm.editFlightApplication(vm.flightAppRules[vm.index]);
    	});

		//FLIGHT APPLICATION
		vm.addFlightApplication = function(){
			vm.entity.data[5].pages.push({
				forms:[
					angular.copy(vm.form13),
					angular.copy(vm.form14),
					angular.copy(vm.formFlightApps),
					angular.copy(vm.formFlightAppsGeographic),
					angular.copy(vm.formTextInformation)
				]
			});
			console.log(vm.entity.data[5]);
		}

		vm.editFlightApplication = function(x){
			// vm.form13 = angular.copy(x.forms[0]);
			// vm.form14 = angular.copy(x.forms[1]);
			// vm.formFlightApps = angular.copy(x.forms[2]);
			// vm.formFlightAppsGeographic = angular.copy(x.forms[3]);
			// vm.formTextInformation = angular.copy(x.forms[4]);

            vm.form13.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form13);
            });
            vm.form14.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[1], vm.form14);
            });
            vm.formFlightApps.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[2], vm.formFlightApps);
            });
            vm.formFlightAppsGeographic.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[3], vm.formFlightAppsGeographic);
            });
            vm.formTextInformation.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[4], vm.formTextInformation);
            });
            // ContractValue.setValueFromDatabase(x.forms[0], vm.form13);
            // ContractValue.setValueFromDatabase(x.forms[1], vm.form14);
            // ContractValue.setValueFromDatabase(x.forms[2], vm.formFlightApps);
            // ContractValue.setValueFromDatabase(x.forms[3], vm.formFlightAppsGeographic);
            // ContractValue.setValueFromDatabase(x.forms[4], vm.formTextInformation);

			vm.editObjectFlightApplication= x;
			vm.isFlightApplicationEdit = true;
		};

		vm.saveFlightApplicationEdit = function(){
            vm.entity.data[5].pages[vm.entity.data[5].pages.indexOf(vm.editObjectFlightApplication)].forms[0] = angular.copy(vm.form13);
			vm.entity.data[5].pages[vm.entity.data[5].pages.indexOf(vm.editObjectFlightApplication)].forms[1] = angular.copy(vm.form14);
			vm.entity.data[5].pages[vm.entity.data[5].pages.indexOf(vm.editObjectFlightApplication)].forms[2] = angular.copy(vm.formFlightApps);
			vm.entity.data[5].pages[vm.entity.data[5].pages.indexOf(vm.editObjectFlightApplication)].forms[3] = angular.copy(vm.formFlightAppsGeographic);
			vm.entity.data[5].pages[vm.entity.data[5].pages.indexOf(vm.editObjectFlightApplication)].forms[4] = angular.copy(vm.formTextInformation);

			vm.editObjectFlightApplication= null;
			vm.isFlightApplicationEdit = false;
		};
		//END FLIGHT APPLICATION

		//CODE SHARE
		vm.pushDataCodeShare = function(x, y){
			if(y != null){
				for(var z=0;z<y.length;z++){
	    			y[z].value = vm.dataFlightRestriction[y[z].tag];
	        	}
			}

			if(y[0].value != undefined && vm.form14.form_items[0].value != ""){
                if(vm.form14.form_items[x].value == null){
                    vm.form14.form_items[x].value = [];
                }
                else{
                }

                vm.form14.form_items[x].value.push(y);
                var z = angular.copy(vm.form14.form_items[x].value);
                vm.form14.form_items[x].value = z;

                vm.dataFlightRestriction = [];
            }

        };

        vm.removeDataCodeShare = function(x, y){
        	vm.form14.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form14.form_items[x].value);
        	vm.form14.form_items[x].value = z;
        }
		//END CODE SHARE

        //Start Geo Spec
        vm.pushDataFlightApplicationGeoSpec = function(x, y){
        	if(x.value == null){
        		x.value = [];
        	}

        	
//        	if(y[2].value != null){
                if(vm.formFlightAppsGeographic.form_items[0].value != "" && vm.formFlightAppsGeographic.form_items[1].value != ""){
                    x.value.push(y);
                    var z = angular.copy(x.value);
                    x.value = z;

                    angular.forEach(y, function(data){
                        data.value = "";
                    });
                }
//            }
        	
                //field gabung option dan option type
//                if(y[4].value != null && y[4].value != "" && y[0].value != "" && y[1].value != ""){
//                    x.value.push(y);
//                    var z = angular.copy(x.value);
//                    x.value = z;
//
//                    angular.forEach(y, function(data){
//                        data.value = "";
//                    });
//                }
        };

        vm.removeDataFlightApplicationGeoSpec = function(item, y){
        	item.value.splice(y, 1);
        	var z = angular.copy(item.value);
        	item.value = z;
        }
        
        
        vm.copyDataFlightApplicationGeoSpec = function(item, y){
        	vm.data = angular.copy(item.value[y]);        	
        	item.value.push(vm.data);
        	
        	var newItemIndex = item.value.length - 1;        	
        	
        	if (vm.isEditGeospec == undefined || vm.isEditGeospec == null) {
        		vm.isEditGeospec = {}
        	}        	
        	
        	vm.isEditGeospec[newItemIndex] = true;
        };
        //End Geo Spec

        //FLIGHT APPLICATION
        vm.pushDataFlightApplication = function(x, y){
        	if(x.value == null){
        		x.value = [];
        	}
        	x.value.push(y);
        	var z = angular.copy(x.value);
        	x.value = z;
        };

        vm.removeDataFlightApplication = function(x, y){
        	x.value.splice(y, 1);
        	var z = angular.copy(x.value);
        	x.value = z;
        };
        //End Flight Application

        vm.pushDataFlightApplicationRange = function(x, y){
            if(y != null){
            	for(var x=0;x<y.length;x++){
        			y[x].value = vm.dataFlightApplicationRange[y[x].tag];
            	}
            }

        	if(y[0].value != undefined && vm.form13.form_items[2].value != ""){
                if(vm.form13.form_items[x].value == null){
                    vm.form13.form_items[x].value = [];
                }

                vm.form13.form_items[x].value.push(y);
                var z = angular.copy(vm.form13.form_items[x].value);
                vm.form13.form_items[x].value = z;

                vm.dataFlightApplicationRange = [];
            }

        };

        vm.removeMultivalue = function(item, y){
        	item.value.splice(y, 1);
        	var z = angular.copy(item.value);
        	item.value = z;
        }
        
        vm.copyMultivalue = function(item, y){
        	vm.data = angular.copy(item.value[y]);        	
        	item.value.push(vm.data);
        	
        	var newItemIndex = item.value.length - 1;        	
        	
        	if (vm.isEditFlightNumberRestriction == undefined || vm.isEditFlightNumberRestriction == null) {
        		vm.isEditFlightNumberRestriction = {}
        	}        	
        	
        	vm.isEditFlightNumberRestriction[newItemIndex] = true;
        };
        
        vm.copyMultivaluex = function(item, y){
        	vm.data = angular.copy(item.value[y]);        	
        	item.value.push(vm.data);
        	
        	var newItemIndex = item.value.length - 1;        	
        	
        	if (vm.isEditFlightRestriction == undefined || vm.isEditFlightRestriction == null) {
        		vm.isEditFlightRestriction = {}
        	}        	
        	
        	vm.isEditFlightRestriction[newItemIndex] = true;
        };
        //END FLIGHT APPLICATION

        vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
    	};

		vm.save = function(){
            var forms = [vm.form13, vm.form14, vm.formFlightApps, vm.formFlightAppsGeographic, vm.formTextInformation];

            if (vm.isFlightApplicationEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractFlightApplication.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractFlightApplication.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isFlightApplicationEdit)
				// vm.saveFlightApplicationEdit();
            // else
				// vm.addFlightApplication();
            //
            // ContractFlightApplication.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.checkError = function(tag, forms) {
        	for (var i = 0; i < forms.length; i++) {
        		if (forms[i].tag == tag) {
        			if (forms[i].errorAPF.length > 0)
        				return true;
        		}
        	}

        	return false;
        }

        vm.checkValueApplyTo = function(value) {
        	if (!vm.isFlightApplicationEdit) {
        		if (value == null || value == "" || value == undefined)
                    return "Outbound & Inbound";
        	}

            return value;
        }

        vm.checkValueOption = function(value) {
        	if (!vm.isFlightApplicationEdit) {
	            if (value == null || value == "" || value == undefined)
	                return "Must Be";
        	}

            return value;
        }

        vm.resetFlightRestriction = function() {
            vm.formFlightApps.form_items[0].value = null;
        }

        vm.resetRelationship = function() {
            vm.formFlightAppsGeographic.form_items[3].value = null;
        }

        vm.checkValueFlightRestriction = function(value, options){
            if(value == null || value == "" || value == undefined){
                return options;
            } else {
                return value;
            }
        };

        vm.checkValueGeospecOption = function(value, options){
            if(value == null || value == "" || value == undefined){
                return options;
            } else {
                return value;
            }
        };

        // vm.tagTab1 = ["rules_flight_application_text_information_only"];
        // vm.tagTabTextOnly = ["rules_flight_application_text_information_only", "rules_flight_application_note"];
    }
})();
