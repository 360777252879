(function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('distribution-group-master', {
            parent: 'entity',
            url: '/distribution-group-master?page&sort&search',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER'],
                pageTitle: 'americanoApp.distributionGroupMaster.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/distribution-group-master/distribution-group-masters.html',
                    controller: 'DistributionGroupMasterController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                travelSegmentIndicatorSabre : ['TravelSegIndicatorSabreCustom', function(TravelSegIndicatorSabreCustom) {
                    return TravelSegIndicatorSabreCustom.get();
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('distributionGroupMaster');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('distribution-group-master-detail', {
            parent: 'entity',
            url: '/distribution-group-master/{id}',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER'],
                pageTitle: 'americanoApp.distributionGroupMaster.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/distribution-group-master/distribution-group-master-detail.html',
                    controller: 'DistributionGroupMasterDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('distributionGroupMaster');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'DistributionGroupMaster', function($stateParams, DistributionGroupMaster) {
                    return DistributionGroupMaster.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'distribution-group-master',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('distribution-group-master-detail.edit', {
            parent: 'distribution-group-master-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/distribution-group-master/distribution-group-master-dialog.html',
                    controller: 'DistributionGroupMasterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DistributionGroupMaster', function(DistributionGroupMaster) {
                            return DistributionGroupMaster.get({id : $stateParams.id}).$promise;
                        }],
                        dataDistributionGroup: ['DistributionGroupCustom', function(DistributionGroupCustom) {
                            return DistributionGroupCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('distribution-group-master.new', {
            parent: 'distribution-group-master',
            url: '/new',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/distribution-group-master/distribution-group-master-dialog.html',
                    controller: 'DistributionGroupMasterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                nameMaster: null,
                                descriptionMaster: null,
                                gdsMaster: null,
                                form_items_child: [],
                                id: null
                            };
                        },
                        pagingParams : [
                            '$stateParams',
                            'PaginationUtil',
                            function($stateParams, PaginationUtil) {
                                return {
                                    page : PaginationUtil
                                        .parsePage($stateParams.page),
                                    sort : $stateParams.sort,
                                    predicate : PaginationUtil
                                        .parsePredicate($stateParams.sort),
                                    ascending : PaginationUtil
                                        .parseAscending($stateParams.sort),
                                    search : $stateParams.search
                                };
                            } ]
                    }
                }).result.then(function() {
                    $state.go('distribution-group-master', null, { reload: 'distribution-group-master' });
                }, function() {
                    $state.go('distribution-group-master');
                });
            }]
        })
        .state('distribution-group-master.edit', {
            parent: 'distribution-group-master',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/distribution-group-master/distribution-group-master-dialog.html',
                    controller: 'DistributionGroupMasterDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['DistributionGroupMaster', function(DistributionGroupMaster) {
                            return DistributionGroupMaster.get({id : $stateParams.id}).$promise;
                        }],
                        pagingParams : [
                            '$stateParams',
                            'PaginationUtil',
                            function($stateParams, PaginationUtil) {
                                return {
                                    page : PaginationUtil
                                        .parsePage($stateParams.page),
                                    sort : $stateParams.sort,
                                    predicate : PaginationUtil
                                        .parsePredicate($stateParams.sort),
                                    ascending : PaginationUtil
                                        .parseAscending($stateParams.sort),
                                    search : $stateParams.search
                                };
                            } ]
                    }
                }).result.then(function() {
                    $state.go('distribution-group-master', null, { reload: 'distribution-group-master' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('distribution-group-master.delete', {
            parent: 'distribution-group-master',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/distribution-group-master/distribution-group-master-delete-dialog.html',
                    controller: 'DistributionGroupMasterDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['DistributionGroupMaster', function(DistributionGroupMaster) {
                            return DistributionGroupMaster.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('distribution-group-master', null, { reload: 'distribution-group-master' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
