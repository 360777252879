(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('DayTimeController', DayTimeController);

    DayTimeController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', '$uibModal',
    'ContractDayTime',
    'travelSegIndicatorDaysTime',
    'travelSegmentIndicatorSabre', 'returnTravelFromAmadeus',
    'form1', 'form2', 'form3', 'ContractValue', '$uibModalInstance'];

    function DayTimeController (
    		$scope, $parse, $state, $stateParams, $uibModal,
    		ContractDayTime,
    		travelSegIndicatorDaysTime,
            travelSegmentIndicatorSabre, returnTravelFromAmadeus,
    		form1, form2, form3, ContractValue, $uibModalInstance) {

    	var vm = this;
    	vm.index = $stateParams.index;
    	vm.entity = ContractDayTime.get({ id : $stateParams.id_contract });
		vm.form1 = form1;
		vm.form2 = form2;
		vm.form3 = form3;
		vm.travelSegIndicatorDaysTime = travelSegIndicatorDaysTime;
		vm.travelSegmentIndicatorSabre = travelSegmentIndicatorSabre;
		vm.returnTravelFromAmadeus = returnTravelFromAmadeus;
		vm.id_contract = $stateParams.id_contract;

		vm.entity.$promise.then(function(contract) {
    		vm.DayTimeRules = contract.data[14].pages;

    		if (vm.index < vm.DayTimeRules.length)
    			vm.editDayTime(vm.DayTimeRules[vm.index]);
    	});

        vm.travelSegIndicatorDaysTime.$promise.then(function (tsiapf) {
            tsiapf.forEach(function (varapf) {
                if(varapf.travel_segment_indicator == "Departure of the First International Sector of Each Fare Component" && vm.form2 != undefined){
                    vm.form2.$promise.then(function(form){
                        for(var i=0; i < form.form_items.length; i++) {
                            if(form.form_items[i].tag == 'rules_days_time_applicable_to_apf') {
                                form.form_items[i].value = varapf;
                                break;
                            }
                        }
                    });
                }
            });
        });

		// DAY TIME
		vm.addDayTime = function(){
			vm.entity.data[14].pages.push({
				forms:[
				    angular.copy(vm.form1),
				    angular.copy(vm.form2),
				    angular.copy(vm.form3)
				]
			});
		};

		vm.editDayTime = function(x){
		    vm.form1.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            });
		    vm.form2.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[1], vm.form2);
            });
		    vm.form3.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[2], vm.form3);
            });
            // ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            // ContractValue.setValueFromDatabase(x.forms[1], vm.form2);
            // ContractValue.setValueFromDatabase(x.forms[2], vm.form3);
			// vm.form1 = angular.copy(x.forms[0]);
			// vm.form2 = angular.copy(x.forms[1]);
			// vm.form3 = angular.copy(x.forms[2]);

			vm.editObjectDayTime = x;
			vm.isDayTimeEdit = true;
		};

		vm.saveDayTimeEdit = function(){
			vm.entity.data[14].pages[vm.entity.data[14].pages.indexOf(vm.editObjectDayTime)].forms[0] = angular.copy(vm.form1);
			vm.entity.data[14].pages[vm.entity.data[14].pages.indexOf(vm.editObjectDayTime)].forms[1] = angular.copy(vm.form2);
			vm.entity.data[14].pages[vm.entity.data[14].pages.indexOf(vm.editObjectDayTime)].forms[2] = angular.copy(vm.form3);
			vm.editObjectDayTime = null;
			vm.isDayTimeEdit = false;
		};

		vm.save = function(){
            var forms = [vm.form1, vm.form2, vm.form3];

            if (vm.isDayTimeEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractDayTime.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractDayTime.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isDayTimeEdit)
				// vm.saveDayTimeEdit();
            // else
				// vm.addDayTime();
            //
            // ContractDayTime.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };
		// END DAY TIME

        vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
    	};

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        //Time
        $scope.ismeridian = false;
        $scope.hstep = 1;
        $scope.mstep = 15;

        $scope.options = {
          hstep: [1, 2, 3],
          mstep: [1, 5, 10, 15, 25, 30]
        };

        $scope.toggleMode = function() {
          $scope.ismeridian = ! $scope.ismeridian;
        };

        $scope.update = function() {
          var d = new Date();
          d.setHours( 14 );
          d.setMinutes( 0 );
          $scope.mytime = d;
        };

        $scope.changed = function () {
//          $log.log('Time changed to: ' + $scope.mytime);
        };

        $scope.clear = function() {
          $scope.mytime = null;
        };

        vm.checkValueApplyTo = function(value) {
        	if (!vm.isDayTimeEdit) {
        		if (value == null || value == "" || value == undefined)
                    return "Outbound and Inbound";
        	}

            return value;
        }

        vm.checkApplicableTo = function(value, defaultValue) {
            if (value != null || value != "" || value != undefined)
                return defaultValue;

            return value;
        }

        vm.resetTime = function(){
            vm.form2.form_items[0].value = null;
            vm.form2.form_items[1].value = null;
        }

        vm.resetCondition = function() {
            vm.form3.form_items[0].value = null;
        }

        vm.resetReturnOnSameDay = function() {
            vm.form3.form_items[1].value = null;
        }

    }
})();
