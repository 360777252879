(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('ZonaWaktu', ZonaWaktu);

    ZonaWaktu.$inject = ['$resource'];

    function ZonaWaktu ($resource) {
        var resourceUrl =  'api/zona-waktus/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
    .module('americanoApp')
    .factory('TimeZOneCustom', TimeZOneCustom);

    TimeZOneCustom.$inject = ['$resource'];

	function TimeZOneCustom($resource) {
		var resourceUrl =  'api/zona-waktus/getAllTime';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	
})();
