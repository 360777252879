(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormRoutingCopyDialogController', FormRoutingCopyDialogController);

    FormRoutingCopyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'Routing'];

    function FormRoutingCopyDialogController ($scope, $stateParams, $uibModalInstance, Routing) {
    	var vm = this; 
    	vm.id = $stateParams.id;
    	vm.index = $stateParams.index;
    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };
        
        vm.confirmCopy = function (id) {
        	Routing.get({id : $stateParams.id}, function(data) {
        		data.id = null;
        		
        		Routing.save(data, function(result) {$uibModalInstance.close(result);}, function(error) {});
        	});
	    };
    }
})();