(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('MaximumStayController', MaximumStayController);

    MaximumStayController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', '$uibModal',
    'ContractMaximumStay', 'stayMeasuredFromApfMaximumStay','stayMeasuredFromAmadeus',
     //    'stayMeasuredFromSabre',
	// 'returnTravelFromSabre',
        'travelSegmentIndicatorSabre',
        'returnTravelFromAmadeus', 'returnTravelFromApfMaximumStay',
	'currencies', 'form1', 'ContractValue', '$uibModalInstance'];

    function MaximumStayController (
    		$scope, $parse, $state, $stateParams, $uibModal,
    		ContractMaximumStay, stayMeasuredFromApfMaximumStay,stayMeasuredFromAmadeus,
            // stayMeasuredFromSabre,
    		// returnTravelFromSabre,
            travelSegmentIndicatorSabre,
            returnTravelFromAmadeus, returnTravelFromApfMaximumStay,
    		currencies, form1, ContractValue, $uibModalInstance) {

    	var vm = this;
    	vm.entity = ContractMaximumStay.get({ id : $stateParams.id_contract });
		vm.form1 = form1;
		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;

		vm.stayMeasuredFromApfMaximumStay = stayMeasuredFromApfMaximumStay;
		vm.stayMeasuredFromAmadeus = stayMeasuredFromAmadeus;

		vm.travelSegmentIndicatorSabre = travelSegmentIndicatorSabre;

		// vm.stayMeasuredFromSabre = stayMeasuredFromSabre;
		// vm.returnTravelFromSabre = returnTravelFromSabre;
		vm.returnTravelFromAmadeus = returnTravelFromAmadeus;
		vm.returnTravelFromApfMaximumStay = returnTravelFromApfMaximumStay;
		vm.currencies = currencies;

		vm.entity.$promise.then(function(contract) {
    		vm.maximumStayRules = contract.data[12].pages;

    		if (vm.index < vm.maximumStayRules.length)
    			vm.editMaximumStay(vm.maximumStayRules[vm.index]);
    	});
		
//		console.log("apabae", vm.form1)
		
		vm.travelSegmentIndicatorSabre.$promise.then(function(rtfs) {
            rtfs.forEach(function(rtf) {
//                console.log("apa", rtf)
//                console.log(form1)
                if (rtf.travelSegmentIndicatorSabreID == "1"  && vm.form1 != undefined) {
                    vm.form1.$promise.then(function(form) {
                        for (var i = 0; i < form.form_items.length; i++) {
                            if (form.form_items[i].tag == "rules_maximum_stay_measured_from_sabre") {
                                form.form_items[i].value = rtf;
//                            	console.log("form3", vm.form3.label)
//                            	console.log("lalala", form3.form_items[i]);
                            }
                        }
                    });
                }
                if (rtf.travelSegmentIndicatorSabreID == "5"  && vm.form1 != undefined) {
                    vm.form1.$promise.then(function(form) {
                        for (var i = 0; i < form.form_items.length; i++) {
                            if (form.form_items[i].tag == "rules_maximum_stay_return_travel_from_sabre") {
                                form.form_items[i].value = rtf;
//                            	console.log("form3", vm.form3.label)
//                            	console.log("lalala", form3.form_items[i]);
                            }
                        }
                    });
                }
            });
        });

		
		
		vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
	    	};

     	//MAXIMUM STAY
     	vm.addMaximumStay = function(){
			vm.entity.data[12].pages.push({
				forms:[
					angular.copy(vm.form1)
				]
			});
		};

		vm.editMaximumStay = function(x){
		    vm.form1.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            });
            // ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
			// vm.form1 = angular.copy(x.forms[0]);

			vm.editObjectMaximumStay = x;
			vm.isMaximumStayEdit = true;
		};

		vm.saveMaximumStayEdit = function(){
			vm.entity.data[12].pages[vm.entity.data[12].pages.indexOf(vm.editObjectMaximumStay)].forms[0] = angular.copy(vm.form1);
			vm.editObjectMaximumStay = null;
			vm.isMaximumStayEdit = false;
		};

		vm.save = function(){
            var forms = [vm.form1];

            if (vm.isMaximumStayEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractMaximumStay.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractMaximumStay.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            // if (vm.isMaximumStayEdit)
				// vm.saveMaximumStayEdit();
            // else
				// vm.addMaximumStay();
            //
            // ContractMaximumStay.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.resetMaximumStayRelationship = function() {
            vm.form1.form_items[13].value = null;
        };

    }
})();
