(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('InfoTextDialogController', InfoTextDialogController);

    InfoTextDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'InfoText'];

    function InfoTextDialogController ($scope, $stateParams, $uibModalInstance, entity, InfoText) {
        var vm = this;
        vm.infoText = entity;
        vm.load = function(id) {
            InfoText.get({id : id}, function(result) {
                vm.infoText = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:infoTextUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.infoText.id !== null) {
                InfoText.update(vm.infoText, onSaveSuccess, onSaveError);
            } else {
                InfoText.save(vm.infoText, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
