(function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('contract.rule-applications', {
            parent: 'entity',
            url: '/contract/rule-applications/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/rule-application/rule-applications.html',
                    controller: 'RuleApplicationsController',
                    controllerAs: 'vm'
                }
            },
            params : {
                page : {
                    value : '1',
                    squash : true
                },
                sort : {
                    value : 'id,desc',
                    squash : true
                },
                search : null,
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractRuleApplicationOnly', function($stateParams, ContractRuleApplicationOnly) {
					return ContractRuleApplicationOnly.get({
						id : $stateParams.id_contract
					});
				}],
                pagingParams : [
                    '$stateParams',
                    'PaginationUtil',
                    function($stateParams, PaginationUtil) {
                        return {
                            page : PaginationUtil
                                .parsePage($stateParams.page),
                            sort : $stateParams.sort,
                            predicate : PaginationUtil
                                .parsePredicate($stateParams.sort),
                            ascending : PaginationUtil
                                .parseAscending($stateParams.sort),
                            search : $stateParams.search
                        };
                    } ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.rule-application', {
            parent: 'contract.rule-applications',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/rule-application/rule-application.html',
                    controller: 'RuleApplicationController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	entity : [ '$stateParams', 'ContractRuleApplicationOnly', function($stateParams, ContractRuleApplicationOnly) {
     				 		return ContractRuleApplicationOnly.get({
     				 			id : $stateParams.id_contract
     				 		});
     				 	}],
                    	form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_rule_application"
        					});
        				}],
        				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('contract.rule-applications', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.rule-applications', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.rule-application.delete', {
            parent : 'contract.rule-applications',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/rule-application/rule-application-delete-dialog.html',
                    controller : 'RuleApplicationDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.rule-applications', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.rule-applications', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.flight-applications', {
            parent: 'entity',
            url: '/contract/flight-applications/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/flight-application/flight-applications.html',
                    controller: 'FlightApplicationsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractFlightApplication', function($stateParams, ContractFlightApplication) {
					return ContractFlightApplication.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.flight-application', {
            parent: 'contract.flight-applications',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/flight-application/flight-application.html',
                    controller: 'FlightApplicationController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
        				airlines : [ 'AirlineCustom', function(AirlineCustom) {
        					return AirlineCustom.get();
        				}],
                        travelSegmentIndicatorSabre : ['TravelSegIndicatorSabre', function(TravelSegIndicatorSabre) {
                            return TravelSegIndicatorSabre.queryByCat({cat:"04"});
                        }],
        				formFlightApps : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_flight_application_flight_apps"
        					});
        				}],
        				formFlightAppsGeographic : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_flight_geographic_application"
        					});
        				}],
        			 	formTextInformation : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_flight_application_text_information"
        					});
        				}],
        				form13 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_flight_application_2"
        					});
        				}],
        				form14 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_flight_application_3"
        					});
        				}],
        				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                	$state.go('contract.flight-applications', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.flight-applications', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.flight-application.copy', {
            parent : 'contract.flight-applications',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                'Contract',
                function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/flight-application/flight-application-copy-dialog.html',
                    controller : 'FlightApplicationCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
                    function(result) {
                        $state.go('contract.flight-application',{
                            index: $stateParams.last_index
                        }, { reload : true });
                    }, function() { $state.go('^'); })
                }]
        })

        .state('contract.flight-application.delete', {
            parent : 'contract.flight-applications',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/flight-application/flight-application-delete-dialog.html',
                    controller : 'FlightApplicationDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.flight-applications', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.flight-applications', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.advance-reservations', {
            parent: 'entity',
            url: '/contract/advance-reservations/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/advance-reservation/advance-reservations.html',
                    controller: 'AdvanceReservationsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractAdvanceReservation', function($stateParams, ContractAdvanceReservation) {
					return ContractAdvanceReservation.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.advance-reservation', {
            parent: 'contract.advance-reservations',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/advance-reservation/advance-reservation.html',
                    controller: 'AdvanceReservationController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
        				form2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_advance_reservation_1"
        					});
        				}],
        				form3 : ['$stateParams', 'FormContract',function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_advance_reservation_2"
        					});
        				}],
        				form4 : ['$stateParams', 'FormContract',function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_advance_reservation_3"
        					});
        				}],
                        travelSegmentIndicatorSabre : ['TravelSegIndicatorSabre', function(TravelSegIndicatorSabre) {
                            return TravelSegIndicatorSabre.queryByCat({cat:"05"});
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.advance-reservations', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.advance-reservations', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.advance-reservation.copy', {
            parent : 'contract.advance-reservations',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                'Contract',
                function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/advance-reservation/advance-reservation-copy-dialog.html',
                    controller : 'AdvanceReservationCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
                    function(result) {
                        $state.go('contract.advance-reservation',{
                            index: $stateParams.last_index
                        }, { reload : true });
                    }, function() { $state.go('^'); })
                }]
        })

        .state('contract.advance-reservation.delete', {
            parent : 'contract.advance-reservations',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/advance-reservation/advance-reservation-delete-dialog.html',
                    controller : 'AdvanceReservationDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.advance-reservations', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.advance-reservations', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.blackouts', {
            parent: 'entity',
            url: '/contract/blackouts/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/blackout/blackouts.html',
                    controller: 'BlackoutsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractBlackout', function($stateParams, ContractBlackout) {
					return ContractBlackout.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.blackout', {
            parent: 'contract.blackouts',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/blackout/blackout.html',
                    controller: 'BlackoutController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
        				form5 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_blackouts_1"
        					});
        				}],
        				form6 : [ '$stateParams', 'FormContract',function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_blackouts_2"
        					});
        				}],
                        travelSegmentIndicatorSabre : ['TravelSegIndicatorSabre', function(TravelSegIndicatorSabre) {
                            return TravelSegIndicatorSabre.queryByCat({cat:"11"});
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.blackouts', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.blackouts', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.blackout.copy', {
			parent : 'contract.blackouts',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/blackout/blackout-copy-dialog.html',
							controller : 'BlackoutCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							resolve : { }}).result.then(
								function(result) {
									$state.go('contract.blackout',{
													index: $stateParams.last_index
												}, { reload : true });
								}, function() { $state.go('^'); })
							}]
		})

        .state('contract.blackout.delete', {
            parent : 'contract.blackouts',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/blackout/blackout-delete-dialog.html',
                    controller : 'BlackoutTravelDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.blackouts', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.blackouts', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.surcharges', {
            parent: 'entity',
            url: '/contract/surcharges/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/surcharge/surcharges.html',
                    controller: 'SurchargesController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractSurcharge', function($stateParams, ContractSurcharge) {
					return ContractSurcharge.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.surcharge', {
            parent: 'contract.surcharges',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/surcharge/surcharge.html',
                    controller: 'SurchargeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
        				form7 : [ '$stateParams', 'FormContract',function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_surcharges_1"
        					});
        				}],
        				form8 : [ '$stateParams', 'FormContract',function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_surcharges_2"
        					});
        				}],
        				form9 : [ '$stateParams', 'FormContract',function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_surcharges_3"
        					});
        				}],
        				form10 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_surcharges_4"
        					});
        				}],
        				form11 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_surcharges_5"
        					});
        				}],
        				currencies : [ 'CurrencyCustom',function(CurrencyCustom) {
        					return CurrencyCustom.get();
        				}],
        				surcharge_type : [ 'SurchargeTypeCustom',function(SurchargeTypeCustom) {
        					return SurchargeTypeCustom.get();
        				}],
        				airlines : [ 'AirlineCustom', function(AirlineCustom) {
        					return AirlineCustom.get();
        				}],
        				// travelSegmentIndicatorSabre : ['TravelSegIndicatorSabreCustom', function(TravelSegIndicatorSabreCustom) {
        				// 	return TravelSegIndicatorSabreCustom.get();
        				// }],
                        travelSegmentIndicatorSabre : ['TravelSegIndicatorSabre', function(TravelSegIndicatorSabre) {
                            return TravelSegIndicatorSabre.queryByCat({cat:"12"});
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.surcharges', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.surcharges', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.surcharge.copy', {
			parent : 'contract.surcharges',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/surcharge/surcharge-copy-dialog.html',
							controller : 'SurchargeCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							resolve : { }}).result.then(
								function(result) {
									$state.go('contract.surcharge',{
													index: $stateParams.last_index
												}, { reload : true });
								}, function() { $state.go('^'); })
							}]
		})

        .state('contract.surcharge.delete', {
            parent : 'contract.surcharges',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/surcharge/surcharge-delete-dialog.html',
                    controller : 'SurchargeDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.surcharges', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.surcharges', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.discounts', {
            parent: 'entity',
            url: '/contract/discounts/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/discount/discounts.html',
                    controller: 'DiscountsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractDiscount', function($stateParams, ContractDiscount) {
					return ContractDiscount.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.discount', {
            parent: 'contract.discounts',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/discount/discount.html',
                    controller: 'DiscountController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
        				form15 : [ '$stateParams', 'FormContract',function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_discounts_1"
        					});
        				}],
        				form16 : [ '$stateParams', 'FormContract',function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_discounts_2"
        					});
        				}],
        				form17 : [ '$stateParams', 'FormContract',function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_discounts_3"
        					});
        				}],
        				form18 : [ '$stateParams', 'FormContract',function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_discounts_4"
        					});
        				}],
        				currencies : [ 'CurrencyCustom',function(CurrencyCustom) {
        					return CurrencyCustom.get();
        				}],
        				discount_passenger_type : [ 'DiscountPassengerTypeCustom',function(DiscountPassengerTypeCustom) {
        					return DiscountPassengerTypeCustom.get();
        				}],
        				passenger_type : [ 'PassengerCustom',function(PassengerCustom) {
        					return PassengerCustom.get();
        				}],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.discounts', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.discounts', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.discount.copy', {
			parent : 'contract.discounts',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/discount/discount-copy-dialog.html',
							controller : 'DiscountCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							resolve : { }}).result.then(
								function(result) {
									$state.go('contract.discount',{
										index: result
									}, { reload : true });
								}, function() { $state.go('^'); })
							}]
		})

        .state('contract.discount.delete', {
            parent : 'contract.discounts',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/discount/discount-delete-dialog.html',
                    controller : 'DiscountDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.discounts', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.discounts', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.seasons', {
            parent: 'entity',
            url: '/contract/seasons/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/season/seasons.html',
                    controller: 'SeasonsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractSeason', function($stateParams, ContractSeason) {
					return ContractSeason.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.season', {
            parent: 'contract.seasons',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/season/season.html',
                    controller: 'SeasonController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
        				form19 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_session"
        					});
        				}],
        				travelSegmentIndicatorAmadeus : ['TravelSegIndicatorAmadeusCustom', function(TravelSegIndicatorAmadeusCustom) {
        					return TravelSegIndicatorAmadeusCustom.get();
        				}],
        				travelSegmentIndicatorAPF : ['TravelSegIndicatorAPFCustom',function(TravelSegIndicatorAPFCustom) {
        					return TravelSegIndicatorAPFCustom.get();
        				}],
                        travelSegmentIndicatorSabre : ['TravelSegIndicatorSabre', function(TravelSegIndicatorSabre) {
                            return TravelSegIndicatorSabre.queryByCat({cat:"03"});
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
        	            season_type : ['SeasonTypeCustom', function(SeasonTypeCustom) {
        					return SeasonTypeCustom.get();
        				}],
                    }
                }).result.then(function() {
                    $state.go('contract.seasons', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.seasons', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.season.copy', {
			parent : 'contract.seasons',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/season/season-copy-dialog.html',
							controller : 'SeasonCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							resolve : { }}).result.then(
								function(result) {
									$state.go('contract.season',{
													index: $stateParams.last_index
												}, { reload : true });
								}, function() { $state.go('^'); })
							}]
		})

        .state('contract.season.delete', {
            parent : 'contract.seasons',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/season/season-delete-dialog.html',
                    controller : 'SeasonDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.seasons', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.seasons', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.minimum-stays', {
            parent: 'entity',
            url: '/contract/minimum-stays/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/minimum-stay/minimum-stays.html',
                    controller: 'MinimumStaysController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractMinimumStay', function($stateParams, ContractMinimumStay) {
					return ContractMinimumStay.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.minimum-stay', {
            parent: 'contract.minimum-stays',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/contract/rules/minimum-stay/minimum-stay.html',
                    controller: 'MinimumStayController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	form1 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_minimum_stay_other_notes"
        					});
        				}],
        				form2 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_minimum_stay_condition"
        					});
        				}],
        				form3 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "	rules_minimum_stay_tsi_geo_spec"
        					});
        				}],
        				form4 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_minimum_stay_days_option"
        					});
        				}],
        				form5 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_minimum_stay_extension_surcharges"
        					});
        				}],
        				stayMeasuredFromApfMinimumStay : ['StayMeasurementFromMinAPFCustom', function(StayMeasurementFromMinAPFCustom) {
        					return StayMeasurementFromMinAPFCustom.get();
        				}],
        				stayMeasuredFromAmadeus : ['StayMeasurementFromAmadeusCustom', function(StayMeasurementFromAmadeusCustom) {
        					return StayMeasurementFromAmadeusCustom.get();
        				}],


                        travelSegmentIndicatorSabre : ['TravelSegIndicatorSabre', function(TravelSegIndicatorSabre) {
                            return TravelSegIndicatorSabre.queryByCat({cat:"06"});
                        }],
        				// stayMeasuredFromSabre : ['StayMeasurementFromSabreCustom', function(StayMeasurementFromSabreCustom) {
        				// 	return StayMeasurementFromSabreCustom.get();
        				// }],
                        // returnTravelFromSabre : ['ReturnTravelSabreCustom', function(ReturnTravelSabreCustom) {
        				// 	return ReturnTravelSabreCustom.get();
        				// }],


        	    		returnTravelFromAmadeus : ['ReturnTravelAmadeusCustom', function(ReturnTravelAmadeusCustom) {
        					return ReturnTravelAmadeusCustom.get();
        				}],
        	    		returnTravelFromApfMinimumStay : ['ReturnTravelMinStayAPFCustom', function(ReturnTravelMinStayAPFCustom) {
        					return ReturnTravelMinStayAPFCustom.get();
        				}],
        				currencies : [ 'CurrencyCustom', function(CurrencyCustom) {
        					return CurrencyCustom.get();
        				}],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.minimum-stays', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.minimum-stays', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]

        })

        .state('contract.minimum-stay.copy', {
			parent : 'contract.minimum-stays',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/minimum-stay/minimum-stay-copy-dialog.html',
							controller : 'MinimumStayCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							resolve : { }}).result.then(
								function(result) {
									$state.go('contract.minimum-stay',{
													index: $stateParams.last_index
												}, { reload : true });
								}, function() { $state.go('^'); })
							}]
		})

        .state('contract.minimum-stay.delete', {
            parent : 'contract.minimum-stays',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/minimum-stay/minimum-stay-delete-dialog.html',
                    controller : 'MinimumStayDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.minimum-stays', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.minimum-stays', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.maximum-stays', {
            parent: 'entity',
            url: '/contract/maximum-stays/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/maximum-stay/maximum-stays.html',
                    controller: 'MaximumStaysController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractMaximumStay', function($stateParams, ContractMaximumStay) {
					return ContractMaximumStay.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.maximum-stay', {
            parent: 'contract.maximum-stays',
            url: '/{index}',
            data: {authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],pageTitle : 'americanoApp.form.detail.title'},
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            	$uibModal.open({
            		templateUrl: 'app/entities/contract/rules/maximum-stay/maximum-stay.html',
            		controller: 'MaximumStayController',
            		controllerAs: 'vm',
            		backdrop: 'static',size: 'lg',
            		resolve: {
        				form1 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_maximum_stay"
        					});
        				}],
         				stayMeasuredFromApfMaximumStay : ['StayMeasurementFromMaxAPFCustom', function(StayMeasurementFromMaxAPFCustom) {
         					return StayMeasurementFromMaxAPFCustom.get();
         					}],
         				stayMeasuredFromAmadeus : ['StayMeasurementFromAmadeusCustom', function(StayMeasurementFromAmadeusCustom) {
         					return StayMeasurementFromAmadeusCustom.get();
         					}],

         				// stayMeasuredFromSabre : ['StayMeasurementFromSabreCustom', function(StayMeasurementFromSabreCustom) {
         				// 	return StayMeasurementFromSabreCustom.get();
         				// 	}],
                        // returnTravelFromSabre : ['ReturnTravelSabreCustom', function(ReturnTravelSabreCustom) {
                        // 	return ReturnTravelSabreCustom.get();
                        // 	}],

                        travelSegmentIndicatorSabre : ['TravelSegIndicatorSabre', function(TravelSegIndicatorSabre) {
                            return TravelSegIndicatorSabre.queryByCat({cat:"07"});
                        }],
         	    		returnTravelFromAmadeus : ['ReturnTravelAmadeusCustom', function(ReturnTravelAmadeusCustom) {
         	    			return ReturnTravelAmadeusCustom.get();
         	    			}],
         	    		returnTravelFromApfMaximumStay : ['ReturnTravelMaxStayAPFCustom', function(ReturnTravelMaxStayAPFCustom) {
         	    			return ReturnTravelMaxStayAPFCustom.get();
         	    			}],
         				currencies : [ 'CurrencyCustom', function(CurrencyCustom) {
         					return CurrencyCustom.get();
         					}],
                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        	 $translatePartialLoader.addPart('fare');
                             $translatePartialLoader.addPart('global');
                             return $translate.refresh();
                         }]
                     }
            	}).result.then(function() {
                    $state.go('contract.maximum-stays', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.maximum-stays', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.maximum-stay.copy', {
			parent : 'contract.maximum-stays',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/maximum-stay/maximum-stay-copy-dialog.html',
							controller : 'MaximumStayCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							resolve : { }}).result.then(
								function(result) {
									$state.go('contract.maximum-stay',{
													index: $stateParams.last_index
												}, { reload : true });
								}, function() { $state.go('^'); })
							}]
		})

        .state('contract.maximum-stay.delete', {
            parent : 'contract.maximum-stays',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/maximum-stay/maximum-stay-delete-dialog.html',
                    controller : 'MaximumStayDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.maximum-stays', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.maximum-stays', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.day-times', {
            parent: 'entity',
            url: '/contract/day-time/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/day-time/day-times.html',
                    controller: 'DayTimesController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractDayTime', function($stateParams, ContractDayTime) {
					return ContractDayTime.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.day-time', {
            parent: 'contract.day-times',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal){
            	$uibModal.open({
            		templateUrl: 'app/entities/contract/rules/day-time/day-time.html',
                    controller: 'DayTimeController',
                    controllerAs: 'vm',
                    backdrop: 'static', size: 'lg',
                    resolve: {
                    	form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "	rules_days_time_1"
        					});
        				}],
        				form2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "	rules_days_time_2"
        					});
        				}],
        				form3 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "	rules_days_time_3"
        					});
        				}],
        				travelSegIndicatorDaysTime : ['TravelSegIndicatorDaysTimeCustom',function(TravelSegIndicatorDaysTimeCustom) {
        					return TravelSegIndicatorDaysTimeCustom.get();
        				}],
        				// returnTravelFromSabre : ['ReturnTravelSabreCustom', function(ReturnTravelSabreCustom) {
        				// 	return ReturnTravelSabreCustom.get();
        				// }],
                        travelSegmentIndicatorSabre : ['TravelSegIndicatorSabre', function(TravelSegIndicatorSabre) {
                            return TravelSegIndicatorSabre.queryByCat({cat:"02"});
                        }],
        	    		returnTravelFromAmadeus : ['ReturnTravelAmadeusCustom', function(ReturnTravelAmadeusCustom) {
        					return ReturnTravelAmadeusCustom.get();
        				}],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
            	}).result.then(function(){
            		$state.go('contract.day-times', {id_contract:$stateParams.id_contract}, { reload: true });
            	}, function() {
            		$state.go('contract.day-times', {id_contract:$stateParams.id_contract}, { reload: true });
            	})
            }]
        })

        .state('contract.day-time.copy', {
            parent : 'contract.day-times',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                'Contract',
                function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/day-time/day-time-copy-dialog.html',
                    controller : 'DaytimeCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
                    function(result) {
                        $state.go('contract.day-time',{
                            index: $stateParams.last_index
                        }, { reload : true });
                    }, function() { $state.go('^'); })
                }]
        })

        .state('contract.day-times.delete', {
            parent : 'contract.day-times',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/day-time/day-time-delete-dialog.html',
                    controller : 'DayTimeDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.day-times', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.day-times', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.endorsements', {
            parent: 'entity',
            url: '/contract/endorsements/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/endorsement/endorsements.html',
                    controller: 'EndorsementsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractEndorsement', function($stateParams, ContractEndorsement) {
					return ContractEndorsement.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.endorsement', {
            parent: 'contract.endorsements',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            	$uibModal.open({
            		templateUrl: 'app/entities/contract/rules/endorsement/endorsement.html',
            		controller: 'EndorsementController',
            		controllerAs: 'vm',
            		backdrop: 'static',size: 'lg',
            		resolve: {
        				form1 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_endorsements"
        					});
        				}],
         				currencies : [ 'CurrencyCustom', function(CurrencyCustom) {
         					return CurrencyCustom.get();
         					}],
                         translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        	 $translatePartialLoader.addPart('fare');
                             $translatePartialLoader.addPart('global');
                             return $translate.refresh();
                         }]
                     }
            	}).result.then(function() {
                    $state.go('contract.endorsements', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.endorsements', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.endorsement.copy', {
            parent : 'contract.endorsements',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [ '$stateParams', '$state', '$uibModal', 'Contract',  function($stateParams, $state, $uibModal, Contract) {
            	$uibModal.open({
                    templateUrl : 'app/entities/contract/rules/endorsement/endorsement-copy-dialog.html',
                    controller : 'EndorsementCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
            		function(result) {
                        $state.go('contract.endorsement',{ index: $stateParams.last_index, operation:'edit'  },  { reload : true });
                    }, function() {
                    	$state.go('^');
                    })
                }]
        })

        .state('contract.endorsement.delete', {
            parent : 'contract.endorsements',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/endorsement/endorsement-delete-dialog.html',
                    controller : 'EndorsementDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.endorsements', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.endorsements', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.combinations', {
            parent: 'entity',
            url: '/contract/combinations/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/combination/combinations.html',
                    controller: 'CombinationsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractCombination', function($stateParams, ContractCombination) {
					return ContractCombination.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.combinations.copy', {
            parent : 'contract.combinations',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                'Contract',
                function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/combination/combination-copy-dialog.html',
                    controller : 'CombinationCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
            		function(result) {
                        $state.go('contract.combination',{
                            index: $stateParams.last_index,
                            operation:'edit'
                        }, { reload : true });
                    }, function() {  })
                }]
        })

        .state('contract.combination', {
            parent: 'contract.combinations',
            url: '/{operation}/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/combination/combination.html',
                    controller: 'CombinationController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	//Tab 1
                    	formCombination : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations"
        					});
        				}],

                    	formOpenJaw : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_open_jaw"
        					});
        				}],

                    	formCombinationWith : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_combined_with"
        					});
        				}],

        				formGeographyRestriction : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_geography_restrictions"
        					});
        				}],

                    	//Tab 2
        				formCombination2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_2"
        					});
        				}],

                    	formCircleTrip : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_circle_trip"
        					});
        				}],

                    	formCombinationWith2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_combined_with_2"
        					});
        				}],

        				//tab 3
        				formCombination3 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_3"
        					});
        				}],
        				formRoundTrip : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_round_trip"
        					});
        				}],
        				formCombinationWith3 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_combined_with_3"
        					});
        				}],

        				//tab 4
        				formCombination4 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_4"
        					});
        				}],
        				formEndOnEnd : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_end_on_end"
        					});
        				}],
        				formCombinationWith4 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_combined_with_4"
        					});
        				}],
        				formCombinationTextInformation  : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_combinations_text_information"
        					});
        				}],
        				// Additional
        				airlines : [ 'AirlineCustom', function(AirlineCustom) {
        					return AirlineCustom.get();
        				}],
        				fare_type : [ 'FareTypeCustom', function(FareTypeCustom) {
        					return FareTypeCustom.get();
        				}],
                        fare_type_apf : [
                            'FareTypeAPFCustom',
                            function(
                                FareTypeAPFCustom) {
                                return FareTypeAPFCustom
                                    .get();
                            }],
                        routing_tariff_amadeus : [
                            'RoutingTariffAmadeusCustom',
                            function(
                                RoutingTariffAmadeusCustom) {
                                return RoutingTariffAmadeusCustom
                                    .get();
                            } ],
                        routing_tariff_sabre : [
                            'RoutingTariffSabreCustom',
                            function(
                                RoutingTariffSabreCustom) {
                                return RoutingTariffSabreCustom
                                    .get();
                            } ],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.combinations', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.combinations', {id_contract:$stateParams.id_contract}, {});
                });
            }]
        })

        .state('contract.combinations.delete', {
            parent : 'contract.combinations',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/combination/combination-delete-dialog.html',
                    controller : 'CombinationDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.combinations', {id_contract:$stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.combinations', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.eligibilitys', {
                parent: 'entity',
                url: '/contract/eligibility/{id_contract}/{items_per_page}',
                data: {
                    authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                    pageTitle : 'americanoApp.form.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/contract/rules/eligibility/eligibilitys.html',
                        controller: 'EligibilitysController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    items_per_page: '5'
                },
                resolve: {
                    entity : [ '$stateParams', 'ContractEligibility', function($stateParams, ContractEligibility) {
                        return ContractEligibility.get({
                            id : $stateParams.id_contract
                        });
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fare');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

        .state('contract.eligibility', {
            parent: 'contract.eligibilitys',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/eligibility/eligibility.html',
                    controller: 'EligibilityController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	form24 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_eligibility"
        					});
                        }],
                        passengers:['PassengerCustom', function(PassengerCustom) {
                            return PassengerCustom.get();
                        }]/*,
                        fare_type : [ 'FareTypeCustom', function(FareTypeCustom) {
        					return FareTypeCustom.get();
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]*/
                    }
                }).result.then(function() {
                    $state.go('contract.eligibilitys', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.eligibilitys', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.eligibility.copy', {
            parent : 'contract.eligibilitys',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                'Contract',
                function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/eligibility/eligibility-copy-dialog.html',
                    controller : 'EligibilityCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
                    function(result) {
                        $state.go('contract.eligibility',{
                            index: $stateParams.last_index
                        }, { reload : true });
                    }, function() { $state.go('^'); })
                }]
        })

        .state('contract.eligibility.delete', {
            parent : 'contract.eligibilitys',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/eligibility/eligibility-delete-dialog.html',
                    controller : 'EligibilityDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.eligibilitys', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.eligibilitys', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.travel-restrictions', {
                parent: 'entity',
                url: '/contract/travel-restriction/{id_contract}/{items_per_page}',
                data: {
                    authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                    pageTitle : 'americanoApp.form.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/contract/rules/travel-restriction/travel-restrictions.html',
                        controller: 'TravelRestrictionsController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    items_per_page: '5'
                },
                resolve: {
                    entity : [ '$stateParams', 'ContractTravelRestriction', function($stateParams, ContractTravelRestriction) {
                        return ContractTravelRestriction.get({
                            id : $stateParams.id_contract
                        });
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fare');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
        })

        .state('contract.travel-restriction', {
            parent: 'contract.travel-restrictions',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/travel-restriction/travel-restriction.html',
                    controller: 'TravelRestrictionController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	form25 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_travel_restriction"
        					});
                        }],
                        travelSegmentIndicatorSabre : ['TravelSegIndicatorSabre', function(TravelSegIndicatorSabre) {
                            return TravelSegIndicatorSabre.queryByCat({cat:"14"});
                        }]/*,
                        fare_type : [ 'FareTypeCustom', function(FareTypeCustom) {
        					return FareTypeCustom.get();
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]*/
                    }
                }).result.then(function() {
                    $state.go('contract.travel-restrictions', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.travel-restrictions', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.travel-restriction.copy', {
            parent : 'contract.travel-restrictions',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                'Contract',
                function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/travel-restriction/travel-restriction-copy-dialog.html',
                    controller : 'TravelRestrictionCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
                    function(result) {
                        $state.go('contract.travel-restriction',{
                            index: $stateParams.last_index
                        }, { reload : true });
                    }, function() { $state.go('^'); })
                }]
        })

        .state('contract.travel-restriction.delete', {
            parent : 'contract.travel-restrictions',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/travel-restriction/travel-restriction-delete-dialog.html',
                    controller : 'TravelRestrictionDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.travel-restrictions', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.travel-restrictions', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.groups', {
            parent: 'entity',
            url: '/contract/groups/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/groups/groups.html',
                    controller: 'GroupsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
                entity : [ '$stateParams', 'ContractGroup', function($stateParams, ContractGroup) {
                    return ContractGroup.get({
                        id : $stateParams.id_contract
                    });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.group', {
            parent: 'contract.groups',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/groups/group.html',
                    controller: 'GroupController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	formGroupCondition : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_groups_condition"
        					});
                        }],
                        formChildrenCondition : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_group_children_condition"
        					});
                        }],
                        formTextInformation : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rules_group_text"
        					});
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.groups', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.groups', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.group.copy', {
            parent : 'contract.groups',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                'Contract',
                function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/groups/group-copy-dialog.html',
                    controller : 'GroupCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
                    function(result) {
                        $state.go('contract.group',{
                            index: $stateParams.last_index
                        }, { reload : true });
                    }, function() { $state.go('^'); })
                }]
        })

        .state('contract.group.delete', {
            parent : 'contract.groups',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/groups/group-delete-dialog.html',
                    controller : 'GroupDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.groups', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.groups', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.sales-restrictions', {
            parent: 'entity',
            url: '/contract/sales-restrictions/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/sales-restriction/sales-restrictions.html',
                    controller: 'SalesRestrictionsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractSalesRestriction', function($stateParams, ContractSalesRestriction) {
					return ContractSalesRestriction.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.sales-restriction', {
            parent: 'contract.sales-restrictions',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/contract/rules/sales-restriction/sales-restriction.html',
                    controller: 'SalesRestrictionController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
        				form1 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_sales_restriction"
        					});
        				}],
        				form2 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_sales_restriction_conditions"
        					});
        				}],
                        owningOtherCarriers : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_sales_restriction_owning_and_other_carriers_restriction"
        					});
        				}],
                        ticketing : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_sales_restriction_ticketing"
        					});
        				}],
                        sellingRestriction : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_sales_restriction_selling_restriction"
        					});
        				}],
                        airlines : [ 'AirlineCustom', function(AirlineCustom) {
                            return AirlineCustom.get();
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }],
                        timezone : [ 'TimeZOneCustom',function(TimeZOneCustom) {
                            return TimeZOneCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.sales-restrictions', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.sales-restrictions', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.sales-restriction.copy', {
			parent : 'contract.sales-restrictions',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/sales-restriction/sales-restriction-copy-dialog.html',
							controller : 'SalesRestrictionCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							resolve : { }}).result.then(
								function(result) {
									$state.go('contract.sales-restriction',{
													index: $stateParams.last_index
												}, { reload : true });
								}, function() { $state.go('^'); })
							}]
		})

        .state('contract.sales-restriction.delete', {
            parent : 'contract.sales-restrictions',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/sales-restriction/sales-restriction-delete-dialog.html',
                    controller : 'SalesRestrictionDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.sales-restrictions', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.sales-restrictions', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

		.state('contract.penalties', {
            parent: 'entity',
            url: '/contract/penalties/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/penalties/penalties.html',
                    controller: 'PenaltiesController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractPenalties', function($stateParams, ContractPenalties) {
					return ContractPenalties.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.penalty', {
            parent: 'contract.penalties',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/contract/rules/penalties/penalty.html',
                    controller: 'PenaltyController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
        				form1 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_penalties"
        					});
        				}],
        				form2 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_penalties_restrictions"
        					});
        				}],
        				form3 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_penalties_condition"
        					});
        				}],
        				form4 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_penalties_amount"
        					});
        				}],
        				form5 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_penalties_application"
        					});
        				}],
        				currencies : [ 'CurrencyCustom',function(CurrencyCustom) {
        					return CurrencyCustom.get();
        				}],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.penalties', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.penalties', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.penalty.copy', {
			parent : 'contract.penalties',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/penalties/penalty-copy-dialog.html',
							controller : 'PenaltyCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							resolve : { }}).result.then(
								function(result) {
									$state.go('contract.penalty',{
													index: $stateParams.last_index
												}, { reload : true });
								}, function() { $state.go('^'); })
							}]
		})

        .state('contract.penalty.delete', {
            parent : 'contract.penalties',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/penalties/penalty-delete-dialog.html',
                    controller : 'PenaltyDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.penalties', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.penalties', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

		.state('contract.accompanied-travels', {
            parent: 'entity',
            url: '/contract/accompanied-travels/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/accompanied-travel/accompanied-travels.html',
                    controller: 'AccompaniedTravelsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractAccompaniedTravels', function($stateParams, ContractAccompaniedTravels) {
					return ContractAccompaniedTravels.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.accompanied-travel', {
            parent: 'contract.accompanied-travels',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/contract/rules/accompanied-travel/accompanied-travel.html',
                    controller: 'AccompaniedTravelController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
        				form1 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_accompanied_travel_general"
        					});
        				}],
        				form2 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "	rule_accompanied_travel_restriction"
        					});
        				}],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.accompanied-travels', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.accompanied-travels', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.accompanied-travel.copy', {
			parent : 'contract.accompanied-travels',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/accompanied-travel/accompanied-travel-copy-dialog.html',
							controller : 'AccompaniedTravelCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							resolve : { }}).result.then(
								function(result) {
									$state.go('contract.accompanied-travel',{
													index: $stateParams.last_index
												}, { reload : true });
								}, function() { $state.go('^'); })
							}]
		})

        .state('contract.accompanied-travel.delete', {
            parent : 'contract.accompanied-travels',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/accompanied-travel/accompanied-travel-delete-dialog.html',
                    controller : 'AccompaniedTravelDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.accompanied-travels', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.accompanied-travels', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        .state('contract.stopovers', {
                parent: 'entity',
                url: '/contract/stopover/{id_contract}/{items_per_page}',
                data: {
                    authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                    pageTitle : 'americanoApp.form.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/contract/rules/stopover/stopovers.html',
                        controller: 'StopoversController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    items_per_page: '5'
                },
                resolve: {
                    entity : [ '$stateParams', 'ContractStopover', function($stateParams, ContractStopover) {
                        return ContractStopover.get({
                            id : $stateParams.id_contract
                        });
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fare');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })

        .state('contract.stopover', {
            parent: 'contract.stopovers',
                url: '/{index}',
                data: {
                    authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                    pageTitle : 'americanoApp.form.detail.title'
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/contract/rules/stopover/stopover.html',
                        controller: 'StopoverController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            stopover : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                                return FormContract.get({
                                    tag : "rules_stopover"
                                });
                            }],
                            stopoverspec : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                                return FormContract.get({
                                    tag : "rules_stopover_specified"
                                });
                            }],
                            stopovercharg : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                                return FormContract.get({
                                    tag : "rules_stopover_charged"
                                });
                            }],
                            stopoverapp : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                                return FormContract.get({
                                    tag : "rules_stopover_application"
                                });
                            }],
                            stopovertotal : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                                return FormContract.get({
                                    tag : "rules_stopover_total"
                                });
                            }],
                            airlines : [ 'AirlineCustom',
                                function(AirlineCustom) {
                                    return AirlineCustom.get();
                            }],
                            currencies : [ 'CurrencyCustom',function(CurrencyCustom) {
                                return CurrencyCustom.get();
                            }]
                        }
                    }).result.then(function() {
                        $state.go('contract.stopovers', {id_contract:$stateParams.id_contract}, { reload: true });
                    }, function() {
                        $state.go('contract.stopovers', {id_contract:$stateParams.id_contract}, { reload: true });
                    });
                }]
            })

        .state('contract.stopover.copy', {
            parent : 'contract.stopovers',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                'Contract',
                function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/stopover/stopover-copy-dialog.html',
                    controller : 'StopoverCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
                    function(result) {
                        $state.go('contract.stopover',{
                            index: $stateParams.last_index
                        }, { reload : true });
                    }, function() { $state.go('^'); })
                }]
        })

        .state('contract.stopover.delete', {
            parent : 'contract.stopovers',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/stopover/stopover-delete-dialog.html',
                    controller : 'StopoverDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.stopovers', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.stopovers', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

		.state('contract.transfers', {
            parent: 'entity',
            url: '/contract/transfers/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/transfer/transfers.html',
                    controller: 'TransfersController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractTransfer', function($stateParams, ContractTransfer) {
					return ContractTransfer.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        .state('contract.transfer', {
            parent: 'contract.transfers',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                	templateUrl: 'app/entities/contract/rules/transfer/transfer.html',
                    controller: 'TransferController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
        				form1 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_transfer_general"
        					});
        				}],
        				form2 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "	rule_transfer_total_transfer"
        					});
        				}],
        				form3 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_transfer_online_interline_transfer"
        					});
        				}],
        				form4 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "	rule_transfer_embedded_surface_sectors"
        					});
        				}],
        				form5 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "rule_transfer_transfer_charges"
        					});
        				}],
        				form6 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "	rule_transfer_specified_transfers"
        					});
        				}],
        				form7 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
        					return FormContract.get({
        						tag : "	rule_transfer_fare_breaks"
        					});
        				}],
        				airlines : [ 'AirlineCustom', function(AirlineCustom) {
        					return AirlineCustom.get();
        				}],
        				currencies : [ 'CurrencyCustom',function(CurrencyCustom) {
        					return CurrencyCustom.get();
        				}],
                        travelSegmentIndicatorSabre : ['TravelSegIndicatorSabre', function(TravelSegIndicatorSabre) {
                            return TravelSegIndicatorSabre.queryByCat({cat:"09"});
                        }],
                        translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('fare');
                            $translatePartialLoader.addPart('global');
                            return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.transfers', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.transfers', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.transfer.copy', {
			parent : 'contract.transfers',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/transfer/transfer-copy-dialog.html',
							controller : 'TransferCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							resolve : { }}).result.then(
								function(result) {
									$state.go('contract.transfer',{
													index: $stateParams.last_index
												}, { reload : true });
								}, function() { $state.go('^'); })
							}]
		})

        .state('contract.transfer.delete', {
            parent : 'contract.transfers',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/transfer/transfer-delete-dialog.html',
                    controller : 'TransferDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.transfers', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.transfers', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        /* Voluntary Changes */
        .state('contract.voluntarys', {
            parent: 'entity',
            url: '/contract/voluntary-change/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/voluntary-change/voluntary-changes.html',
                    controller: 'VoluntaryChangesController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractVoluntaryChange', function($stateParams, ContractVoluntaryChange) {
					return ContractVoluntaryChange.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        /* Input or Edit Voluntary Changes */
        .state('contract.voluntary', {
            parent: 'contract.voluntarys',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/voluntary-change/voluntary-change.html',
                    controller: 'VoluntaryChangeController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_changes"
                            });
                        }],
                        form2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_changes_passengers"
                            });
                        }],
                        form3 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_changes_change_condition"
                            });
                        }],
                        form4 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_changes_reissue_charges"
                            });
                        }],
                        form5 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_changes_reissue_list"
                            });
                        }],
                        airlines : [ 'AirlineCustom',
                            function(AirlineCustom) {
                                return AirlineCustom.get();
                        }],
                        routing_tariff_sabre : ['RoutingTariffSabreCustom',
                            function(RoutingTariffSabreCustom) {
                                return RoutingTariffSabreCustom.get();
                        }],
                        fare_type : [ 'FareTypeCustom',
                            function(FareTypeCustom) {
                                return FareTypeCustom.get();
                            }],
                        currencies : [ 'CurrencyCustom',function(CurrencyCustom) {
                            return CurrencyCustom.get();
                        }],
                        passenger_type : [ 'PassengerCustom',function(PassengerCustom) {
                            return PassengerCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.voluntarys', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.voluntarys', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.voluntary.copy', {
			parent : 'contract.voluntarys',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/voluntary-change/voluntary-copy-dialog.html',
							controller : 'VoluntaryChangeCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							resolve : { }}).result.then(
								function(result) {
									$state.go('contract.voluntary',{
													index: $stateParams.last_index
												}, { reload : true });
								}, function() { $state.go('^'); })
							}]
		})

        .state('contract.voluntary.delete', {
            parent : 'contract.voluntarys',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/voluntary-change/voluntary-change-delete-dialog.html',
                    controller : 'VoluntaryChangeDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.voluntarys', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.voluntarys', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

		/* Hip */
        .state('contract.higher-intermediate-points', {
            parent: 'entity',
            url: '/contract/higher-intermediate-point/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/higher-intermediate-point/higher-intermediate-points.html',
                    controller: 'HigherIntermediatePointsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractHigherIntermediatePoint', function($stateParams, ContractHigherIntermediatePoint) {
					return ContractHigherIntermediatePoint.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        /* Input or Edit Hip */
        .state('contract.higher-intermediate-point', {
            parent: 'contract.higher-intermediate-points',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/higher-intermediate-point/higher-intermediate-point.html',
                    controller: 'HigherIntermediatePointController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_higher_intermediate_point"
                            });
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.higher-intermediate-points', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.higher-intermediate-points', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.higher-intermediate-point.delete', {
            parent : 'contract.higher-intermediate-points',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/higher-intermediate-point/higher-intermediate-point-delete-dialog.html',
                    controller : 'HigherIntermediatePointDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.higher-intermediate-points', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.higher-intermediate-points', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        /* Mft */
        .state('contract.miscellaneous-fare-tags', {
            parent: 'entity',
            url: '/contract/miscellaneous-fare-tag/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
				pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/miscellaneous-fare-tag/miscellaneous-fare-tags.html',
                    controller: 'MiscellaneousFareTagsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
            	entity : [ '$stateParams', 'ContractMiscellaneousFareTag', function($stateParams, ContractMiscellaneousFareTag) {
					return ContractMiscellaneousFareTag.get({
						id : $stateParams.id_contract
					});
				}],
				translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        /* Input or Edit Mft */
        .state('contract.miscellaneous-fare-tag', {
            parent: 'contract.miscellaneous-fare-tags',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/miscellaneous-fare-tag/miscellaneous-fare-tag.html',
                    controller: 'MiscellaneousFareTagController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_miscellaneous_fare_tag"
                            });
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.miscellaneous-fare-tags', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.miscellaneous-fare-tags', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

         .state('contract.miscellaneous-fare-tag.copy', {
			parent : 'contract.miscellaneous-fare-tags',
			url : '/copy/{index}/{last_index}',
			data : {
				authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
			},
			onEnter : [
					'$stateParams',
					'$state',
					'$uibModal',
					'Contract',
					function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
							templateUrl : 'app/entities/contract/rules/miscellaneous-fare-tag/miscellaneous-fare-tag-copy-dialog.html',
							controller : 'MiscellaneousFareTagCopyDialogController',
							controllerAs : 'vm',
							size : 'md',
							 resolve : { }}).result.then(
					            		function(result) {
					                        $state.go('contract.miscellaneous-fare-tag',{ index: $stateParams.last_index, operation:'edit'  },  { reload : true });
					                    }, function() {  })
					                }]
         })

        .state('contract.miscellaneous-fare-tag.delete', {
            parent : 'contract.miscellaneous-fare-tags',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/miscellaneous-fare-tag/miscellaneous-fare-tag-delete-dialog.html',
                    controller : 'MiscellaneousFareTagDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.miscellaneous-fare-tags', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.miscellaneous-fare-tags', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })

        /* Voluntary Refunds */
        .state('contract.voluntary-refunds', {
            parent: 'entity',
            url: '/contract/voluntary-refund/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/voluntary-refund/voluntary-refunds.html',
                    controller: 'VoluntaryRefundsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
                entity : [ '$stateParams', 'ContractVoluntaryRefund', function($stateParams, ContractVoluntaryRefund) {
                    return ContractVoluntaryRefund.get({
                        id : $stateParams.id_contract
                    });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        /* Input or Edit Voluntary Refund */
        .state('contract.voluntary-refund', {
            parent: 'contract.voluntary-refunds',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/voluntary-refund/voluntary-refund.html',
                    controller: 'VoluntaryRefundController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        voluntary_refund : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund"
                            });
                        }],
                        time_matching_information : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_time_matching_information"
                            });
                        }],
                        advance_cancellation : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_advance_cancellation"
                            });
                        }],
                        originally_scheduled_flight_element : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_originally_scheduled_flight_element"
                            });
                        }],
                        customer_first_element : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_customer_first_element"
                            });
                        }],
                        condition_matching_element : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_specific_condition_matching_element"
                            });
                        }],
                        cancellation_indicator_element : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_cancellation_indicator_element"
                            });
                        }],
                        flown_portion_repricing_element : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_flown_portion_repricing_element"
                            });
                        }],
                        repricing_rules_element : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_repricing_rules_element"
                            });
                        }],
                        fare_class_or_family_element : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_fare_class_or_family_element"
                            });
                        }],
                        fare_type_application : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_fare_type_application"
                            });
                        }],
                        charges_and_application_element : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_charges_and_application_element"
                            });
                        }],
                        refund_carrier_application : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_carrier_application"
                            });
                        }],
                        information_only : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_voluntary_refund_information_only"
                            });
                        }],
                        airlines : [ 'AirlineCustom',
                            function(AirlineCustom) {
                                return AirlineCustom.get();
                            }],
                        fare_type : [ 'FareTypeCustom',
                            function(FareTypeCustom) {
                                return FareTypeCustom.get();
                            }],
                        currencies : [ 'CurrencyCustom',function(CurrencyCustom) {
                            return CurrencyCustom.get();
                        }],
                        passenger_type : [ 'PassengerCustom',function(PassengerCustom) {
                            return PassengerCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.voluntary-refunds', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.voluntary-refunds', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.voluntary-refund.copy', {
            parent : 'contract.voluntary-refunds',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                'Contract',
                function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/voluntary-refund/voluntary-refund-copy-dialog.html',
                    controller : 'VoluntaryRefundCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
                    function(result) {
                        $state.go('contract.voluntary-refund',{
                            index: $stateParams.last_index
                        }, { reload : true });
                    }, function() { $state.go('^'); })
                }]
        })

        .state('contract.voluntary-refund.delete', {
            parent : 'contract.voluntary-refunds',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/voluntary-refund/voluntary-refund-delete-dialog.html',
                    controller : 'VoluntaryRefundDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.voluntary-refunds', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.voluntary-refunds', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })


        /* Tour Agents Other Discount */
        .state('contract.tour-agents-other-discounts', {
            parent: 'entity',
            url: '/contract/rules/tour-agents-other-discount/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/tour-agents-other-discount/tour-agents-other-discounts.html',
                    controller: 'TourAgentsOtherDiscountsController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
                entity : [ '$stateParams', 'ContractTourAgentsOtherDiscount', function($stateParams, ContractTourAgentsOtherDiscount) {
                    return ContractTourAgentsOtherDiscount.get({
                        id : $stateParams.id_contract
                    });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        /* Input or Edit Tour Agents Other Discount */
        .state('contract.tour-agents-other-discount', {
            parent: 'contract.tour-agents-other-discounts',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/tour-agents-other-discount/tour-agents-other-discount.html',
                    controller: 'TourAgentsOtherDiscountController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	tour_agents_other_discount : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "rules_tour_agents_other_discount"
                            });
                        }],
                        fare_type : [ 'FareTypeCustom',
                            function(FareTypeCustom) {
                                return FareTypeCustom.get();
                            }],
                        currencies : [ 'CurrencyCustom',function(CurrencyCustom) {
                            return CurrencyCustom.get();
                        }],
                        passenger_type : [ 'PassengerCustom',function(PassengerCustom) {
                            return PassengerCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.tour-agents-other-discounts', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.tour-agents-other-discounts', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.tour-agents-other-discount.copy', {
            parent : 'contract.tour-agents-other-discounts',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                'Contract',
                function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/tour-agents-other-discount/tour-agents-other-discount-copy-dialog.html',
                    controller : 'TourAgentsOtherDiscountCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
                    function(result) {
                        $state.go('contract.tour-agents-other-discount',{
                            index: $stateParams.last_index
                        }, { reload : true });
                    }, function() { $state.go('^'); })
                }]
        })

        .state('contract.tour-agents-other-discount.delete', {
            parent : 'contract.tour-agents-other-discounts',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/tour-agents-other-discount/tour-agents-other-discount-delete-dialog.html',
                    controller : 'TourAgentsOtherDiscountDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.tour-agents-other-discounts', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.tour-agents-other-discounts', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })


        /* Tours*/
        .state('contract.tours', {
            parent: 'entity',
            url: '/contract/rules/tour/{id_contract}/{items_per_page}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/contract/rules/tours/tours.html',
                    controller: 'ToursController',
                    controllerAs: 'vm'
                }
            },
            params: {
                items_per_page: '5'
            },
            resolve: {
                entity : [ '$stateParams', 'ContractTours', function($stateParams, ContractTours) {
                    return ContractTours.get({
                        id : $stateParams.id_contract
                    });
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fare');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })

        /* Input or Edit Tours  */
        .state('contract.tour', {
            parent: 'contract.tours',
            url: '/{index}',
            data: {
                authorities: [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/contract/rules/tours/tour.html',
                    controller: 'TourController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	tours : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "master_tours"
                            });
                        }],
                        package1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "master_tours_package1"
                            });
                        }],
                        package2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "master_tours_package2"
                            });
                        }],
                        tour_price : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "master_tours_tour_price"
                            });
                        }],
                        ground_cruise_segment : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "master_tours_ground_cruise_segments"
                            });
                        }],
                        airlines : [ 'AirlineCustom', function(AirlineCustom) {
        					return AirlineCustom.get();
        				}],
                        currencies : [ 'CurrencyCustom',function(CurrencyCustom) {
                            return CurrencyCustom.get();
                        }],
                        travelSegmentIndicatorSabre : ['TravelSegIndicatorSabreCustom', function(TravelSegIndicatorSabreCustom) {
            				return TravelSegIndicatorSabreCustom.get();
                        }],
                        passenger_type : [ 'PassengerCustom',function(PassengerCustom) {
                            return PassengerCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('contract.tours', {id_contract:$stateParams.id_contract}, { reload: true });
                }, function() {
                    $state.go('contract.tours', {id_contract:$stateParams.id_contract}, { reload: true });
                });
            }]
        })

        .state('contract.tour.copy', {
            parent : 'contract.tours',
            url : '/copy/{index}/{last_index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : [
                '$stateParams',
                '$state',
                '$uibModal',
                'Contract',
                function($stateParams, $state, $uibModal, Contract) { $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/tours/tour-copy-dialog.html',
                    controller : 'TourCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : { }}).result.then(
                    function(result) {
                        $state.go('contract.tour',{
                            index: $stateParams.last_index
                        }, { reload : true });
                    }, function() { $state.go('^'); })
                }]
        })

        .state('contract.tour.delete', {
            parent : 'contract.tours',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/rules/tours/tour-delete-dialog.html',
                    controller : 'TourDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                    $state.go('contract.tours', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                    $state.go('contract.tours', {id_contract:$stateParams.id_contract}, { reload: true });
                })
            }]
        })
    }
})();
