(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('Fare', Fare);

    Fare.$inject = ['$resource'];

    function Fare ($resource) {
        var resourceUrl =  'api/fares/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    
    angular.module('americanoApp')
    	.factory('FareSearch', FareSearch);
    FareSearch.$inject = ['$resource'];
    function FareSearch($resource){
    	var resourceUrl = 'api/_search/fares/:id_contract/:query';
    	return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
    
    angular.module('americanoApp')
		.factory('FareCustom', FareCustom);
    FareCustom.$inject = ['$resource'];
	function FareCustom($resource){
		var resourceUrl = 'api/fares/id_contract/:id_contract';
		return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
	}
})();
