(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('InfoTextDeleteController',InfoTextDeleteController);

    InfoTextDeleteController.$inject = ['$uibModalInstance', 'entity', 'InfoText'];

    function InfoTextDeleteController($uibModalInstance, entity, InfoText) {
        var vm = this;
        vm.infoText = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            InfoText.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
