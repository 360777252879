(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FareDialogController', FareDialogController);

    FareDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'Fare', 'Airlines', 'Contracts', 'Passengers'];

    function FareDialogController ($scope, $stateParams, $uibModalInstance, entity, Fare, Airlines, Contracts, Passengers) {
        var vm = this;
        vm.fare = entity;
        vm.airlines = Airlines;
        vm.contracts = Contracts;
        vm.passengers = Passengers;
        
        vm.load = function(id) {
            Fare.get({id : id}, function(result) {
                vm.fare = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:fareUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.fare.id !== null) {
                Fare.update(vm.fare, onSaveSuccess, onSaveError);
            } else {
                Fare.save(vm.fare, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
