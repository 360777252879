(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('AdvanceReservationCopyDialogController', AdvanceReservationCopyDialogController);

    AdvanceReservationCopyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'ContractAdvanceReservation'];

    function AdvanceReservationCopyDialogController ($scope, $stateParams, $uibModalInstance, ContractAdvanceReservation) {
    	var vm = this;
    	vm.id = $stateParams.id;
    	vm.index = $stateParams.index;

    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };

        vm.confirmCopy = function (id, index, lastIndex) {
            console.log("ID CONTRACT: "+$stateParams.id_contract+" | INDEX : "+index);
            ContractAdvanceReservation.copy({id:$stateParams.id_contract, index:index}, function(data){
                $uibModalInstance.close(data.id);
            });
        	// Contract.get({id:$stateParams.id_contract}, function(data){
            //
        	// 	data.data[6].pages.push(data.data[6].pages[index]);
            //
        	// 	Contract.update(data, function(result){
    	    // 		console.log("Update sukses");
    	    // 		$uibModalInstance.close(true);
    	    // 	}, function(error){
    	    // 		console.log("Update error ");
    	    // 		console.log(error);
    	    // 	});
        	// });
	    };
    }
})();
