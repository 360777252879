(function() {
    'use strict';

    angular.module('americanoApp').config(stateConfig);

    stateConfig.$inject = [ '$stateProvider' ];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('contract', {
            parent : 'entity',
            url : '/contract?page&sort&search&filter',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER' ],
                pageTitle : 'americanoApp.contract.home.title'
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/contract/contracts.html',
                    controller : 'ContractController',
                    controllerAs : 'vm'
                }
            },
            params : {
                page : {
                    value : '1',
                    squash : true
                },
                sort : {
                    value : 'id,desc',
                    squash : true
                },
                search : null,
                filter : null
            },
            resolve : {
                pagingParams : ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                	return {
                		page : PaginationUtil.parsePage($stateParams.page),
                		sort : $stateParams.sort,
                      	predicate : PaginationUtil.parsePredicate($stateParams.sort),
                     	ascending : PaginationUtil.parseAscending($stateParams.sort),
                       	search : $stateParams.search,
                     	filter : $stateParams.filter
                 	};
            	}],
                translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('contract');
                 	$translatePartialLoader.addPart('global');
                 	return $translate.refresh();
             	}]
            }
        })
        .state( 'contract-detail', {
            parent : 'entity',
            url : '/contract/{id_contract}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER' ],
                pageTitle : 'americanoApp.contract.detail.title'
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/contract/contract-detail.html',
                    controller : 'ContractDetailController',
                    controllerAs : 'vm'
                }
            },
            resolve : {
                translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('contract');
                	$translatePartialLoader.addPart('fare');
                	return $translate.refresh();
             	}],
                entity : [ '$stateParams', 'Contract', function($stateParams, Contract) {
                	return Contract.get({id : $stateParams.id_contract});
                }],
                fare : [ '$stateParams', 'ContractFare', function($stateParams, ContractFare) {
                	return ContractFare.getAllFares({id : $stateParams.id_contract}).$promise;
                }]
            }
        })
        .state('contract.copy', {
            parent : 'contract',
            url : '/copy/{id_contract}',
            data : {
                authorities : [ 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_ADMIN_USER' ],
            },
            onEnter : ['$stateParams','$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
            	$uibModal.open({
                    templateUrl : 'app/entities/contract/form-contract-copy-dialog.html',
                    controller : 'FormContractCopyDialogController',
                    controllerAs : 'vm',
                    size : 'lg',
                    resolve : {}
                }).result.then(function(result) {
                	$state.go('contract.header', { id_contract : result.id }, { reload : true });
                }, function() {
                	$state.go('^');
            	})
            }]
        })
        .state('contract.header', {
            parent : 'entity',
            url : '/contract/header/{id_contract}?operation',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER' ],
                pageTitle : 'americanoApp.contract.home.title'
            },
            params : {
                operation : null
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/contract/form-contract-header.html',
                    controller : 'FormContractHeaderController',
                    controllerAs : 'vm'
                }
            },
            resolve : {
                translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('contract');
                	return $translate.refresh();
                }],
                entity : [ '$stateParams', 'ContractHeader', '$resource', function($stateParams, ContractHeader, $resource) {
                    if ($stateParams.id_contract != '') {
                        return ContractHeader.get({ id : $stateParams.id_contract});
                    } else {
                    	return {
                         	id : null,
                         	contract_type : $stateParams.contract_type,
                            contract_type_security : $stateParams.contract_type_security,
                          	target_system : [],
                          	contractGdsStatus : [],
                         	data : [
	                         	//0	Contract Header
	                         	{ pages : [] },
	                         	//1 Fare
	                         	{ pages : [] },
	                         	//2 Routing
	                         	{ pages : [] },
	                         	//3 Distribution
	                         	{ pages : [] },
	                         	//4 Rules Rule Application
	                         	{ pages : [] },
	                         	//5 Rules Rule Flight Application
	                         	{ pages : [] },
	                         	//6 Rules Rule Advanced Reservation
	                         	{ pages : [] },
	                         	//7 Rules Rule Blackouts
	                         	{ pages : [] },
	                         	//8 Rules Rule Surcharges
	                         	{ pages : [] },
	                         	//9 Rules Rule Discounts
	                         	{ pages : [] },
	                         	//10 Rules Rule Seasonality
	                         	{ pages : [] },
	                         	//11 Rules Rule Minimum Stay
	                         	{ pages : [] },
	                         	//12 Rules Rule Maximum Stay
	                         	{ pages : [] },
	                         	//13 Rules Rule Endorsement
	                         	{ pages : [] },
	                         	//14 Rules Rule Day Time
	                         	{ pages : [] },
	                         	//15 Rules Rule Combination
	                         	{ pages : [] },
	                         	//16 Rules Rule Eligibility
	                         	{ pages : [] },
	                         	//17 Rules Rule Sales Restriction
	                         	{ pages : [] },
	                         	//18 Rules Rule Penalties
	                         	{ pages : [] },
	                         	//19 Rules Rule Travel Restriction
	                         	{ pages : [] },
	                         	//20 Rules Rule Accompanied Travel
	                         	{ pages : [] },
	                         	//21 Rules Rule Stopover
	                         	{ pages : [] },
	                         	//22 Rules Rule Transfer
	                         	{ pages : [] },
	                         	//23 Rules Rule Voluntary Changes
	                         	{ pages : [] },
	                         	//24 Rules Rule Groups
	                         	{ pages : [] },
	                         	//25 Rules Rule Higher Intermediate Point
	                         	{ pages : [] },
	                         	//26 Rules Rule Miscellaneous Fare Tag
	                         	{ pages : [] },
	                         	//27 Rules Rule Voluntary Refund
	                         	{ pages : [] },
	                         	//28 Rules Rule Tour Agents Other Discount
	                         	{ pages : [] },
	                         	//29 Rules Rule Tours 
	                         	{ pages : [] }
                         	]
                    	}
                    }
            	}],
                c1 : [ 'FormContract', function(FormContract, $stateParams) {
                	return FormContract.get({ tag : 'c1' });
             	}],
                c2 : [ 'FormContract', function(FormContract, $stateParams) {
                	return FormContract.get({ tag : 'c2' });
             	}],
                c3 : [ 'FormContract', function(FormContract, $stateParams) {
                	return FormContract.get({ tag : 'c3' });
                }],
                c4 : [ 'FormContract', function(FormContract, $stateParams) {
                    return FormContract.get({ tag : 'c4' });
                }],
                c5 : [ 'FormContract', function(FormContract, $stateParams) {
                    return FormContract.get({ tag : 'c5' });
                }],
                airlines : [ 'AirlineCustom', function(AirlineCustom) {
                	return AirlineCustom.get();
               	}]
            }
        })
        .state('contract.delete', {
            parent : 'contract',
            url : '/{id}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER' ]
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            	$uibModal.open({
                    templateUrl : 'app/entities/contract/contract-delete-dialog.html',
                    controller : 'ContractDeleteController',
                    controllerAs : 'vm',
                    size : 'md'
                }).result.then(function() {
                	$state.go('contract', null, { reload : true });
                }, function() {
                	$state.go('^');
                });
            }]
        })
        .state('contract.rules', {
            parent : 'entity',
            url : '/contract/rules/{id_contract}?page&sort&search',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER' ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/contract/form-rules.html',
                    controller : 'FormRulesController',
                    controllerAs : 'vm'
                }
            },
            params : {
                page : {
                    value : '1',
                    squash : true
                },
                sort : {
                    value : 'id,desc',
                    squash : true
                },
                search : null
            },
            resolve : {
                translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('rules');
                	return $translate.refresh();
                }],
                pagingParams : ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page : PaginationUtil.parsePage($stateParams.page),
                        sort : $stateParams.sort,
                        predicate : PaginationUtil.parsePredicate($stateParams.sort),
                        ascending : PaginationUtil.parseAscending($stateParams.sort),
                        search : $stateParams.search
                    };
                }],
            }
        })
        .state('contract.gds', {
            parent : 'contract.header',
            url : '/gds',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER' ],
                pageTitle : 'americanoApp.contract.home.title'
            },
            onEnter : ['$stateParams', '$state', '$uibModal', '$rootScope', function($stateParams, $state, $uibModal, $rootScope) {
            	$uibModal.open({
                    templateUrl : 'app/entities/contract/form-contract-publish.html',
                    controller : 'FormGDSSystemController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                    	translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('contract');
                        	$translatePartialLoader.addPart('global');
                        	return $translate.refresh();
                        }],
                    }
                }).result.then(function() {
                	$state.go('contract.header', { id_contract : $stateParams.id_contract }, { reload : true });
                	
                	if($rootScope.isCalculated) {
                        $("#tab1calculated").trigger("click");
                    }
                    else {
                        $("#tab1").trigger("click");
                    }
//                	$("#tab1").trigger("click");
                }, function() {
                 	$state.go('contract.header', { id_contract : $stateParams.id_contract }, { reload : true });
                	if($rootScope.isCalculated) {
                        $("#tab1calculated").trigger("click");
                    }
                    else {
                        $("#tab1").trigger("click");
                    }
//                	$("#tab1").trigger("click");
                });
            }]
        })
        
        .state('contract.expired', {
            parent : 'contract.header',
            url : '/expired',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER' ],
                pageTitle : 'americanoApp.contract.home.title'
            },
            onEnter : ['$stateParams', '$state', '$uibModal', '$rootScope', function($stateParams, $state, $uibModal, $rootScope) {
            	$uibModal.open({
                    templateUrl : 'app/entities/contract/form-contract-expired.html',
                    controller : 'FormContractExpiredController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                    	translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('contract');
                        	$translatePartialLoader.addPart('global');
                        	return $translate.refresh();
                        }],
                    }
                }).result.then(function() {
                	$state.go('contract.header', { id_contract : $stateParams.id_contract }, { reload : true });
                	
                	if($rootScope.isCalculated) {
                        $("#tab1calculated").trigger("click");
                    }
                    else {
                        $("#tab1").trigger("click");
                    }
//                	$("#tab1").trigger("click");
                }, function() {
                 	$state.go('contract.header', { id_contract : $stateParams.id_contract }, { reload : true });
                	if($rootScope.isCalculated) {
                        $("#tab1calculated").trigger("click");
                    }
                    else {
                        $("#tab1").trigger("click");
                    }
//                	$("#tab1").trigger("click");
                });
            }]
        })
        
        .state('contract.gds-multiple', {
            parent : 'contract',
            url : '/gds/multiple/{id_list}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER' ]
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/form-contract-publish.html',
                    controller : 'FormContractPublishMultipleController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        translatePartialLoader : ['$translate','$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('contract');
                        	$translatePartialLoader.addPart('fare');
                         	return $translate.refresh();
                        }]
                    }
                }).result.then(function() {
	                $state.go('contract');
	                $("#tab1").trigger("click");
	            }, function() {
	                $state.go('contract');
	                $("#tab1").trigger("click");
	            });
            }]
        })
//        .state('contract.upload.home', {
//            parent : 'home',
//            url : 'home/contract/upload',
//            data : {
//                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER' ],
//                pageTitle : 'americanoApp.form.detail.title'
//            },
//            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
//            	$uibModal.open({
//                    templateUrl : 'app/entities/contract/form-upload-contract.html',
//                    controller : 'FormUploadContractController',
//                    controllerAs : 'vm',
//                    backdrop : 'static',
//                    size : 'md',
//                    resolve : {
//                        entity : function() {
//                        	return {
//	                            file : null
//                            };
//                        },
//                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
//                        	$translatePartialLoader.addPart('contract');
//                        	return $translate.refresh();
//                     	}]
//                    }
//                }).result.then(function() {
//	                $state.go('home', null, { reload : true });
//                }, function() {
//                    $state.go('home', null, { reload : true });
//                });
//            }]
//        })
        .state('contract.upload-validate', {
            parent : 'contract.header',
            url : '/upload/validate/{id_contract}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER' ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            	$uibModal.open({
                    templateUrl : 'app/entities/contract/form-upload-validate-contract.html',
                    controller : 'FormUploadValidateContractController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'md',
                    resolve : {
                    	entity : function() {
                        	return {
	                            file : null
                            };
                        },
                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('contract');
                        	return $translate.refresh();
                     	}]
                    }
                }).result.then(function() {
                	$state.go('contract.header', { id_contract : $stateParams.id_contract }, { reload : true });
                	if($rootScope.isCalculated) {
                        $("#tab1calculated").trigger("click");
                    }
                    else {
                        $("#tab1").trigger("click");
                    }
                }, function() {
                	$state.go('contract.header', { id_contract : $stateParams.id_contract }, { reload : true });
                	if($rootScope.isCalculated) {
                        $("#tab1calculated").trigger("click");
                    }
                    else {
                        $("#tab1").trigger("click");
                    }
                });
            }]
        })
        .state('contract.upload', {
            parent : 'entity',
            url : '/upload',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER' ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            	$uibModal.open({
                    templateUrl : 'app/entities/contract/form-upload-contract.html',
                    controller : 'FormUploadContractController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'md',
                    resolve : {
                    	entity : function() {
                        	return {
	                            file : null
                            };
                        },
                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('contract');
                        	return $translate.refresh();
                     	}]
                    }
                }).result.then(function() {
                	$state.go('contract');
                }, function() {
                	$state.go('contract');
                });
            }]
        })
    }
})();
