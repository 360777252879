(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormFareCalculatedController', FormFareCalculatedController);

    FormFareCalculatedController.$inject = ['$scope', '$state', '$stateParams', 'ContractFareCalculated', 'ParseLinks', 'AlertService', 'pagingParams'];

    function FormFareCalculatedController ($scope, $state, $stateParams, ContractFareCalculated,  ParseLinks, AlertService, pagingParams) {
    	var vm = this;
        vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;
        vm.totalFares = 0;
        vm.allGroups = [];
        vm.id_contract = $stateParams.id_contract;
        vm.isSearching = false;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.activeGroup = $stateParams.group;
        vm.searchQuery = $stateParams.search;
        vm.isNewGroup = $stateParams.newGroup;
        vm.searchType = "Origin";

        vm.searchTypeOptions= [{key:'origin', value:'Origin'},
            {key:'direction', value:'Direction'},
            {key:'destination', value:'Destination'},
            {key:'passenger_type', value:'Passenger Type'},
            {key:'tariff_number', value:'Tariff Number'},
            {key:'fare_basis_code', value:'Fare Basis Code'},
            {key:'amount', value:'Amount'},
            {key:'currency', value:'Currency'},
            {key:'effective_date', value:'Effective Date'},
            {key:'discontinue_date', value:'Discontinue Date'},
            {key:'class', value:'Class'},
            {key:'type_of_journey', value:'Type of Journey'},
            {key:'season_type', value:'Season Type'},
            {key:'ticket_code', value:'Ticket Code'},
            {key:'ticket_designator', value:'Ticket Designator'},
            {key:'routing_type', value:'Routing Type'},
            {key:'routing_number', value:'Routing Number'},
            {key:'fare_type', value:'Fare Type'},
            {key:'fare_type_apf', value:'Fare Type APF'},
            {key:'cabin_class', value:'Cabin Class'}];

        if (vm.searchQuery != undefined && vm.searchQuery != null && vm.searchQuery != '')
        	vm.isSearching = true;

        if (vm.activeGroup != "DEFAULT")
        	vm.farePlaceholder = "Search for Fare in " + vm.activeGroup;
        else
        	vm.farePlaceholder = "Search for Fare";

		vm.loadAll = function () {
			ContractFareCalculated.query({
        		id:$stateParams.id_contract,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                search: vm.searchQuery,
                type: vm.searchType,
                sort: sort(),
                group: encodeURIComponent(vm.activeGroup)
            }, onSuccess, onError);

			function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            };

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));

                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.total = vm.queryCount;
                vm.currentPageFares = data.fares;
                vm.allGroups = data.groups;

                vm.moveGroupsSelection = [];

                var found = false;

                for (var i = 0; i < vm.allGroups.length; i++) {
                	if (vm.allGroups[i].name == vm.activeGroup)
                		found = true;

                	if (vm.allGroups[i].name != "DEFAULT" && vm.allGroups[i].name != vm.activeGroup)
                		vm.moveGroupsSelection.push(vm.allGroups[i]);
                }

                if (!found && vm.isNewGroup == "0")
                	$state.go($state.current.name, { group: "DEFAULT" }, { reload: true });

                var active = null;

                for (var i = 0; i < vm.allGroups.length; i++) {
                	if (vm.allGroups[i].name == vm.activeGroup) {
                		active = angular.copy(vm.allGroups[i]);
                		vm.allGroups.splice(i, 1);
                		break;
                	}
                }

                vm.allGroups.unshift(active);

                vm.lastIndex = vm.currentPageFares.length;
                vm.page = pagingParams.page;
            }

            function onError(error) {
                if(error.data != null)
                    AlertService.error(error.data.message);
            }
        };

        vm.loadAll();

        vm.loadPage = function (page) {
            vm.page = page;
            vm.transition();
        };

        vm.transition = function (group) {
        	pagingParams.page = vm.page;

        	if (group != undefined)
        		vm.activeGroup = group;

        	if (vm.searchQuery != undefined && vm.searchQuery != '' && vm.searchQuery != null)
        		vm.isSearching = true;

    		$state.transitionTo($state.$current, {
            	id_contract:$stateParams.id_contract,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.searchQuery,
                group: vm.activeGroup
            });
        };

        vm.search = function () {
        	if (vm.searchQuery != undefined && vm.searchQuery != '' && vm.searchQuery != null) {
        		vm.loadAll();
            	vm.isSearching = true;
        	}
        };

        vm.emptySearchCheck = function() {
        	if (vm.searchQuery == "") {
        		vm.isSearching = false;
        		vm.loadAll();
        	}
        }

        vm.editFareCalculated = function(originalIndex) {
        	$state.go('contract.fare-calculated.edit', {
				index: originalIndex,
				id_contract : $stateParams.id_contract,
				duplicateFare : false
			}, { reload : true });
        }

        vm.copyFareCalculated = function(originalIndex) {
        	console.log("Copy fare calculated");
        	$state.go('contract.fare-calculated.copy', {
				index: originalIndex,
				id_contract : $stateParams.id_contract
			}, { reload : true });
        }

        vm.viewFareCalculated = function(originalIndex) {
        	$state.go('contract.fare-calculated.view', {
				index: originalIndex,
				id_contract : $stateParams.id_contract
			}, { reload : true });
        }

        vm.deleteFareCalculated = function(originalIndex) {
        	$state.go('contract.fare-calculated.delete', {
				index: originalIndex,
				id_contract : $stateParams.id_contract
			}, { reload : true });
        }

        // Checkbox
        vm.selectedFares = [];
        vm.selectedStatus = [];
        vm.currentPageFares = [];
        vm.selectedRow = [];

        vm.registerAllFares = function(isChecked) {
        	if (isChecked) {
        		vm.selectedRow = [];
        		vm.selectedFares = [];
        		for (var i = 0; i < vm.currentPageFares.length; i++) {
            		vm.selectedFares.push(vm.currentPageFares[i]);
            		vm.selectedStatus[i] = true;
            		vm.selectedRow.push(vm.lastIndex - i);
            	}

        	} else {
        		vm.selectedRow = [];
        		vm.selectedFares = [];
        		for (var i = 0; i < vm.selectedStatus.length; i++) {
        			vm.selectedStatus[i] = false;
        		}
        	}
        }

        vm.registerFare = function(transactionIndex, fare, index) {
        	if (vm.selectedStatus[index]) {
        		vm.selectedFares.push(fare);
            	vm.selectedStatus[index] = true;

        	} else {
        		vm.selectedFares.splice(vm.selectedFares.indexOf(fare), 1);
        		vm.selectedStatus[index] = false;
        	}

        	if (vm.selectedFares.length == vm.currentPageFares.length) {
        		vm.master = true;
        	} else
        		vm.master = false;
        }

        vm.rowHighlighted = function(row, fare, index) {
        	var selectedExist = false;

			for (var i = 0; i < vm.selectedRow.length; i++) {
				if (vm.selectedRow[i] == row) {
					vm.selectedRow.splice(i, 1);
					selectedExist = true;
					break;
				}
			}

			if (selectedExist)
				vm.selectedStatus[index] = false;
			else {
				vm.selectedStatus[index] = true;
				vm.selectedRow.push(row);
			}

			vm.registerFare(row, fare, index);
	 	}


        vm.resetCheckbox = function() {
	       	vm.selectedFares = [];
	       	vm.selectedRow  = [];
            for (var i = 0; i < vm.selectedStatus.length; i++) {
           	 vm.selectedStatus[i] = false;
            }
            vm.master = false;
       }
        //End Checkbox

        // Calculate
        vm.calculationType = ["Amount", "Percentage"];
        vm.calculationOperator = ["Increase", "Decrease"];
        vm.typeValue = vm.calculationType[0];
        vm.operatorValue = vm.calculationOperator[0];


        vm.calculateValue = 0;
        vm.calculate = function() {
        	if (vm.calculateValue != "" && vm.calculateValue && vm.calculateValue != null && vm.calculateValue != undefined) {
        		if (vm.selectedFares.length != 0) {

        			var listIdFare = [];
        			vm.selectedFares.forEach(function(fare){
        				listIdFare.push(fare.id);
        			});
        			var fareCalculateData = {
        				contractId:$stateParams.id_contract,
        				type:vm.typeValue,
        				operator: vm.operatorValue,
        				value: vm.calculateValue+"",
        				fares: listIdFare
        			};
        			ContractFare.calculate(fareCalculateData, function(data) {
        				vm.loadAll();
        			}, function(error) {});
        		}
        		vm.resetCheckbox();
        	}
        }
        // End Calculate

        // Create New Fare Group
        vm.createNewFareGroup = function() {
        	if (vm.newGroupName != undefined && vm.newGroupName != null && vm.newGroupName != "") {
        		vm.fareGroupExists = false;

        		for (var i = 0; i < vm.allGroups.length; i++) {
        			if (vm.allGroups[i].name.toUpperCase() == vm.newGroupName.trim().toUpperCase()) {
        				vm.fareGroupExists = true;
        				break;
        			}
        		}

        		vm.fareGroupNameIsEmpty = false;

        		if (!vm.fareGroupExists) {
        			$state.go('contract.fare-calculated.new', { group: vm.newGroupName.toUpperCase(), newGroup: "1" }, { reload: true });
        		}
        	}

        	vm.fareGroupNameIsEmpty = true;
        }
        // End Create New Fare Group


        // Move To Group
        vm.moveToGroup = function() {
        	var objectGroupFare = {
        			contractId: $stateParams.id_contract,
        			group: vm.destGroup,
        			fareIdList: []
        	}

        	vm.selectedFares.forEach(function(fare){
        		objectGroupFare.fareIdList.push(fare.id);
			});

        	ContractFareCalculated.move(objectGroupFare, function(onSuccess) {
        		vm.resetCheckbox();

        		$state.go($state.current.name, { group: vm.destGroup.name }, { reload: true });
        	}, function(onError) {})
        }
        // End Move To Group

        // Delete Selected Fares
        vm.deleteSelectedFares = function() {
            var objectDeleteFares = {
                contractId: $stateParams.id_contract,
                 idList: []
            };

            vm.selectedFares.forEach(function(fare){
            	objectDeleteFares.idList.push(fare.id);
    		});

            ContractFareCalculated.deleteMultiple(objectDeleteFares, function(onSuccess) {
            	vm.resetCheckbox();

        		$state.go($state.current.name, null, { reload: true });
            }, function(onError) {})
        }
        // End Delete Selected Fares

		vm.back = function(){
		   $( "#tab1calculated" ).trigger( "click" );
		};

		vm.next = function(){
			$( "#tab3calculated" ).trigger( "click" );
	 	};

	 	vm.DirectToException = function() {
	 		$state.go("contract.exception-fare", {id_contract: $stateParams.id_contract, group:vm.activeGroup});
	 	}
	}
})();
