(function() {
    'use strict';
    
    angular
        .module('americanoApp')
        .factory('Airline', Airline);

    Airline.$inject = ['$resource'];

    function Airline ($resource) {
        var resourceUrl =  'api/airlines/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
	    .module('americanoApp')
	    .factory('AirlineCustom', AirlineCustom);
    
    AirlineCustom.$inject = ['$resource'];
    
    function AirlineCustom($resource) {
    	var resourceUrl =  'api/airlines/getAllAirlines';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
    	.module('americanoApp')
        .factory('AirlineSearch', AirlineSearch);
    
    AirlineSearch.$inject = ['$resource'];

    function AirlineSearch($resource) {
    	var resourceUrl = 'api/_search/airlines/:query'; 
    	return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
    
})();
