(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', '$uibModalInstance', 'entity', 'User', 'JhiLanguageService'];

    function UserManagementDialogController ($stateParams, $uibModalInstance, entity, User, JhiLanguageService) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN'];
        vm.accounts = ['AUFCTG', 'NZFCTG'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;
        vm.roles = [];
        
        vm.selectedUserAccount = [];
        vm.selectedRow = [];
        vm.selectedStatus = [];
        vm.accountIndex = [];
        //console.log(vm.user.accounts);
        
        User.getRoles(function(data) {
        	vm.roles = data;
        });
        
        if(vm.user.id != null){
	        vm.user.$promise.then(function(){
	        	User.getAccounts(function(data){
	            	vm.accounts = data;
	            });
	        });
        }
        else{
        	User.getAccounts(function(data){
            	vm.accounts = data;
            });        	
        }
        vm.addSelectedUserAccount = function() {
        	vm.selectedUserAccount.forEach(function(account) {
        		vm.user.accounts.push(account);
          });
//        	vm.user.accounts.push()
//            if(!vm.distributionGroupMaster.form_items_child){
//                vm.distributionGroupMaster.form_items_child = [];
//            }
//
//            vm.selectedRoutes.forEach(function(distribution) {
//                vm.distributionGroupMaster.form_items_child.push(distribution);
//            });
//            getSelectedRoutesInContract()
//            vm.resetCheckbox();
        }
        
        vm.registerAllAccount = function(isChecked) {
            if (isChecked) {
                vm.selectedRow = [];
                vm.selectedUserAccount = [];
                vm.accountIndex = [];
                for (var i = 0; i < vm.accounts.length; i++) {
                    vm.selectedUserAccount.push(vm.accounts[i]);
                    vm.accountIndex.push(vm.lastIndex - i - (vm.index * vm.size));
                    vm.selectedStatus[i] = true;
                    vm.selectedRow.push(vm.lastIndex - i);
                }

            } else {
                vm.selectedRow = [];
                vm.selectedRoutes = [];
                vm.routeIndex = [];
                for (var i = 0; i < vm.selectedStatus.length; i++) {
                    vm.selectedStatus[i] = false;
                }
            }

        }
        
        vm.resetCheckbox = function() {
            vm.selectedUserAccount = [];
            for (var i = 0; i < vm.selectedStatus.length; i++) {
                vm.selectedStatus[i] = false;
            }
            vm.master = false;
        }
        
        vm.deleteAccountInUser = function(index) {
            vm.user.accounts.splice(index, 1);
//            vm.selectedDistributionMasterSaved.splice(index, 1);
        }
        
        vm.rowHighlighted = function(row, account, index) {
    		 var selectedExist = false;

             for (var i = 0; i < vm.selectedRow.length; i++) {
                 if (vm.selectedRow[i] == row) {
                     vm.selectedRow.splice(i, 1);
                     selectedExist = true;
                     break;
                 }
             }

             if (selectedExist)
                 vm.selectedStatus[index] = false;
             else {
                 vm.selectedStatus[index] = true;
                 vm.selectedRow.push(row);
             }

             vm.registerUserAccount(row, account, index);
        }
        
        vm.registerUserAccount = function(transactionIndex, account, index) {
            if (vm.selectedStatus[index]) {
                vm.selectedUserAccount.push(account);
                vm.accountIndex.push(transactionIndex - (vm.index * vm.size));
                vm.selectedStatus[index] = true;

            } else {
                vm.selectedUserAccount.splice(vm.selectedUserAccount.indexOf(account), 1);
                vm.accountIndex.splice(vm.accountIndex.indexOf(transactionIndex), 1);
                vm.selectedStatus[index] = false;
            }

//            if (vm.selectedUserAccount.length == vm.dataDistributionGroup.length) {
//                vm.master = true;
//            } else
//                vm.master = false;

        }
        
        vm.getSelectedRowStatus = function(row) {
            var status = false;

            if (vm.master) {
                status = true;
            } else {
                for (var i = 0; i < vm.selectedRow.length; i++) {
                    if (vm.selectedRow[i] == row) {
                        status = true;
                        break;
                    }
                }
            }

            return status;
        }
        
        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            $uibModalInstance.close(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function save () {
//        	console.log(vm.user);
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }
    }
})();
