(function() {
	'use strict';

	angular.module('americanoApp').controller('ZoningDetailController',
			ZoningDetailController);

	ZoningDetailController.$inject = [ '$scope', '$rootScope', '$stateParams',
			'entity', 'Zoning' ];

	function ZoningDetailController($scope, $rootScope, $stateParams, entity,
			Zoning) {
		var vm = this;
		vm.zoning = entity;
		vm.load = function(id) {
			Zoning.get({
				id : id
			}, function(result) {
				vm.zoning = result;
			});
		};

		var unsubscribe = $rootScope.$on('americanoApp:zoningUpdate', function(
				event, result) {
			vm.team = result;
		});
		$scope.$on('$destroy', unsubscribe);

	}
})();
