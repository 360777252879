(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('RoutingTariffAmadeus', RoutingTariffAmadeus);

    RoutingTariffAmadeus.$inject = ['$resource'];

    function RoutingTariffAmadeus ($resource) {
        var resourceUrl =  'api/routing-tariff-amadeuses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
	    .module('americanoApp')
	    .factory('RoutingTariffAmadeusCustom', RoutingTariffAmadeusCustom);
	
    RoutingTariffAmadeusCustom.$inject = ['$resource'];
	
	function RoutingTariffAmadeusCustom ($resource) {
	    var resourceUrl =  'api/routing-tariff-amadeuses/getRoutingTariffAmadeus';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	angular
	.module('americanoApp')
    .factory('TariffNumberSearch', TariffNumberSearch);

	TariffNumberSearch.$inject = ['$resource'];

	function TariffNumberSearch($resource) {
		var resourceUrl = 'api/_search/tariff-number/:query'; 
		return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true}
    	});
	}
})();
