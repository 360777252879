(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('GdsFormMappingDeleteController',GdsFormMappingDeleteController);

    GdsFormMappingDeleteController.$inject = ['$uibModalInstance', 'entity', 'GdsFormMapping'];

    function GdsFormMappingDeleteController($uibModalInstance, entity, GdsFormMapping) {
        var vm = this;
        vm.gdsFormMapping = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            GdsFormMapping.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
