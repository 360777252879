(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('SeasonTypeDeleteController',SeasonTypeDeleteController);

    SeasonTypeDeleteController.$inject = ['$uibModalInstance', 'entity', 'SeasonType'];

    function SeasonTypeDeleteController($uibModalInstance, entity, SeasonType) {
        var vm = this;
        vm.seasonType = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            SeasonType.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
