(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('StayMeasurementFromMinAPFDetailController', StayMeasurementFromMinAPFDetailController);

    StayMeasurementFromMinAPFDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'StayMeasurementFromMinAPF'];

    function StayMeasurementFromMinAPFDetailController($scope, $rootScope, $stateParams, entity, StayMeasurementFromMinAPF) {
        var vm = this;
        vm.stay_measurement_from_min_apf = entity;
        vm.load = function (id) {
        	StayMeasurementFromMinAPF.get({id: id}, function(result) {
                vm.stay_measurement_from_min_apf = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:stay_measurement_from_min_apfUpdate', function(event, result) {
            vm.stay_measurement_from_min_apf = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
