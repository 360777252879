(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FareDetailController', FareDetailController);

    FareDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'Fare'];

    function FareDetailController($scope, $rootScope, $stateParams, entity, Fare) {
        var vm = this;
        vm.fare = entity;
        vm.load = function (id) {
            Fare.get({id: id}, function(result) {
                vm.fare = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:fareUpdate', function(event, result) {
            vm.fare = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
