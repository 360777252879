(function() {
    'use strict';

    angular.module('americanoApp').config(stateConfig);

    stateConfig.$inject = [ '$stateProvider' ];

    function stateConfig($stateProvider) {
    	$stateProvider
    	.state('contract.fare', {
            parent : 'entity',
            url : '/contract/fare/{id_contract}?page&sort&search&group&newGroup',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/contract/fare/form-fare.html',
                    controller : 'FormFareController',
                    controllerAs : 'vm'
                }
            },
            params : {
                page : {
                    value : '1',
                    squash : true
                },
                sort : {
                    value : 'id,desc',
                    squash : true
                },
                search : null,
                group : "DEFAULT",
                newGroup: "0",
                duplicateFare: false,
                type:null
            },
            resolve : {
                translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('fare');
                		return $translate.refresh();
                }],
                pagingParams : ['$stateParams', 'PaginationUtil', function($stateParams, PaginationUtil) {
                    return {
                        page : PaginationUtil
                            .parsePage($stateParams.page),
                        sort : $stateParams.sort,
                        predicate : PaginationUtil
                            .parsePredicate($stateParams.sort),
                        ascending : PaginationUtil
                            .parseAscending($stateParams.sort),
                        search : $stateParams.search
                    };
                }]
            }
    	})
    	.state('contract.fare.view', {
            parent : 'contract.fare',
            url : '/view/{index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            views : {
                'content@' : {
                    templateUrl : 'app/entities/contract/fare/form-fare-view.html',
                    controller : 'FormViewFareController',
                    controllerAs : 'vm'
                }
            },
            resolve : {
                translatePartialLoader : ['$translate','$translatePartialLoader',
                    function($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('fare');
                        return $translate.refresh();
                    }],
                form1 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
                	return FormContract.get({tag : "fare_flight_information"});
               	}],
                form2 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
                	return FormContract.get({tag : "fare_fare_information"});
                }],
                form3 : [ '$stateParams', 'FormContract', function($stateParams, FormContract) {
                	return FormContract.get({tag : "fare_ticket_information"});
             	}],
            }
        })
        .state('contract.fare.new', {
            parent : 'contract.fare',
            url : '/new',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/fare/form-fare-dialog.html',
                    controller : 'FormFareDialogController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'lg',
                    resolve : {
                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('fare');
                        	return $translate.refresh();
                       	}],
                        form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({tag : "fare_flight_information"});
                        }],
                        form2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({tag : "fare_fare_information"});
                   	 	}],
                   	 	form3 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                         	return FormContract.get({tag : "fare_ticket_information"});
                    	}],
                    	airlines : ['AirlineCustom', function(AirlineCustom) {
                    		return AirlineCustom.get();
                     	}],
                        passengers : ['PassengerCustom', function(PassengerCustom) {
                        	return PassengerCustom.get();
                        }],
                        currencies : ['CurrencyCustom', function(CurrencyCustom) {
                          	return CurrencyCustom.get();
                      	}],
                        season_type : ['SeasonTypeCustom', function(SeasonTypeCustom) {
                        	return SeasonTypeCustom.get();
                        }],
                        routing_tariff_amadeus : ['RoutingTariffAmadeusCustom', function(RoutingTariffAmadeusCustom) {
                        	return RoutingTariffAmadeusCustom.get();
                     	}],
                        fare_type : ['FareTypeCustom', function(FareTypeCustom) {
                        	return FareTypeCustom.get();
                        }],
                        fare_type_apf : ['FareTypeAPFCustom', function(FareTypeAPFCustom) {
                        	return FareTypeAPFCustom.get();
                        }],
                        transporting_class : ['TransportingClassCustom', function(TransportingClassCustom) {
                        	return TransportingClassCustom.get();
                        }],
                    }
                }).result.then(function() {
                	$state.go('contract.fare', {newGroup: "0"}, {reload : true});
                }, function() {
                	$state.go('contract.fare', {group : 'DEFAULT', newGroup: "0"}, {reload : true});
                });
            }]
        })
        .state('contract.fare.edit', {
            parent : 'contract.fare',
            url : '/edit/{index}',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/fare/form-fare-dialog.html',
                    controller : 'FormFareDialogController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    keyboard: false,
                    size : 'lg',
                    resolve : {
                    	translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('fare');
                        	return $translate.refresh();
                       	}],
                        form1 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({tag : "fare_flight_information"});
                        }],
                        form2 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                        	return FormContract.get({tag : "fare_fare_information"});
                   	 	}],
                   	 	form3 : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                         	return FormContract.get({tag : "fare_ticket_information"});
                    	}],
                    	airlines : ['AirlineCustom', function(AirlineCustom) {
                    		return AirlineCustom.get();
                     	}],
                        passengers : ['PassengerCustom', function(PassengerCustom) {
                        	return PassengerCustom.get();
                        }],
                        currencies : ['CurrencyCustom', function(CurrencyCustom) {
                          	return CurrencyCustom.get();
                      	}],
                        season_type : ['SeasonTypeCustom', function(SeasonTypeCustom) {
                        	return SeasonTypeCustom.get();
                        }],
                        routing_tariff_amadeus : ['RoutingTariffAmadeusCustom', function(RoutingTariffAmadeusCustom) {
                        	return RoutingTariffAmadeusCustom.get();
                     	}],
                        fare_type : ['FareTypeCustom', function(FareTypeCustom) {
                        	return FareTypeCustom.get();
                        }],
                        fare_type_apf : ['FareTypeAPFCustom', function(FareTypeAPFCustom) {
                        	return FareTypeAPFCustom.get();
                        }],
                        transporting_class : ['TransportingClassCustom', function(TransportingClassCustom) {
                        	return TransportingClassCustom.get();
                        }],
                    }
                }).result.then(function() {
                	$state.go('contract.fare', null, {reload : true});
                }, function() {
                	$state.go('contract.fare', null, {reload : true});
                });
            }]
        })
        .state('contract.fare.delete', {
            parent : 'contract.fare',
            url : '/{index}/delete',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
            },
            onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/fare/form-fare-delete-dialog.html',
                    controller : 'FormFareDeleteDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {}
                }).result.then(function(result) {
                	$state.go('contract.fare', {id_contract : $stateParams.id_contract}, {reload : true});
                }, function() {
                	$state.go('^');
            	})
            }]
        })
        .state('contract.fare.copy', {
        	parent : 'contract.fare',
        	url : '/{index}/copy',
        	data : {
        		authorities : [ 'ROLE_ADMIN', 'ROLE_USER', 'ROLE_ADMIN_USER' ],
        	},
        	onEnter : ['$stateParams', '$state', '$uibModal', 'Contract', function($stateParams, $state, $uibModal, Contract) {
                $uibModal.open({
                    templateUrl : 'app/entities/contract/fare/form-fare-copy-dialog.html',
                    controller : 'FormFareCopyDialogController',
                    controllerAs : 'vm',
                    size : 'md',
                    resolve : {
                    }
                }).result.then(function(result) {
                	$state.go('contract.fare.edit', {index : result, id_contract : $stateParams.id_contract, duplicateFare : true}, {reload : true});
                }, function() {
                	$state.go('^');
                })
            }]
        })
        .state('contract.fare.upload', {
            parent : 'contract.fare',
            url : '/upload',
            data : {
                authorities : [ 'ROLE_USER', 'ROLE_ADMIN_USER'  ],
                pageTitle : 'americanoApp.form.detail.title'
            },
            onEnter : ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
            	$uibModal.open({
                    templateUrl : 'app/entities/contract/fare/form-upload-fare.html',
                    controller : 'FormUploadFareController',
                    controllerAs : 'vm',
                    backdrop : 'static',
                    size : 'md',
                    resolve : {
                        entity : function() {
                        	return {
	                            file : null,
	                            contractId : null,
	                            contentType : null,
	                            containError : false,
	                            fareList : null,
	                            userList : [],
	                            group : null
                            };
                        },
                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                        	$translatePartialLoader.addPart('fare');
                        	return $translate.refresh();
                     	}]
                    }
                }).result.then(function() {
	                $state.go('contract.fare', null, { reload : true });
                }, function() {
                    $state.go('contract.fare', null, { reload : true });
                });
            }]
        })
    }
})();
