(function() {
	'use strict';
	angular.module('americanoApp').factory('Zoning', Zoning);

	Zoning.$inject = [ '$resource' ];

	function Zoning($resource) {
		var resourceUrl = 'api/zonings/:id';

		return $resource(resourceUrl, {}, {
			'query' : {
				method : 'GET',
				isArray : true
			},
			'get' : {
				method : 'GET',
				transformResponse : function(data) {
					data = angular.fromJson(data);
					return data;
				}
			},
			'update' : {
				method : 'PUT'
			},
            'getAll' : {
			    method : 'GET',
                url : 'api/zonings/getAllZonings',
                isArray : true
            },
            'publish': {method:'POST', url : 'api/zonings/publish/:id'}
		});
	}

})();
