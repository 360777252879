(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('EligibilityController', EligibilityController);

    EligibilityController.$inject = [
        '$scope', '$parse', '$state', '$stateParams', '$uibModal',
        'ContractEligibility', 'form24', '$uibModalInstance', 'passengers', 'ContractValue'];

    function EligibilityController (
        $scope, $parse, $state, $stateParams, $uibModal,
        ContractEligibility, form24, $uibModalInstance, passengers, ContractValue) {

        var vm = this;
        vm.passengers = passengers;
        vm.entity = ContractEligibility.get({ id : $stateParams.id_contract });
        vm.form24 = form24;
        vm.id_contract = $stateParams.id_contract;
        vm.index = $stateParams.index;

        vm.passengers.$promise.then(function(group) {
        	group.forEach(function(passenger) {
        		if (passenger.code == "ADT") {
        			if (vm.form24 != undefined) {
        				vm.form24.$promise.then(function(form) {
            				for (var i = 0; i < form.form_items.length; i++) {
            					if (form.form_items[i].tag == "rules_eligibility_passenger_type") {
                					form.form_items[i].value = passenger;
                					break;
                				}
            				}
            			});
        			}
        		}
        	});
        });

        vm.entity.$promise.then(function(contract) {
            vm.ruleAppRules = contract.data[16].pages;

            if (vm.index < vm.ruleAppRules.length)
                vm.editRuleEligibility(vm.ruleAppRules[vm.index]);
        });

        //RULES ELIGIBILITY
        vm.addRuleEligibility = function(){
            vm.entity.data[16].pages.push({
                forms:[
                    angular.copy(vm.form24)
                ]
            });
        }

        vm.editRuleEligibility = function(x){
            vm.form24.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form24);
            });
            // ContractValue.setValueFromDatabase(x.forms[0], vm.form24);
            // vm.form24 = angular.copy(x.forms[0]);

            vm.editObjectRuleEligibility= x;
            vm.isRuleEligibilityEdit = true;
        };

        vm.saveEligibilityEdit = function(){
            vm.entity.data[16].pages[vm.entity.data[16].pages.indexOf(vm.editObjectRuleEligibility)].forms[0] = angular.copy(vm.form24);


            vm.editObjectRuleEligibility= null;
            vm.isRuleEligibilityEdit = false;
        };
        //END RULES ELIGIBILITY

        vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
    	};

		vm.save = function() {
            var forms = [vm.form24];

            if (vm.isRuleEligibilityEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractEligibility.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractEligibility.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isRuleEligibilityEdit)
				// vm.saveEligibilityEdit();
            // else
				// vm.addRuleEligibility();
            //
            // ContractEligibility.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.resetRadio = function() {
            vm.form24.form_items[0].value = null;
        }
    }
})();
