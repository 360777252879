(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('Passenger', Passenger);

    Passenger.$inject = ['$resource'];

    function Passenger ($resource) {
        var resourceUrl =  'api/passengers/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
	.module('americanoApp')
    .factory('SearchPassenger', SearchPassenger);

    SearchPassenger.$inject = ['$resource'];

	function SearchPassenger($resource) {
		var resourceUrl = 'api/_search/passengers/:query'; 
		return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true}
	    });
	}

	angular
    .module('americanoApp')
    .factory('PassengerCustom', PassengerCustom);

	PassengerCustom.$inject = ['$resource'];
	
	function PassengerCustom($resource) {
		var resourceUrl =  'api/passengers/getAllPassengers';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
})();
