(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('ReturnTravelSabreDialogController', ReturnTravelSabreDialogController);

    ReturnTravelSabreDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ReturnTravelSabre'];

    function ReturnTravelSabreDialogController ($scope, $stateParams, $uibModalInstance, entity, ReturnTravelSabre) {
        var vm = this;
        vm.returnTravelSabre = entity;
        vm.load = function(id) {
            ReturnTravelSabre.get({id : id}, function(result) {
                vm.returnTravelSabre = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:returnTravelSabreUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.returnTravelSabre.id !== null) {
                ReturnTravelSabre.update(vm.returnTravelSabre, onSaveSuccess, onSaveError);
            } else {
                ReturnTravelSabre.save(vm.returnTravelSabre, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
