(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('SurchargeController', SurchargeController);

    SurchargeController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', '$uibModal',
    'ContractSurcharge',
    'currencies',
    'surcharge_type', 'airlines', 'travelSegmentIndicatorSabre', 'form7', 'form8', 'form9', 'form10', 'form11', '$uibModalInstance', 'ContractValue'];

    function SurchargeController (
    		$scope, $parse, $state, $stateParams, $uibModal,
    		ContractSurcharge,
    		currencies,
    		surcharge_type, airlines, travelSegmentIndicatorSabre, form7, form8, form9, form10, form11, $uibModalInstance, ContractValue) {

    	var vm = this;
    	vm.entity = ContractSurcharge.get({ id : $stateParams.id_contract });
    	vm.currencies = currencies;
    	vm.surcharge_type = surcharge_type;
    	vm.airlines = airlines;
    	vm.travelSegmentIndicatorSabre = travelSegmentIndicatorSabre;
		vm.form7 = form7;
		vm.form8 = form8;
		vm.form9 = form9;
		vm.form10 = form10;
		vm.form11 = form11;
		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;
		vm.countervia = 0;
		vm.counterbetween = 0;	
		vm.counter = 0;
		vm.typeGeo = null;

		vm.entity.$promise.then(function(contract) {
    		vm.surchargeRules = contract.data[8].pages;

    		if (vm.index < vm.surchargeRules.length)
    			vm.editSurcharge(vm.surchargeRules[vm.index]);
    	});


		//SURCHARGE
		vm.addSurcharge = function(){
			vm.entity.data[8].pages.push({
				forms:[
					angular.copy(vm.form7),
					angular.copy(vm.form8),
					angular.copy(vm.form9),
					angular.copy(vm.form10),
					angular.copy(vm.form11),
				]
			});
		}

		vm.editSurcharge = function(x){
            vm.form7.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form7);
            });
            vm.form8.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[1], vm.form8);
            });
            vm.form9.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[2], vm.form9);
            });
            vm.form10.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[3], vm.form10);
            });
            vm.form11.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[4], vm.form11);
            });
            // vm.form7 = angular.copy(x.forms[0]);
			// vm.form8 = angular.copy(x.forms[1]);
			// vm.form9 = angular.copy(x.forms[2]);
			// vm.form10 = angular.copy(x.forms[3]);
			// vm.form11 = angular.copy(x.forms[4]);

			vm.editObjectSurcharge = x;
			vm.isSurchargeEdit = true;
		};

		vm.saveSurchargeEdit = function(){
			vm.entity.data[8].pages[vm.entity.data[8].pages.indexOf(vm.editObjectSurcharge)].forms[0] = angular.copy(vm.form7);
			vm.entity.data[8].pages[vm.entity.data[8].pages.indexOf(vm.editObjectSurcharge)].forms[1] = angular.copy(vm.form8);
			vm.entity.data[8].pages[vm.entity.data[8].pages.indexOf(vm.editObjectSurcharge)].forms[2] = angular.copy(vm.form9);
			vm.entity.data[8].pages[vm.entity.data[8].pages.indexOf(vm.editObjectSurcharge)].forms[3] = angular.copy(vm.form10);
			vm.entity.data[8].pages[vm.entity.data[8].pages.indexOf(vm.editObjectSurcharge)].forms[4] = angular.copy(vm.form11);

			vm.editObjectSurcharge = null;
			vm.isSurchargeEdit = false;
		};

		//GEOGRAPHY APPLICATION
		 vm.pushGeographyApplication = function(x, y){
             y[0].value = vm.geographyApplication.rules_surcharges_5_geography_application_multivalue_geographic_restriction;
             y[1].value = vm.geographyApplication.rules_surcharges_5_geography_application_multivalue_tsi;
             y[2].value = vm.geographyApplication.rules_surcharges_5_geography_application_multivalue_type;
             y[3].value = vm.geographyApplication.rules_surcharges_5_geography_application_multivalue_location_1;
             y[4].value = vm.geographyApplication.rules_surcharges_5_geography_application_multivalue_tsi_2;
             y[5].value = vm.geographyApplication.rules_surcharges_5_geography_application_multivalue_type_2;
             y[6].value = vm.geographyApplication.rules_surcharges_5_geography_application_multivalue_location_2;

             var checkValue = false;
             if(y[0].value != undefined){
                 if(y[0].value.trim() == 'Surcharge only applies when travel is to/from/via' && y[3].value != undefined){
                     checkValue = true;
                 } else if(y[0].value.trim() == 'Surcharge only applies when travel is between' && y[3].value != undefined && y[6].value != undefined){
                     checkValue = true;
                 }
             }

             if(checkValue){
                 if(vm.form11.form_items[x].value == null){
                     vm.form11.form_items[x].value = [];
                 }
                 
                 if (vm.checkCanBeAdded(x, y)) {
                	 vm.form11.form_items[x].value.push(y);
                     var z = angular.copy(vm.form11.form_items[x].value);
                     vm.form11.form_items[x].value = z;
                 }
                 
                 vm.geographyApplication = [];
             }

        };
        
        vm.checkCanBeAdded = function(x, y) {
        	var viaCtr = 0, betweenCtr = 0;
            var viaCanBeAdded = true, betweenCanBeAdded = true;
            
            for (var i = 0; i < vm.form11.form_items[x].value.length; i++) {
           	 if (vm.form11.form_items[x].value[i][0].value.trim() == 'Surcharge only applies when travel is to/from/via')
           		 viaCtr++;
           	 else if (vm.form11.form_items[x].value[i][0].value.trim() == 'Surcharge only applies when travel is between')
           		 betweenCtr++;
            }
            
            if (viaCtr >= 1 && y[0].value.trim() == 'Surcharge only applies when travel is to/from/via')
           	 viaCanBeAdded = false;
            
            if (betweenCtr >= 2 && y[0].value.trim() == 'Surcharge only applies when travel is between')
           	 betweenCanBeAdded = false;
            
            return viaCanBeAdded && betweenCanBeAdded;
        }

        vm.removeGeographyApplication = function(x, y){
            vm.form11.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form11.form_items[x].value);
        	vm.form11.form_items[x].value = z;
        	vm.counter = vm.counter-1;
        };
        
        vm.copyGeographyApplication = function(x, y){
        	var data = angular.copy(vm.form11.form_items[x].value[y]);
        	
        	if (vm.checkCanBeAdded(x, data)) {
            	vm.form11.form_items[x].value.push(data);
            	
            	var newItemIndex = vm.form11.form_items[x].value.length - 1;        	
            	
            	if (vm.isEditGeographyApplication == undefined || vm.isEditGeographyApplication == null) {
            		vm.isEditGeographyApplication = {}
            	}        	
            	
            	vm.isEditGeographyApplication[newItemIndex] = true;
        	}
        };
		//END GEOGRAPHY APPLICATION

        //FLIGHT RESTRICTION
        vm.pushFlightRestriction = function(x, y){
            if(vm.flightRestriction.rules_surcharges_4_flight_restriction_multivalue_marketing_carrier != undefined )
                {
                y[0].value = vm.flightRestriction.rules_surcharges_4_flight_restriction_multivalue_marketing_carrier;
                y[1].value = vm.flightRestriction.rules_surcharges_4_flight_restriction_multivalue_operating_carrier;
                y[2].value = vm.flightRestriction.rules_surcharges_4_flight_restriction_multivalue_flight_number_option;
                y[3].value = vm.flightRestriction.rules_surcharges_4_flight_restriction_multivalue_flight_number;
                y[4].value = vm.flightRestriction.rules_surcharges_4_flight_restriction_multivalue_through;

                if(vm.form10.form_items[x].value == null){
                    vm.form10.form_items[x].value = [];
                }
                vm.form10.form_items[x].value.push(y);
                var z = angular.copy(vm.form10.form_items[x].value);
                vm.form10.form_items[x].value = z;

                vm.flightRestriction = [];
            }
        };

        vm.removeFlightRestriction = function(x, y){
        	vm.form10.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form10.form_items[x].value);
        	vm.form10.form_items[x].value = z;
        }
        
        vm.copyFlightRestriction = function(x, y){
        	vm.data = angular.copy(vm.form10.form_items[x].value[y]);        	
        	vm.form10.form_items[x].value.push(vm.data);
        	
        	var newItemIndex = vm.form10.form_items[x].value.length - 1;        	
        	
        	if (vm.isEditFlightRestriction == undefined || vm.isEditFlightRestriction == null) {
        		vm.isEditFlightRestriction = {}
        	}        	
        	
        	vm.isEditFlightRestriction[newItemIndex] = true;
        };
        //END FLIGHT RESTRICTION

        //AMOUNT SURCHARGE
        vm.pushAmountSurcharge = function(x, y){

            if(vm.amountSurcharge.rules_surcharges_2_surcharges_amount_multivalue_currency != undefined && vm.amountSurcharge.rules_surcharges_2_surcharges_amount_multivalue_amount != undefined){
                y[0].value = vm.amountSurcharge.rules_surcharges_2_surcharges_amount_multivalue_currency;
                y[1].value = vm.amountSurcharge.rules_surcharges_2_surcharges_amount_multivalue_amount;

                if(vm.form8.form_items[x].value == null){
                    vm.form8.form_items[x].value = [];
                }
                vm.form8.form_items[x].value.push(y);
                var z = angular.copy(vm.form8.form_items[x].value);
                vm.form8.form_items[x].value = z;

                vm.amountSurcharge = [];
            }
        };

        vm.removeAmountSurcharge = function(x, y){
        	vm.form8.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form10.form_items[x].value);
        	vm.form8.form_items[x].value = z;
        }
        
        vm.copyAmountSurcharge = function(x, y){
        	vm.data = angular.copy(vm.form8.form_items[x].value[y]);        	
        	vm.form8.form_items[x].value.push(vm.data);
        	
        	var newItemIndex = vm.form8.form_items[x].value.length - 1;        	
        	
        	if (vm.isEditSurchargesAmount == undefined || vm.isEditSurchargesAmount == null) {
        		vm.isEditSurchargesAmount = {}
        	}        	
        	
        	vm.isEditSurchargesAmount[newItemIndex] = true;
        };
        //END AMOUNT SURCHARGE

        vm.checkDate = function(arg) {
     	   var date = Date.parse(arg)

     	   if (isNaN(date) == false)
     	       return arg;
     	   else
     		   return vm.Date(null);
        }

        vm.Date = function(arg){
 	       	if(arg == null){
 	         	   return new Date();
 	       	}else{
 	       		if(arg != "indef"){
 	       			return new Date(arg);
 	       		}else{
 	       			return "indef";
 	       		}
 	       	}
     	};

     	vm.save = function(){
            var forms = [vm.form7, vm.form8, vm.form9, vm.form10, vm.form11];

            if (vm.isSurchargeEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractSurcharge.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractSurcharge.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isSurchargeEdit)
				// vm.saveSurchargeEdit();
            // else
				// vm.addSurcharge();
            //
            // ContractSurcharge.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened = [];
            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.checkValueApplyTo = function(value) {
        	if (!vm.isSurchargeEdit) {
        		if (value == null || value == "" || value == undefined)
                    return "Outbound & Inbound";
        	}

            return value;
        }

        //Time
        $scope.ismeridian = false;
        $scope.hstep = 1;
        $scope.mstep = 15;

        $scope.options = {
          hstep: [1, 2, 3],
          mstep: [1, 5, 10, 15, 25, 30]
        };

        $scope.toggleMode = function() {
          $scope.ismeridian = ! $scope.ismeridian;
        };

        $scope.update = function() {
          var d = new Date();
          d.setHours( 14 );
          d.setMinutes( 0 );
          $scope.mytime = d;
        };

        $scope.changed = function () {
        //$log.log('Time changed to: ' + $scope.mytime);
        };

        $scope.clear = function() {
          $scope.mytime = null;
        };

        vm.resetTime = function(){
            vm.form9.form_items[4].value = null;
            vm.form9.form_items[5].value = null;
        }

            vm.checkTSI = function(value, value2) {
            if (value == null || value == "" || value == undefined)
                return value2;
        }


        vm.checkFlightNumberOption = function(tag, value){
            var isShow = false;

            if(value != undefined) {
                value = value.trim();

                if (value.trim() == "Flight Range Number") {
                    if('rules_surcharges_4_flight_restriction_multivalue_through') {
                        isShow = true;
                    }
                }
            }
          return isShow;
        };

        vm.checkValueFlightNumberOption = function(tag, value, options) {
            if(tag == 'rules_surcharges_4_flight_restriction_multivalue_flight_number_option'){
                if (value == null || value == "" || value == undefined){
                    return options;
                }else{
                    return value;
                }
            }
        };

        vm.checkValueRestriction = function(value, options){

            if(value != undefined)
                var valueTrim = value.trim();

            if(valueTrim == null || valueTrim == "" || valueTrim == undefined){
                return options;
            } else {
                return valueTrim;
            }
        };
        
        vm.changeType = function(options) {
        	if (options.trim() == "Surcharge only applies when travel is to/from/via") {
				vm.typeGeo = "via";
			} else if (options.trim() == "Surcharge only applies when travel is between") {
				vm.typeGeo = "between";
			}
		};

    }
})();
