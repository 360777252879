(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('GdsTemplateElementDeleteController',GdsTemplateElementDeleteController);

    GdsTemplateElementDeleteController.$inject = ['$uibModalInstance', 'entity', 'GdsTemplateElement'];

    function GdsTemplateElementDeleteController($uibModalInstance, entity, GdsTemplateElement) {
        var vm = this;
        vm.gdsTemplateElement = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            GdsTemplateElement.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
