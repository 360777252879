
angular.module('americanoApp')
    .directive('checkboxGroupCustom', ["$q", "$filter", "$timeout", "$compile", function($q, $filter, timer, $compile) {
        return {
        	scope: {
        		item:"=data",
        		parentIndex:"@parentIndex",
        		options:"=options",
        		checkboxName: "@name"
        	},
            controller: function($scope) {
            	angular.forEach($scope.item.validation_rules, function(value){
            		if(value.tag == "mandatory"){
      					$scope.validated = false;
      			  	}
            	});
            	if($scope.validated == undefined)
            		$scope.validated = true;

            	if ($scope.options.length > 1)
            		$scope.single = false;
            	else
            		$scope.single = true;
            },
            link: function(scope, element, attrs, ngModel){
//            	console.log(attrs.name);
            },
            template:
            		'<div class="checkbox-group" ng-if="validated && !single"'
        			+'<checkbox-group min-required="0" name="{{checkboxName}}">'
					+' 	<label ng-repeat="option in options">'
					+'		   <input type="checkbox" name="{{parentIndex}}" checklist-model="item.value" checklist-value="option" validation-form data="item" > {{option}} <br/>'
					+'	</label>'
					+'</checkbox-group>'
					+'</div>'
					+'<div class="checkbox-group single" ng-if="validated && single"'
        			+'<checkbox-group min-required="0" name="{{checkboxName}}">'
					+' 	<label ng-repeat="option in options">'
					+'		   <input type="checkbox" name="{{parentIndex}}" checklist-model="item.value" checklist-value="option" validation-form data="item" > {{option}} <br/>'
					+'	</label>'
					+'</checkbox-group>'
					+'</div>'
            		+'<div class="checkbox-group" ng-if="!validated" ng-class="{single: single}">'
        			+'<checkbox-group min-required="1" name="{{checkboxName}}">'
					+' 	<label ng-repeat="option in options">'
					+'		   <input type="checkbox" name="{{parentIndex}}" checklist-model="item.value" checklist-value="option" validation-form data="item" > {{option}} <br/>'
					+'	</label>'
					+'</checkbox-group>'
					+'</div>'
        }
    }]);
