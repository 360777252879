(function() {
    'use strict';
   
    angular
        .module('americanoApp')
        .factory('VendorAggregateMember', VendorAggregateMember);

    VendorAggregateMember.$inject = ['$resource'];

    function VendorAggregateMember ($resource) {
        var resourceUrl =  'api/vendor-aggregate-members/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
        });
    }
    
    angular
    .module('americanoApp')
    .factory('VendorAggregateMemberFindByVendorAggregate', VendorAggregateMemberFindByVendorAggregate);

	VendorAggregateMemberFindByVendorAggregate.$inject = ['$resource'];
	
	function VendorAggregateMemberFindByVendorAggregate ($resource) {
	    var resourceUrl =  'api/vendor-aggregate-members/findAllByVendorAggregate/:id';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	 angular
	    .module('americanoApp')
	    .factory('VendorAggregateMemberDeleteByVendor', VendorAggregateMemberDeleteByVendor);

	 VendorAggregateMemberDeleteByVendor.$inject = ['$resource'];
		
		function VendorAggregateMemberDeleteByVendor ($resource) {
		    var resourceUrl =  'api/vendor-aggregate-members/deleteByVendor/:id';
		
		    return $resource(resourceUrl, {}, {
		        'query': { method: 'GET', isArray: true},
		        'get': {
		            method: 'GET',
		            transformResponse: function (data) {
		                data = angular.fromJson(data);
		                return data;
		            }
		        },
		        'update': { method:'PUT' }
		    });
		}
})();
