(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormRoutingDeleteDialogController', FormRoutingDeleteDialogController);

    FormRoutingDeleteDialogController.$inject = ['$scope', '$uibModalInstance', 'entity', 'Routing'];

    function FormRoutingDeleteDialogController ($scope, $uibModalInstance, entity, Routing) {
        var vm = this; 
    	vm.routing = entity;
         vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
         };
         
         vm.confirmDelete = function () {
         	 vm.routing.activated = false;
         	 Routing.update(vm.routing, function () {
                	 $uibModalInstance.close(true);
              });
         };
    }
})();