(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('GroupController', GroupController);

    GroupController.$inject = [
        '$scope', '$parse', '$state', '$stateParams', '$uibModal',
        'ContractGroup', 'formGroupCondition', 'formChildrenCondition', 'formTextInformation', '$uibModalInstance', 'ContractValue'];

    function GroupController (
        $scope, $parse, $state, $stateParams, $uibModal,
        ContractGroup, formGroupCondition, formChildrenCondition, formTextInformation, $uibModalInstance, ContractValue) {

        var vm = this;
        vm.entity = ContractGroup.get({ id : $stateParams.id_contract });
        vm.formGroupCondition = formGroupCondition;
        vm.formChildrenCondition = formChildrenCondition;
        vm.formTextInformation = formTextInformation;
        vm.id_contract = $stateParams.id_contract;
        vm.index = $stateParams.index;

        vm.entity.$promise.then(function(contract) {
            vm.ruleAppRules = contract.data[24].pages;

            if (vm.index < vm.ruleAppRules.length)
                vm.editRuleGroup(vm.ruleAppRules[vm.index]);
        });

        //RULES GROUP
        vm.addRuleGroup = function(){
            vm.entity.data[24].pages.push({
                forms:[
                    angular.copy(vm.formGroupCondition),
                    angular.copy(vm.formChildrenCondition),
                    angular.copy(vm.formTextInformation)
                ]
            });
        }

        vm.editRuleGroup = function(x){
            vm.formGroupCondition.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.formGroupCondition);
            });
            vm.formChildrenCondition.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[1], vm.formChildrenCondition);
            });
            vm.formTextInformation.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[2], vm.formTextInformation);
            });
            // ContractValue.setValueFromDatabase(x.forms[0], vm.formGroupCondition);
            // ContractValue.setValueFromDatabase(x.forms[1], vm.formChildrenCondition);
            // ContractValue.setValueFromDatabase(x.forms[2], vm.formTextInformation);

            // vm.formGroupCondition = angular.copy(x.forms[0]);
            // vm.formChildrenCondition = angular.copy(x.forms[1]);
            // vm.formTextInformation = angular.copy(x.forms[2]);

            vm.editObjectRuleGroup = x;
            vm.isRuleGroupEdit = true;
        };

        vm.saveGroupEdit = function(){

            vm.entity.data[24].pages[vm.entity.data[24].pages.indexOf(vm.editObjectRuleGroup)].forms[0] = angular.copy(vm.formGroupCondition);
            vm.entity.data[24].pages[vm.entity.data[24].pages.indexOf(vm.editObjectRuleGroup)].forms[1] = angular.copy(vm.formChildrenCondition);
            vm.entity.data[24].pages[vm.entity.data[24].pages.indexOf(vm.editObjectRuleGroup)].forms[2] = angular.copy(vm.formTextInformation);

            vm.editObjectRuleGroup = null;
            vm.isRuleGroupEdit = false;
        };
        //END RULES GROUP

        vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
    	};

		vm.save = function(){
            var forms = [vm.formGroupCondition, vm.formChildrenCondition, vm.formTextInformation];

            if (vm.isRuleGroupEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractGroup.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractGroup.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isRuleGroupEdit)
				// vm.saveGroupEdit();
            // else
				// vm.addRuleGroup();
            //
            // // ContractRuleApplicationOnly.update(vm.entity, function(result){
            // ContractGroup.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.setValue = function () {
            if(vm.formChildrenCondition.form_items[0].value == 'Each Child Counts as One Passenger'){
                vm.formChildrenCondition.form_items[3].value = '';
                vm.formChildrenCondition.form_items[2].value = '';
            } else if(vm.formChildrenCondition.form_items[0].value == 'Number of Children Grouped as One Passenger'){
                vm.formChildrenCondition.form_items[1].value = '';
            }

        }

        vm.resetOption = function() {
            vm.formChildrenCondition.form_items[0].value = null;
        };
    }
})();
