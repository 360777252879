(function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('gds-template-element', {
            parent: 'entity',
            url: '/gds-template-element?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.gdsTemplateElement.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/gds-template-element/gds-template-elements.html',
                    controller: 'GdsTemplateElementController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gdsTemplateElement');
                    $translatePartialLoader.addPart('enumYesNo');
                    $translatePartialLoader.addPart('enumYesNo');
                    $translatePartialLoader.addPart('enumDataType');
                    $translatePartialLoader.addPart('enumFormatType');
                    $translatePartialLoader.addPart('enumYesNo');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('gds-template-element-detail', {
            parent: 'entity',
            url: '/gds-template-element/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.gdsTemplateElement.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/gds-template-element/gds-template-element-detail.html',
                    controller: 'GdsTemplateElementDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gdsTemplateElement');
                    $translatePartialLoader.addPart('enumYesNo');
                    $translatePartialLoader.addPart('enumYesNo');
                    $translatePartialLoader.addPart('enumDataType');
                    $translatePartialLoader.addPart('enumFormatType');
                    $translatePartialLoader.addPart('enumYesNo');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'GdsTemplateElement', function($stateParams, GdsTemplateElement) {
                    return GdsTemplateElement.get({id : $stateParams.id});
                }]
            }
        })
        .state('gds-template-element.new', {
            parent: 'gds-template-element',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gds-template-element/gds-template-element-dialog.html',
                    controller: 'GdsTemplateElementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                elementTag: null,
                                elementName: null,
                                elementParent: null,
                                elementGroup: 'NO',
                                elementAttibute: 'NO',
                                elementDescription: null,
                                elementDataType: 'STRING',
                                elementFormatType: 'ELEMENTTAG',
                                elementFormatValue: null,
                                elementValueTagReff: null,
                                elementDefaultValue: null,
                                elementCreatedDate: null,
                                elementCreatedBy: null,
                                elementModifiedDate: null,
                                elementModifiedBy: null,
                                elementActive: 'YES',
                                templateTag: null,
                                id: null
                            };
                        },
                        gdstemplates:['GdsTemplateCustom', function(GdsTemplateCustom){
                        	return GdsTemplateCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('gds-template-element', null, { reload: true });
                }, function() {
                    $state.go('gds-template-element');
                });
            }]
        })
        .state('gds-template-element.edit', {
            parent: 'gds-template-element',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gds-template-element/gds-template-element-dialog.html',
                    controller: 'GdsTemplateElementDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['GdsTemplateElement', function(GdsTemplateElement) {
                            return GdsTemplateElement.get({id : $stateParams.id});
                        }],
                        gdstemplates:['GdsTemplateCustom', function(GdsTemplateCustom){
                        	return GdsTemplateCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('gds-template-element', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('gds-template-element.delete', {
            parent: 'gds-template-element',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gds-template-element/gds-template-element-delete-dialog.html',
                    controller: 'GdsTemplateElementDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['GdsTemplateElement', function(GdsTemplateElement) {
                            return GdsTemplateElement.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('gds-template-element', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
