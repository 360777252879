(function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('interior', {
            parent: 'entity',
            url: '/interior?page&sort&search',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER'],
                pageTitle: 'americanoApp.interior.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/interior/interiors.html',
                    controller: 'InteriorController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('interior');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('interior-detail', {
            parent: 'interior',
            url: '/interior/{id}',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER'],
                pageTitle: 'americanoApp.interior.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/interior/interior-detail.html',
                    controller: 'InteriorDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('interior');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Interior', function($stateParams, Interior) {
                    return Interior.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'interior',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('interior-detail.edit', {
            parent: 'interior-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/interior/interior-dialog.html',
                    controller: 'InteriorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Interior', function(Interior) {
                            return Interior.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('interior.new', {
            parent: 'interior',
            url: '/new',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/interior/interior-dialog.html',
                    controller: 'InteriorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                description: null,
                                include: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('interior', null, { reload: 'interior' });
                }, function() {
                    $state.go('interior');
                });
            }]
        })
        .state('interior.edit', {
            parent: 'interior',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/interior/interior-dialog.html',
                    controller: 'InteriorDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Interior', function(Interior) {
                            return Interior.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('interior', null, { reload: 'interior' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('interior.delete', {
            parent: 'interior',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER','ROLE_ADMIN_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/interior/interior-delete-dialog.html',
                    controller: 'InteriorDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Interior', function(Interior) {
                            return Interior.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('interior', null, { reload: 'interior' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
