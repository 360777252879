(function() {
	'use strict';

	angular.module('americanoApp').controller('NavbarController',
			NavbarController);

	NavbarController.$inject = ['$scope', '$rootScope', '$location', '$state', 'Auth', 'Principal',
			'ENV', 'LoginService', 'NotificationCustom', 'WebsocketService'];

	function NavbarController($scope, $rootScope, $location, $state, Auth, Principal, ENV,
			LoginService, NotificationCustom, WebsocketService) {
		var vm = this;
		vm.settingsAccount = null;
		loadNotification();
		
		function loadNotification() {
			NotificationCustom.get({}, function(data) {
				vm.newNotifications = data.customLongVariableType;
			}, function(error) {});
		}
		
		// Receive notification from server
		WebsocketService.receiveNotification().then(null, null, function(notification) {
			$scope.$emit('americanoApp:refreshNotification', "");
			
            loadNotification();
        });
		
		// Update notification count
		var unsubscribeUN = $rootScope.$on('americanoApp:updateNotification', function(event, result) { 
			loadNotification();
		});
		
		$scope.$on('$destroy', unsubscribeUN);
		
		/**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };
        
        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
        });

		vm.navCollapsed = true;
		vm.isAuthenticated = Principal.isAuthenticated;
		vm.inProduction = ENV === 'dev';
		vm.login = login;
		vm.logout = logout;
		vm.$state = $state;

		function login() {
			LoginService.open();
		}

		function logout() {
			Auth.logout();
			$state.go('login');
		}
	}
})();


//var unsubscribeFU = $rootScope.$on('americanoApp:fareUpload', function(event, result) { 
//	loadNotification();
//});
//
//$scope.$on('$destroy', unsubscribeFU);

//var unsubscribeVC = $rootScope.$on('americanoApp:contractValidate', function(event, result) { 
//	loadNotification();
//});
//
//$scope.$on('$destroy', unsubscribeVC);
//
//var unsubscribePC = $rootScope.$on('americanoApp:publishContract', function(event, result) { 
//	loadNotification();
//});
//
//$scope.$on('$destroy', unsubscribePC);
