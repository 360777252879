(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormDistributionCopyDialogController', FormDistributionCopyDialogController);

    FormDistributionCopyDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'ContractDistribution'];

    function FormDistributionCopyDialogController ($scope, $stateParams, $uibModalInstance, ContractDistribution) {
    	var vm = this;

    	vm.clear = function() {
             $uibModalInstance.dismiss('cancel');
        };

        vm.confirmCopy = function () {
        	ContractDistribution.copy({id:$stateParams.id_contract, index:$stateParams.index}, function(data){
        		$uibModalInstance.close(data.id);
        	});
	    };
    }
})();
