(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('SalesRestrictionController', SalesRestrictionController);

    SalesRestrictionController.$inject = ['$scope', '$parse', '$state', '$stateParams', '$uibModal', '$uibModalInstance', 'form1', 'form2', 'owningOtherCarriers', 'ticketing', 'sellingRestriction', 'airlines', 'ContractValue', 'ContractSalesRestriction','timezone'];

    function SalesRestrictionController ($scope, $parse, $state, $stateParams, $uibModal, $uibModalInstance, form1, form2, owningOtherCarriers, ticketing, sellingRestriction, airlines, ContractValue, ContractSalesRestriction, timezone) {
    	var vm = this;
    	vm.contractValue = ContractValue;
    	vm.entity = ContractSalesRestriction.get({ id : $stateParams.id_contract });
		vm.form1 = form1;
		vm.form2 = form2;
		vm.owningOtherCarriers = owningOtherCarriers;
		vm.ticketing = ticketing;
		vm.sellingRestriction = sellingRestriction;
		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;
		vm.timezone = timezone;
        vm.airlines = airlines;

		vm.entity.$promise.then(function(contract) {
    		vm.salesRestrictionRules = contract.data[17].pages;

    		if (vm.index < vm.salesRestrictionRules.length)
    			vm.editSalesRestriction(vm.salesRestrictionRules[vm.index]);
    	});

		vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
	    	};

     	//SALES RESTRICTION
		vm.addSalesRestriction = function(){
			vm.entity.data[17].pages.push({
				forms:[
					angular.copy(vm.form1),
					angular.copy(vm.form2),
                    angular.copy(vm.owningOtherCarriers),
                    angular.copy(vm.ticketing),
                    angular.copy(vm.sellingRestriction)
				]
			});
		};

		vm.removeDayTimeRestriction = function(x, y){
        	vm.form2.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form2.form_items[x].value);
        	vm.form2.form_items[x].value = z;
        }
		
		vm.pushDayTimeRestriction = function(x, y){
			var z = angular.copy(y);
			var daytimeRestriction = angular.copy(vm.daytimeRestriction);
			z[0].value = daytimeRestriction.rule_sales_restriction_from_hours;
			z[1].value = daytimeRestriction.rule_sales_restriction_to_hours;
			z[2].value = daytimeRestriction.rule_sales_restriction_time_zone;
	       	
			if(vm.form2.form_items[x].value == null){
	       		vm.form2.form_items[x].value = [];
	       	}
//			
			console.log("After copy", z);
			vm.form2.form_items[x].value.push(z);
			 vm.daytimeRestriction = [];
			/*
			
	       	
//			console.log("Before push", vm.form2.form_items[x].value);
	       	vm.form2.form_items[x].value.push(z);
//			console.log("After push", vm.form2.form_items[x].value);
//	       	var z = angular.copy(vm.form2.form_items[x].value);
//	       	vm.form2.form_items[x].value = z;
//			console.log("After angular copy", vm.form2.form_items[x].value);

	       	//vm.dateBlackout = [];
	       	
	       	*/
       };
        vm.clearField = function(tag, value){
            if(tag == 'rule_sales_restriction_agency_use_validate_ticket_on' && value != 'Specified system code (C)'){
                vm.owningOtherCarriers.form_items[1].value = "";
            }
            if(tag == 'rule_sales_restriction_agency_use_validate_ticket_on' && value == 'Specified system code (C)'){
                vm.owningOtherCarriers.form_items[2].value = "";
            }
            if(tag == 'rule_sales_restriction_airline_use_validate_ticket_on' && value != 'Specified system code (C)'){
                vm.owningOtherCarriers.form_items[4].value = "";
            }
            if(tag == 'rule_sales_restriction_airline_use_validate_ticket_on' && value == 'Specified system code (C)'){
                vm.owningOtherCarriers.form_items[5].value = "";
            }
        }

		vm.editSalesRestriction = function(x){
			// vm.form1 = angular.copy(x.forms[0]);
			// vm.form2 = angular.copy(x.forms[1]);

            vm.form1.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            });
            vm.form2.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[1], vm.form2);
            });
            vm.owningOtherCarriers.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[2], vm.owningOtherCarriers);
            });
            vm.ticketing.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[3], vm.ticketing);
            });
            vm.sellingRestriction.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[4], vm.sellingRestriction);
            });

            vm.editObjectSalesRestriction = x;
			vm.isSalesRestrictionEdit = true;
		};

		vm.saveSalesRestrictionEdit = function(){
			vm.entity.data[17].pages[vm.entity.data[17].pages.indexOf(vm.editObjectSalesRestriction)].forms[0] = angular.copy(vm.form1);
			vm.entity.data[17].pages[vm.entity.data[17].pages.indexOf(vm.editObjectSalesRestriction)].forms[1] = angular.copy(vm.form2);
			vm.entity.data[17].pages[vm.entity.data[17].pages.indexOf(vm.editObjectSalesRestriction)].forms[2] = angular.copy(vm.owningOtherCarriers);
			vm.entity.data[17].pages[vm.entity.data[17].pages.indexOf(vm.editObjectSalesRestriction)].forms[3] = angular.copy(vm.ticketing);
			vm.entity.data[17].pages[vm.entity.data[17].pages.indexOf(vm.editObjectSalesRestriction)].forms[4] = angular.copy(vm.sellingRestriction);
			vm.editObjectSalesRestriction = null;
			vm.isSalesRestrictionEdit = false;
		};

		//END SALES RESTRICTION

		vm.save = function(){
            var forms = [vm.form1, vm.form2, vm.owningOtherCarriers, vm.ticketing, vm.sellingRestriction];

            if (vm.isSalesRestrictionEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractSalesRestriction.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractSalesRestriction.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isSalesRestrictionEdit)
				// vm.saveSalesRestrictionEdit();
            // else
				// vm.addSalesRestriction();
            //
            // ContractSalesRestriction.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        $scope.ismeridian = false;
        $scope.hstep = 1;
        $scope.mstep = 15;

        $scope.options = {
          hstep: [1, 2, 3],
          mstep: [1, 5, 10, 15, 25, 30]
        };

        $scope.toggleMode = function() {
          $scope.ismeridian = ! $scope.ismeridian;
        };

        $scope.update = function() {
          var d = new Date();
          d.setHours( 14 );
          d.setMinutes( 0 );
          $scope.mytime = d;
        };

        $scope.clear = function() {
          $scope.mytime = null;
        };

        vm.resetTime = function(){
            vm.form2.form_items[5].value = null;
            vm.form2.form_items[6].value = null;
        }
    }
})();
