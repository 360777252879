(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('GdsTemplateDetailController', GdsTemplateDetailController);

    GdsTemplateDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'GdsTemplate'];

    function GdsTemplateDetailController($scope, $rootScope, $stateParams, entity, GdsTemplate) {
        var vm = this;
        vm.gdsTemplate = entity;
        vm.load = function (id) {
            GdsTemplate.get({id: id}, function(result) {
                vm.gdsTemplate = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:gdsTemplateUpdate', function(event, result) {
            vm.gdsTemplate = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
