(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('Routing', Routing);

    Routing.$inject = ['$resource'];

    function Routing ($resource) {
        var resourceUrl =  'api/routings/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    angular
    .module('americanoApp')
    .factory('RoutingActivated', RoutingActivated);

    RoutingActivated.$inject = ['$resource'];

	function RoutingActivated ($resource) {
	    var resourceUrl =  'api/routings-activated/:id';

	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}

    angular
	    .module('americanoApp')
	    .factory('RoutingSearch', RoutingSearch);

    RoutingSearch.$inject = ['$resource'];

	function RoutingSearch ($resource) {
	    var resourceUrl =  'api/_search/routing/:query/:type';

	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}

})();
