(function() {
	'use strict';

	angular.module('americanoApp').controller('ZoningDialogController',
			ZoningDialogController);

	ZoningDialogController.$inject = [ '$scope', '$stateParams',
			'$uibModalInstance', 'entity', 'form', 'Zoning'];

	function ZoningDialogController($scope, $stateParams, $uibModalInstance,
			entity, form, Zoning) {
		var vm = this;
		vm.zoning = entity;
		vm.form = form;
		
		vm.save = function() {
            if (vm.zoning.id != null) {
                Zoning.update(vm.zoning, function (result) {
                	
                	Zoning.publish(result, function(resp){  
                        
            			console.log("Publish Message: " + resp.customStringVariableType);

            			var publishResult = JSON.parse(resp.customStringVariableType);

            			var res = true;
            			for(var i=0;i<publishResult.length;i++){
            				var result = publishResult[i];
            				if (result.Travelport != undefined  && result.Travelport.toLowerCase() != 'success' && result.Travelport.toLowerCase() != 'error'){
            					vm.publishTravelportResult =  result.Travelport;
            					res = false;
            				}
            				if (result.Amadeus != undefined  && result.Amadeus.toLowerCase() != 'success' && result.Amadeus.toLowerCase() != 'error'){
            					vm.publishAmadeusResult = result.Amadeus;
            					res = false;
            				}
            				if (result.Sabre != undefined  && result.Sabre.toLowerCase() != 'success' && result.Sabre.toLowerCase() != 'error'){
            					vm.publishSabreResult = result.Sabre;
            					res = false;
            				}            					
            			}
            			
            			if(res){
                            $uibModalInstance.close(result);
            			}
            			else{
            				
            			}
    		        }, function(error){
    		        	vm.publishTravelportResult = "Internal Server Error";
		        		vm.publishAmadeusResult = "Internal Server Error";
	        			vm.publishSabreResult = "Internal Server Error";
    		        	alert(error.data.headers['x-americanoapp-alert']);
    		        });
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                Zoning.save(vm.zoning, function (result) {
                	
                	Zoning.publish(result, function(resp){  
                        
            			console.log("Publish Message: " + resp.customStringVariableType);

            			var publishResult = JSON.parse(resp.customStringVariableType);

            			var res = true;
            			for(var i=0;i<publishResult.length;i++){
            				var result = publishResult[i];
            				if (result.Travelport != undefined  && result.Travelport.toLowerCase() != 'success' && result.Travelport.toLowerCase() != 'error'){
            					vm.publishTravelportResult =  result.Travelport;
            					res = false;
            				}
            				if (result.Amadeus != undefined  && result.Amadeus.toLowerCase() != 'success' && result.Amadeus.toLowerCase() != 'error'){
            					vm.publishAmadeusResult = result.Amadeus;
            					res = false;
            				}
            				if (result.Sabre != undefined  && result.Sabre.toLowerCase() != 'success' && result.Sabre.toLowerCase() != 'error'){
            					vm.publishSabreResult = result.Sabre;
            					res = false;
            				}            					
            			}
            			
            			if(res){
                            $uibModalInstance.close(result);
            			}
            			else{
            				
            			}
    		        }, function(error){
    		        	vm.publishTravelportResult = "Internal Server Error";
		        		vm.publishAmadeusResult = "Internal Server Error";
	        			vm.publishSabreResult = "Internal Server Error";
    		        	alert(error.data.headers['x-americanoapp-alert']);
    		        });
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
		};

		vm.clear = function() {
			$uibModalInstance.dismiss('cancel');
		};
		
		//INCLUDE
		 vm.pushDataZoning = function(x, y){
			 var type = vm.dataZoning.zoning_include_multivalue_geo_type;
			 var location = vm.dataZoning.zoning_include_multivalue_location;
			 var type2 = vm.dataZoning.zoning_exclude_multivalue_geo_type;
			 var location2 = vm.dataZoning.zoning_exclude_multivalue_location;
			 
			 var includeexclude = {
					 geo_type: null,
					 location: null
			 }
			 
			 
			if (x == 3) {
				includeexclude.geo_type = type;
				includeexclude.location = location;
				if(includeexclude.geo_type != null && includeexclude.location != null){
					vm.zoning.include.push(includeexclude);
				}
				
			} else {
				includeexclude.geo_type = type2;
				includeexclude.location = location2;
				if(includeexclude.geo_type != null && includeexclude.location != null){
					vm.zoning.exclude.push(includeexclude);
				}
				
			}
			 
            vm.dataZoning = [];
       };

       vm.removeDataZoning = function(x, y){
    	   if (x == 3) {
    		   vm.zoning.include.splice(y, 1);
    	   } else {
    		   vm.zoning.exclude.splice(y, 1);
    	   }
       };
		//END INCLUDE
	}
})();
