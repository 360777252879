(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('GdsTemplateElementDetailController', GdsTemplateElementDetailController);

    GdsTemplateElementDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'GdsTemplateElement'];

    function GdsTemplateElementDetailController($scope, $rootScope, $stateParams, entity, GdsTemplateElement) {
        var vm = this;
        vm.gdsTemplateElement = entity;
        vm.load = function (id) {
            GdsTemplateElement.get({id: id}, function(result) {
                vm.gdsTemplateElement = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:gdsTemplateElementUpdate', function(event, result) {
            vm.gdsTemplateElement = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
