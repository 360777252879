(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('RoutingTariffSabreDetailController', RoutingTariffSabreDetailController);

    RoutingTariffSabreDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'entity', 'RoutingTariffSabre'];

    function RoutingTariffSabreDetailController($scope, $rootScope, $stateParams, entity, RoutingTariffSabre) {
        var vm = this;
        vm.routingTariffSabre = entity;
        vm.load = function (id) {
            RoutingTariffSabre.get({id: id}, function(result) {
                vm.routingTariffSabre = result;
            });
        };
        var unsubscribe = $rootScope.$on('americanoApp:routingTariffSabreUpdate', function(event, result) {
            vm.routingTariffSabre = result;
        });
        $scope.$on('$destroy', unsubscribe);

    }
})();
