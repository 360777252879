(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('MiscellaneousFareTagController', MiscellaneousFareTagController);

    MiscellaneousFareTagController.$inject = [
        '$scope', '$parse', '$state', '$stateParams', '$uibModal',
        'ContractMiscellaneousFareTag', 'form1', '$uibModalInstance', 'ContractValue'];

    function MiscellaneousFareTagController (
        $scope, $parse, $state, $stateParams, $uibModal,
        ContractMiscellaneousFareTag, form1, $uibModalInstance, ContractValue) {

        var vm = this;
        vm.entity = ContractMiscellaneousFareTag.get({ id : $stateParams.id_contract });
        vm.form1 = form1;
        vm.id_contract = $stateParams.id_contract;
        vm.index = $stateParams.index;

        vm.entity.$promise.then(function(contract) {
            vm.mftRules = contract.data[26].pages;

            if (vm.index < vm.mftRules.length)
                vm.editMft(vm.mftRules[vm.index]);
        });

        //MFT
        vm.addMft = function(){
            vm.entity.data[26].pages.push({
                forms:[
                    angular.copy(vm.form1)
                ]
            });
        }

        vm.editMft = function(x){
            vm.form1.$promise.then(function(){
                ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            });

            // vm.form1 = angular.copy(x.forms[0]);

            vm.editObjectMft = x;
            vm.isMftEdit = true;
        };

        vm.saveMftEdit = function(){
            vm.entity.data[26].pages[vm.entity.data[26].pages.indexOf(vm.editObjectMft)].forms[0] = angular.copy(vm.form1);


            vm.editObjectMft= null;
            vm.isMftEdit = false;
        };
        //END MFT

		vm.save = function(){
            var forms = [vm.form1];

            if (vm.isMftEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractMiscellaneousFareTag.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractMiscellaneousFareTag.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isMftEdit)
				// vm.saveMftEdit();
            // else
				// vm.addMft();
            //
            // ContractMiscellaneousFareTag.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// }, function(error){
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.checkValue = function(value) {
        	if (!vm.isMftEdit) {
            	if (value == null || value == "" || value == undefined)
            		return "No";
        	}

        	return value;
        }

        vm.resetOption = function() {
            vm.form1.form_items[0].value = null;
        };
    }
})();
