(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('TravelSegIndicatorAmadeus', TravelSegIndicatorAmadeus);

    TravelSegIndicatorAmadeus.$inject = ['$resource'];

    function TravelSegIndicatorAmadeus ($resource) {
        var resourceUrl =  'api/travel-seg-indicator-amadeuses/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    
    angular
    .module('americanoApp')
    .factory('TravelSegIndicatorAmadeusCustom', TravelSegIndicatorAmadeusCustom);

    TravelSegIndicatorAmadeusCustom.$inject = ['$resource'];
	
	function TravelSegIndicatorAmadeusCustom ($resource) {
	    var resourceUrl =  'api/travel-seg-indicator-amadeuses/getAllTravelSegIndicatorAmadeus';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	angular
	.module('americanoApp')
    .factory('TravelSegAmadeusSearch', TravelSegAmadeusSearch);

	TravelSegAmadeusSearch.$inject = ['$resource'];

	function TravelSegAmadeusSearch($resource) {
		var resourceUrl = 'api/_search/travel-segment-amadeus/:query'; 
		return $resource(resourceUrl, {}, {
			'query': { method: 'GET', isArray: true}
		});
	}
})();
