(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('RoutingTariffSabre', RoutingTariffSabre);

    RoutingTariffSabre.$inject = ['$resource'];

    function RoutingTariffSabre ($resource) {
        var resourceUrl =  'api/routing-tariff-sabres/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
	    .module('americanoApp')
	    .factory('RoutingTariffSabreCustom', RoutingTariffSabreCustom);
	
    RoutingTariffSabreCustom.$inject = ['$resource'];
	
	function RoutingTariffSabreCustom ($resource) {
	    var resourceUrl =  'api/routing-tariff-sabres/getRoutingTariffSabres';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	angular
	.module('americanoApp')
    .factory('RoutingTariffSabreSearch', RoutingTariffSabreSearch);

	RoutingTariffSabreSearch.$inject = ['$resource'];

	function RoutingTariffSabreSearch($resource) {
		var resourceUrl = 'api/_search/routing-tariff-sabre/:query'; 
		return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true}
    	});
	}
})();
