(function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('gds-template', {
            parent: 'entity',
            url: '/gds-template?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.gdsTemplate.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/gds-template/gds-templates.html',
                    controller: 'GdsTemplateController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gdsTemplate');
                    $translatePartialLoader.addPart('enumYesNo');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('gds-template-detail', {
            parent: 'entity',
            url: '/gds-template/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.gdsTemplate.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/gds-template/gds-template-detail.html',
                    controller: 'GdsTemplateDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('gdsTemplate');
                    $translatePartialLoader.addPart('enumYesNo');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'GdsTemplate', function($stateParams, GdsTemplate) {
                    return GdsTemplate.get({id : $stateParams.id});
                }]
            }
        })
        .state('gds-template.new', {
            parent: 'gds-template',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gds-template/gds-template-dialog.html',
                    controller: 'GdsTemplateDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                templateTag: null,
                                templateName: null,
                                templateDesciprion: null,
                                templateFileName: null,
                                templateContent: null,
                                templateVersion: null,
                                templateCreatedDate: null,
                                templateCreatedBy: null,
                                templateModifiedDate: null,
                                templateModifiedBy: null,
                                templateActive: 'YES',
                                gdsTag: null,
                                id: null
                            };
                        },
                        gdstypes:['GdsTypeCustom', function(GdsTypeCustom){
                        	return GdsTypeCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('gds-template', null, { reload: true });
                }, function() {
                    $state.go('gds-template');
                });
            }]
        })
        .state('gds-template.edit', {
            parent: 'gds-template',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gds-template/gds-template-dialog.html',
                    controller: 'GdsTemplateDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['GdsTemplate', function(GdsTemplate) {
                            return GdsTemplate.get({id : $stateParams.id});
                        }],
                        gdstypes:['GdsTypeCustom', function(GdsTypeCustom){
                        	return GdsTypeCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('gds-template', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('gds-template.delete', {
            parent: 'gds-template',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/gds-template/gds-template-delete-dialog.html',
                    controller: 'GdsTemplateDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['GdsTemplate', function(GdsTemplate) {
                            return GdsTemplate.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('gds-template', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
