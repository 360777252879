(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TransferController', TransferController);

    TransferController.$inject = ['$scope', '$parse', '$state', '$stateParams', '$uibModal', '$uibModalInstance', 'form1', 'form2', 'form3', 'form4', 'form5', 'form6', 'form7', 'airlines', 'currencies', 'travelSegmentIndicatorSabre', 'ContractValue', 'ContractTransfer'];

    function TransferController ($scope, $parse, $state, $stateParams, $uibModal, $uibModalInstance, form1, form2, form3, form4, form5, form6, form7, airlines, currencies, travelSegmentIndicatorSabre, ContractValue, ContractTransfer) {
    	var vm = this;
    	vm.contractValue = ContractValue;
    	vm.entity = ContractTransfer.get({ id : $stateParams.id_contract });
		vm.form1 = form1;
		vm.form2 = form2;
		vm.form3 = form3;
		vm.form4 = form4;
		vm.form5 = form5;
		vm.form6 = form6;
		vm.form7 = form7;
		vm.airlines = airlines;
		vm.currencies = currencies;
		vm.travelSegmentIndicatorSabre = travelSegmentIndicatorSabre;
		vm.id_contract = $stateParams.id_contract;
		vm.index = $stateParams.index;

		vm.entity.$promise.then(function(contract) {
    		vm.transferRules = contract.data[22].pages;

    		if (vm.index < vm.transferRules.length)
    			vm.editTransfer(vm.transferRules[vm.index]);
    	});

		vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
	    	};

		//EMBEDDED SURFACE SECTORS
        vm.pushEmbeddedSurfaceSectors = function(x, y){
        	y[0].value = vm.dataSurfaceSectors.rule_transfer_embedded_surface_sectors_surface_sector_requirements_apply_to;
        	y[1].value = vm.dataSurfaceSectors.rule_transfer_embedded_surface_sectors_surface_sector;
        	y[2].value = vm.dataSurfaceSectors.rule_transfer_embedded_surface_sectors_origin_destination;
        	y[3].value = vm.dataSurfaceSectors.rule_transfer_embedded_surface_sectors_restriction_indicator;
        	y[4].value = vm.dataSurfaceSectors.rule_transfer_embedded_surface_sectors_type;
        	y[5].value = vm.dataSurfaceSectors.rule_transfer_embedded_surface_sectors_between_value;
        	y[6].value = vm.dataSurfaceSectors.rule_transfer_embedded_surface_sectors_between_value_2;

        	if(vm.form4.form_items[x].value == null){
        		vm.form4.form_items[x].value = [];
        	}

        	vm.form4.form_items[x].value.push(y);
        	var z = angular.copy(vm.form4.form_items[x].value);
        	vm.form4.form_items[x].value = z;

        	vm.dataSurfaceSectors = [];
        };

        vm.removeEmbeddedSurfaceSectors = function(x, y){
        	vm.form4.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form4.form_items[x].value);
        	vm.form4.form_items[x].value = z;
        }

        vm.copyEmbeddedSurfaceSectors = function(x, y){
        	vm.data = angular.copy(vm.form4.form_items[x].value[y]);
        	vm.form4.form_items[x].value.push(vm.data);

        	var newItemIndex = vm.form4.form_items[0].value.length - 1;

        	if (vm.isEditEmbeddedSurfaceSectors == undefined || vm.isEditEmbeddedSurfaceSectors == null) {
        		vm.isEditEmbeddedSurfaceSectors = {}
        	}

        	vm.isEditEmbeddedSurfaceSectors[newItemIndex] = true;
        };
        //END EMBEDDED SURFACE SECTORS

        //SPECIFIED TRANSFERS
        vm.pushSpecifiedTransfers = function(x, y){
        	y[0].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_application;
        	y[1].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_number_of_stop_over;
        	y[2].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_options;
        	y[3].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_conn_stop;
        	y[4].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_in_carrier;
        	y[5].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_out_carrier;
        	y[6].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_into_out_of_either_cxr;
        	y[7].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_out_in;
        	y[8].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_gateway;
        	y[9].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_restriction;
        	y[10].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_charge;
        	y[11].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_travel_segment;
        	y[12].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_type;
        	y[13].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_location_1;
        	y[14].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_location_2;
        	y[15].value = vm.dataSpecifiedTransfers.rule_transfer_specified_transfers_between;

        	if(vm.form6.form_items[x].value == null){
        		vm.form6.form_items[x].value = [];
        	}

        	vm.form6.form_items[x].value.push(y);
        	var z = angular.copy(vm.form6.form_items[x].value);
        	vm.form6.form_items[x].value = z;

        	vm.dataSpecifiedTransfers = [];
        };

        vm.removeSpecifiedTransfers = function(x, y){
        	vm.form6.form_items[x].value.splice(y, 1);
        	var z = angular.copy(vm.form6.form_items[x].value);
        	vm.form6.form_items[x].value = z;
        }

        vm.copySpecifiedTransfers = function(x, y){
        	vm.data = angular.copy(vm.form6.form_items[x].value[y]);
        	vm.form6.form_items[x].value.push(vm.data);

        	var newItemIndex = vm.form6.form_items[0].value.length - 1;

        	if (vm.isEditSpecifiedTransfers == undefined || vm.isEditSpecifiedTransfers == null) {
        		vm.isEditSpecifiedTransfers = {}
        	}

        	vm.isEditSpecifiedTransfers[newItemIndex] = true;
        };
        //END SPECIFIED TRANSFERS

     	//TRANSFER
		vm.addTransfer = function(){
			vm.entity.data[22].pages.push({
				forms:[
					angular.copy(vm.form1),
					angular.copy(vm.form2),
					angular.copy(vm.form3),
					angular.copy(vm.form4),
					angular.copy(vm.form5),
					angular.copy(vm.form6),
					angular.copy(vm.form7)
				]
			});
		};

		vm.editTransfer = function(x){

            vm.form1.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form1);
            });
            vm.form2.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[1], vm.form2);
            });
            vm.form3.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[2], vm.form3);
            });

            vm.form4.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[3], vm.form4);
            });

            vm.form5.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[4], vm.form5);
            });

            vm.form6.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[5], vm.form6);
            });

            vm.form7.$promise.then(function() {
                ContractValue.setValueFromDatabase(x.forms[6], vm.form7);
            });

            // ContractValue.setValueFromDatabase(x.forms[1], vm.form2);
            // ContractValue.setValueFromDatabase(x.forms[2], vm.form3);
            // ContractValue.setValueFromDatabase(x.forms[3], vm.form4);
            // ContractValue.setValueFromDatabase(x.forms[4], vm.form5);
            // ContractValue.setValueFromDatabase(x.forms[5], vm.form6);
            // ContractValue.setValueFromDatabase(x.forms[6], vm.form7);

            // vm.form1 = angular.copy(x.forms[0]);
			// vm.form2 = angular.copy(x.forms[1]);
			// vm.form3 = angular.copy(x.forms[2]);
			// vm.form4 = angular.copy(x.forms[3]);
			// vm.form5 = angular.copy(x.forms[4]);
			// vm.form6 = angular.copy(x.forms[5]);
			// vm.form7 = angular.copy(x.forms[6]);

			vm.editObjectTransfer = x;
			vm.isTransferEdit = true;
		};

		vm.saveTransferEdit = function(){
			vm.entity.data[22].pages[vm.entity.data[22].pages.indexOf(vm.editObjectTransfer)].forms[0] = angular.copy(vm.form1);
			vm.entity.data[22].pages[vm.entity.data[22].pages.indexOf(vm.editObjectTransfer)].forms[1] = angular.copy(vm.form2);
			vm.entity.data[22].pages[vm.entity.data[22].pages.indexOf(vm.editObjectTransfer)].forms[2] = angular.copy(vm.form3);
			vm.entity.data[22].pages[vm.entity.data[22].pages.indexOf(vm.editObjectTransfer)].forms[3] = angular.copy(vm.form4);
			vm.entity.data[22].pages[vm.entity.data[22].pages.indexOf(vm.editObjectTransfer)].forms[4] = angular.copy(vm.form5);
			vm.entity.data[22].pages[vm.entity.data[22].pages.indexOf(vm.editObjectTransfer)].forms[5] = angular.copy(vm.form6);
			vm.entity.data[22].pages[vm.entity.data[22].pages.indexOf(vm.editObjectTransfer)].forms[6] = angular.copy(vm.form7);

			vm.editObjectTransfer = null;
			vm.isTransferEdit = false;
		};
		//END TRANSFER

		vm.save = function(){
            var forms = [vm.form1, vm.form2, vm.form3, vm.form4, vm.form5, vm.form6, vm.form7];

            if (vm.isTransferEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractTransfer.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractTransfer.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            // if (vm.isTransferEdit)
				// vm.saveTransferEdit();
            // else
				// vm.addTransfer();
            //
            // ContractTransfer.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.resetForm1 = function() {
        	vm.form1.form_items.forEach(function(item) {
        		item.value = "";
        	});
        }

        vm.resetForm2 = function() {
        	vm.form2.form_items.forEach(function(item) {
        		item.value = "";
        	});
        }

        vm.resetAmadeus = function() {
            vm.form1.form_items[2].value = null;
        };
        vm.resetAPF = function() {
            vm.form1.form_items[3].value = null;
        };
        vm.resetOption = function() {
            vm.form2.form_items[2].value = null;
            vm.form2.form_items[3].value = null;
            vm.form2.form_items[4].value = null;
        };

        vm.setValue = function () {
            if(vm.form2.form_items[2].value != 'Number Outbound / Number Inbound'){
                vm.form2.form_items[3].value = '';
                vm.form2.form_items[4].value = '';
            }
        };

        vm.tagTab1 = ["rule_transfer_general_amadeus", "rule_transfer_general_apf"];
        vm.tagTabTextOnly = ["rule_transfer_text_information_only", "rule_transfer_general_note_amadeus","rule_transfer_general_note_apf","rule_transfer_general_note_sabre", "rule_transfer_general_note", "rule_transfer_override"];
    }
})();
