angular.module('americanoApp')
    .directive('validationForm', ["$q", "$filter", "$timeout", "$compile", function($q, $filter, timer, $compile) {
        return {
        	require: 'ngModel', 
        	scope: false,
            controller: function($scope) {
            	
            },
            link: function(scope, element, attrs, ngModel){
            	var differenceInDays = function(firstdate, seconddate) {
                    var dt1 = firstdate.split('/');
                    var dt2 = seconddate.split('/');
                    var one = new Date(dt1[2], dt1[1]-1, dt1[0]);
                    var two = new Date(dt2[2], dt2[1]-1, dt2[0]);

                    var millisecondsPerDay = 1000 * 60 * 60 * 24;
                    var millisBetween = two.getTime() - one.getTime();
                    var days = millisBetween / millisecondsPerDay;

                    return Math.floor(days);      
                };
                
            	var validateTag = function(tag, value){
                	if(tag == "mandatory"){
                		if (value == null || value == undefined || value == "" || value == []) {
                    		return false;
                        }
                	}
                	else if(tag == "no_backdate"){
                		if (value != null) {
	                		var secondDate = $filter('date')(value, 'dd/MM/yyyy');
	                		var firstDate = $filter('date')(new Date(), 'dd/MM/yyyy');
	                		
	                		if(differenceInDays(firstDate, secondDate) < 0){
	                			return false;
	                		}
                		}
                	}
                };
                
                var validateDropdownAutoInput = function(value){
                	if(value == null || value == "" || value == undefined)
                		return true;
                	else {
                		if(value instanceof Object){
		   				  return true;
		   			  	} else {
		   				  return false;
		   			  	}
                	}
                };

                ngModel.$validators.valid = function(modelValue, viewValue) {
                	var isMandatory = false;
                	angular.forEach(angular.fromJson(scope.item).validation_rules, function(value) {
                		if(value.tag == "mandatory"){
                			isMandatory = true;
                		}
                	});
                	
          		  	var validated;
          		  	if(angular.fromJson(scope.item).type == "dropdown_auto"){
          		  		validated = validateDropdownAutoInput(modelValue);
          		  	}
          		  	
          		  	if(validated === undefined){ validated = true; }
	          		  return validated;
                };

                
            	  ngModel.$validators.mandatory = function(modelValue, viewValue) {
            		  var validated;
            		  angular.forEach(angular.fromJson(scope.item).validation_rules, function(value) {
            			  if(!validated){
	            			  if(value.tag == "mandatory"){
	            				  if(angular.fromJson(scope.item).type != "checkbox"){
	            					  validated = validateTag(value.tag, modelValue);
	            				  }
	            			  }
            			  }
                	  });
            		  if(validated === undefined){ validated = true; }
            		  return validated;
                  };
                  
                  ngModel.$validators.no_backdate = function(modelValue, viewValue) {
                	  var validated;
            		  angular.forEach(angular.fromJson(scope.item).validation_rules, function(value) {
            			  if(value.tag == "no_backdate"){
            				  validated = validateTag(value.tag, modelValue);
            			  }
                	  });
            		  if(validated === undefined){ validated = true; }
            		  return validated;
                  };
                  
            }
        }
    }]);

angular.module('americanoApp')
.directive('multivalueValidationForm', ["$q", "$filter", "$timeout", "$compile", function($q, $filter, timer, $compile) {
    return {
    	require: 'ngModel', 
    	scope: false,
        controller: function($scope) {
        	
        },
        link: function(scope, element, attrs, ngModel){
        	var validateMultivalue = function(value){
	            if(value instanceof Object || value == null || value == ""){
	   				  return true;
	   			  }else{
	   				  return false;
	   			  }
            };

            ngModel.$validators.validMultivalue = function(modelValue, viewValue) {
          		  var validated;
          		  
          		  if(angular.fromJson(scope.item).type == "multivalue"){
          			  validated = validateMultivalue(modelValue);
          		  } 
          		  
          		  if(validated === undefined){ validated = true; }
          		  	return validated;
            };
        }
    }
}]);

angular.module('americanoApp')
.directive('smartFloat', function ($filter) {
    var FLOAT_REGEXP_1 = /^\$?\d+.(\d{3})*(\,\d*)$/; //Numbers like: 1.123,56
    var FLOAT_REGEXP_2 = /^\$?\d+,(\d{3})*(\.\d*)$/; //Numbers like: 1,123.56
    var FLOAT_REGEXP_3 = /^\$?\d+(\.\d*)?$/; //Numbers like: 1123.56
    var FLOAT_REGEXP_4 = /^\$?\d+(\,\d*)?$/; //Numbers like: 1123,56

    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
            ctrl.$parsers.unshift(function (viewValue) {
                if (FLOAT_REGEXP_1.test(viewValue)) {
                    ctrl.$setValidity('float', true);
                    return parseFloat(viewValue.replace('.', '').replace(',', '.'));
                } else if (FLOAT_REGEXP_2.test(viewValue)) {
                        ctrl.$setValidity('float', true);
                        return parseFloat(viewValue.replace(',', ''));
                } else if (FLOAT_REGEXP_3.test(viewValue)) {
                        ctrl.$setValidity('float', true);
                        return parseFloat(viewValue);
                } else if (FLOAT_REGEXP_4.test(viewValue)) {
                        ctrl.$setValidity('float', true);
                        return parseFloat(viewValue.replace(',', '.'));
                }else {
                    ctrl.$setValidity('float', false);
                    return undefined;
                }
            });

            ctrl.$formatters.unshift(
               function (modelValue) {
                   return $filter('number')(parseFloat(modelValue) , 2);
               }
           );
        }
    };
});