(function () {
    'use strict';

    angular
        .module('americanoApp')
        .controller('DistributionExceptionErrorController', DistributionExceptionErrorController);

    DistributionExceptionErrorController.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$uibModalInstance'];

    function DistributionExceptionErrorController ($rootScope, $scope, $state, $stateParams, $uibModalInstance) {
        var vm = this;
        
        vm.parent = $scope.$parent.vm;
        vm.total = vm.parent.total;
        
        vm.distributionExceptions = [];
        vm.selectedDistributions = vm.parent.selectedDistributions;
        
        var exception = null;
        var distributions = [];
        var object = {
        	distributions: null,
        	exception: null
        }
        
        var exceptionInJSON = null;
        var i = null;
        var exist = false;
       
        vm.selectedDistributions.forEach(function(distribution) {
        	exception = distribution.exception;
        	exist = false;
        	exceptionInJSON = JSON.stringify(exception);
        	
        	for (i = 0; i < vm.distributionExceptions.length; i++) {
        		if (JSON.stringify(vm.distributionExceptions[i].exception) == exceptionInJSON){
        			exist = true;
        			break;
        		}
        	}
        	
        	if (!exist) {
        		distributions.push(distribution);
        		vm.temp = angular.copy(object);
        		vm.temp.distributions = distributions;
        		vm.temp.exception = exception;
        		vm.distributionExceptions.push(vm.temp);
        		distributions = [];
        	} else 
        		vm.distributionExceptions[i].distributions.push(distribution);
        });
        
        vm.modifyException = function(distributionException){
        	vm.parent.selectedDistributions = [];
        	vm.parent.selectedDistributions = distributionException.distributions;
        	
        	$uibModalInstance.dismiss();
        	vm.parent.openExceptionModal();
        }
        
        vm.modifyAllException = function(){
//        	vm.parent.selectedDistributions = [];
//        	vm.parent.selectedDistributions = vm.selectedDistributions;
        	
        	console.log(vm.parent.selectedDistributions);
        	
        	$uibModalInstance.dismiss();
        	vm.parent.openExceptionModal();
        }
        
        vm.close = function() {
        	$uibModalInstance.dismiss();
        	
        	vm.parent.resetCheckbox();
        }
    }
})();
