(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('TravelSegIndicatorDaysTimeDeleteController',TravelSegIndicatorDaysTimeDeleteController);

    TravelSegIndicatorDaysTimeDeleteController.$inject = ['$uibModalInstance', 'entity', 'TravelSegIndicatorDaysTime'];

    function TravelSegIndicatorDaysTimeDeleteController($uibModalInstance, entity, TravelSegIndicatorDaysTime) {
        var vm = this;
        vm.travelSegIndicatorDaysTime = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
            TravelSegIndicatorDaysTime.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
