(function() {
    'use strict';

    angular
    .module('americanoApp')
    .factory('ContractFare', ContractFare);

	ContractFare.$inject = ['$resource'];

	function ContractFare ($resource) {
	    var resourceUrl =  'api/contracts/fare/:id/:index';

		return $resource(resourceUrl, {}, {
		    'query': { method: 'GET', url: 'api/contracts/fare/get/:id/:group/:type/:search'},
		    'queryOriginDestination': { method: 'GET', url: 'api/contracts/fare/get/origin-destination'},
		    'get': { method: 'GET', transformResponse: function (data) { data = angular.fromJson(data); return data; } },
		    'update': { method:'PUT' },
		    'delete': { method:'DELETE'},
		    'move': { method:'PUT', url: 'api/contracts/fare/move'},
		    'rename': { method:'PUT', url: 'api/contracts/fare/rename'},
		    'copy': { method:'PUT', url: 'api/contracts/fare/copy'},
		    'calculate': { method:'PUT', url: 'api/contracts/fare/calculate'},
		    'deleteMultiple': { method:'POST', url: 'api/contracts/fare/delete-multiple'},
		    'getAllFares': { method:'GET', isArray: true, url: 'api/contracts/all-fare/:id'}
	    });
	}

	angular
    .module('americanoApp')
    .factory('ContractFareCopy', ContractFareCopy);

	ContractFareCopy.$inject = ['$resource'];

	function ContractFareCopy ($resource) {
	    var resourceUrl =  'api/contract-fares/copy/:id/:index';

	    return $resource(resourceUrl, {}, {
	        'copy': {method:'POST'}
	    });
	}

	angular
    .module('americanoApp')
    .factory('ContractFareUpload', ContractFareUpload);

	ContractFareUpload.$inject = ['$resource'];

	function ContractFareUpload($resource) {
	    var resourceUrl = 'api/contracts/fare/upload/:contract_id';
	    return $resource(resourceUrl, {}, {
	        'save': { method: 'POST'},
	        'get': {method: 'GET', url:'api/contracts/fare/upload/:id'},
            'download': {method: 'GET', url:'api/contracts/fare/download/:id'}
	    });
	}
})();

