(function() {
	'use strict';

	angular.module('americanoApp').controller('AddOnDetailController',
			AddOnDetailController);

	AddOnDetailController.$inject = [ '$scope', '$rootScope', '$stateParams',
			'entity', 'AddOn' ];

	function AddOnDetailController($scope, $rootScope, $stateParams, entity,
			AddOn) {
		var vm = this;
		vm.addOn = entity;
		vm.load = function(id) {
			AddOn.get({
				id : id
			}, function(result) {
				vm.addOn = result;
			});
		};

		var unsubscribe = $rootScope.$on('americanoApp:addOnUpdate', function(
				event, result) {
			vm.team = result;
		});
		$scope.$on('$destroy', unsubscribe);

	}
})();
