(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('DistributionGroupController', DistributionGroupController);

    DistributionGroupController.$inject = ['$scope', '$state', '$stateParams', 'DistributionGroup', 'DistributionGroupSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function DistributionGroupController ($scope, $state, $stateParams, DistributionGroup, DistributionGroupSearch, ParseLinks, AlertService, paginationConstants, pagingParams) {
        var vm = this;

        vm.size = 10;
        vm.itemsPerPage = 10;
        vm.searchResultItemsPerPage = 10;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll = loadAll;
        vm.currentSearch = "";

        loadAll();

        function loadAll () {
        	DistributionGroup.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.distributionGroups = data;
                vm.page = pagingParams.page;
                vm.searchQuery = "";
                vm.currentSearch = "";
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            /*$state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });*/

            pagingParams.page = vm.page;

            if(vm.currentSearch != "")
                vm.search();
            else
                vm.loadAll();
        }

        vm.checkSearchQuery = function() {
        	if (vm.searchQuery == ""){
        		vm.loadAll();
        	}
        }

        vm.setCurrentSearch = function(search) {
            vm.currentSearch = search;
        }

        vm.search = function () {
        	DistributionGroupSearch.query({
        		page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort(),
                query: vm.searchQuery
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.distributionGroups = data;
                vm.page = pagingParams.page;
                vm.searchQuery = vm.currentSearch;
            }
            function onError(error) {
                vm.loadAll();
            }
        };

        vm.copyPccMaster = function(id) {
            $state.go('distribution-group.copy', {
                id: id,
            }, { reload : true });
        }
    }
})();
