(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormPageDialogController', FormPageDialogController);

    FormPageDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'FormPage', 'formMaster'];

    function FormPageDialogController ($scope, $stateParams, $uibModalInstance, entity, FormPage, formMaster) {
        var vm = this;
        vm.formPage = entity;
        vm.formMaster = formMaster;
        vm.load = function(id) {
            FormPage.get({id : id}, function(result) {
                vm.formPage = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:formPageUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.formPage.id !== null) {
            	vm.formPage.formModifiedDate = "0000-00-00";
            	vm.formPage.formModifiedBy = "-";
                FormPage.update(vm.formPage, onSaveSuccess, onSaveError);
            } else {
            	vm.formPage.formCreatedDate = "0000-00-00";
            	vm.formPage.formCreatedBy = "-";
                FormPage.save(vm.formPage, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.formCreatedDate = false;
        vm.datePickerOpenStatus.formModifiedDate = false;

        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };
    }
})();
