(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('SeasonController', SeasonController);

    SeasonController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', '$uibModal', 'ContractSeason', 'travelSegmentIndicatorAmadeus', 'travelSegmentIndicatorAPF', 'travelSegmentIndicatorSabre',
    'season_type', 'form19', '$uibModalInstance', 'ContractValue'];

    function SeasonController (
    		$scope, $parse, $state, $stateParams, $uibModal, ContractSeason, travelSegmentIndicatorAmadeus, travelSegmentIndicatorAPF, travelSegmentIndicatorSabre,
    		season_type, form19, $uibModalInstance, ContractValue) {

    	var vm = this;
    	vm.entity = ContractSeason.get({ id : $stateParams.id_contract });
		vm.form19 = form19;
		vm.id_contract = $stateParams.id_contract;
		vm.season_type = season_type;
		vm.travelSegmentIndicatorAmadeus = travelSegmentIndicatorAmadeus;
		vm.travelSegmentIndicatorSabre = travelSegmentIndicatorSabre;
		vm.travelSegmentIndicatorAPF = travelSegmentIndicatorAPF;
		vm.index = $stateParams.index;

		vm.entity.$promise.then(function(contract) {
    		vm.seasonRules = contract.data[10].pages;

    		if (vm.index < vm.seasonRules.length)
    			vm.editSeason(vm.seasonRules[vm.index]);
    	});

		//SEASON
		vm.addSeason = function(){
			vm.entity.data[10].pages.push({
				forms:[
					angular.copy(vm.form19)
				]
			});
		};

		vm.editSeason= function(x){
		    vm.form19.$promise.then(function () {
                ContractValue.setValueFromDatabase(x.forms[0], vm.form19);
            });
		    
            // ContractValue.setValueFromDatabase(x.forms[0], vm.form19);
            // vm.form19 = angular.copy(x.forms[0]);

			vm.editObjectSeason = x;
			vm.isSeasonEdit = true;
		};

		vm.saveSeasonEdit = function(){
			vm.entity.data[10].pages[vm.entity.data[10].pages.indexOf(vm.editObjectSeason)].forms[0] = angular.copy(vm.form19);
			vm.editObjectSeason= null;
			vm.isSeasonEdit = false;
		};
		//END SEASON

		vm.Date = function(arg){
        	if(arg == null){
          	   return new Date();
        	}else{
          	   return new Date(arg);
        	}
     	};

     	vm.save = function(){
            var forms = [vm.form19];

            if (vm.isSeasonEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractSeason.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractSeason.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }

            // if (vm.isSeasonEdit)
				// vm.saveSeasonEdit();
            // else
				// vm.addSeason();
            //
            // ContractSeason.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();
            $scope.opened = [];
            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.checkValueApplyTo = function(value) {
        	if (!vm.isSeasonEdit) {
	        	if (value == null || value == "" || value == undefined)
	        		return "Outbound And Inbound";
        	}

        	return value;
        }

        vm.checkValueDirectionality = function(value) {
        	if (!vm.isSeasonEdit) {
        		if (value == null || value == "" || value == undefined)
            		return "Between";
        	}

        	return value;
        }
        
        vm.checkDateFormat = function(tag){
        	for(var x=0;x<vm.form19.form_items.length;x++){
        		if(tag == "rules_season_first_date"){
        			if(vm.form19.form_items[x].tag == "rules_season_first_date_format"){
            			if(vm.form19.form_items[x].value != null && vm.form19.form_items[x].value != ""){
            				return vm.form19.form_items[x].value;
            			}
            		}
            	}else if(tag == "rules_season_last_date"){
            		if(vm.form19.form_items[x].tag == "rules_season_last_date_format"){
            			if(vm.form19.form_items[x].value != null && vm.form19.form_items[x].value != ""){
            				return vm.form19.form_items[x].value;
            			}
            		}
            	}
        	}
        	return "ddMMMyy";
        }

        vm.formatDate = function(date, tag){
        	if(date instanceof Date){        		
        		if(tag == "rules_season_first_date"){
        			var format = vm.checkDateFormat("rules_season_first_date");
        			if(format == "ddMMM"){
        				date.setFullYear(new Date().getFullYear());
        			}
        		}
        		else if(tag == "rules_season_last_date"){
        			var format = vm.checkDateFormat("rules_season_last_date");
        			if(format == "ddMMM"){
        				date.setFullYear(new Date().getFullYear());
        			}
        		}
        		return date;
        	}
        };
        
        vm.resetSeasonApplication = function() {
            vm.form19.form_items[0].value = null;
        }
    }
})();
