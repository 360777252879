(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('MasterAccountDialogController', MasterAccountDialogController);

    MasterAccountDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'MasterAccount',
        'DistributionGroupFindByGdsAndTypePcc', 'DistributionGroupMaster'
    ];

    function MasterAccountDialogController ($scope, $stateParams, $uibModalInstance, entity, MasterAccount,
                                            DistributionGroupFindByGdsAndTypePcc, DistributionGroupMaster) {
        var vm = this;
        vm.masterAccount = entity;

        vm.typeSelected = function(gds, type){
            console.log(gds);
            console.log(type);
            if(gds == 'travelport'){
                vm.pccTravelport = [];
                DistributionGroupFindByGdsAndTypePcc.query({gds:"travelport", typePcc:type}, function(data){
                    vm.pccTravelport = data;
                });
            }
            else if(gds == 'amadeus'){
                vm.pccAmadeus = [];
                DistributionGroupFindByGdsAndTypePcc.query({gds:"amadeus", typePcc:type}, function(data){
                    vm.pccAmadeus = data;
                });
            }
            else if(gds == 'sabre'){
                vm.pccSabre = [];
                DistributionGroupFindByGdsAndTypePcc.query({gds:"sabre", typePcc:type}, function(data){
                    vm.pccSabre = data;
                });
            }
        };

        DistributionGroupMaster.findByGds({gds:"travelport"}, function(data){
            vm.pccTravelportMaster = data;
            console.log(data);
        });
        DistributionGroupMaster.findByGds({gds:"amadeus"}, function(data){
            vm.pccAmadeusMaster = data;
            console.log(data);
        });
        DistributionGroupMaster.findByGds({gds:"sabre"}, function(data){
            vm.pccSabreMaster = data;
            console.log(data);
        });

        vm.load = function(id) {
            MasterAccount.get({id : id}, function(result) {
                vm.masterAccount = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:masterAccountUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.masterAccount.id !== null) {
                MasterAccount.update(vm.masterAccount, onSaveSuccess, onSaveError);
            } else {
                MasterAccount.save(vm.masterAccount, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        /* Multivalue Travelport */
        vm.pushDataTravelport = function(data) {
            console.log(data);
            if(data != null) {
                if(vm.masterAccount.pccTravelport == null) {
                    vm.masterAccount.pccTravelport = [];
                }
                vm.masterAccount.pccTravelport.push(data.pcc);
            }
            vm.dataTravelportPcc = [];
        };

        vm.removeDataTravelport = function(x, y) {
            vm.masterAccount.pccTravelport.splice(y, 1);
        };
        /* End Multivalue Travelport */

        /* Multivalue Amadeus */
        vm.pushDataAmadeus = function(data) {
            console.log(data);
            if(data != null) {
                if(vm.masterAccount.pccAmadeus == null) {
                    vm.masterAccount.pccAmadeus = [];
                }
                vm.masterAccount.pccAmadeus.push(data.pcc);
            }
            vm.dataAmadeusPcc = [];
        };

        vm.removeDataAmadeus = function(x, y) {
            vm.masterAccount.pccAmadeus.splice(y, 1);
        };
        /* End Multivalue Amadeus */

        /* Multivalue Sabre */
        vm.pushDataSabre = function(data) {
            console.log(data);
            if(data != null) {
                if(vm.masterAccount.pccSabre == null) {
                    vm.masterAccount.pccSabre = [];
                }
                vm.masterAccount.pccSabre.push(data.pcc);
            }
            vm.dataSabrePcc = [];
        };

        vm.removeDataSabres = function(x, y) {
            vm.masterAccount.pccSabre.splice(y, 1);
        };
        /* End Multivalue Amadeus */

        /* Multivalue Travelport Group */
        vm.pushDataTravelportGroup = function(data) {
            console.log(data);
            if(data != null) {
                if(vm.masterAccount.pccGroupTravelport == null) {
                    vm.masterAccount.pccGroupTravelport = [];
                }
                vm.masterAccount.pccGroupTravelport.push(data.pcc);
            }
            vm.dataTravelportGroupPcc = [];
        };

        vm.removeDataGroupTravelport = function(x, y) {
            vm.masterAccount.pccGroupTravelport.splice(y, 1);
        };
        /* End Multivalue Travelport Group */

        /* Multivalue Amadeus Group */
        vm.pushDataAmadeusGroup = function(data) {
            console.log(data);
            if(data != null) {
                if(vm.masterAccount.pccGroupAmadeus == null) {
                    vm.masterAccount.pccGroupAmadeus = [];
                }
                vm.masterAccount.pccGroupAmadeus.push(data.pcc);
            }
            vm.dataAmadeusGroupPcc = [];
        };

        vm.removeDataGroupAmadeus = function(x, y) {
            vm.masterAccount.pccGroupAmadeus.splice(y, 1);
        };
        /* End Multivalue Amadeus Group */

        /* Multivalue Sabre Group */
        vm.pushDataSabreGroup = function(data) {
            console.log(data);
            if(data != null) {
                if(vm.masterAccount.pccGroupSabre == null) {
                    vm.masterAccount.pccGroupSabre = [];
                }
                vm.masterAccount.pccGroupSabre.push(data.pcc);
            }
            vm.dataSabreGroupPcc = [];
        };

        vm.removeDataGroupSabre = function(x, y) {
            vm.masterAccount.pccGroupSabre.splice(y, 1);
        };
        /* End Multivalue Amadeus Group */
    }
})();
