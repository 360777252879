(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('FormUploadValidateContractController', FormUploadValidateContractController);

    FormUploadValidateContractController.$inject = ['$scope', '$stateParams', '$state', '$uibModal', '$uibModalInstance', 'DataUtils', 'entity', 'ContractUpload', 'ContractUploadV2', 'ContractValue', 'AirlineCustom', 'ParseLinks'];

    function FormUploadValidateContractController ($scope, $stateParams, $state, $uibModal, $uibModalInstance, DataUtils, entity, ContractUpload, ContractUploadV2, ContractValue, AirlineCustom, ParseLinks) {
    	var vm = this; 
    	var fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    	vm.contractFile = entity;
    	vm.contractFileContentType = null;
    	vm.contractFileName = null;
    	vm.fileType = null;
    	vm.fileName = null;
		vm.uploadIsInProgress = false;
    	vm.error = false;
		vm.success = false;
		vm.showmore = false;
		vm.contractValue = ContractValue;
		vm.airlines = AirlineCustom.get();
		
		vm.contractId = $stateParams.id_contract;
		
		vm.fileUpload = {
				file: null,
				type: null,
				airline: null,
				contractId: null
		};
		
    	vm.setFile = function ($file, file) {
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                    	file.file = base64Data;
                    	vm.fileType = $file.type;
                    	vm.fileName = $file.name;
                    	
                    	vm.contractResult = null;
                    });
                });
                
                vm.success = false;
            }
        };
        
        vm.upload = function() {
        	vm.isSubmitted = true;
        	
        	if (vm.airline != null && vm.contractFile.file && vm.contractFileContentType == fileType) {
				vm.uploadIsInProgress = true;
				vm.error = false;
				vm.contractFile.airline = vm.airline;
				
				ContractUpload.upload(vm.contractFile, function(result) {
					vm.uploadIsInProgress = false;
					vm.message = result.message;
					vm.contract = result.contract;
					
					if (vm.contract != null && vm.contract.id != null) {
						vm.success = true;
					} else
						vm.error = true;
				}, function(error) {
					vm.uploadIsInProgress = false;
					vm.error = true;
				});
        	}	
    	}
        
        vm.uploadV2 = function() {
        	vm.isSubmitted = true;
        	
        	if (vm.airline != null && vm.fileUpload.file && vm.fileType == fileType) {
        		vm.contractResult = null;
				vm.uploadIsInProgress = true;
				vm.error = false;
				vm.fileUpload.type = vm.fileType;
				vm.fileUpload.airline = vm.airline.airline_code;
				vm.fileUpload.contractId = vm.contractId;
				
//				console.log(vm.fileUpload);
				
				ContractUploadV2.uploadAndValidate(vm.fileUpload, function(result) {
					vm.uploadIsInProgress = false;
					
					vm.contractResult = result;
					console.log(vm.contractResult);
					
//					vm.message = result.message;
//					vm.contract = result.contract;
//					vm.responses = result.responses;
//					
//					console.log(vm.responses);
					
//					if (vm.contract != null && vm.contract.id != null) {
//						vm.success = true;
//					} else
//						vm.error = true;
				}, function(error) {
					vm.uploadIsInProgress = false;
					vm.error = true;
				});
        	}	
    	}
        
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
     
    	function b64toBlob(b64Data, contentType, sliceSize) {
			  contentType = contentType || '';
			  sliceSize = sliceSize || 512;
	
			  var byteCharacters = atob(b64Data);
			  var byteArrays = [];
	
			  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			    var slice = byteCharacters.slice(offset, offset + sliceSize);
	
			    var byteNumbers = new Array(slice.length);
			    for (var i = 0; i < slice.length; i++) {
			      byteNumbers[i] = slice.charCodeAt(i);
			    }
	
			    var byteArray = new Uint8Array(byteNumbers);
	
			    byteArrays.push(byteArray);
			  }
	
			  var blob = new Blob(byteArrays, {type: contentType});
			  
			  return blob;
		}
    	
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();