(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('StayMeasurementFromMaxAPF', StayMeasurementFromMaxAPF);

    StayMeasurementFromMaxAPF.$inject = ['$resource'];

    function StayMeasurementFromMaxAPF ($resource) {
        var resourceUrl =  'api/stay-measurement-from-max-apfs/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
    
    angular
    .module('americanoApp')
    .factory('StayMeasurementFromMaxAPFCustom', StayMeasurementFromMaxAPFCustom);

    StayMeasurementFromMaxAPFCustom.$inject = ['$resource'];
	
	function StayMeasurementFromMaxAPFCustom ($resource) {
	    var resourceUrl =  'api/stay-measurement-from-max-apfs/getAllStayMeasurementAPF';
	
	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}
	
	angular
	.module('americanoApp')
    .factory('StayMeasurementMaxAPFSearch', StayMeasurementMaxAPFSearch);

	StayMeasurementMaxAPFSearch.$inject = ['$resource'];

	function StayMeasurementMaxAPFSearch($resource) {
		var resourceUrl = 'api/_search/stay-measurement-max-apf/:query'; 
		return $resource(resourceUrl, {}, {
        	'query': { method: 'GET', isArray: true}
    	});
	}
})();
