(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('StayMeasurementFromSabreDialogController', StayMeasurementFromSabreDialogController);

    StayMeasurementFromSabreDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'StayMeasurementFromSabre'];

    function StayMeasurementFromSabreDialogController ($scope, $stateParams, $uibModalInstance, entity, StayMeasurementFromSabre) {
        var vm = this;
        vm.stay_measurement_from_sabre = entity;
        vm.load = function(id) {
        	StayMeasurementFromSabre.get({id : id}, function(result) {
                vm.stay_measurement_from_sabre = result;
            });
        };

        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:stay_measurement_from_sabreUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            if (vm.stay_measurement_from_sabre.id !== null) {
            	StayMeasurementFromSabre.update(vm.stay_measurement_from_sabre, onSaveSuccess, onSaveError);
            } else {
            	StayMeasurementFromSabre.save(vm.stay_measurement_from_sabre, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
