(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('AddOnDeleteController',AddOnDeleteController);

    AddOnDeleteController.$inject = ['$uibModalInstance', 'entity', 'AddOn'];

    function AddOnDeleteController($uibModalInstance, entity, AddOn) {
        var vm = this;
        vm.addOn = entity;
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function (id) {
        	AddOn.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
