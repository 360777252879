 (function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('field-page', {
            parent: 'entity',
            url: '/field-page?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.fieldPage.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/field-page/field-pages.html',
                    controller: 'FieldPageController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('fieldPage');
                    $translatePartialLoader.addPart('enumDataType');
                    $translatePartialLoader.addPart('enumYesNo');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }],
                fieldPages: ['FieldPage', function(FieldPage) {
                	return FieldPage.query();
                }]
            }
        })
       
        .state('field-page-register-field', {
            parent: 'field-page',
            url: '/register-field/{id}/{tag}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.fieldPage.manage.title'
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/field-page/field-page-dialog-register.html',
                    controller: 'FieldPageDialogRegisterController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    }
                }).result.then(function() {
                    $state.go('field-page', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('field-page-manage', {
        	parent: 'field-page',
        	url: '/manage/{id}/{tag}?page&sort&search',
        	data: {
        		authorities: ['ROLE_USER'],
        		pageTitle: 'americanoApp.fieldPage.manage.title'
        	},
        	views: {
        		'content@': {
        			templateUrl: 'app/entities/field-page/field-page-manage.html',
        			controller: 'FieldPageManageController',
        			controllerAs: 'vm'
        		}
        	},
        	resolve: {
        		formPage: ['$stateParams', 'FormPage', function($stateParams, FormPage) {
        			return FormPage.get({id : $stateParams.id});
        		}],
            	pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
        		translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
        			$translatePartialLoader.addPart('fieldPage');
        			$translatePartialLoader.addPart('enumDataType');
        			$translatePartialLoader.addPart('enumYesNo');
        			return $translate.refresh();
        		}]
        	}
        })
        
        .state('field-page-manage.new', {
            parent: 'field-page-manage',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/field-page/field-page-dialog.html',
                    controller: 'FieldPageDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	entity: ['FieldPageTag', function(FieldPageTag) {
        					return FieldPageTag.get({tag : $stateParams.tag});
        				}],
                    }
                }).result.then(function() {
                    $state.go('field-page-manage', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('field-page.edit', {
        	parent: 'field-page-manage',
        	url: '/{idx}/edit',
        	data: {
        		authorities: ['ROLE_USER']
        	},
        	onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
        		$uibModal.open({
        			templateUrl: 'app/entities/field-page/field-page-dialog.html',
        			controller: 'FieldPageDialogController',
        			controllerAs: 'vm',
        			backdrop: 'static',
        			size: 'lg',
        			resolve: {
        				entity: ['FieldPageTag', function(FieldPageTag) {
        					return FieldPageTag.get({tag : $stateParams.tag});
        				}],
        			}
        		}).result.then(function() {
        			$state.go('field-page-manage', null, { reload: true });
        		}, function() {
        			$state.go('^');
        		});
        	}]
        })
        .state('field-page.delete', {
            parent: 'field-page-manage',
            url: '/{idx}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/field-page/field-page-delete-dialog.html',
                    controller: 'FieldPageDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['FieldPageTag', function(FieldPageTag) {
                            return FieldPageTag.get({tag : $stateParams.tag});
                        }]
                    }
                }).result.then(function() {
                    $state.go('field-page-manage', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        ;
    }

})();
