(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('DistributionGroupDetailController', DistributionGroupDetailController);

    DistributionGroupDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'DistributionGroup'];

    function DistributionGroupDetailController($scope, $rootScope, $stateParams, previousState, entity, DistributionGroup) {
        var vm = this;

        vm.distributionGroup = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('americanoApp:distributionGroupUpdate', function(event, result) {
            vm.distributionGroup = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
