(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('DiscountController', DiscountController);

    DiscountController.$inject = [
    '$scope', '$parse', '$state', '$stateParams', '$uibModal',
    'ContractDiscount',
    'currencies',
    'passenger_type',
    'discount_passenger_type', 'form15', 'form16', 'form17', 'form18', 'ContractValue', '$uibModalInstance'];

    function DiscountController (
    		$scope, $parse, $state, $stateParams, $uibModal,
    		ContractDiscount,
    		currencies,
    		passenger_type,
    		discount_passenger_type, form15, form16, form17, form18, ContractValue, $uibModalInstance) {

    	var vm = this;
    	vm.contractValue = ContractValue;
    	vm.form15 = form15;
    	vm.form16 = form16;
    	vm.form17 = form17;
    	vm.form18 = form18;
    	vm.currencies = currencies;
    	vm.entity = ContractDiscount.get({ id : $stateParams.id_contract });
		vm.id_contract = $stateParams.id_contract;
		vm.original_discount = discount_passenger_type;
		vm.passenger_type = passenger_type;
		vm.index = $stateParams.index;

		vm.entity.$promise.then(function(contract) {
    		vm.discountRules = contract.data[9].pages;

    		if (vm.index < vm.discountRules.length)
    			vm.editDiscount(vm.discountRules[vm.index]);
    	});

		//DISCOUNT
		vm.addDiscount = function(){
			vm.entity.data[9].pages.push({
				forms:[
					angular.copy(vm.form15),
					angular.copy(vm.form16),
					angular.copy(vm.form17),
					angular.copy(vm.form18)
				]
			});
		};

		vm.editDiscount= function(x){
			 vm.form15.$promise.then(function() {
                 ContractValue.setValueFromDatabase(x.forms[0], vm.form15);
             });

			 vm.form16.$promise.then(function() {
                 ContractValue.setValueFromDatabase(x.forms[1], vm.form16);

                 vm.form16.form_items.forEach(function(item) {
             		if (item.tag == "rules_discounts_primary_passenger_type") {
             			vm.updateDiscount(item.value);
             		}
             	});
             });

			 vm.form17.$promise.then(function() {
                 ContractValue.setValueFromDatabase(x.forms[2], vm.form17);
             });

			 vm.form18.$promise.then(function() {
                 ContractValue.setValueFromDatabase(x.forms[3], vm.form18);

             	vm.form18.form_items.forEach(function(item) {
             		if (item.tag == "rules_discounts_4_primary_passenger_type") {
             			vm.updateDiscount2(item.value);
             		}
             	});
             });

            // vm.form15 = angular.copy(x.forms[0]);
			// vm.form16 = angular.copy(x.forms[1]);
			// vm.form17 = angular.copy(x.forms[2]);
			// vm.form18 = angular.copy(x.forms[3]);

			vm.editObjectDiscount = x;
			vm.isDiscountEdit = true;
		};

		vm.saveDiscountEdit = function(){
			vm.entity.data[9].pages[vm.entity.data[9].pages.indexOf(vm.editObjectDiscount)].forms[0] = angular.copy(vm.form15);
			vm.entity.data[9].pages[vm.entity.data[9].pages.indexOf(vm.editObjectDiscount)].forms[1] = angular.copy(vm.form16);
			vm.entity.data[9].pages[vm.entity.data[9].pages.indexOf(vm.editObjectDiscount)].forms[2] = angular.copy(vm.form17);
			vm.entity.data[9].pages[vm.entity.data[9].pages.indexOf(vm.editObjectDiscount)].forms[3] = angular.copy(vm.form18);


			vm.editObjectDiscount= null;
			vm.isDiscountEdit = false;
		};
		//END DISCOUNT

		vm.Date = function(arg){
	       	if(arg == null){
	         	   return new Date();
	       	}else{
	       		if(arg != "indef"){
	       			return new Date(arg);
	       		}else{
	       			return "indef";
	       		}
	       	}
	    	};

     	vm.save = function(){
            var forms = [vm.form15, vm.form16, vm.form17, vm.form18];

            if (vm.isDiscountEdit){
                console.log("UPDATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract,
                    index: vm.index
                };

                ContractDiscount.update(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            else{
                console.log("CREATE");
                var newData = {
                    forms: forms,
                    contractId: $stateParams.id_contract
                };

                ContractDiscount.save(newData, function (result) {
                    $uibModalInstance.close(result);
                }, function (error) {
                    console.log(error);
                    alert(error.data.headers['x-americanoapp-alert']);
                });
            }
            // if (vm.isDiscountEdit)
				// vm.saveDiscountEdit();
            // else
				// vm.addDiscount();
            //
            // ContractDiscount.update(vm.entity, function(result){
				// $uibModalInstance.close(result);
	    	// 	console.log("Save sukses");
	    	// }, function(error){
	    	// 	console.log("Save error ");
	    	// 	console.log(error);
	    	// });
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.opened = [];
        $scope.openDatePicker = function($event, index) {
            $event.preventDefault();
            $event.stopPropagation();

            $scope.opened[index] = true;
        };

        vm.findFormItemByTag = function(form, tag){
        	var val = null;
        	var found = false;
        	form.form_items.forEach(function(data){
    		if(data.tag == tag && !found){
        			val = data;
        			found = true;
        		}
        	});
        	return val;
        };

        vm.updateDiscount = function(value) {
        	var temp = [];

        	for (var i = 0; i < vm.original_discount.length; i++) {
        		if (vm.original_discount[i].passengerType.code == value.code) {
        			temp.push(vm.original_discount[i]);
        		}
        	}

        	vm.discount_passenger_type = temp;
        }

        vm.updateDiscount2 = function(value) {
        	var temp = [];

        	for (var i = 0; i < vm.original_discount.length; i++) {
        		if (vm.original_discount[i].passengerType.code == value.code) {
        			temp.push(vm.original_discount[i]);
        		}
        	}

        	vm.discount_passenger_type2 = temp;
        }
    }
})();
