(function() {
    'use strict';

    angular
        .module('americanoApp')
        .factory('stateHandler', stateHandler);

    stateHandler.$inject = ['$rootScope', '$state', '$translate', 'JhiLanguageService', 'translationHandler',
        'Auth', 'Principal', 'ENV', 'VERSION', 'Contract', '$location'];

    function stateHandler($rootScope, $state, $translate, JhiLanguageService, translationHandler,
        Auth, Principal, ENV, VERSION, Contract) {
        return {
            initialize: initialize
        };

        function initialize() {
            $rootScope.ENV = ENV;
            $rootScope.VERSION = VERSION;
            $rootScope.back = back;
            $rootScope.isAuthenticated = Principal.isAuthenticated;
            $rootScope.showAllContractTabs = true;
            $rootScope.rulesRoute = [
                     "contract.rules",
	                 "contract.rules.new",
	                 "contract.rules.edit",
	                 "contract.rule-application",
	                 "contract.rule-applications",
	                 "contract.rule-application.delete",
	                 "contract.flight-application",
	                 "contract.flight-applications",
	                 "contract.flight-application.copy",
	                 "contract.flight-application.delete",
	                 "contract.advance-reservation",
	                 "contract.advance-reservations",
	                 "contract.advance-reservation.copy",
	                 "contract.advance-reservation.delete",
	                 "contract.blackout",
	                 "contract.blackouts",
	                 "contract.blackout.copy",
	                 "contract.blackout.delete",
	                 "contract.surcharge",
	                 "contract.surcharges",
	                 "contract.surcharge.copy",
	                 "contract.surcharge.delete",
	                 "contract.discount",
	                 "contract.discounts",
	                 "contract.discount.copy",
	                 "contract.discount.delete",
	                 "contract.season",
	                 "contract.seasons",
	                 "contract.season.copy",
	                 "contract.season.delete",
	                 "contract.endorsement",
	                 "contract.endorsements",
	                 "contract.endorsement.copy",
	                 "contract.endorsement.delete",
	                 "contract.minimum-stays",
	                 "contract.minimum-stay",
	                 "contract.minimum-stay.copy",
	                 "contract.minimum-stay.delete",
	                 "contract.maximum-stays",
	                 "contract.maximum-stay",
	                 "contract.maximum-stay.copy",
	                 "contract.maximum-stay.delete",
	                 "contract.day-times",
	                 "contract.day-time",
	                 "contract.day-time.copy",
	                 "contract.day-times.delete",
	                 "contract.combinations",
	                 "contract.combination",
	                 "contract.combinations.copy",
	                 "contract.combinations.delete",
	                 "contract.eligibilitys",
	                 "contract.eligibility",
	                 "contract.eligibility.copy",
	                 "contract.eligibility.delete",
	                 "contract.sales-restrictions",
	                 "contract.sales-restriction",
	                 "contract.sales-restriction.copy",
	                 "contract.sales-restriction.delete",
	                 "contract.penalties",
	                 "contract.penalty",
	                 "contract.penalty.copy",
	                 "contract.penalty.delete",
	                 "contract.travel-restrictions",
	                 "contract.travel-restriction",
	                 "contract.travel-restriction.copy",
	                 "contract.travel-restriction.delete",
	                 "contract.accompanied-travels",
	                 "contract.accompanied-travel",
	                 "contract.accompanied-travel.copy",
	                 "contract.accompanied-travel.delete",
	                 "contract.transfers",
	                 "contract.transfer",
	                 "contract.transfer.copy",
	                 "contract.transfer.delete",
	                 "contract.stopovers",
	                 "contract.stopover",
	                 "contract.stopover.copy",
	                 "contract.stopover.delete",
                     "contract.voluntarys",
	                 "contract.voluntary",
	                 "contract.voluntary.copy",
	                 "contract.voluntary.delete",
                     "contract.voluntary-refunds",
	                 "contract.voluntary-refund",
	                 "contract.voluntary-refund.copy",
	                 "contract.voluntary-refund.delete",
	                 "contract.groups",
	                 "contract.group",
	                 "contract.group.copy",
	                 "contract.group.delete",
	                 "contract.higher-intermediate-points",
	                 "contract.higher-intermediate-point",
	                 "contract.higher-intermediate-point.delete",
	                 "contract.miscellaneous-fare-tags",
	                 "contract.miscellaneous-fare-tag",
	                 "contract.miscellaneous-fare-tag.delete",
	                 "contract.miscellaneous-fare-tag.copy",
	                 "contract.tour-agents-other-discounts",
	                 "contract.tour-agents-other-discount",
	                 "contract.tour-agents-other-discount.copy",
	                 "contract.tour-agents-other-discount.delete",
	                 "contract.tours",
	                 "contract.tour",
	                 "contract.tour.copy",
	                 "contract.tour.delete"
            ];

            $rootScope.listContractState = [
                 "contract.header",
                 "contract.routing",
                 "contract.routing.new",
                 "contract.routing.edit",
                 "contract.fare",
                 "contract.fare.new",
                 "contract.exception-fare",
                 "contract.exception-fare-dialog",
                 "contract.exception-fare-copy",
                 "contract.distribution",
                 "contract.distribution.new",
                 "contract.distribution.edit",
                 "contract.vendorAggregate",
                 "contract.vendorAggregate.new",
                 "contract.vendorAggregate.edit",
                 "contract.vendor",
                 "contract.vendor.new",
                 "contract.vendor.edit",
                 "contract.gds",
                 "contract.gds-multiple",

                //// calculated
                "contract.fare-calculated",
                "contract.fare-calculated.new",
                "contract.fare-calculated.edit"
            ];


            $rootScope.listContractState = $rootScope.listContractState.concat($rootScope.rulesRoute);

            Principal.identity().then(function(account) {
            	$rootScope.currentAccount = account;
            });

            var stateChangeStart = $rootScope.$on('$stateChangeStart', function (event, toState, toStateParams) {
                $rootScope.toState = toState;
                $rootScope.toStateParams = toStateParams;

                if($rootScope.listContractState.indexOf(toState.name) !== -1) {
                	if (toState.name == "contract.header" && toStateParams.id_contract == "")
                		$rootScope.showAllContractTabs = false;
                	else
                		$rootScope.showAllContractTabs = true;
                }

                if (Principal.isIdentityResolved()) {
                    Auth.authorize();
                }

                // Update the language
                JhiLanguageService.getCurrent().then(function (language) {
                    $translate.use(language);
                });
            });

            $rootScope.selectedGds = [];

            var stateChangeSuccess = $rootScope.$on('$stateChangeSuccess',  function(event, toState, toParams, fromState, fromParams) {
                var titleKey = 'global.title' ;

                if($rootScope.listContractState.indexOf(toState.name) !== -1) {
                 	$rootScope.contractId = toParams.id_contract;
                 	$rootScope.isInContract = true;

                 	Contract.getType({id:$rootScope.contractId}, function(result){
                 	    if(result.contract_type == 'calculated_contract') {
                            $rootScope.isCalculated = true;
                        }else{
                            $rootScope.isCalculated = false;
                        }
                 	    
                 	   if(result.contract_type_security == 'fixed_price_fare_security') {
                           $rootScope.contract_type_security = true;
                       }else{
                           $rootScope.contract_type_security = false;
                       }
                 	    
                    });
                 } else {
                 	$rootScope.contractId = null;
                 	$rootScope.isInContract = false;
                 	$rootScope.selectedGds = [];
                 }

                // Remember previous state unless we've been redirected to login or we've just
                // reset the state memory after logout. If we're redirected to login, our
                // previousState is already set in the authExpiredInterceptor. If we're going
                // to login directly, we don't want to be sent to some previous state anyway
                if (!$rootScope.redirected && $rootScope.previousStateName) {
                    $rootScope.previousStateName = fromState.name;
                    $rootScope.previousStateParams = fromParams;
                }

                // Set the page title key to the one configured in state or use default one
                if (toState.data.pageTitle) {
                    titleKey = toState.data.pageTitle;
                }
                translationHandler.updateTitle(titleKey);

                $('#sidemenu').removeClass('pushmenu-opened');

                if((toParams.id_contract != fromParams.id_contract && fromParams.id_contract != undefined && fromParams.id_contract != "") &&
                   (toParams.id_contract !=  undefined && fromParams.id_contract != undefined) && fromState.name != "contract.copy") {
                   location.reload();
                }
            });

            $rootScope.$on('$destroy', function () {
                if(angular.isDefined(stateChangeStart) && stateChangeStart !== null){
                    stateChangeStart();
                }
                if(angular.isDefined(stateChangeSuccess) && stateChangeSuccess !== null){
                    stateChangeSuccess();
                }
            });

            function back() {
                // If previous state is 'activate' or do not exist go to 'home'
                if ($rootScope.previousStateName === 'activate' || $state.get($rootScope.previousStateName) === null) {
                    $state.go('home');
                } else {
                    $state.go($rootScope.previousStateName, $rootScope.previousStateParams);
                }
            }
        }
    }
})();
