(function() {
    'use strict';

    angular
        .module('americanoApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('master-account', {
            parent: 'entity',
            url: '/master-account?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.masterAccount.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/master-account/master-accounts.html',
                    controller: 'MasterAccountController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('masterAccount');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('master-account-detail', {
            parent: 'entity',
            url: '/master-account/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'americanoApp.masterAccount.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/master-account/master-account-detail.html',
                    controller: 'MasterAccountDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('masterAccount');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'MasterAccount', function($stateParams, MasterAccount) {
                    return MasterAccount.get({id : $stateParams.id});
                }]
            }
        })
        .state('master-account.new', {
            parent: 'master-account',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/master-account/master-account-dialog.html',
                    controller: 'MasterAccountDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                accountName: null,
                                accountDescription: null,
                                teamName: null,
                                pccType: null,
                                pcc: null,
                                id: null
                            };
                        },

                        translatePartialLoader : ['$translate', '$translatePartialLoader', function($translate, $translatePartialLoader) {
                            $translatePartialLoader.addPart('distribution');
                            return $translate.refresh();
                        }],
                        formVendorSetDefaultAssignPermission : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_vendor_set_default_assign_permission"
                            });
                        }],
                        formDistributionSpecificTicketDetailPlateStock : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_specific_ticket_detail_plate_stock"
                            });
                        }],
                        formDistributionSpecificTicketDetailTicketingSegment : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_specific_ticket_detail_ticketing_segment"
                            });
                        }],
                        formDistributionSpecificTicketDetailCommision : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_specific_ticket_detail_commision"
                            });
                        }],
                        formDistributionSpecificTicketDetailBaggage : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_specific_ticket_detail_baggage"
                            });
                        }],
                        formDistributionFareCreator : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_fare_creator"
                            });
                        }],
                        formDistributionFareCreatorFareCalculation : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_fare_creator_fare_calculation"
                            });
                        }],
                        formDistributionFareBox : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_farebox"
                            });
                        }],
                        formDistributionFareBoxPayment : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_payment"
                            });
                        }],
                        formDistributionSpecificTicketDetailPassenger : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "distribution_specific_ticket_detail_passenger"
                            });
                        }],
                        formDistributionTextInformation : ['$stateParams', 'FormContract', function($stateParams, FormContract) {
                            return FormContract.get({
                                tag : "	distribution_text_information"
                            });
                        }],
                        passengers : [ 'PassengerCustom',function(PassengerCustom) {
                            return PassengerCustom.get();
                        }],
                        airlines : ['AirlineCustom', function(AirlineCustom) {
                            return AirlineCustom.get();
                        }],
                        Currencies : ['CurrencyCustom', function(CurrencyCustom) {
                            return CurrencyCustom.get();
                        }]
                    }
                }).result.then(function() {
                    $state.go('master-account', null, { reload: true });
                }, function() {
                    $state.go('master-account');
                });
            }]
        })
        .state('master-account.edit', {
            parent: 'master-account',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/master-account/master-account-dialog.html',
                    controller: 'MasterAccountDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['MasterAccount', function(MasterAccount) {
                            return MasterAccount.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('master-account', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('master-account.delete', {
            parent: 'master-account',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/master-account/master-account-delete-dialog.html',
                    controller: 'MasterAccountDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['MasterAccount', function(MasterAccount) {
                            return MasterAccount.get({id : $stateParams.id});
                        }]
                    }
                }).result.then(function() {
                    $state.go('master-account', null, { reload: true });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
