(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('ContractUploaderExcelTemplateDialogController', ContractUploaderExcelTemplateDialogController);

    ContractUploaderExcelTemplateDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'ContractUploaderExcelTemplate', 'formTree'];

    function ContractUploaderExcelTemplateDialogController ($scope, $stateParams, $uibModalInstance, entity, ContractUploaderExcelTemplate, formTree) {
        var vm = this;
        vm.template = entity;
        vm.formTree = formTree;
        
        console.log(vm.formTree);
        
        vm.positions = ["UP", "DOWN", "LEFT", "RIGHT", "SELF"];
//        vm.masters = ["", "CITY", "PASSENGER TYPE", "CURRENCY", "SEASON", "FARE TYPE"];
        
        var onSaveSuccess = function (result) {
            $scope.$emit('americanoApp:templateUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            
            if (vm.atr.id !== null) {
            	ContractUploaderExcelTemplate.update(vm.template, onSaveSuccess, onSaveError);
            } else {
            	ContractUploaderExcelTemplate.save(vm.template, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
