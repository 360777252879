(function(){
    'use strict';

    angular
        .module('americanoApp')
        .factory('ContractValue', ContractValue);

    function ContractValue () {

        var service = {
            findFormItemByTag : findFormItemByTag,
            setValueFromDatabase:setValueFromDatabase,
            getValue:getValue
        };

        return service;

        function findFormItemByTag (contract, type, tag, pages) {
            var val = null;
            var found = false;

            if(type == "contract_header"){
            	if (contract.data != undefined) {
            		var contractHeader = contract.data[0];
                    var forms = contractHeader.pages[0].forms;
                    forms.forEach(function(data){
                        data.form_items.forEach(function(data2){
                            if(data2.tag == tag && !found){
                                val = data2;
                                found = true;
                            }
                        });
                    });
            	}
            }
            else if(type == "routing"){
                var routing = contract.data[2];
                if(routing.pages[0] != undefined){
                    var forms = routing.pages[0].forms;
                    forms.forEach(function(data){
                        data.form_items.forEach(function(data2){
                            if(data2.tag == tag && !found){
                                val = data2;
                                found = true;
                            }
                        });
                    });
                }
            }
            else if(type == "fare"){
                var fare = contract.data[1];
                if(fare.pages[0] != undefined){
                    var forms = fare.pages[0].forms;
                    forms.forEach(function(data){
                        data.form_items.forEach(function(data2){
                            if(data2.tag == tag && !found) {
                                val = data2;
                                found = true;
                            }
                        });
                    });
                }
            }
            else if(type == "distribution"){
                var distribution = contract.data[3];
                if(distribution.pages[0] != undefined) {
                    var forms = distribution.pages[0].forms;
                    forms.forEach(function(data) {
                        if(data.form_items != null){
                            data.form_items.forEach(function(data2) {
                                if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                            });
                        }
                    });
                }
            }
            else if(type == "rule_application"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {
                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });


            }
            else if(type == "flight_application"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });

            }
            else if(type == "advance_reservation"){
                var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {
                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }
            else if(type == "blackout"){
            	 var forms = pages.forms;
                 forms.forEach(function(data) {
                     if(data.form_items != null){
                         data.form_items.forEach(function(data2) {
                         	if(tag.split(".").length > 1){
                         		var baseFormTag = tag.split(".")[0];
                         		var formItemTag = tag.split(".")[1];
                         		if(data2.tag == baseFormTag && !found) {

                         			data2.value.forEach(function(data3) {
                         				data3.forEach(function(data4){
                         					if(data4.tag == formItemTag){
                                 			}
                         				});
                             		});
                                 }
                         	}
                         	else{
                         		if(data2.tag == tag && !found ) {
                                     val = data2;
                                     found = true;
                                 }
                         	}
                         });
                     }
                 });
            }
            else if(type == "surcharge"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }
            else if(type == "discount"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }
            else if(type == "season"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }
            else if(type == "minimum_stay"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }
            else if(type == "maximum_stay"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }
            else if(type == "endorsement"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }
            else if(type == "day_time"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }
            else if(type == "eligibility"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }
            else if(type == "combination"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }else if(type == "sales_restriction"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }else if(type == "accompanied_travel"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }else if(type == "transfer"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }else if(type == "hip"){
            	var forms = pages.forms;
                forms.forEach(function(data) {
                    if(data.form_items != null){
                        data.form_items.forEach(function(data2) {
                        	if(tag.split(".").length > 1){
                        		var baseFormTag = tag.split(".")[0];
                        		var formItemTag = tag.split(".")[1];
                        		if(data2.tag == baseFormTag && !found) {

                        			data2.value.forEach(function(data3) {
                        				data3.forEach(function(data4){
                        					if(data4.tag == formItemTag){
                                			}
                        				});
                            		});
                                }
                        	}
                        	else{
                        		if(data2.tag == tag && !found ) {
                                    val = data2;
                                    found = true;
                                }
                        	}
                        });
                    }
                });
            }

            return val;
        }

        function getValue(forms, tag){
        	if (forms != undefined && forms.form_items != undefined) {
        		for(var x=0;x<forms.form_items.length;x++){
                    if(forms.form_items[x].tag == tag){
                        return forms.form_items[x].value;
                    }
                }
        	}
            
            return null;
        };

        function getOptions(forms, tag){
        	if (forms != undefined && forms.form_items != undefined) {
        		for(var x=0;x<forms.form_items.length;x++){
                    if(forms.form_items[x].tag == tag){
                        return forms.form_items[x].options;
                    }
                }
        	}
            
            return null;
        };

        function getMultivalueOptions(forms, tag, child_tag){
        	if (forms != undefined && forms.form_items != undefined) {
        		for(var x=0;x<forms.form_items.length;x++){
                    if(forms.form_items[x].tag == tag){
                        for(var y=0;y<forms.form_items[x].form_items_child.length;y++){
                            if (forms.form_items[x].form_items_child[y].tag == child_tag)
                                return forms.form_items[x].form_items_child[y].options;
                        }
                        // return forms.form_items[x].options;
                    }
                }
        	}
        	
            return null;
        };

        function getMultivalueData(forms, tag, child_tag){
        	if (forms != undefined && forms.form_items != undefined) {
        		for(var x=0;x<forms.form_items.length;x++){
                    if(forms.form_items[x].tag == tag){
                        for(var y=0;y<forms.form_items[x].form_items_child.length;y++){
                            if (forms.form_items[x].form_items_child[y].tag == child_tag)
                                return forms.form_items[x].form_items_child[y].data;
                        }
                        // return forms.form_items[x].options;
                    }
                }
        	}
            
            return null;
        };

        function setValueFromDatabase(formDatabase, formClient){
            angular.forEach(formClient.form_items, function(formItemClient){
                if(formItemClient.type == "date"){
                    var value = getValue(formDatabase, formItemClient.tag);
                    if(value != "indef" && value != null) {
                        formItemClient.value = new Date(getValue(formDatabase, formItemClient.tag));
                    }
                    else if(value == "indef"){
                        formItemClient.value = "indef";
                    }
                    else{
                        formItemClient.value = null;
                    }
                }

                else{
                    formItemClient.value = getValue(formDatabase, formItemClient.tag);


                    if(formItemClient.type == "multivalue"){
                        if(formItemClient.value != null) {


                            for (var i = 0; i < formItemClient.value.length; i++) {
                                var multivalueValue = formItemClient.value[i];

                                for (var j = 0; j < multivalueValue.length; j++) {
                                    var formItemChild = multivalueValue[j];
                                    formItemChild.options = getMultivalueOptions(formClient, formItemClient.tag, formItemChild.tag);
                                    formItemChild.data = getMultivalueData(formClient, formItemClient.tag, formItemChild.tag);
                                }
                            }

                        }
                    }
                }

                formItemClient.errorAPF = getError(formDatabase, formItemClient.tag, "APF");
                formItemClient.errorAmadeus = getError(formDatabase, formItemClient.tag, "Amadeus");
                formItemClient.errorSabre = getError(formDatabase, formItemClient.tag, "Sabre");
                formItemClient.errors = getError(formDatabase, formItemClient.tag, "Errors");
                formItemClient.fareSheetErrors = getError(formDatabase, formItemClient.tag, "fareSheetErrors");
            });
        }

        function getError(forms, tag, gds){
        	if (forms != undefined && forms.form_items != undefined) {
        		for(var x=0;x<forms.form_items.length;x++){
                    if(forms.form_items[x].tag == tag){
                        if(gds == "APF") 
                            return forms.form_items[x].errorAPF;
                        else if(gds == "Sabre") 
                            return forms.form_items[x].errorSabre;
                        else if(gds == "Amadeus")
                            return forms.form_items[x].errorAmadeus;
                        else if (gds == "Errors") 
                        	return forms.form_items[x].errors; 
                        else if (gds == "fareSheetErrors") 
                        	return forms.form_items[x].fareSheetErrors; 
                    }
                }
        	}
            
            return null;
        };
    }
})();
