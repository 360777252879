(function () {
    'use strict';

    angular
        .module('americanoApp')
        .factory('User', User);

    User.$inject = ['$resource'];

    function User ($resource) {
        var service = $resource('api/users/:login', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'},
            'search':{ method:'GET', isArray: true, url: 'api/_search/user/:query' },
            'getRoles':{ method:'GET', isArray: true, url: 'api/user-roles' },
            'getAccounts':{ method:'GET', isArray: true, url: 'api/master-accounts/getAll' },
        });
        return service;
        }
})();
