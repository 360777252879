(function() {
    'use strict';

    angular
        .module('americanoApp')
        .controller('ContractDeleteController',ContractDeleteController);

    ContractDeleteController.$inject = ['$stateParams', '$uibModalInstance', 'Contract'];

    function ContractDeleteController($stateParams, $uibModalInstance, Contract) {
        var vm = this;
        vm.contractID = $stateParams.id;
        
        console.log(vm.contractID);
        
        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        vm.confirmDelete = function () {
            Contract.delete({id: vm.contractID},
                function () {
                    $uibModalInstance.close(true);
                });
        };
    }
})();
