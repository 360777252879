(function() {
    'use strict';
    angular
        .module('americanoApp')
        .factory('TravelSegIndicatorSabre', TravelSegIndicatorSabre);

    TravelSegIndicatorSabre.$inject = ['$resource'];

    function TravelSegIndicatorSabre ($resource) {
        var resourceUrl =  'api/travel-seg-indicator-sabres/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'update': { method:'PUT' },
            'queryByCat': { method:'GET', isArray : true,  url: 'api/travel-seg-indicator-sabres/queryByCat/:cat'}
        });
    }

    angular
    .module('americanoApp')
    .factory('TravelSegIndicatorSabreCustom', TravelSegIndicatorSabreCustom);

    TravelSegIndicatorSabreCustom.$inject = ['$resource'];

	function TravelSegIndicatorSabreCustom ($resource) {
	    var resourceUrl =  'api/travel-seg-indicator-sabres/getAllTravelSegIndicatorSabre';

	    return $resource(resourceUrl, {}, {
	        'query': { method: 'GET', isArray: true},
	        'get': {
	            method: 'GET',
	            isArray: true,
	            transformResponse: function (data) {
	                data = angular.fromJson(data);
	                return data;
	            }
	        },
	        'update': { method:'PUT' }
	    });
	}

	angular
	.module('americanoApp')
    .factory('TravelSegSabreSearch', TravelSegSabreSearch);

	TravelSegSabreSearch.$inject = ['$resource'];

	function TravelSegSabreSearch($resource) {
		var resourceUrl = 'api/_search/travel-segment-sabre/:query';
		return $resource(resourceUrl, {}, {
			'query': { method: 'GET', isArray: true}
		});
}
})();
